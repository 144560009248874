import List from './list/FlowsList';
import Edit from './edit/FlowEdit';

export const routes = [
	{
		path: '/flows',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'flows',
				path: '',
				component: List
			},
			{
				name: 'flows.add',
				path: 'add',
				component: Edit
			},
			{
				name: 'flows.edit',
				path: 'edit/:id',
				component: Edit
			}
		]
	}
];
