import Vue from 'vue';
import clone from 'lodash/clone';
import R from 'ramda';
import mapValues from 'lodash/mapValues';
import find from 'lodash/find';
import get from 'lodash/get';
import { pathToArray } from '../../lib/flowInspectionUtils';
import { sanitizeFlow, reorderNodes } from '../../lib/flowSanitisationUtils';
import defaultFlow from '../../lib/defaultFlow';

export const NEW_FLOW = state => {
	state.flow = clone(defaultFlow);
};

export const OPEN_RUNFLOW_MODAL = (state, { runConfiguration, flow }) => {
	state.flow = flow;
	state.runFlowModal = {
		...state.runFlowModal,
		isOpen: true,
		flow,
		runConfiguration
	};
};

export const OPEN_EDIT_INFO_FLOW_MODAL = (state, data = {}) => {
	state.editFlowInfoModal = {
		...data,
		isOpen: true
	};
};

export const CLOSE_EDIT_INFO_FLOW_MODAL = state => {
	state.editFlowInfoModal = {
		isOpen: false
	};
};

export const OPEN_IMPORT_SITEFLOW_ATTRIBUTES_MODAL = (state, data = {}) => {
	state.importSiteflowAttributesModal = {
		...data,
		isOpen: true
	};
};

export const CLOSE_IMPORT_SITEFLOW_ATTRIBUTES_MODAL = state => {
	state.importSiteflowAttributesModal = {
		isOpen: false
	};
};

export const CLOSE_RUNFLOW_MODAL = state => {
	state.runFlowModal = {
		...state.runFlowModal,
		isOpen: false,
		flow: null,
		runConfiguration: null
	};
};

export const NEW_NODES = (state, { nodes }) => {
	state.flow.nodes = nodes;
	// state.flow.nodes = nodes.sort((a, b) => a.id - b.id);
	state.flow = { ...reorderNodes(state.flow) };
};

export const SELECT_NODE = (state, { name }) => {
	state.selectedNodeName = name;
};

export const DESELECT_NODE = state => {
	state.selectedNode = null;
};

export const SET_NODE_INPUT_VALUE = ({ flow }, { node, inputKeyPath, value, options = {} }) => {
	let newNode;
	if (options.replace) {
		const [inputKey] = inputKeyPath.split('.');
		newNode = R.assocPath(pathToArray(`inputs.${inputKey}.values`), [value])(node);
	} else {
		newNode = R.assocPath(pathToArray(`inputs.${inputKeyPath}`), value)(node);
	}

	const index = R.findIndex(R.propEq('id', newNode.id))(flow.nodes);
	flow.nodes = R.update(index, newNode, flow.nodes);
};

export const SET_NODE_INPUTS_VALUE = ({ flow }, { node, inputs, options = {} }) => {
	let newNode = node;
	if (options.replace) {
		inputs.forEach(input => {
			const [inputKey] = input.inputKeyPath.split('.');
			newNode = R.assocPath(pathToArray(`inputs.${inputKey}.values`), [input.value])(newNode);
		});
	} else {
		inputs.forEach(input => {
			newNode = R.assocPath(pathToArray(`inputs.${input.inputKeyPath}`), input.value)(newNode);
		});
	}

	const index = R.findIndex(R.propEq('id', newNode.id))(flow.nodes);
	flow.nodes = R.update(index, newNode, flow.nodes);
};

export const CLEAR_NODE_INPUT_VALUE = (state, { node, inputKey }) => {
	node.inputs[inputKey] = {};
	state.flow = { ...state.flow };
};

export const FLOW_HAS_CHANGES = (state, flowHasChanges) => (state.flowHasChanges = flowHasChanges);

export const OBSERVE_FLOW_RECEIVE = (state, { data: item, options = {} }) => {
	const mountPath = options.mountPath || 'flow';
	Vue.set(state, mountPath, sanitizeFlow(item));
};

export const OBSERVE_FLOWS_RECEIVE = (state, { data: items, options = {} }) => {
	const mountPath = options.mountPath || 'flows';
	const sanitizedItems = items.map(sanitizeFlow);
	Vue.set(state, mountPath, sanitizedItems);
};

export function SELECT_NODE_INPUT_KEY_FOR_LINKING(state, selectedKey) {
	state.selectedNodeInputKey = selectedKey;
}

export function SELECT_OUTPUT_KEY_INDEX(state, selectedOutputKeyIndex) {
	state.selectedOutputKeyIndex = selectedOutputKeyIndex;
}

export const ADD_START_NODE_INPUT = ({ flow }) => {
	const index = R.findIndex(R.propEq('type', 'input'))(flow.nodes);
	const startNode = flow.nodes[index];
	const newStartNode = R.assoc(
		'inputs',
		R.append(
			{
				key: '',
				value: '',
				type: ''
			},
			startNode.inputs
		)
	)(startNode);

	flow.nodes = R.update(index, newStartNode, flow.nodes);
};

export const REMOVE_START_NODE_INPUT = ({ flow }, index) => {
	const nodeIndex = R.findIndex(R.propEq('type', 'input'))(flow.nodes);
	const startNode = flow.nodes[nodeIndex];
	const inputToRemove = (startNode.inputs || [])[index] || {};
	const newStartNode = R.assoc('inputs', R.remove(index, 1, startNode.inputs))(startNode);

	const removeInput = R.update(nodeIndex, newStartNode);
	const removeLinksToInput = R.map(
		R.ifElse(
			R.or(R.propEq('type', 'input'), R.propEq('type', 'end')),
			R.identity,
			R.over(
				R.lensProp('inputs'),
				R.mapObjIndexed(
					R.over(
						R.lensProp('values'),
						R.reject(
							R.whereEq({
								nodeName: 'inputs',
								type: 'ref',
								field: inputToRemove.key
							})
						)
					)
				)
			)
		)
	);

	flow.nodes = R.compose(removeLinksToInput, removeInput)(flow.nodes);
};

export const IS_ADD_INPUTS_TO_START_NODE_MODAL_VISIBLE = (state, value) => {
	state.isAddInputsToStartNodeModalVisible = value;
};

export const SET_EPM_PRESETS = (state, presets) => {
	state.epmPresets = presets;
};

export const SET_EPM_PROFILES = (state, value) => {
	state.epmCustomProfiles = value;
};

export const SET_ARE_PROFILES_FETCHED = state => {
	state.areProfilesFetched = true;
};

export const NULLIFY_PROFILE_ID = state => {
	const epmProfileNode = find(state.flow.nodes, { name: state.selectedNodeName });
	epmProfileNode.inputs = {
		...epmProfileNode.inputs,
		profileId: {
			values: [{ value: null }]
		}
	};
};

export const LOAD_VALUES_FROM_EPM_PROFILE = (state, selectedEPMProfile) => {
	const epmProfileNode = find(state.flow.nodes, { name: state.selectedNodeName });

	if (!selectedEPMProfile) {
		return;
	}

	// mapping inputKey: profileKey
	const mapping = {
		blackMinSizeToQualifyMm: 'profile.blackChecks.checkElements.minSizeToQualifyMm',
		blackSmallestBlackLineMm: 'profile.blackChecks.checkElements.smallestBlackLineMm',
		blacksmallestBlackTextPt: 'profile.blackChecks.checkElements.smallestBlackTextPt',
		deltaCMaxBlobSizeMm: 'profile.colourChecks.checkDeltaC.maxBlobSizeMm',
		deltaCThreshold: 'profile.colourChecks.checkDeltaC.threshold',
		deltaEMaxBlobSizeMm: 'profile.colourChecks.checkDeltaE.maxBlobSizeMm',
		deltaEThreshold: 'profile.colourChecks.checkDeltaE.threshold',
		pagesMaxBlackPagesPercent: 'profile.blackChecks.checkPages.maxBlackPagesPercent',
		spotColoursInkList: 'profile.colourChecks.checkSpotColours.inkList',
		spotColoursMethod: 'profile.colourChecks.checkSpotColours.method',
		disabledBlackChecks: 'disabledOptions.blackChecks',
		disabledCheckElements: 'disabledOptions.checkElements',
		disabledCheckPages: 'disabledOptions.checkPages',
		disabledColourChecks: 'disabledOptions.colourChecks',
		disabledCheckSpotColours: 'disabledOptions.checkSpotColours',
		disabledCheckDeltaC: 'disabledOptions.checkDeltaC',
		disabledCheckDeltaE: 'disabledOptions.checkDeltaE',
		profileId: '_id'
	};

	epmProfileNode.inputs = _.reduce(
		mapping,
		(acc, profileKey, inputKey) => {
			const value = _.get(selectedEPMProfile, profileKey);
			return _.set(acc, `${inputKey}.values[0].value`, value);
		},
		{ ...epmProfileNode.inputs }
	);
};
