import getActions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default function(store) {
	const state = {
		modals: {}
	};
	const actions = getActions(store);
	const confirmModalModule = { state, actions, mutations, getters, store };
	store.registerModule('confirmModals', confirmModalModule);
}
