import Preview from './Preview';

export const routes = [
	{
		path: '/preview',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'preview',
				path: '',
				component: Preview
			}
		]
	}
];
