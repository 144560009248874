<template>
	<article class="Preview">
		<ChartDisplay
			v-if="flow"
			v-bind="{
				flow
			}"
		>
		</ChartDisplay>
	</article>
</template>

<script type="text/javascript">
import ChartDisplay from '../../components/Chart/Chart';

export default {
	components: {
		ChartDisplay
	},
	computed: {
		flow() {
			try {
				return JSON.parse(this.$route.query.flow);
			} catch (err) {
				return null;
			}
		}
	}
};
</script>

<style lang="scss">
.Route-preview {
	printos-header {
		display: none;
	}
}
</style>
