<template>
	<DefaultLayout>
		<div>
			<div class="WorkqueueListLayout">
				<list-layout
					:resource="'workqueue'"
					:config="{ defaultSort: 'createdAt DESC' }"
					:table-config="workqueuesTableConfig"
					:boxes-config="workqueuesBoxesConfig"
					:filters-config="workqueuesFiltersConfig"
				/>
			</div>
		</div>
	</DefaultLayout>
</template>

<script type="text/javascript">
import _get from 'lodash/get';
import moment from 'moment';
import Tabs from '../../../components/Tabs.vue';
import Tab from '../../../components/Tab.vue';
import ListLayout from '../../../components/ListLayout.vue';
import DefaultLayout from '../../../components/DefaultLayout.vue';

export default {
	components: {
		DefaultLayout,
		Tab,
		Tabs,
		ListLayout
	},
	props: [],
	data() {
		const workqueuesTableConfig = {
			isRowClickable: true,
			baseUrl: 'activity/view',
			columns: [
				{
					title: 'Name',
					field: 'name',
					isSortable: true
				},
				{
					title: 'Created',
					field: 'createdAt',
					type: 'html',
					isSortable: true,
					getHtml(item) {
						if (!item.createdAt) return;
						return moment(item.createdAt).format('H:mm:ss, MMMM Do YYYY');
					}
				},
				{
					title: 'Progress',
					type: 'progress',
					getProgress(item) {
						let type;

						switch (_get(item, 'data.status')) {
						case 'completed':
								type = 'success';
							break;
							case 'failed':
							type = 'danger';
							break;
						}

						const totalTasks = item.flow.nodes.filter(n => n.type === 'task').length;
						let completedTasks = 0;

						(item.tasks || []).forEach(t => {
							if (['ended', 'skipped'].includes(t.status)) {
								completedTasks += 1;
							}
						});

						const amount = Math.ceil((completedTasks * 100) / totalTasks);

						return { type, amount };
					}
				}
			]
		};
		const workqueuesFiltersConfig = [
			{
				name: 'search',
				basePath: 'where',
				key: 'name',
				type: 'search'
			}
		];
		const workqueuesBoxesConfig = {
			titleField: 'flow.name',
			baseLink: '/activity/view'
		};

		return {
			workqueuesTableConfig,
			workqueuesFiltersConfig,
			workqueuesBoxesConfig
		};
	}
};
</script>
<style lang="scss">
.WorkqueueListLayout {
	position: relative;
	.Tabs-panes {
		.Pane {
			/*background: none;
                box-shadow: none;*/
		}
	}
}
</style>
