import Vue from 'vue';
import _trim from 'lodash/trim';
import _get from 'lodash/get';
import _startsWith from 'lodash/startsWith';
import localStorage from '../lib/store2';

const setupMiddleware = store => {
	Vue.$ofsCrud.addMiddleware('pre', (args, next) => {
		const transportName = args.transport && args.transport.name;
		const token = _get(store, 'state.auth.token');
		const accountId = _get(store, 'state.account.currentAccountId');
		args.headers = args.headers || {};

		if (token) {
			args.headers.Authorization = `Bearer ${token}`;
		}

		if (accountId && transportName !== 'printos') {
			args.headers['x-oneflow-accountid'] = accountId;
		}

		// local environments
		const testUserId = localStorage.get('test-userId');
		if (testUserId) {
			args.headers['x-consumer-custom-id'] = testUserId;
		}

		// local environments
		const testAccounts = localStorage.get('test-accounts');
		if (testAccounts) {
			const aclGroupsHeader = _trim(
				testAccounts.split(',').reduce((acc, a) => `${acc},accountId:${a}`, ''),
				','
			);
			args.headers['x-consumer-groups'] = aclGroupsHeader;
		}

		next();
	});

	Vue.$ofsCrud.addMiddleware('post', async result => {
		const skipPostMiddleware =
			result?.requestArgs?.options?.skipPostMiddleware || result?.options?.skipPostMiddleware;
		if (skipPostMiddleware) return;

		if (result.response && [401, 403].includes(result.response.status)) {
			if (_startsWith(result.response.request.responseURL, window.$config.connectApiBase)) {
				return;
			}

			await store.dispatch('auth/doLogout');
		}
	});
};

export default setupMiddleware;
