import { uploadFile as uploadFileService } from '../../lib/uploadUtils';

export const uploadFile = async ({ commit, dispatch }, { input, file }) => {
	const { url, s3Path } = await dispatch('getUploadUrl', { type: file.type });
	const { promise, observable } = uploadFileService(url, file);
	return {
		promise: promise.then(() => ({ url, s3Path })),
		observable
	};
};

export const getUploadUrl = async ({ commit, dispatch }, { type }) => {
	const response = await dispatch('api/request', {
		method: 'GET',
		path: `${window.$config.fileApiBase}/api/files/getuploadurl`,
		query: { type }
	});
	return response.data;
};

export const getDownloadUrl = async ({ commit, dispatch }, { id }) => {
	const response = await dispatch('api/request', {
		method: 'GET',
		path: `${window.$config.fileApiBase}/api/files/${id}/getDownloadUrl`
	});

	return response.data;
};

export const createFilePath = async ({ commit, dispatch }, { file, s3Path }) => {
	const response = await dispatch('api/request', {
		method: 'POST',
		path: `${window.$config.fileApiBase}/api/browse/autoflow`,
		payload: {
			path: {
				name: file.name,
				type: 'file'
			},
			file: {
				name: file.name,
				s3Path,
				size: file.size,
				contentType: file.type
			},
			createParent: true,
			check: true
		}
	});

	return response.data;
};
