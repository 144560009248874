/* eslint-disable prettier/prettier */
import intersectionWith from 'lodash/intersectionWith';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import maxBy from 'lodash/maxBy';
import _get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import R from 'ramda';
import { getNodeParents, getNodeChildren, getNodeType, getNodeInputs, getNodeOutputs } from './flowInspectionUtils';

export function addTaskToFlow({ flow, task, fromId, toId, tasks, options }) {
	let result;
	let newNodes = [];
	let newNode = null;

	switch (task.type) {
	case 'macro':
		newNodes = addMacro({ nodes: flow.nodes, task, fromId, toId, tasks, options });
		break;
	case 'branch':
		// add placeholder 1
		result = addSingleTask(flow.nodes, {
			fromId,
			toId,
			task: {
				name: 'placeholder',
				type: 'placeholder'
			},
			tasks
		});

		newNodes = result.newNodes;
		newNode = result.newNode;

		// add placeholder 2
		result = addSingleTask(
			newNodes,
			{
				fromId,
				toId,
				task: {
					name: 'placeholder',
					type: 'placeholder'
				}
			},
			tasks
		);

		newNodes = result.newNodes;
		newNode = result.newNode;
		break;

	default:
		result = addSingleTask(
			flow.nodes,
			{
				fromId,
				toId,
				task
			},
			tasks
		);
		newNodes = result.newNodes;
		newNode = result.newNode;
		break;
	}

	return { newNodes, newNode };
}

/**
 * This function builds an array of arrays with ids of all possible branches in the graph,
 * so way we can identify the node where the newly added node can be merged into (addedNode.next())
 * @param {*} nodes
 */
function getGraphArrays(nodes) {
	const nodesCopy = cloneDeep(nodes);
	const graphs = [];

	/**
	 * helper function that builds a branch array
	 * @param {*} id
	 */
	function buildFlowPath(id) {
		let results = [];
		const node = nodesCopy.find(n => n.id === id);
		results.push(node.id);

		let nextId;
		if (node.next.length) {
			nextId = node.next[0];
		} else {
			return results;
		}

		const childNodes = buildFlowPath(nextId);
		results = [...results, ...childNodes];

		return results;
	}

	/**
	 * helper function that removes the processed branches from the copy of the graph
	 * @param {*} lastNode
	 * @param {*} flow
	 */
	function removeProcessedBranches(lastNode, flow) {
		const parents = getNodeParents(lastNode, nodesCopy);
		const parent = parents.find(p => flow.includes(p.id));
		if (!parent) {
			return;
		}
		if (parent.next.length > 1) {
			parent.next.shift();
			return;
		}
		removeProcessedBranches(parent, flow);
	}

	while (nodesCopy.some(n => n.next.length > 1)) {
		const flow = buildFlowPath(0);

		const flowNodes = nodesCopy.filter(n => flow.includes(n.id));
		const lastNode = flowNodes.find(n => n.id === 1);
		removeProcessedBranches(lastNode, flow);

		graphs.push(flow);
	}
	const flow = buildFlowPath(0);
	graphs.push(flow);

	return graphs;
}

export function addBranchToFlow({ node: targetNode, task, nodes }) {
	let addedNode = null;
	if (task.type === 'branch') {
		addedNode = createNode({ nodes, task: { type: 'placeholder' } });
	} else {
		addedNode = createNode({ nodes, task: { ...task } });
	}

	const nodesCopy = [...nodes, addedNode];
	const targetParentNode = getNodeParents(targetNode, nodes)[0];
	let nextIndex;

	if (targetNode.id === 0) {
		targetNode.next.push(addedNode.id);
		nextIndex = 1; // targetNode.next[0];
	} else if (targetNode.next.length > 1) {
		// if the target node has multiple children we add the new branch to the target node
		targetNode.next.push(addedNode.id);

		// and find the next merging point
		const graphs = getGraphArrays(nodesCopy);
		const currentNodes = graphs.filter(g => g.includes(targetNode.id)).filter(g => !g.includes(addedNode.id));
		// added node needs to be removed because this point we don't know what to put
		// in addedNode.next() and this could throw an error later
		const idx = currentNodes[0].indexOf(targetNode.id);

		// find the node where the addedNode should be merged into
		// eslint-disable-next-line no-plusplus
		for (let i = idx + 1; i < currentNodes[0].length; i++) {
			const nextNode = currentNodes[0][i];

			if (currentNodes.every(n => n.includes(nextNode))) {
				nextIndex = nextNode;
				break;
			}
		}
	} else {
		// we add the new branch to the parent of the target branch (old logic worked like this)
		targetParentNode.next.push(addedNode.id);
		nextIndex = targetNode.next[0];
	}

	addedNode.next = [nextIndex];
	return nodesCopy;
}

function addMacro({ nodes, task, fromId, toId, tasks, options }) {
	const configuration = task.configuration ? cloneDeep(task.configuration, true) : {};
	const nodesToAdd = configuration.nodes;
	const nodesAdded = [];
	let newNodes = [...nodes];
	const firstNodes = [];
	let lastNodes = [];
	let nodesToLink = [];
	const conditionsToLink = [];

	nodesToAdd.forEach(nodeToAdd => {
		const taskData = nodeToAdd.taskData;
		const taskName = taskData.name;
		const nodeTask = tasks.find(t => t.name === taskName) || {};
		const node = createNode({ nodes: newNodes, task: { ...nodeTask, ...taskData } });
		if (node.condition && node.condition.enabled) {
			node.uninitialized = true;
		}
		newNodes.push(node);
		nodesAdded.push(node);

		if (typeof nodeToAdd.parent === 'number') {
			const parentNode = nodesAdded[nodeToAdd.parent];
			if (parentNode) {
				parentNode.next.push(node.id);
			}
		}

		if (nodeToAdd.automaticLinking) {
			nodesToLink.push(node);
		}

		if (typeof nodeToAdd.linkConditionTo === 'number') {
			conditionsToLink.push({ node, destinationNodeIndex: nodeToAdd.linkConditionTo });
		}

		if (nodeToAdd.isFirstNode) firstNodes.push(node);
		if (nodeToAdd.isLastNode) lastNodes.push(node);
	});

	// Stitching them together
	const firstNodesIds = firstNodes.map(node => node.id);

	const fromNodeIndex = R.findIndex(R.propEq('id', fromId[0]), nodes);
	const fromNode = nodes[fromNodeIndex];
	const removeIdFromNext = [];

	let newFromNode;
	const nextLens = R.lensProp('next');

	if (toId.length > 1) {
		toId.forEach(id => {
			removeIdFromNext.push(node => R.assoc('next', R.reject(R.equals(id), node.next), node)); // R.compose(R.assoc('next'), R.reject(R.equals(id)));
		});
		const appendIdToNext = node => R.assoc('next', R.append(firstNodesIds[0], node.next), node);
		newFromNode = R.compose(
			...removeIdFromNext,
			appendIdToNext
		)(fromNode);
	} else {
		const id = toId[0];
		const nextIndex = R.findIndex(R.equals(id), fromNode.next);
		if (nextIndex > -1) {
			newFromNode = R.over(nextLens, R.update(nextIndex, firstNodesIds[0]), fromNode);
		} else {
			newFromNode = R.over(nextLens, R.append(firstNodesIds[0]), fromNode);
		}
	}
	newNodes = R.update(fromNodeIndex, newFromNode, newNodes);

	lastNodes = lastNodes.map(node => {
		node.next = toId;
		return node;
	});

	// Perform automatic node linking
	nodesToLink = nodesToLink.map(node =>
		performRecursiveLinking({
			node,
			nodes: newNodes,
			tasks
		})
	);

	// Perform automatic conditions linking
	const mapOverConditions = destinationNode => condition => {
		if (typeof condition[0] === 'object' && typeof condition[0].var === 'string' && /{.+}/.test(condition[0].var)) {
			condition[0].var = condition[0].var.replace(/{.+}/, destinationNode.name);
		}

		return condition;
	};

	const mapOverComparisonOperators = destinationNode => comparisonOperator =>
		mapValues(comparisonOperator, mapOverConditions(destinationNode));

	const mapOverLogicalOperators = destinationNode => logicalOperator =>
		logicalOperator.map(mapOverComparisonOperators(destinationNode));

	conditionsToLink.forEach(({ node, destinationNodeIndex }) => {
		const destinationNode = nodesAdded[destinationNodeIndex];
		node.condition.logic = mapValues(node.condition.logic, mapOverLogicalOperators(destinationNode));
	});

	return newNodes;
}

function addSingleTask(nodes, link = { task: {} }, tasks) {
	let newNode = createNode({ nodes, task: link.task, tasks });
	const task = link.task;
	let newNodes = nodes;

	link.fromId.forEach(fId => {
		const fromNodeIndex = R.findIndex(R.propEq('id', fId), nodes);
		const fromNode = nodes[fromNodeIndex];
		newNode.next = [...link.toId];
		const removeIdFromNext = [];

		let newFromNode;
		const nextLens = R.lensProp('next');

		if (link.toId.length > 1) {
			link.toId.forEach(id => {
				removeIdFromNext.push(node => R.assoc('next', R.reject(R.equals(id), node.next), node)); // R.compose(R.assoc('next'), R.reject(R.equals(id)));
			});
			const appendIdToNext = node => R.assoc('next', R.append(newNode.id, node.next), node);
			newFromNode = R.compose(
				...removeIdFromNext,
				appendIdToNext
			)(fromNode);
		} else {
			const id = link.toId[0];
			const nextIndex = R.findIndex(R.equals(id), fromNode.next);
			if (nextIndex > -1) {
				// newFromNode = R.assoc('next', R.update(nextIndex, newNode.id, fromNode.next), fromNode);
				newFromNode = R.over(nextLens, R.update(nextIndex, newNode.id), fromNode);
			} else {
				// newFromNode = R.assoc('next', R.append(newNode.id, fromNode.next), fromNode);
				newFromNode = R.over(nextLens, R.append(newNode.id), fromNode);
			}
		}

		newNodes = R.update(fromNodeIndex, newFromNode, newNodes);
	});

	// TODO: automatic input/output linking
	newNode = performRecursiveLinking({ node: newNode, nodes: [...newNodes, newNode], tasks });

	if (task.type === 'inlineCondition') {
		newNode.condition = {
			enabled: false,
			logic: { and: [{ '==': ['', ''] }] }
		};
	}

	newNodes.push(newNode);

	return { newNodes, newNode };
}

export function deleteNodeFromFlow({ node: nodeToDelete, flow }) {
	const nodeId = nodeToDelete.id;
	const nodes = flow.nodes;

	// const isInsideBranch = checkIfInsideBranchTask(nodeToDelete, nodes);
	const parentIsMergeTask = getNodeChildren(getNodeParents(nodeToDelete, nodes)[0], nodes).length > 1;
	const childIsBranchTask = getNodeParents(getNodeChildren(nodeToDelete, nodes)[0], nodes).length > 1;

	const nodesH = flow.nodes.reduce((nodesHash, node) => {
		const parentNodes = getNodeParents(node, nodes);
		const childrenNodes = getNodeChildren(node, nodes);

		const isSingleNodeBetweenTwoBranches = parentNodes.length > 1 && childrenNodes.length > 1;

		if (node.id === nodeToDelete.id && isSingleNodeBetweenTwoBranches) {
			// single node between two branches: http://prntscr.com/fdusrc
			const { id, next } = node;
			const placeholder = createPlaceholder(nodes, { id, next });
			nodesHash[placeholder.id] = placeholder;
		} else if (node.id === nodeToDelete.id && !parentIsMergeTask) {
			// Matching regular nodeToDelete, delete
			getNodeParents(nodeToDelete, nodes).forEach(parentNode => {
				parentNode.next = [...nodeToDelete.next];
				nodesHash[parentNode.id] = parentNode;
			});
		} else if (node.id === nodeToDelete.id && parentIsMergeTask) {
			// Matching nodeToDelete inside a branch
			if (childIsBranchTask && nodeToDelete.type !== 'placeholder') {
				const { id, next } = node;
				const placeholder = createPlaceholder(nodes, { id, next });
				nodesHash[placeholder.id] = placeholder;
			} else if (childIsBranchTask && nodeToDelete.type === 'placeholder') {
				// deleting the placeholder, get rid of the branch.
				const parent = parentNodes[0];
				parent.next = parent.next.filter(id => id !== nodeToDelete.id);
				nodesHash[parent.id] = parent;
				delete nodesHash[nodeToDelete.id];
			} else {
				parentNodes.forEach(parent => {
					const matchIndex = parent.next.indexOf(nodeToDelete.id);
					if (matchIndex > -1) {
						parent.next = R.remove(matchIndex, 1, parent.next);
						parent.next.push(...nodeToDelete.next);
					}
					nodesHash[parent.id] = parent;
				});

				delete nodesHash[nodeToDelete.id];
			}
		} else if (node.id !== nodeId) {
			let newNode = node;
			// delete linking to deleted node
			if (node.inputs && node.type === 'task') {
				const newInputs = R.map(
					inputList => ({
						values: (inputList.values || []).map(input => {
							if (input.type === 'ref' && input.nodeName === nodeToDelete.name) return {};
							return input;
						})
					}),
					node.inputs
				);

				newNode = R.assoc('inputs', newInputs, newNode);
			}
			nodesHash[newNode.id] = newNode;
		}

		return nodesHash;
	}, {});

	return values(nodesH);
}

export function removeRefsFromInputs(nodes, removedNode) {
	const nodesCopy = cloneDeep(nodes);
	const inputNode = nodesCopy.find(n => n.name === 'inputs');

	// array of linked field/keys, ie: ['File to Spine Size', 'Old Spine', 'New Spine']
	const linkedInputs = Object.values(removedNode.inputs)
		.filter(val => val.values[0] && val.values[0].type === 'ref' && val.values[0].nodeName === 'inputs')
		.map(val => val.values[0].field);

	inputNode.inputs = inputNode.inputs.filter(i => {
		if (linkedInputs.includes(i.key) && !i.siteflowAttribute) {
			// check if its linked to something else too
			const isFound = nodesCopy
				.filter(n => n.name !== 'inputs') // don't check INPUTS node
				.find(n => {
					const nodeInputs = Object.values(n.inputs).find(ni => {
						if (ni.values && ni.values[0]) {
							return (
								ni.values[0].type === 'ref' &&
								ni.values[0].nodeName === 'inputs' &&
								ni.values[0].field === i.key
							);
						}
					});
					return nodeInputs ? true : false;
				});
			// only remove if no other linkings found
			return isFound ? true : false;
		}
		return true;
	});

	return nodesCopy;
}

export function replaceFlowTask({ replaceId, task, flow, tasks }) {
	const { nodes } = flow;
	const nodeToReplace = nodes.filter(node => node.id === replaceId)[0];

	if (nodeToReplace.type === 'placeholder') {
		if (task.type === 'branch') {
			const node = nodes.find(n => n.id === nodeToReplace.id);
			const parentNodes = getNodeParents(node, nodes);
			const childrenNodes = getNodeChildren(node, nodes);

			return addTaskToFlow({
				flow,
				fromId: parentNodes[0].id,
				toId: childrenNodes[0].id,
				task: {
					name: 'Placeholder',
					type: 'placeholder'
				},
				tasks
			});
		} else if (task.type === 'macro') {
			const theNode = nodes.filter(n => n.id === nodeToReplace.id)[0];
			const theParentNodes = getNodeParents(theNode, nodes);
			const theChildrenNodes = getNodeChildren(theNode, nodes);

			return addMacro({
				nodes,
				task,
				fromId: theParentNodes[0].id,
				toId: theChildrenNodes[0].id,
				tasks
			});
		}
	}

	const newNode = {
		...createNode({ nodes, task, tasks }),
		next: nodeToReplace.next,
		id: nodeToReplace.id
	};

	if (task.type === 'inlineCondition') {
		newNode.condition = {
			enabled: false,
			logic: { and: [{ '==': ['', ''] }] }
		};
	}

	const newNodes = R.update(R.findIndex(R.equals(nodeToReplace), nodes), newNode, nodes);

	const newNodeLinked = performRecursiveLinking({ node: newNode, nodes: newNodes, tasks });

	return {
		nodes: R.update(R.findIndex(R.equals(newNode), newNodes), newNodeLinked, newNodes),
		newNode
	};
}

function performLinkingBetweenTwoNodes(fromNode, toNode, tasks) {
	const fromNodeInputParams = values(getNodeInputs(fromNode, tasks));
	const toNodeOutputParams = values(getNodeOutputs(toNode, tasks));
	const matchedKey = intersectionWith(
		toNodeOutputParams,
		fromNodeInputParams,
		(el1, el2) => el1.isPayload && el2.isPayload && el1.key === el2.key && el1.type === el2.type
	);

	if (matchedKey.length > 0) {
		const match = matchedKey[0];
		return R.assocPath(
			['inputs', match.key, 'values'],
			[
				{
					type: 'ref',
					nodeName: toNode.name,
					taskDisplayName: toNode.displayName,
					field: match.key
				}
			],
			fromNode
		);
	}

	return false;
}

function performRecursiveLinking({ node, parentNode, nodes, tasks = [] }) {
	if (!parentNode) {
		const parents = getNodeParents(node, nodes);
		if (!parents || parents.length === 0 || parents.length > 1) return node;
		const newParentNode = parents[0];
		return performRecursiveLinking({ node, parentNode: newParentNode, nodes, tasks });
	}

	const linkedNode = performLinkingBetweenTwoNodes(node, parentNode, tasks);
	if (!linkedNode) {
		const newParentNode = getNodeParents(parentNode, nodes)[0];
		if (!newParentNode) return node;
		return performRecursiveLinking({ node, parentNode: newParentNode, nodes, tasks });
	}

	return linkedNode;
}

export function createPlaceholder(nodes, additionalProps = {}) {
	const placeholder = Object.assign(
		{
			name: 'placeholder',
			type: 'placeholder',
			inputs: []
		},
		additionalProps
	);
	if (nodes) {
		placeholder.name = createNodeIdentifier(nodes, placeholder);
	}

	return placeholder;
}

function createNode({ nodes, task, tasks }) {
	const newNode = {
		id: maxBy(nodes, 'id').id + 1,
		displayName: task.displayName,
		name: task.name,
		status: task.status,
		type: getNodeType(task.type),
		taskName: task.name,
		next: [],
		inputs: mapNodeInitialInputs(task)
	};

	newNode.name = createNodeIdentifier(nodes, newNode, tasks);

	if (task.condition) {
		newNode.condition = task.condition;
	}

	return newNode;
}

function mapNodeInitialInputs({ inputs = {} }) {
	return mapValues(inputs, input => {
		const inputVal = { values: [{}] };
		if (typeof input.defaultValue !== 'undefined') {
			inputVal.values[0] = {
				value: input.defaultValue
			};
		}

		return inputVal;
	});
}

function checkIfInsideBranchTask(node, nodes) {
	const type = node.type;
	const parentNodes = getNodeParents(node, nodes);
	const isMergeTask = parentNodes.length > 1;

	const isBranchTask = parentNodes[0] && getNodeChildren(parentNodes[0], nodes).length > 1;

	if (isMergeTask || type === 'input') {
		return false;
	} else if (isBranchTask) {
		return true;
	}

	return checkIfInsideBranchTask(parentNodes[0], nodes);
}

export function createNodeIdentifier(nodes, newNode) {
	const baseName = namify(_get(newNode, 'taskName', newNode.name));
	let n = 0;
	for (; find(nodes, { name: `${baseName}-${n}` }); n += 1);

	return `${baseName}-${n}`;
}

export function uuid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
		let r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export function namify(text = '') {
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w\-]+/g, '') // Remove all non-word chars
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
}
