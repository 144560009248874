<template>
	<div class="FormGenerator">
		<div class="epm-profiles-header">
			<h2>Profile:</h2>
			<div class="epm-profiles-body py-2">
				<div class="profile-name" data-test-id="epmProfileLabel">
					<div v-if="isSelectedEpmProfileLoading"><i class="ion-load-c spin"></i> Loading</div>
					<h5 v-else-if="selectedEPMProfile">{{ selectedEPMProfile.name }}</h5>
					<h5 v-else>Custom</h5>
				</div>
				<div class="profile-buttons">
					<button class="button" data-test-id="loadProfilesButton" @click="handleProfileLoadBtnClick()">
						Load Profiles
					</button>
					<button class="button" style="margin-left: 10px;" @click="handleProfileSaveBtnClick()">
						Save current values as Profile
					</button>
				</div>
			</div>
		</div>

		<div v-for="(section, index) in epmSections" :key="index" class="FormSection">
			<div class="FormSection-title-epm py-3">
				<div class="checkbox">
					<label class="i-checks">
						<input
							type="checkbox"
							:checked="!get(inputs, `${section.disabledKey}.values.0`, {}).value"
							:data-test-id="`epmCheckBox${section.disabledKey.replace('disabled', '')}`"
							@input="handleValueChange(`${section.disabledKey}.values.0`, !$event.target.checked)"
						/>
						<i></i> {{ section.title }}
					</label>
				</div>
			</div>

			<div
				v-for="(group, index) in section.groups"
				v-show="!get(inputs, `${section.disabledKey}.values.0`, {}).value"
				:key="index"
			>
				<div v-for="(row, index) in group.rows" :key="index">
					<div v-if="row.isCheckboxRow" class="FormRow">
						<div
							v-for="(column, index) in row.columns"
							:key="index"
							class="FormColumn"
							:style="column.style"
						>
							<div class="checkbox">
								<label class="i-checks">
									<input
										type="checkbox"
										:checked="!get(inputs, `${group.disabledKey}.values.0`, {}).value"
										:data-test-id="`epmCheckBox${group.disabledKey.replace('disabled', '')}`"
										@input="
											handleValueChange(`${group.disabledKey}.values.0`, !$event.target.checked)
										"
									/>
									<i></i> {{ column.label }}
								</label>
							</div>
						</div>
					</div>
					<div v-else v-show="!get(inputs, `${group.disabledKey}.values.0`, {}).value" class="FormRow">
						<div
							v-for="(column, index) in row.columns"
							:key="index"
							class="FormColumn"
							:style="column.style"
						>
							<InputLinkList
								v-if="inputs[column.key]"
								v-bind="{
									inputKey: column.key,
									selectedNodeInputKey,
									onCreateLinkBtnClick,
									onClearLinkBtnClick,
									onDisableSelectionBtnClick,
									onCustomValueChange: handleValueChange,
									onShowAdvancedInputField,
									onAddInputToInputNodeBtnClick,
									node,
									task,
									tasks,
									flow,
									inputValues: inputs[column.key],
									config: internalConfig,
									handleAddNodeInputRow,
									handleRemoveNodeInputRow,
									readOnly: get(inputs, `${group.disabledKey}.values.0`, {}).value || false
								}"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-dom-portal>
			<ProfileSaveModal
				:show="isProfileSaveModalOpen"
				:on-confirm="handleProfileSaveModal"
				:on-close="handleProfileSaveModalClose"
			/>
		</div>
		<div v-dom-portal>
			<ProfileLoadModal
				v-if="isProfileLoadModalOpen"
				:show="isProfileLoadModalOpen"
				:node="null"
				:on-confirm="handleProfileLoadModal"
				:on-close="handleProfileLoadModalClose"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import get from 'lodash/get';
import InputLinkList from './NodeInputFieldList';
import InputRenderer from '../../../components/FormGenerator/InputRenderer';
import ProfileSaveModal from './ProfileSaveModal';
import ProfileLoadModal from './ProfileLoadModal';

export default {
	props: [
		'inputs',
		'formLayout',
		'selectedNodeInputKey',
		'onCreateLinkBtnClick',
		'onClearLinkBtnClick',
		'onDisableSelectionBtnClick',
		'onCustomValueChange',
		'onShowAdvancedInputField',
		'onAddInputToInputNodeBtnClick',
		'node',
		'task',
		'tasks',
		'flow',
		'config',
		'handleAddNodeInputRow',
		'handleRemoveNodeInputRow',
		'readOnly'
	],
	computed: {
		...mapGetters({
			epmProfiles: 'epmProfiles',
			selectedNode: 'selectedNode',
			areProfilesFetched: 'areProfilesFetched'
		}),
		internalConfig() {
			const config = get(this, 'config', {});
			return { ...config, allowModalitySwitching: false };
		},
		isCustomProfileSelected() {
			const profileId = get(this, 'selectedNode.inputs.profileId.values.0.value');
			return profileId && ['tolerant', 'standard', 'strict'].includes(profileId);
		}
	},
	data() {
		return {
			isProfileSaveModalOpen: false,
			isProfileLoadModalOpen: false,
			// isProfileModified: false,
			isSelectedEpmProfileLoading: false,
			selectedEPMProfile: null,
			epmSections: [
				{
					title: 'Black Checks',
					disabledKey: 'disabledBlackChecks', // map to vuex task inputs disabled properties
					groups: [
						{
							disabledKey: 'disabledCheckElements',
							rows: [
								{
									isCheckboxRow: true,
									columns: [{ label: 'Check Elements' }]
								},
								{ columns: [{ key: 'blackMinSizeToQualifyMm' }, { key: 'blackSmallestBlackLineMm' }] },
								{ columns: [{ key: 'blacksmallestBlackTextPt' }] }
							]
						},
						{
							disabledKey: 'disabledCheckPages',
							rows: [
								{
									isCheckboxRow: true,
									columns: [{ label: 'Check Pages' }]
								},
								{
									columns: [
										{ key: 'pagesMaxBlackPagesPercent' }
									]
								}
							]
						}
					]
				},
				{
					title: 'Colour Checks',
					disabledKey: 'disabledColourChecks',
					groups: [
						{
							disabledKey: 'disabledCheckSpotColours',
							rows: [
								{
									isCheckboxRow: true,
									columns: [{ label: 'Check Spot Colours' }]
								},
								{ columns: [{ key: 'spotColoursMethod' }, { key: 'spotColoursInkList' }] }
							]
						},
						{
							disabledKey: 'disabledCheckDeltaE',
							rows: [
								{
									isCheckboxRow: true,
									columns: [{ label: 'Check Delta E' }]
								},
								{ columns: [{ key: 'deltaEThreshold' }, { key: 'deltaEMaxBlobSizeMm' }] }
							]
						},
						{
							disabledKey: 'disabledCheckDeltaC',
							rows: [
								{
									isCheckboxRow: true,
									columns: [{ label: 'Check Delta C' }]
								},
								{ columns: [{ key: 'deltaCThreshold' }, { key: 'deltaCMaxBlobSizeMm' }] }
							]
						}
					]
				}
			]
		};
	},
	methods: {
		...mapActions({
			saveEPMProfile: 'saveEPMProfile',
			getEPMProfiles: 'getEPMProfiles',
			updateEPMProfile: 'updateEPMProfile',
			loadEPMProfileValues: 'loadEPMProfileValues',
			getEPMProfile: 'getEPMProfile',
			nullifyProfileId: 'nullifyProfileId',
			getEPMPresets: 'getEPMPresets'
		}),
		get,
		// handle profile save
		handleProfileSaveBtnClick() {
			this.isProfileSaveModalOpen = true;
		},
		handleProfileSaveModalClose() {
			this.isProfileSaveModalOpen = false;
		},
		async handleProfileSaveModal(profileName, isUpdate = false) {
			const profileData = {
				name: profileName,
				disabledOptions: {
					blackChecks: get(this.inputs, 'disabledBlackChecks.values.0.value', false),
					checkElements: get(this.inputs, 'disabledCheckElements.values.0.value', false),
					checkPages: get(this.inputs, 'disabledCheckPages.values.0.value', false),
					colourChecks: get(this.inputs, 'disabledColourChecks.values.0.value', false),
					checkSpotColours: get(this.inputs, 'disabledCheckSpotColours.values.0.value', false),
					checkDeltaC: get(this.inputs, 'disabledCheckDeltaC.values.0.value', false),
					checkDeltaE: get(this.inputs, 'disabledCheckDeltaE.values.0.valu', false)
				},
				profile: {
					blackChecks: {
						checkElements: {
							minSizeToQualifyMm: this.inputs.blackMinSizeToQualifyMm.values[0].value,
							smallestBlackLineMm: this.inputs.blackSmallestBlackLineMm.values[0].value,
							smallestBlackTextPt: this.inputs.blacksmallestBlackTextPt.values[0].value
						},
						checkPages: {
							maxBlackPagesPercent: this.inputs.pagesMaxBlackPagesPercent.values[0].value
						}
					},
					colourChecks: {
						checkDeltaE: {
							threshold: this.inputs.deltaEThreshold.values[0].value,
							maxBlobSizeMm: this.inputs.deltaEMaxBlobSizeMm.values[0].value
						},
						checkDeltaC: {
							maxBlobSizeMm: this.inputs.deltaCMaxBlobSizeMm.values[0].value,
							threshold: this.inputs.deltaCThreshold.values[0].value
						},
						checkSpotColours: {
							method: this.inputs.spotColoursMethod.values[0].value,
							inkList: this.inputs.spotColoursInkList.values[0].value
						}
					}
				}
			};

			let profile;
			// send profile back to asset locker
			if (isUpdate) {
				const profileToUpdate = this.epmProfiles.find(p => p.name === profileName);
				profileData._id = profileToUpdate._id;
				profile = (await this.updateEPMProfile(profileData))[0]; // update returns an array
			} else {
				profile = await this.saveEPMProfile(profileData);
			}

			await this.getEPMProfiles();
			this.loadEPMProfileValues(profile);

			this.isProfileSaveModalOpen = false;
		},
		// handle profile load
		handleProfileLoadBtnClick() {
			this.isProfileLoadModalOpen = true;
		},
		handleProfileLoadModalClose() {
			this.isProfileLoadModalOpen = false;
		},
		handleProfileLoadModal(profile) {
			this.loadEPMProfileValues(profile);
			this.isProfileLoadModalOpen = false;
		},
		handleValueChange(path, value) {
			if (path !== 'profileId.values.0') {
				this.onCustomValueChange('profileId.values.0', null);
				this.nullifyProfileId();
			}
			this.onCustomValueChange(path, value);
			this.selectedEPMProfile = null;
		},
		async handleEpmProfileSelection(newVal, oldVal) {
			this.isSelectedEpmProfileLoading = true;
			const profileId = get(this.selectedNode, 'inputs.profileId.values.0.value');
			if (profileId && newVal !== oldVal && this.epmProfiles.length) {
				if (['tolerant', 'standard', 'strict'].includes(profileId) || this.areProfilesFetched) {
					this.selectedEPMProfile = this.epmProfiles.find(epmPreset => epmPreset._id === profileId);
				} else {
					this.selectedEPMProfile = await this.getEPMProfile(profileId).catch(() => {
						// if profile was removed but the flow still has
						// the id of the deleted profile
						// this throws a 404 here so I'm just nulling the saved id
						this.nullifyProfileId();
					});
				}

				this.isSelectedEpmProfileLoading = false;
			}

			if (!profileId) {
				this.isSelectedEpmProfileLoading = false;
			}
		}
	},
	components: { InputLinkList, InputRenderer, ProfileSaveModal, ProfileLoadModal },
	watch: {
		'selectedNode.inputs.profileId.values.0.value': {
			immediate: true,
			handler: 'handleEpmProfileSelection'
		},
		epmProfiles: {
			immediate: true,
			handler: 'handleEpmProfileSelection'
		}
	},
	created() {
		return this.getEPMPresets();
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.epm-profiles-header {
	display: flex;
	flex-direction: column;
	margin: 10px 20px;
}

.epm-profiles-body {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	.profile-name {
		flex-basis: 38%;
	}
}

.spin::before {
	animation: spin 1s infinite linear;
}

@keyframes "spin" {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.FormSection {
	.FormSection-title-epm {
		background: whitesmoke;
		padding-left: 20px;
	}
	.FormRow {
		display: flex;
		border-bottom: 1px solid $gray-border-color;
		.FormColumn {
			flex: 1;
			border-right: 1px solid $gray-border-color;
			padding: 10px 20px;
			&:last-child {
				border-right: none;
			}
		}
	}
}
</style>
