import Vue from 'vue';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';
import { defaultListPreferences } from '../defaultListPreferences';

export default function() {
	const state = {
		listPreferences: {
			...defaultListPreferences
		}
	};

	Vue.$ofsCrud.addResource({
		name: 'task',
		options: { namespaced: false },
		transport: 'autoflow',
		getters,
		actions,
		mutations,
		state
	});
}
