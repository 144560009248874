<template>
	<div class="TruncateText" :class="classes">
		<div class="TruncateText-content">
			<slot></slot>
		</div>
		<div v-if="isTruncated" class="TruncateText-arrow" @click="handleArrowClick">
			<i v-if="this.isCollapsed" class="ion-chevron-down"></i>
			<i v-if="!this.isCollapsed" class="ion-chevron-up"></i>
		</div>
	</div>
</template>
<script type="text/javascript">
import $ from 'jquery';

export default {
	props: ['config', 'values', 'onChange'],
	data() {
		return {
			isTruncated: {},
			isCollapsed: true
		};
	},
	computed: {
		classes() {
			return {
				'is-collapsed': this.isTruncated && this.isCollapsed,
				'is-expanded': this.isTruncated && !this.isCollapsed
			};
		}
	},
	created() {
		this.$nextTick(this.checkOverflow);
	},
	updated() {
		this.$nextTick(this.checkOverflow);
	},
	methods: {
		checkOverflow() {
			const $element = $(this.$el).find('.TruncateText-content');
			const $c = $element
				.clone()
				.css({ display: 'inline', width: 'auto', visibility: 'hidden' })
				.appendTo('body');

			if ($c.width() > $element.width()) {
				this.isTruncated = true;
			} else {
				this.isTruncated = false;
			}

			$c.remove();
		},
		handleArrowClick() {
			this.isCollapsed = !this.isCollapsed;
		}
	}
};
</script>
<style lang="scss">
.TruncateText {
	display: flex;
	align-items: flex-end;
	.TruncateText-content {
		flex-grow: 1;
	}
	.TruncateText-arrow {
		cursor: pointer;
	}
	&.is-collapsed {
		.TruncateText-content {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	&.is-expanded {
		.TruncateText-content {
			word-wrap: break-word;
			overflow: hidden;
		}
	}
}
</style>
