import Vue from 'vue';

export const withSubscriptionMixin = {
	created() {
		this._subscriptions = [];
	},
	destroyed() {
		return this.unsubscribeAll();
	},
	methods: {
		async registerObserve(action, data) {
			const dispatchResult = await this.$store.dispatch(action, data);
			const { subscription, observable } = dispatchResult;

			this._subscriptions.push(subscription);
			return dispatchResult;
		},
		addSubscription(subscription) {
			return this._subscriptions.push(subscription);
		},
		unsubscribeAll() {
			this._subscriptions.forEach(s => s.unsubscribe());
			this._subscriptions = [];
		}
	}
};
