<template>
	<section class="EditFlowHeader">
		<form @submit.prevent="handleSaveBtnClick">
			<div v-if="flow" class="EditFlowHeader-wrapper">
				<div class="EditFlowHeader-left">
					<div class="EditFlowHeader-leftInner">
						<span class="EditFlowHeader-leftInnerName">{{ flow.name }}</span>
						<span class="EditFlowHeader-leftInnerDescription">{{ flow.description }}</span>
					</div>
				</div>

				<div class="EditFlowHeader-right">
					<button
						v-tippy
						type="button"
						class="button button--iconOnly button--noBorder"
						:title="$t('Click to edit the description and the name')"
						@click="onEditFlow"
					>
						<i class="ion-edit" />
					</button>
					<button
						v-tippy
						title="Save"
						data-test-id="saveFlowButton"
						type="button"
						class="button button--iconOnly button--noBorder"
						:disabled="!flow.name || flow.name.length >= 30"
						@click="handleSaveBtnClick"
					>
						<v-icon name="save"></v-icon>
					</button>
					<button
						v-tippy
						title="Run Flow"
						data-test-id="runFlowButton"
						type="button"
						class="button button--iconOnly button--noBorder"
						:disabled="requiredAccess !== true"
						@click="onRunFlowClick"
					>
						<i class="ion-play" />
					</button>
					<button
						v-tippy
						title="Delete Flow"
						data-test-id="deleteFlowButton"
						type="button"
						class="button button--iconOnly button--noBorder"
						@click="onDeleteFlowBtnClick"
					>
						<i class="ion-ios-trash" />
					</button>
				</div>
			</div>
		</form>
	</section>
</template>

<script type="text/javascript">
import { mapActions } from 'vuex';
import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon';
import _throttle from 'lodash/throttle';
import './FlowEditHeader.scss';

const THROTTLE_SAVE_TIMEOUT = 1500;

export default {
	components: {
		Icon,
		'v-icon': Icon
	},
	props: ['flow', 'onSaveFlow', 'onNameChange', 'requiredAccess', 'onRunFlowClick', 'onDeleteFlowBtnClick'],
	methods: {
		...mapActions(['openEditFlowInfoModal']),
		onEditFlow() {
			this.openEditFlowInfoModal({ flow: { ...this.flow } });
		},
		handleSaveBtnClick: _throttle(async function() {
			await this.onSaveFlow();
		}, THROTTLE_SAVE_TIMEOUT),
		handleNameChange($event) {
			this.onNameChange($event.target.value);
		}
	}
};
</script>
