module.exports = {
	properties: [
		{ key: 'url', name: 'Artwork File', type: 'file', defaultValue: '' },
		{ key: 'sourceItemId', name: 'Source Item ID', type: 'text', defaultValue: '' },
		{ key: 'sourceOrderId', name: 'Source Order ID', type: 'text', defaultValue: '' },
		{ key: 'componentId', name: 'Component ID', type: 'text', defaultValue: '' },
		{ key: 'itemSku', name: 'Item SKU', type: 'text', defaultValue: '' },
		{ key: 'itemBarcode', name: 'Item Barcode', type: 'text', defaultValue: '' },
		{ key: 'componentBarcode', name: 'Component Barcode', type: 'text', defaultValue: '' }
	],
	shipAddress: [
		{ key: 'shipTo.name', name: 'Shipment Name', type: 'text', defaultValue: '' },
		{ key: 'shipTo.address1', name: 'Shipment Address 1', type: 'text', defaultValue: '' },
		{ key: 'shipTo.address2', name: 'Shipment Address 2', type: 'text', defaultValue: '' },
		{ key: 'shipTo.town', name: 'Shipment Town', type: 'text', defaultValue: '' },
		{ key: 'shipTo.postcode', name: 'Shipment Post Code', type: 'text', defaultValue: '' },
		{ key: 'shipTo.state', name: 'Shipment State', type: 'text', defaultValue: '' },
		{ key: 'shipTo.isoCountry', name: 'Shipment ISO Country', type: 'text', defaultValue: '' }
	]
};
