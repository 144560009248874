<template>
	<DefaultLayout class="EditPage" :class="classes">
		<LeftPanel
			slot="left-panel"
			:mode="leftPanelMode"
			:on-show-search="showLeftPanelSearch"
			:on-close-search="hideLeftPanelSearch"
			:on-toggle-toolbox-overlay="toggleToolboxOverlay"
			:on-drag-status-change="handleTaskDragStatusChange"
			:on-show-upgrade-account-modal="upgrade"
			:show-only-included-tasks="showOnlyIncludedTasks"
			:on-show-toolbox-task-details="showToolboxTaskDetails"
		/>

		<section class="EditPage-main">
			<div v-if="requiredPlanLevel !== true" class="UpgradeBar">
				<div class="UpgradeBar-message">
					<p class="UpgradeBar-paragraph">
						A <strong class="text-uppercase" v-text="requiredPlanLevel.toUpperCase()"></strong> Plan is
						required to run this flow.
					</p>
				</div>
				<button class="UpgradeBar-button" @click="upgrade">Upgrade</button>
			</div>
			<EditHeader
				:flow="flow"
				:on-name-change="handleNameChange"
				:on-save-flow="handleSaveFlow"
				:required-access="requiredPlanLevel"
				:on-run-flow-click="handleRunFlowClick"
				:on-delete-flow-btn-click="handleDeleteFlowBtnClick"
			/>
			<div class="EditPage-chartContainer">
				<ConnectorsCanvas
					v-if="isConnectorsCanvasVisible && areNodeConfigurationSectionsVisible"
					v-bind="{
						selectedNodeLinkedInputs,
						selectedNodeLinkedOutputs,
						scrollingValues
					}"
				/>
				<ChartDisplay
					v-if="flow"
					v-bind="{
						flow,
						setInput,
						tasks,
						upgrade,
						onNodeClick: handleNodeClick,
						onNodeHover: handleNodeHover,
						onCanvasClick: handleCanvasClick,
						selectedNode,
						selectedNodeInputKey,
						outputsThatCanBeLinked,
						onTaskDrop: handleTaskDropEvent,
						onOutputSelection: handleOutputSelection,
						taskDragStatus,
						draggedTask,
						onChartRendered: handleChartRendered,
						onDeleteNodeRequest: handleDeleteNodeRequest,
						onScroll: handleChartScroll,
						areNodeOutputsVisible,
						onDragStatusChange: handleTaskDragStatusChange
					}"
				>
				</ChartDisplay>

				<div class="d-md-none">
					<transition name="t-MainSettingsPanel-fade" :duration="500">
						<MainSettingsPanel
							v-if="selectedNode && areNodeConfigurationSectionsVisible"
							v-bind="{
								showSiteflowImport: isSiteflowFlow,
								flow,
								node: selectedNode,
								task: selectedNodeTask,
								tasks,
								onNodeNameChange: handleNodeNameChange,
								selectNodeInput,
								selectedNodeLinkedInputs,
								selectedNodeLinkedOutputs,
								selectConditionRuleInput,
								selectedConditionRuleInput,
								selectOutputKeyIndex: handleSelectOutputKeyIndex,
								selectedOutputKeyIndex,
								selectedNodeInputKey,
								setInput,
								onDeleteTaskRequest: handleDeleteNodeRequest,
								updateCondition,
								onAddInputToInputNodeBtnClick: handleAddInputToInputNodeBtnClick,
								handleAddNodeInputRow,
								handleRemoveNodeInputRow,
								componentStyle: mainSettingsPanelStyle,
								onScroll: handleMainSettingsPanelScroll,
								hidePanel: selectNode,
								readOnly: true,
								onUpgradeClick: upgrade
							}"
						/>
					</transition>
				</div>
				<div class="d-none d-md-block">
					<transition name="t-MainSettingsPanel-fade" :duration="500">
						<MainSettingsPanel
							v-if="selectedNode && areNodeConfigurationSectionsVisible"
							v-bind="{
								showSiteflowImport: isSiteflowFlow,
								flow,
								node: selectedNode,
								task: selectedNodeTask,
								tasks,
								onNodeNameChange: handleNodeNameChange,
								selectNodeInput,
								selectedNodeLinkedInputs,
								selectedNodeLinkedOutputs,
								selectConditionRuleInput,
								selectedConditionRuleInput,
								selectOutputKeyIndex: handleSelectOutputKeyIndex,
								selectedOutputKeyIndex,
								selectedNodeInputKey,
								setInput,
								onDeleteTaskRequest: handleDeleteNodeRequest,
								updateCondition,
								onAddInputToInputNodeBtnClick: handleAddInputToInputNodeBtnClick,
								handleAddNodeInputRow,
								handleRemoveNodeInputRow,
								componentStyle: mainSettingsPanelStyle,
								onScroll: handleMainSettingsPanelScroll,
								hidePanel: selectNode,
								readOnly: false,
								onUpgradeClick: upgrade
							}"
						/>
					</transition>
				</div>
			</div>
		</section>

		<div v-if="showToolboxOverlay" class="ToolboxOverlay">
			<header class="ToolboxOverlay-header">
				<p class="ToolboxOverlay-plan">
					Current Plan: <span>{{ userAccess }}</span>
				</p>
				<button v-if="requiredPlanLevel !== true" class="ToolboxOverlay-button" @click="upgrade">
					Upgrade
				</button>
			</header>
			<div class="ToolboxOverlay-content">
				<div class="ToolboxOverlay-filter">
					<input id="showPremium" v-model="showOnlyIncludedTasks" type="checkbox" name="showPremium" />
					<label for="showPremium">Only show tasks included in my plan</label>
				</div>
			</div>
		</div>

		<ImportSiteflowAttributesModal />
		<AddInputsToStartNodeModal
			:show="isAddInputsToStartNodeModalVisible"
			:node="selectedNode"
			:task="selectedNodeTask"
			:start-node="startNode"
			:on-submit="handleAddInputsToStartNodeModalSubmit"
			:on-close="handleAddInputsToStartNodeModalClose"
		/>
		<TaskDetailsModal
			:show="isToolboxTaskDetailsModalShow"
			:task="selectedDetailsTask"
			:on-close="handleTaskDetailsModalClose"
		/>
		<FillConditionInputsModal
			:show="isFillConditionInputsModal"
			:nodes="uninitializedNodes"
			:tasks="tasks"
			:on-close="handleCloseFillConditionInputsModal"
			:flow="flow"
			:update-condition="updateCondition"
		/>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/droppable';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import find from 'lodash/find';
import set from 'lodash/set';

import { getInputFormConfig, getNodeParents } from '../../../lib/flowInspectionUtils';
import { userHasAccessToRunFlow } from '../../../lib/flowValidationUtils';
import DefaultLayout from '../../../components/DefaultLayout';
import EditHeader from './FlowEditHeader';
import ChartDisplay from '../../../components/Chart/Chart';
import ConnectorsCanvas from './ConnectorsCanvas';
import LeftPanel from './LeftPanel';
import AdvancedFieldModal from './AdvancedFieldModal';
import MainSettingsPanel from './Panels/MainSettingsPanel';
import AddInputsToStartNodeModal from './AddInputsToStartNodeModal';
import TaskDetailsModal from './TaskDetailsModal';
import ImportSiteflowAttributesModal from './ImportSiteflowAttributesModal';
import FillConditionInputsModal from './FillConditionInputsModal';
import { withSubscriptionMixin } from '../../../lib/withSubscriptionMixin';
import analytics from '../../../lib/analytics';
import './FlowEdit.scss';

export default {
	mixins: [withSubscriptionMixin],
	data() {
		return {
			isRunFlowModalShown: false,
			isRunningFlowModalShown: false,
			runConfiguration: {},
			// outputsThatCanBeLinked: new Map(),
			leftPanelMode: 'list',
			isInputModalShown: false,
			fieldsConfig: null,
			taskDragStatus: 'ready',
			draggedTask: null,
			hoveredNode: null,
			selectedConditionRuleInput: null,
			chartScrollingValues: '',
			mainSettingsPanelScrollingValues: '',
			isConnectorsCanvasVisible: true,
			isToolboxTaskDetailsModalShow: false,
			selectedDetailsTask: null,
			isFillConditionInputsModal: false,
			uninitializedNodes: [],
			showToolboxOverlay: false,
			showOnlyIncludedTasks: false, // Show tasks in my plan
			areNodeConfigurationSectionsVisible: true
		};
	},
	computed: {
		...mapGetters([
			'siteflowProduct',
			'flow',
			'userAccess',
			'workqueue',
			'tasks',
			'selectedNodeInputKey',
			'selectedOutputKeyIndex',
			'selectedNodeName',
			'selectedNode',
			'selectedNodeTask',
			'selectedNodeLinkedInputs',
			'selectedNodeLinkedOutputs',
			'flowHasChanges',
			'outputsThatCanBeLinked',
			'isAddInputsToStartNodeModalVisible',
			'$config',
			'selectedEPMProfile'
		]),
		id() {
			return this.$route.params.id;
		},
		scrollingValues() {
			return this.chartScrollingValues + this.mainSettingsPanelScrollingValues;
		},
		startNode() {
			const nodes = get(this, 'flow.nodes', []);
			return find(nodes, { type: 'input' });
		},
		classes() {
			return {
				'is-dragging': this.taskDragStatus === 'dragging'
			};
		},
		requiredPlanLevel() {
			let userCanRunFlow = true;

			if (this.$config.isHpMode && this.flow) {
				userCanRunFlow = userHasAccessToRunFlow({
					flow: this.flow,
					tasks: this.tasks,
					userAccess: this.userAccess
				});
			}

			return userCanRunFlow;
		},
		mainSettingsPanelStyle() {
			if (this.selectedNode && this.selectedNode.type.toLowerCase().indexOf('condition') > -1) {
				return 'conditionWidth';
			}
			return '';
		},
		productId() {
			return get(this.flow, 'productId') || get(this.$route, 'query.productId');
		},
		componentId() {
			return get(this.flow, 'componentId') || get(this.$route, 'query.componentId');
		},
		showAttributesModal() {
			return get(this.$route, 'query.showAttributesModal');
		},
		isSiteflowFlow() {
			return get(this.flow, 'siteflow') || !!(this.productId && this.componentId);
		}
	},

	watch: {
		flow(flow) {
			if (flow && flow.nodes) {
				this.calcOutputsThatCanBeLinked();
				this.uninitializedNodes = flow.nodes.filter(node => node.uninitialized);
				if (this.uninitializedNodes.length && !this.isAddInputsToStartNodeModalVisible) {
					this.isFillConditionInputsModal = true;
				}
			}
		},
		'selectedNode.name': function() {
			// fighting vuejs component state caching
			this.areNodeConfigurationSectionsVisible = false;
			this.$nextTick(() => {
				this.areNodeConfigurationSectionsVisible = true;
			});
		}
	},
	async created() {
		this.loadFlow();
		this.loadTasks();

		if (this.showAttributesModal) {
			await this.$nextTick();
			this.handleShowImportAttributes();
		}
	},

	beforeRouteLeave(to, from, next) {
		if (this.flowHasChanges) {
			const title = this.$t('Current flow has unsaved changes');
			const body = this.$t('Are you sure you want to discard them, and change page?');
			return this.openConfirmModal({ title, body })
				.then(() => {
					this.markFlowChanges(false); // or discarded
					next();
				})
				.catch(() => next(false));
		}

		return next();
	},
	methods: {
		...mapActions([
			'observeFlow',
			'saveFlow',
			'newFlow',
			'deleteFlow',
			'selectNode',
			'deleteNode',
			'updateNode',
			'selectNodeInputKeyForLinking',
			'setNodeInputValue',
			'setNodeInputsValue',
			'addNewStartTaskInput',
			'handleTaskDrop',
			'handleTaskReposition',
			'observeTasks',
			'findTasks',
			'displayToast',
			'openConfirmModal',
			'updateNodeCondition',
			'addNodeInputRow',
			'removeNodeInputRow',
			'selectOutputKeyIndex',
			'openRunFlowModal',
			'runFlow',
			'markFlowChanges',
			'openDeleteFlowModal',
			'setAddInputsToStartNodeModalVisible',
			'openImportSiteflowAttributesModal'
		]),
		...mapActions('upgradeAccountModal', { setUpgradeAccountModal: 'setOptions' }),

		/**
		 * Load the flow or create a new empty one
		 * @returns {*|Promise}
		 */
		loadFlow() {
			if (this.id) {
				return this.registerObserve('observeFlow', { id: this.id });
			}

			this.newFlow();
		},

		toggleToolboxOverlay() {
			this.showToolboxOverlay = !this.showToolboxOverlay;
		},

		upgrade(e) {
			this.setUpgradeAccountModal({ isOpen: true, requiredPlan: this.requiredPlanLevel });
			e.preventDefault();
		},

		/**
		 * Observe tasks
		 * @returns {*|Promise}
		 */
		loadTasks() {
			return this.findTasks({
				query: { filter: { order: 'updatedAt ASC' } }
			});
		},

		handleNameChange(name) {
			this.$set(this.flow, 'name', name);
		},

		/**
		 * Routine for save the flow
		 * @returns {*|Promise}
		 */
		async handleSaveFlow() {
			try {
				if (this.flow.id) {
					await this.saveFlow({ id: this.flow.id, data: this.flow });
				} else {
					const flow = await this.saveFlow({ data: this.flow });
					this.$router.push({ name: 'flows.edit', params: { id: flow.id } });
					this.loadFlow();
				}

				const toast = {
					title: 'Flow saved successfully'
				};
				this.displayToast({ data: toast });

				analytics.trackEventFlowSave(this.flow);
			} catch (err) {
				this.displayToast({ data: err });
			}
		},

		async handleDeleteFlowBtnClick() {
			const isDeleted = await this.openDeleteFlowModal(this.flow);
			if (isDeleted) {
				analytics.trackEventFlowDelete(this.flow);
				this.$router.push({ name: 'flows' });
			}
		},

		// Node input/output linking
		selectNodeInput(inputKeyPath) {
			this.selectNodeInputKeyForLinking({ inputKeyPath, selectedNode: this.selectedNode });
		},

		handleSelectOutputKeyIndex(index, key) {
			this.selectOutputKeyIndex({ index });
		},

		// Condition input/output linking
		selectConditionRuleInput(selectedConditionRuleInput) {
			this.selectedConditionRuleInput = selectedConditionRuleInput;
		},

		calcOutputsThatCanBeLinked(node = this.selectedNode) {
			if (isEmpty(this.selectedNode)) {
				return [];
			}
		},

		setInput(type, selectedKeyPath, { node, taskInput, value } = {}) {
			const { type: nodeType } = this.selectedNode;

			if (nodeType === 'task') {
				this.handleSetNodeInputValue(type, selectedKeyPath, { node, taskInput, value });
			} else if (nodeType === 'input') {
				this.handleSetStartNodeFieldValue(type, selectedKeyPath, { node, taskInput, value });
			} else if (nodeType === 'end') {
				this.handleSetEndNodeFieldValue(type, selectedKeyPath, { node, taskInput, value });
			}

			this.calcOutputsThatCanBeLinked();
		},

		handleSetStartNodeFieldValue(type, selectedInputKeyIndex, { node, taskInput, value } = {}) {
			if (typeof selectedInputKeyIndex === 'undefined') {
				return false;
			}

			const inputVal = {
				type: 'custom',
				value
			};

			const inputs = [...(this.selectedNode.inputs || [])];

			inputs[selectedInputKeyIndex] = { ...inputs[selectedInputKeyIndex], ...inputVal };
			const startNode = {
				...this.selectedNode,
				inputs
			};

			this.updateNode({ node: startNode });
		},

		handleSetNodeInputValue(type, selectedInputKeyPath, { node, taskInput, value } = {}) {
			selectedInputKeyPath = selectedInputKeyPath || this.selectedNodeInputKey;
			if (!selectedInputKeyPath) {
				return false;
			}

			let inputVal;

			if (type === 'ref') {
				inputVal = {
					type,
					nodeName: node.name,
					taskDisplayName: node.displayName,
					field: taskInput
				};
			} else {
				inputVal = {
					type: 'custom',
					value
				};
			}

			this.setNodeInputValue({
				node: this.selectedNode,
				inputKeyPath: selectedInputKeyPath,
				value: inputVal
			});

			this.selectNodeInputKeyForLinking();
		},

		handleSetEndNodeFieldValue(type, selectedOutputKeyIndex, { node, taskInput, value } = {}) {
			if (typeof selectedOutputKeyIndex === 'undefined') {
				return false;
			}

			let inputVal;

			if (type === 'ref') {
				inputVal = {
					type,
					nodeName: node.name,
					taskDisplayName: node.displayName,
					field: taskInput
				};
			} else {
				inputVal = {
					type: 'custom',
					value
				};
			}

			const outputs = [...(this.selectedNode.outputs || [])];

			outputs[selectedOutputKeyIndex].value = inputVal;
			const endNode = {
				...this.selectedNode,
				outputs
			};

			this.updateNode({ node: endNode });

			this.selectOutputKeyIndex();
		},

		handleOutputSelection(node, outputKey) {
			// check if is a Node input linking, or a condition linking
			if (this.selectedNodeInputKey) {
				this.setInput('ref', this.selectedNodeInputKey, { node, taskInput: outputKey });
			} else if (this.selectedConditionRuleInput) {
				const { path } = this.selectedConditionRuleInput;
				const value = { var: `$state.${node.name}.${outputKey}` };
				set(this.selectedNode.condition.logic, path, value);
				this.updateCondition(this.selectedNode.condition);
				this.selectedConditionRuleInput = null;
			} else if (typeof this.selectedOutputKeyIndex === 'number') {
				this.setInput('ref', this.selectedOutputKeyIndex, { node, taskInput: outputKey });
			}
		},

		updateCondition(condition, nodeName) {
			this.updateNodeCondition({
				nodes: this.flow.nodes,
				nodeName: nodeName || this.selectedNodeName,
				condition
			});
		},

		handleRunFlowClick() {
			let userCanRunFlow = true;
			if (this.$config.isHpMode) {
				userCanRunFlow = userHasAccessToRunFlow({
					flow: this.flow,
					tasks: this.tasks,
					userAccess: this.userAccess
				});

				if (userCanRunFlow !== true) {
					this.displayToast({
						data: {
							title: `This flow required a ${userCanRunFlow.toUpperCase()} Plan to run`,
							type: 'error'
						}
					});
					return;
				}
			}
			this.runConfiguration = {
				inputs: cloneDeep(this.startNode.inputs)
			};

			this.openRunFlowModal({
				runConfiguration: this.runConfiguration,
				flow: this.flow
			});
		},

		handleRunFlowModalClose() {
			this.isRunFlowModalShown = false;
		},

		handleRunningFlowModalOpen() {
			this.isRunningFlowModalShown = true;
		},

		handleRunningFlowModalClose() {
			this.isRunningFlowModalShown = false;
		},

		handleRunFlowStart(workqueue) {
			this.runFlow({ workqueue, flow: this.flow })
				.catch(err => {
					console.log(err);
				})
				.then(this.handleRunningFlowModalOpen)
				.then(this.handleRunFlowModalClose);
		},

		// Chart and nodes events
		async handleNodeClick(name) {
			// warn user this is a premium task if they are basic
			const type = find(this.flow.nodes, { name }).type;
			const args = type === 'placeholder' ? {} : { name };
			await this.selectNodeInputKeyForLinking();
			await this.selectNode(args);
			await this.calcOutputsThatCanBeLinked();
		},

		async handleTaskDragStatusChange(status, draggedTask) {
			this.taskDragStatus = status;
			this.draggedTask = draggedTask;
		},

		showToolboxTaskDetails(task) {
			this.isToolboxTaskDetailsModalShow = true;
			this.selectedDetailsTask = task;
			analytics.trackEventTaskInfo(task);
		},

		handleTaskDetailsModalClose() {
			this.isToolboxTaskDetailsModalShow = false;
		},

		/**
		 * Chart render callback
		 */
		handleChartRendered() {
			this.bindTaskDraggingUI();
			this.isConnectorsCanvasVisible = true;
		},

		handleCanvasClick() {
			this.selectNode();
			this.selectOutputKeyIndex();
		},

		// Left Panel
		showLeftPanelSearch() {
			this.leftPanelMode = 'search';
		},

		hideLeftPanelSearch() {
			this.leftPanelMode = 'list';
		},

		handleDeleteNodeRequest(node) {
			const title = `Delete ${node.displayName}`;
			const body = 'Are you sure?';
			this.openConfirmModal({ title, body })
				.then(() => this.deleteNode({ node, flow: this.flow }))
				// .then(() => this.buildChart())
				.catch(() => {});
		},

		handleNodeHover(node) {
			this.hoveredNode = node;
		},

		handleNodeNameChange(name) {
			let node = this.selectedNode;
			node = { ...node, displayName: name };
			this.updateNode({ node });
		},
		async handleAddInputToInputNodeBtnClick(node, inputKey) {
			const form = await this.selectedNodeTask.form;
			const { key, value = '', type } = this.selectedNodeTask.inputs[inputKey];
			const inputForm = Object.values(form).find(el => el.key === key) || form[inputKey];
			const name = getInputFormConfig(inputKey, get(this, 'selectedNodeTask.form')).displayName || inputKey;
			const input = { key: name, value, type, form: inputForm };
			const startNode = await this.addNewStartTaskInput(input);

			this.setNodeInputValue({
				node,
				inputKeyPath: inputKey,
				value: {
					type: 'ref',
					nodeName: startNode.name,
					taskDisplayName: startNode.displayName,
					field: input.key
				},
				options: { replace: true }
			});
		},

		async handleAddInputsToStartNodeModalSubmit({ node, selectedInputs = [] } = {}) {
			const form = await this.selectedNodeTask.form;
			const inputs = await Promise.all(
				selectedInputs.map(async ({ inputKey, name }) => {
					const { key, value = '', type } = await this.selectedNodeTask.inputs[inputKey];
					const inputForm = Object.values(form).find(el => el.displayName === name) || form[inputKey];
					const input = { key: name || key, value, type, form: inputForm };
					const startNode = await this.addNewStartTaskInput(input);

					return {
						inputKeyPath: inputKey,
						value: {
							type: 'ref',
							nodeName: startNode.name,
							taskDisplayName: startNode.displayName,
							field: input.key
						}
					};
				})
			);
			this.setNodeInputsValue({ node, inputs, options: { replace: true } });
			this.setAddInputsToStartNodeModalVisible(false);
		},
		handleAddInputsToStartNodeModalClose() {
			this.setAddInputsToStartNodeModalVisible(false);
		},
		bindTaskDraggingUI() {
			const _this = this;

			const $droppableElements = $('.Chart-node-dropzone, .Placeholder, .Node, .ConditionNode, .InputNode');
			try {
				$droppableElements.droppable('destroy');
			} catch (err) {}

			$droppableElements.droppable({
				// activeClass: "ui-state-default",
				hoverClass() {
					if ($(this).is('.has-dropzone')) {
						return 'is-hovering';
					}
				},
				accept: '.Toolbox-draggable',
				over(event, ui) {
					const $this = $(this);
					// if ($droppableElements.filter('.is-hovering').length > 0) return;
					if ($this.is('.is-visible')) {
						$this.addClass('is-hovering');
					}
				},
				out(event, ui) {
					const $this = $(this);
					if ($this.is('.is-visible')) {
						$this.removeClass('is-hovering');
					}
				},
				drop(event, { draggable }) {
					const $this = $(this);
					const isDropzone = $this.is('.Chart-node-dropzone');
					const isNode = $this.is('.Node');

					if (isDropzone && !$this.is('.is-visible')) return false;
					if (isNode && !$this.is('.has-dropzone')) return false;

					const draggedNode = draggable[0].dataset;

					let target, nodeId;
					if (isDropzone) {
						target = this.dataset.target;
						nodeId = Number(this.dataset.nodeId);
					} else {
						target = 'node';
						nodeId = Number(this.dataset.nodeId);
					}

					const node = find(_this.flow.nodes, { id: nodeId });
					const nodeName = draggedNode.name;
					const nodeTaskStatus = draggedNode.nodeTaskStatus || draggedNode.status;
					// dataset.nodeTaskStatus is used when moving a node.
					// it is the status of the task that the node references, not the status of the node itself.
					// dataset.status is used when creating a node - by dragging a task into a flow.

					const nodeFromId = Number(draggedNode.nodeId);
					_this.isConnectorsCanvasVisible = false;

					if (nodeName) {
						_this.handleTaskDropEvent({ node, nodeName, nodeTaskStatus, target });
					} else {
						let isCurrentNode = false;

						if (nodeFromId === nodeId) isCurrentNode = true;
						const parentNodes = getNodeParents(node, _this.flow.nodes);
						parentNodes.forEach(node => {
							if (node.id === nodeFromId) isCurrentNode = true;
						});
						if (isCurrentNode) return false;

						const selectedNode = _this.flow.nodes.find(node => node.id === nodeFromId);
						_this.handleTaskDropEvent({
							node,
							nodeName: selectedNode.taskName,
							nodeTaskStatus: selectedNode.status,
							target,
							copyNode: selectedNode
						});
					}
				}
			});
		},

		/**
		 * Handle the event fired when dragging a worker on the flow
		 * @param node
		 * @param nodeName
		 * @param {('node'|'top'|'bottom')} target
		 */
		async handleTaskDropEvent({ node, nodeName, nodeTaskStatus, target, copyNode }) {
			this.handleTaskDrop({
				node,
				nodeName,
				nodeTaskStatus,
				target,
				tasks: this.tasks,
				flow: this.flow,
				copyNode
			});
		},

		handleAddNodeInputRow({ node, inputKey, atIndex }) {
			this.addNodeInputRow({
				nodes: this.flow.nodes,
				nodeName: node.name,
				inputKey,
				atIndex
			});
		},

		handleRemoveNodeInputRow({ node, inputKey, atIndex }) {
			this.removeNodeInputRow({
				nodes: this.flow.nodes,
				nodeName: node.name,
				inputKey,
				atIndex
			});
		},

		handleChartScroll(chartScrollingValues) {
			this.chartScrollingValues = chartScrollingValues;
		},

		areNodeOutputsVisible(node) {
			const isNodeInputKeySelected = this.selectedNodeInputKey;
			const isConditionRuleInputSelected = this.selectedConditionRuleInput;
			const isOutputKeySelected = Number(this.selectedOutputKeyIndex) > -1;
			return (
				(isNodeInputKeySelected || isConditionRuleInputSelected || isOutputKeySelected) &&
				this.hoveredNode === node
			);
		},

		handleMainSettingsPanelScroll(mainSettingsPanelScrollingValues) {
			this.mainSettingsPanelScrollingValues = mainSettingsPanelScrollingValues;
		},

		handleCloseFillConditionInputsModal() {
			this.isFillConditionInputsModal = false;
			this.uninitializedNodes.forEach(node => {
				// delete node.uninitialized;
				const { uninitialized, ...data } = node;
				this.updateNode({ node: data });
			});
		},

		handleShowImportAttributes() {
			this.openImportSiteflowAttributesModal();
		}
	},
	components: {
		DefaultLayout,
		EditHeader,
		ChartDisplay,
		LeftPanel,
		AdvancedFieldModal,
		MainSettingsPanel,
		AddInputsToStartNodeModal,
		TaskDetailsModal,
		FillConditionInputsModal,
		ConnectorsCanvas,
		ImportSiteflowAttributesModal
	}
};
</script>
