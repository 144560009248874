import Promise from 'bluebird';
import _ from 'lodash';
import store from '../../vuex';

const Edit = () => import('./edit/TaskEdit');
const List = () => import('./list/TaskList');

const routes = [
	{
		path: '/tasks',
		name: 'tasks',
		component: { template: '<router-view/>' },
		beforeEnter: async (to, from, next) => {
			const storedUser = _.get(store, 'state.user.user');
			const me =
				storedUser ||
				(await new Promise(resolve => {
					// the 'getUser' action trigger when the app starts.
					// we are doing that to avoid triggering multiple 'getUser' calls
					const dispose = store.subscribe((mutation, state) => {
						if (mutation.type === 'SET_USER') {
							dispose();
							resolve(state.user.user);
						}
					});
				}));

			if (me.isAdmin) {
				next();
			}

			next(false);
		},
		children: [
			{
				name: 'tasks',
				path: '',
				component: List
			},
			{
				name: 'tasks.add',
				path: 'add',
				component: Edit,
				props: true
			},
			{
				name: 'tasks.edit',
				path: 'edit/:id',
				component: Edit,
				props: true
			}
		]
	}
];

export default routes;
