<template>
	<div class="FormGenerator">
		<div v-if="formLayout">
			<div v-for="section in formLayout.sections" class="FormSection">
				<h3 v-if="section.title" class="FormSection-title">{{ section.title }}</h3>
				<div
					v-for="row in section.rows"
					v-if="!(row.columns.length === 1 && !inputs[row.columns[0].key])"
					class="FormRow"
				>
					<div
						v-for="column in row.columns"
						v-if="inputs[column.key]"
						class="FormColumn"
						:style="column.style"
					>
						<InputLinkList
							v-bind="{
								inputKey: column.key,
								selectedNodeInputKey,
								onCreateLinkBtnClick,
								onClearLinkBtnClick,
								onDisableSelectionBtnClick,
								onCustomValueChange,
								onShowAdvancedInputField,
								onAddInputToInputNodeBtnClick,
								node,
								task,
								tasks,
								flow,
								inputValues: inputs[column.key],
								config,
								handleAddNodeInputRow,
								handleRemoveNodeInputRow,
								readOnly
							}"
						/>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!formLayout">
			<h3>{{ this.node.displayName && `${this.node.displayName} -` }}{{ $t('Settings') }}</h3>
			<div v-for="(inputValues, inputKey) in inputs" class="MainSettingsPanel-input">
				<InputLinkList
					v-bind="{
						inputKey,
						selectedNodeInputKey,
						onCreateLinkBtnClick,
						onClearLinkBtnClick,
						onDisableSelectionBtnClick,
						onCustomValueChange,
						onShowAdvancedInputField,
						onAddInputToInputNodeBtnClick,
						node,
						task,
						tasks,
						flow,
						inputValues,
						config,
						handleAddNodeInputRow,
						handleRemoveNodeInputRow,
						readOnly
					}"
				/>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import InputLinkList from '../../containers/Flow/edit/NodeInputFieldList';

export default {
	components: { InputLinkList },
	props: [
		'inputs',
		'formLayout',
		'selectedNodeInputKey',
		'onCreateLinkBtnClick',
		'onClearLinkBtnClick',
		'onDisableSelectionBtnClick',
		'onCustomValueChange',
		'onShowAdvancedInputField',
		'onAddInputToInputNodeBtnClick',
		'node',
		'task',
		'tasks',
		'flow',
		'config',
		'handleAddNodeInputRow',
		'handleRemoveNodeInputRow',
		'readOnly'
	]
};
</script>

<style lang="scss">
@import '../../style/colors';

.FormSection {
	.FormSection-title {
		background: whitesmoke;
	}
	.FormRow {
		display: flex;
		border-bottom: 1px solid $gray-border-color;
		.FormColumn {
			flex: 1;
			border-right: 1px solid $gray-border-color;
			padding: 10px 20px;
			&:last-child {
				border-right: none;
			}
		}
	}
}
</style>
