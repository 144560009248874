import Vue from 'vue';
import * as mutations from './mutations';
import * as getters from './getters';
import * as actions from './actions';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'attribute',
		options: { namespaced: false },
		transport: 'autoflow',
		state: {
			siteflowProduct: null
		},
		actions,
		mutations,
		getters
	});
}
