<template>
	<div class="HorizontalAlignmentField">
		<ul>
			<li
				v-for="option in getOptions()"
				class="HorizontalAlignmentField-item"
				:class="getItemClass(option)"
				:data-test-id="'horizontalAlign' + option.value"
				@click="!config.readOnly && handleItemClick(option)"
				@mouseover="handleItemMouseOver(option)"
				@mouseout="handleItemMouseOut(option)"
			></li>
		</ul>

		<div class="HorizontalAlignmentField-label" :class="labelClass">
			{{ label }}
		</div>
	</div>
</template>
<script type="text/javascript">
import axios from 'axios';
import _get from 'lodash/get';
export default {
	components: {},
	props: ['value', 'config', 'onChange'],
	data() {
		return {
			options: null,
			hoveredItem: null
		};
	},
	computed: {
		label() {
			const hoveredItemLabel = _get(this, 'hoveredItem.label');
			const selectedItemLabel = _get(this, 'selectedItem.label');
			return hoveredItemLabel || selectedItemLabel;
		},
		selectedItem() {
			return this.getOptions().find(option => option.value === this.value);
		},
		labelClass() {
			const isHoveringItem = !!this.hoveredItem && this.selectedItem !== this.hoveredItem;
			const isSelectedItem = !!this.selectedItem;
			return {
				'is-hoveringItem': isHoveringItem,
				'is-selectedItem': !isHoveringItem && isSelectedItem
			};
		}
	},
	created() {
		if (this.config.async) {
			this.asyncLoad();
		}
	},
	methods: {
		getOptions() {
			return this.options || this.config.options || [];
		},
		handleSelect(selectedItem) {
			selectedItem && this.onChange && this.onChange(selectedItem.value);
		},
		handleItemClick(option) {
			this.onChange && this.onChange(option.value);
		},
		handleItemMouseOver(option) {
			this.hoveredItem = option;
		},
		handleItemMouseOut(option) {
			this.hoveredItem = null;
		},
		asyncLoad() {
			const {
				url,
				headers,
				method = 'GET',
				body: data,
				query: params,
				afterSending = response => response.data
			} = this.config.async;

			return axios({
				method,
				url,
				headers,
				data,
				params
			})
				.then(this.parseFunction(afterSending))
				.then(options => (this.options = options));
		},

		parseFunction(fn) {
			if (typeof fn === 'function') return fn;

			return response => {
				const _fn = new Function('response', `return (${fn})(response)`);
				return _fn(response);
			};
		},

		getItemClass(option) {
			return {
				'is-selected': option.value === this.value
			};
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/utils';

.HorizontalAlignmentField {
	padding: 20px 0;
	text-align: center;
	> ul {
		@extend %u-listUnstyled;
		display: flex;
		width: 80px;
		margin: 0 auto;
		justify-content: space-between;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			z-index: 5;
			left: 0;
			width: 100%;
			top: calc(50% - 1px);
			height: 2px;
			border-bottom: 2px dotted $gray-border-color;
		}
	}
	.HorizontalAlignmentField-item {
		width: 10px;
		height: 10px;
		border: 2px solid $gray-border-color;
		border-radius: 50%;
		background: white;
		cursor: pointer;
		position: relative;
		z-index: 10;
		&:hover {
			background: $gray-border-color;
		}
		&.is-selected {
			background: $blue-brand-color;
			border: 2px solid $blue-brand-color;
		}
	}
	.HorizontalAlignmentField-label {
		margin-top: 5px;
		font-size: 12px;
		color: #7f7f7f;
		&.is-selectedItem {
			color: $blue-brand-color;
		}
	}
}
</style>
