import { event } from 'vue-analytics';

function trackEvent({ category, action, label, value }) {
	return event({
		eventCategory: category,
		eventAction: action,
		eventLabel: label,
		eventValue: value
	});
}

function trackEventFlowRun(flow) {
	trackEvent({
		category: 'flow',
		action: 'run',
		label: flow ? flow.id : null
	});
}

function trackEventFlowCreate() {
	trackEvent({
		category: 'flow',
		action: 'create'
	});
}

function trackEventFlowDuplicate(flow) {
	trackEvent({
		category: 'flow',
		action: 'duplicate',
		label: flow ? flow.id : null
	});
}

function trackEventFlowEdit(flow) {
	trackEvent({
		category: 'flow',
		action: 'edit',
		label: flow ? flow.id : null
	});
}

function trackEventFlowRename(flow) {
	trackEvent({
		category: 'flow',
		action: 'rename',
		label: flow ? flow.id : null
	});
}

function trackEventFlowDelete(flow) {
	trackEvent({
		category: 'flow',
		action: 'delete',
		label: flow ? flow.id : null
	});
}

function trackEventFlowSave(flow) {
	trackEvent({
		category: 'flow',
		action: 'save',
		label: flow ? flow.id : null
	});
}

function trackEventTaskInfo(task) {
	trackEvent({
		category: 'task',
		action: 'info',
		label: task ? task.name : null
	});
}

export default {
	trackEventFlowRun,
	trackEventFlowDuplicate,
	trackEventFlowEdit,
	trackEventFlowRename,
	trackEventFlowDelete,
	trackEventFlowSave,
	trackEventFlowCreate,
	trackEventTaskInfo
};
