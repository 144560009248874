import _get from 'lodash/get';
import _set from 'lodash/set';

export function UPDATE_AF_MENU(state, { path, content, updateMethod }) {
	const fullPath = path ? `menu.${path}` : 'menu';
	if (updateMethod === 'merge') {
		const item = _get(state, fullPath);
		_set(state, fullPath, { ...item, ...content });
	} else if (updateMethod === 'replace') {
		_set(state, fullPath, content);
	} else if (updateMethod === 'concat') {
		const items = _get(state, fullPath, []);
		_set(state, fullPath, items.concat(content));
	}
}
