/**
 * Click outside directive
 */
import Vue from 'vue';

const HANDLER = '_clickaway_handler';

function addListener(element, binding) {
	element[HANDLER] = e => {
		if (e.target !== element && !element.contains(e.target)) {
			binding.value();
		}
	};
	window.addEventListener('click', element[HANDLER], false);
}

function removeListener(element) {
	window.removeEventListener('click', element[HANDLER], false);
	delete element[HANDLER];
}

Vue.directive('clickOutside', {
	bind(element, binding) {
		if (typeof binding.value !== 'function') {
			if (process.env.NODE_ENV !== 'production') {
				Vue.util.warn(`ClickOutside only work with a function, received:
				v-${binding.name}="${binding.expression}"`);
			}
		} else {
			addListener(element, binding);
		}
	},
	update(element, binding) {
		removeListener(element);
		addListener(element, binding);
	},
	unbind(element) {
		removeListener(element);
	}
});
