import { LoopbackHttpTransport } from '@workflow-solutions/ofs-vuex-crud';
import Promise from 'bluebird';
import _fp from 'lodash/fp';
import isEqual from 'lodash/isEqual';
import Rx from 'rxjs';
import axios from 'axios';

const defaultOptions = Object.freeze({ globalErrorHandler: true });

const httpClient = {
	request: args => axios.request({ ...args, withCredentials: true })
};

class AutoflowHttpProviderInstance extends LoopbackHttpTransport {
	constructor() {
		super(window.$config, httpClient);
	}

	observe({ resourceName, query, id, observeData = {}, options = defaultOptions }) {
		const args = { resourceName, id, query, observeData, options };
		if (options.modality === 'changefeed') return this.observeWithChangefeed(args);

		return this.observeWithPolling(args);
	}

	observeWithChangefeed({ resourceName, query, observeData = {}, options = defaultOptions }) {
		return Promise.try(() => {
			if (observeData.channel) {
				return this.unsubscribe(observeData.channel);
			}
			return false;
		})
			.then(
				() =>
					new Promise((resolve, reject) => {
						this.primus.send(
							'invoke',
							{
								methodString: `${resourceName}.subscribeChangeFeed`,
								args: query
							},
							(err, data) => {
								if (err) {
									const { err: message } = err;
									return reject(new Error(message));
								}
								return resolve(data.channel);
							}
						);
					})
			)
			.then(channel => {
				const observable = Rx.Observable.create(observer => {
					const onData = data => {
						if (data.error) {
							this.handleError(data.error, { query, options, observeData });
							return observer.error(data);
						}

						return observer.next(data);
					};

					this.primus.on(channel, onData);

					return () => {
						if (channel) {
							this.unsubscribe(channel);
						}
					};
				});

				return { observable, data: { channel } };
			});
	}

	observeWithPolling({ resourceName, query, id, observeData = {}, options = defaultOptions }) {
		const path = id ? `${resourceName}s/${id}` : `${resourceName}s`;

		return Promise.try(() => {
			const observable = Rx.Observable.create(observer => {
				let previousData = null;
				const doCheck = async () => {
					const newQuery = _fp.flow(
						_fp.omit('fields'),
						_fp.defaultsDeep({ filter: { fields: ['id', 'updatedAt'] } })
					)(query);

					const { data } = await this.makeRequest({
						method: 'GET',
						path,
						query: newQuery,
						headers: {}
					});
					if (!isEqual(data, previousData)) {
						fetchData();
					}

					previousData = data;
				};

				const fetchData = () =>
					this.makeRequest({
						method: 'GET',
						path,
						query,
						headers: {}
					}).then(data => {
						if (data.error) {
							this.handleError(data.error, { query, options, observeData });
							return observer.error(data);
						}

						return observer.next(data.data);
					});

				doCheck();
				const interval = Rx.Observable.interval(2000).subscribe(() => doCheck());

				return () => (interval ? interval.unsubscribe() : null);
			}).share();

			return { observable };
		});
	}
}

const providerInstance = new AutoflowHttpProviderInstance({
	apiBase: /* 'http://localhost:3000' || */ window.$config.apiBase
});

export default providerInstance;
