<template>
	<div class="BoxesView">
		<li v-for="item in items">
			<router-link v-if="getLink(item)" :to="getLink(item)" tag="a">
				<Box :title="getTitle(item)" :link="getLink(item)" :actions="getActions(item)" />
			</router-link>

			<Box v-if="!getLink(item)" :title="getTitle(item)" :link="getLink(item)" :actions="getActions(item)" />
		</li>
	</div>
</template>

<script type="text/javascript">
import _get from 'lodash/get';
import Box from './Box.vue';
import './BoxesView.scss';

export default {
	components: {
		Box
	},
	props: ['items', 'config'],
	methods: {
		getTitle(item) {
			const titleField = _get(this, 'config.titleField') ? _get(this, 'config.titleField') : 'name';
			return _get(item, titleField);
		},
		getLink(item) {
			const baseLink = _get(this, 'config.baseLink');
			if (!baseLink) {
				return false;
			}
			return `${baseLink}/${item.id}`;
		},

		getActions(item) {
			return _get(this, 'config.getActions', () => [])(item);
		}
	}
};
</script>
