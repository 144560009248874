import _ from 'lodash';
import store2 from 'store2';
import { defaultListPreferences } from './defaultListPreferences';
import * as types from './types';

const resources = ['flow', 'workqueue'];

export const saveListPreferences = ({ commit, state }, { resource, prefs, mountPath }) => {
	const path = _.compact([resource, mountPath, 'listPreferences']).join('.');
	store2(path, prefs);
	commit(types.LIST_PREFERENCES, { resource, mountPath, prefs });
};

export const siteflowRequest = async ({ commit, dispatch, rootState }, data) => {
	const { connectApiBase } = rootState.$config;
	const { path } = data;

	if (!path) {
		throw new Error('Siteflow path does not provided');
	}

	try {
		const response = await dispatch('api/request', { ...data, path: `${connectApiBase}/api/${path}` });
		commit('SET_IS_SITEFLOW_USER', true);
		return response.data;
	} catch (err) {
		const status = _.get(err, 'response.status');

		if ([401, 403].includes(status)) {
			commit('SET_IS_SITEFLOW_USER', false);
			return false;
		}

		throw err;
	}
};

export const setDefaultPreferences = ({ commit, state }) =>
	resources.forEach(resource => saveListPreferences({ commit, state }, { resource, prefs: defaultListPreferences }));
