import pickBy from 'lodash/pickBy';
import reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import { getInputFormConfig } from './flowInspectionUtils';

const accessLevels = ['basic', 'standard', 'premium'];

export function validateNodeInput({ inputValue, inputFormConfig }) {
	if (!inputFormConfig) return true;
	const validation = inputFormConfig.validation || {};
	return reduce(
		pickBy(validation, (val, key) => ['required', 'match', 'number'].includes(key)),
		(acc, ruleValue, ruleKey) => {
			acc[ruleKey] = isValueValid(inputValue, { ruleKey, ruleValue });
			return acc;
		},
		{}
	);
}

export function validateNodeInputs(node, tasks) {
	if (!tasks) return true;

	return reduce(
		node.inputs,
		(acc, inputValuesList, inputKey) => {
			const task = tasks.find(t => t.name === node.taskName);
			if (!task) return acc;

			const inputFormConfig = getInputFormConfig(inputKey, task.form);
			const { values = [] } = inputValuesList || {};

			const isRequiredAndNoValue = _get(inputFormConfig, 'validation.required') && values.length === 0;

			const areAllValid =
				!isRequiredAndNoValue &&
				values.every(inputValue => {
					if (inputValue && inputValue.type === 'ref') return acc;

					const validationResult = validateNodeInput({
						inputValue: inputValue && inputValue.value,
						inputFormConfig
					});
					return Object.keys(validationResult).every(key => validationResult[key]);
				});

			return acc && areAllValid;
		},
		true
	);
}

export function validateFlow(flow = {}, tasks) {
	if (!flow) return false;
	const { nodes } = flow;
	if (!nodes || nodes.length <= 2) return false;
	return reduce(
		nodes,
		(acc, node) => {
			if (['input', 'end'].includes(node.type)) return acc;
			if (node.type === 'placeholder') return false;
			return acc && validateNodeInputs(node, tasks);
		},
		true
	);
}

function isValueValid(inputValue, { ruleValue, ruleKey }) {
	const isEmpty = inputValue === null || typeof inputValue === 'undefined' || String(inputValue) === '';
	if (ruleKey !== 'required' && isEmpty) return true;

	let result;
	switch (ruleKey) {
	case 'match':
		result = new RegExp(ruleValue).test(inputValue);
			break;
		case 'required':
			result = !ruleValue || !isEmpty;
		break;
	case 'number':
		result = true;
		if ((ruleValue.min || ruleValue.min === 0) && inputValue < ruleValue.min) result = false;
		if (ruleValue.max && inputValue > ruleValue.max) result = false;
			if (ruleValue.step && !Number.isInteger((inputValue * 100) / ruleValue.step / 100)) {
				result = false;
			}
			break;
	default:
			result = true;
		break;
	}

	return result;
}

// Returns true if has access, else the required access level
export function userHasAccessToRunFlow({ flow, tasks, userAccess }) {
	let requiredAccess = accessLevels[0];

	// If top access level, has full access
	if (accessLevels.indexOf(userAccess) === accessLevels.length - 1) {
		return true;
	}

	if (!flow.nodes) {
		return true;
	}

	const flowTasks = flow.nodes.map(node => node.taskName).filter(taskName => taskName !== undefined);

	_forEach(tasks, task => {
		if (flowTasks.includes(task.name)) {
			if (accessLevels.indexOf(userAccess) < accessLevels.indexOf(task.access)) {
				if (task.access && accessLevels.indexOf(requiredAccess) < accessLevels.indexOf(task.access)) {
					requiredAccess = task.access;
				}
			}
		}
		return true;
	});

	// If required flow level is permitted, return true
	if (accessLevels.indexOf(userAccess) >= accessLevels.indexOf(requiredAccess)) {
		return true;
	}

	return requiredAccess;
}

export function userHasAccess({ requiredAccess, userAccess }) {
	if (accessLevels.indexOf(userAccess) < accessLevels.indexOf(requiredAccess)) {
		return requiredAccess;
	}

	return true;
}
