import _map from 'lodash/map';
import _find from 'lodash/find';
import * as Sentry from '@sentry/browser';
import menuStructure from '../../router/menuStructure';

const findOneflowAccount = accounts => {
	const enviromentSuffix = window.$config.environment === 'stage' ? `-${window.$config.environment}` : '';
	const accountName = `oneflow${enviromentSuffix}`;

	return _find(accounts, { name: accountName });
};

export const fetchBaseData = async ({ dispatch, rootGetters }) => {
	const user = await dispatch('getUser');

	if (window.$config.isHpMode) {
		await dispatch('account/fetchCurrentHpAccount', null, { root: true });
		await dispatch('getUserAccess');
		return dispatch('auth/generateJWT', null, { root: true });
	}

	await dispatch('account/getUserMemberships', user._id);

	const currentAccount = rootGetters['account/currentAccount'];
	const accounts = _map(rootGetters['account/memberships'], 'account');
	if (!currentAccount) {
		const account = findOneflowAccount(accounts) || accounts[0] || {};
		await dispatch('account/setCurrentAccount', account._id);
	}
};

export const setAuthFromQueryString = async ({ dispatch }, { token, accountId }) => {
	// Siteflow: Save accountId and token to localStorage if they are provided in the query string
	if (accountId && token) {
		await dispatch('auth/setToken', token);
		await dispatch('auth/setAuthenticated', true);
		await dispatch('account/setCurrentAccount', accountId);

		await dispatch('fetchBaseData');
	}
};

export const getUser = async ({ commit, dispatch }) => {
	const { data: me } = await dispatch('api/request', { method: 'GET', path: 'users/me' });

	Sentry.configureScope(scope => {
		scope.setUser({
			id: me._id,
			email: me.email,
			username: me.username
		});
	});

	commit('SET_USER', me);
	return me;
};

export const resetMenuItems = async ({ dispatch }) => {
	await dispatch('menu/updateAfMenu', { content: menuStructure, updateMethod: 'replace' });
};

export const getUserAccess = async ({ commit, dispatch }) => {
	const { data } = await dispatch(
		'api/request',
		{
			path: 'enforcer/v1/organizations/entitlements?name=autoflow&type=application&canRun=true',
			method: 'GET',
			transport: 'printos'
		},
		{ root: true }
	);

	// Uncomment this to mock the entitlements when working on localhost
	/* const data = {
		applications: [
			{
				name: 'autoflow',
				description: 'Autoflow',
				canRun: true,
				canBuy: true,
				canGrant: true,
				shouldIncludeDevices: false
			}
		]
	}; */

	const autoflow = _find(data.applications, {
		name: 'autoflow'
	});

	const DEFAULT_VARIANT = 'premium';
	if (autoflow) {
		const userAccess = (autoflow.variantNames && autoflow.variantNames[0]) || DEFAULT_VARIANT;
		const canRun = autoflow.canRun;
		commit('SET_USER_ACCESS', { canRun, variantName: userAccess });
		return userAccess;
	}

	commit('SET_USER_ACCESS', { canRun: false, variantName: null });
	return null;
};
