<template>
	<div class="ConditionConfiguration">
		<div class="ConditionBuilder">
			<div class="ConditionBuilder-wrapper">
				<div class="ConditionBuilder-top hide">
					<h3>Rule 1</h3>
				</div>
				<div v-show="showNodeName" class="ConditionBuilder-if">
					<span class="text-bold">{{ node.displayName }}</span>
				</div>
				<div class="ConditionBuilder-if">
					if
					<select
						class="form-control"
						:value="logicalOperator"
						:disabled="readOnly"
						@change="handleLogicalOperatorChange"
					>
						<option value="or">any</option>
						<option value="and">all</option>
					</select>
					of the following applies
				</div>

				<div class="ConditionBuilder-rules">
					<ul>
						<li v-for="(rule, ruleIndex) in rules">
							<div class="ConditionBuilder-rule">
								<div class="ConditionBuilder-rule-content">
									<div class="ConditionBuilder-rule-input1">
										<NodeField
											:is-active="isInputLinkActive(rule.left)"
											:config="{
												allowModalitySwitching: false,
												modality: 'link',
												allowClearValue: true
											}"
											:on-create-link-btn-click="handleCreateLinkBtnClick(rule.left)"
											:on-disable-selection-btn-click="handleDisableSelectionBtnClick"
											:on-clear-link-btn-click="handleClearLinkBtnClick(rule.left)"
											:node="node"
											:flow="flow"
											:disabled="readOnly"
											:value="rule.left"
										/>
									</div>
									<div class="ConditionBuilder-rule-operator">
										<div class="form-group md-group">
											<select
												class="form-control"
												:value="rule.comparisonOp"
												:disabled="readOnly"
												@change="handleComparisonOperatorChange($event, ruleIndex)"
											>
												<option value="==">Equal to</option>
												<option value="!=">Not equal to</option>
												<option value="<">Less than</option>
												<option value="<=">Less or equal to</option>
												<option value=">">More than</option>
												<option value=">=">More or equal to</option>
												<option value="contains">Contains</option>
												<option value="notContains">Doesn't contain</option>
											</select>
										</div>
									</div>
									<div class="ConditionBuilder-rule-input2">
										<NodeField
											:is-active="isInputLinkActive(rule.right)"
											:config="{ allowModalitySwitching: false, allowClearValue: true }"
											:input-config="rule.right.dataType"
											:read-only="readOnly"
											:on-create-link-btn-click="handleCreateLinkBtnClick(rule.right)"
											:on-disable-selection-btn-click="handleDisableSelectionBtnClick"
											:on-clear-link-btn-click="handleClearLinkBtnClick(rule.right)"
											:on-custom-value-change="handleCustomValueChange(rule.right)"
											:node="node"
											:flow="flow"
											:value="rule.right"
										/>
									</div>
								</div>
								<div
									v-if="showAddMoreConditionsButton && !readOnly"
									class="ConditionBuilder-rule-actions"
								>
									<button
										class="ActionButton ActionButton--small button button--small button--round"
										@click="handleAddRuleBtnClick()"
									>
										<i class="ion-plus-round"></i>
									</button>
									<button
										class="ActionButton ActionButton--small button button--small button--round"
										:disabled="!canRemoveRule(ruleIndex)"
										@click="handleRemoveRuleBtnClick(ruleIndex)"
									>
										<i class="ion-minus-round"></i>
									</button>
								</div>
							</div>
						</li>
					</ul>
				</div>

				<!--<div class="ConditionBuilder-then">
                    then
                    <select class="form-control">
                        <option value="run" selected>Run</option>
                        <option value="dontrun">Don't run</option>
                    </select>
                </div>-->
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import startsWith from 'lodash/startsWith';
import _set from 'lodash/fp/set';
import throttle from 'lodash/throttle';
import pullAt from 'lodash/fp/pullAt';
import NodeField from './NodeInputField';

export default {
	components: {
		NodeField
	},
	props: {
		node: null,
		tasks: null,
		flow: null,
		onCreate: null,
		selectConditionRuleInput: null,
		selectedConditionRuleInput: null,
		updateCondition: {
			type: Function,
			default: () => () => {}
		},
		showNodeName: null,
		showAddMoreConditionsButton: {
			type: Boolean,
			default: true
		},
		readOnly: false
	},
	data() {
		return {
			selectedKey: null,
			selectedRuleInput: null
		};
	},
	computed: {
		condition() {
			return this.node.condition;
		},
		logicalOperator() {
			if (!this.condition) return null;
			return Object.keys(this.condition.logic)[0];
		},
		rules() {
			if (!this.condition) return [];
			return this.condition.logic[this.logicalOperator].map((rule, index) => {
				const comparisonOp = Object.keys(rule)[0];
				if (!comparisonOp) return {};

				const left = this.parseConditionVar(
					rule[comparisonOp][0],
					`${this.logicalOperator}.${index}.${comparisonOp}.0`
				);
				const right = this.parseConditionVar(
					rule[comparisonOp][1],
					`${this.logicalOperator}.${index}.${comparisonOp}.1`
				);

				if (left.nodeName) {
					const node = this.flow.nodes.find(node => node.name === left.nodeName);
					const task = this.tasks.find(task => task.name === node.taskName);
					if (!task) {
						right.dataType = { type: 'text' };
					} else {
						const output = task.outputs[left.field];
						right.dataType = { type: 'text' };
						if (
							comparisonOp === '==' ||
							comparisonOp === '!=' ||
							output.FormType === 'integer' ||
							output.FormType === 'fileSize'
						) {
							switch (output.FormType) {
							case 'textarea':
							case 'text':
							case 'file':
								right.dataType.type = output.FormType;
									break;
							case 'integer':
								case 'fileSize':
								right.dataType.type = 'number';
									break;
							case 'select':
								right.dataType.type = output.FormType;
									right.dataType.options = task.outputs[left.field].options;
								break;
							case 'objectEditor':
									right.dataType.type = 'textarea';
									break;
							default:
									break;
							}
						}
					}
				} else {
					right.dataType = { type: 'text' };
				}

				return {
					left,
					comparisonOp,
					right
				};
			});
		}
	},
	methods: {
		parseConditionVar(variable, path) {
			if (typeof variable === 'object' && startsWith(variable.var, '$state')) {
				const [, nodeName, field] = String(variable.var).split('.');
				return {
					type: 'ref',
					nodeName,
					field,
					path
				};
			}

			return { value: variable, path };
		},

		handleLogicalOperatorChange($event) {
			const logicalOperator = $event.target.value;
			const currentLogicalOperator = this.logicalOperator;
			const logic = this.condition.logic[currentLogicalOperator];

			this.updateCondition(_set('logic', { [logicalOperator]: logic }, this.condition));
		},

		handleCreateLinkBtnClick(selectedRuleInput) {
			return () => this.selectConditionRuleInput(selectedRuleInput);
		},

		handleClearLinkBtnClick(selectedRuleInput) {
			return () => this.updateCondition(_set(`logic.${selectedRuleInput.path}`, '', this.condition));
		},

		handleComparisonOperatorChange({ currentTarget: { value } }, ruleIndex) {
			const rules = this.condition.logic[this.logicalOperator];
			const rule = rules[ruleIndex];
			const oldComparisonOp = Object.keys(rule)[0];

			this.updateCondition(
				_set(`logic.${this.logicalOperator}[${ruleIndex}]`, { [value]: rule[oldComparisonOp] }, this.condition)
			);
		},
		handleDisableSelectionBtnClick() {},
		handleCustomValueChange(selectedRuleInput) {
			return throttle(
				value => this.updateCondition(_set(`logic.${selectedRuleInput.path}`, value, this.condition)),
				300
			);
		},
		onShowAdvancedInputField() {},
		isInputLinkActive(ruleInput) {
			return this.selectedConditionRuleInput && this.selectedConditionRuleInput.path === ruleInput.path;
		},
		handleAddRuleBtnClick() {
			this.updateCondition(
				_set(
					`logic.${this.logicalOperator}`,
					[...this.condition.logic[this.logicalOperator], { '==': ['', ''] }],
					this.condition
				)
			);
		},
		canRemoveRule() {
			return this.condition.logic[this.logicalOperator].length > 1;
		},
		handleRemoveRuleBtnClick(index) {
			const newArray = pullAt(index, this.condition.logic[this.logicalOperator]);
			this.updateCondition(_set(`logic.${this.logicalOperator}`, newArray, this.condition));
		}
	}
};
</script>
<style lang="scss">
@import '../../../style/colors';
@import '../../../style/utils';

.ConditionConfiguration {
	padding: 10px 0;
}

.ConditionBuilder {
	select {
		appearance: none;
		background: transparent;
		display: inline-block;
		width: auto;
		border: none;
		border-radius: 0;
		border-bottom: 1px solid $primary-color;
		padding: 2px;
		height: auto;
		margin: 0 2px;
	}
}

.ConditionBuilder .ConditionBuilder-top {
	padding: 10px 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	h3 {
		font-size: 16px;
		margin: 0;
	}
}

button.ActionButton {
	font-size: 14px;
	width: 20px;
	height: 20px;
	padding: 0 !important;
	border-color: #979797;
	color: #979797;
	line-height: 1;
	&.ActionButton--small {
		font-size: 12px;
		width: 16px;
		height: 16px;
		padding: 0;
	}
	i {
		line-height: 1;
	}
	span {
		display: block;
		line-height: 1;
		transform: translateY(-33%);
	}
}

.ConditionBuilder .ConditionBuilder-if {
	padding: 10px 15px;
}

.ConditionBuilder .ConditionBuilder-rules {
	background: #edf1f2;
	> ul {
		@extend %u-listUnstyled;
		> li {
			padding: 10px 15px;
		}
	}
}

.ConditionBuilder .ConditionBuilder-rule {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.ConditionBuilder-rule-content {
		display: flex;
		align-items: flex-end;
		flex-grow: 1;
		width: calc(100% - 30px);
		.ConditionBuilder-rule-input1,
		.ConditionBuilder-rule-operator,
		.ConditionBuilder-rule-input2 {
			/*width: calc(100% / 3);*/
			padding: 0 5px;
		}

		.ConditionBuilder-rule-input1,
		.ConditionBuilder-rule-input2 {
			width: 40%;
		}
		.ConditionBuilder-rule-operator {
			width: 20%;
		}

		.ConditionBuilder-rule-operator {
			select {
				width: 100%;
				min-height: 40px;
			}
		}
	}
	.ConditionBuilder-rule-actions {
		white-space: nowrap;
		margin-top: -2px;
	}
}

.ConditionBuilder .ConditionBuilder-then {
	padding: 10px 15px;
}
</style>
