<template>
	<form class="Filters">
		<template v-for="filter in leftFilters">
			<div v-if="filter.type === 'search'" :key="filter.name" class="form-group">
				<div class="input-container input-container--big has-icon">
					<i class="ion-ios-search-strong"></i>
					<input
						type="text"
						class="form-control input-lg"
						data-test-id="flowSearchInput"
						:value="getValue(filter)"
						:placeholder="$t('Search')"
						@keyup="handleChange(filter, $event)"
					/>
				</div>
			</div>
		</template>
		<template v-for="filter in rightFilters">
			<div v-if="filter.type === 'checkbox'" :key="filter.name">
				<input
					:id="filter.name"
					type="checkbox"
					:name="filter.key"
					:checked="getValue(filter)"
					@change="handleChange(filter, $event)"
				/>
				<label :for="filter.name" v-text="filter.label"></label>
			</div>
		</template>
	</form>
</template>
<script type="text/javascript">
import cloneDeep from 'lodash/cloneDeep';

export default {
	props: ['config', 'values', 'onChange'],
	data() {
		return {
			valuesCopy: {}
		};
	},
	computed: {
		leftFilters() {
			return this.config.filter(filter => filter.position !== 'right');
		},
		rightFilters() {
			return this.config.filter(filter => filter.position === 'right');
		}
	},
	created() {
		this.valuesCopy = cloneDeep(this.values);
	},
	methods: {
		getValue(filter) {
			const values = this.valuesCopy || {};
			const { name } = filter;
			return values[name];
		},

		handleChange(filter, event) {
			if (filter.type === 'checkbox') {
				filter.value = event.target.checked;
				this.onChange(filter, event.target.checked);
				return;
			}
			this.onChange(filter, event.target.value);
		}
	}
};
</script>
<style lang="scss">
.input-container.has-icon {
	position: relative;
	i {
		position: absolute;
		left: 7px;
		top: 50%;
		transform: translateY(-50%);
		color: #575757;
	}
	.form-control {
		padding-left: 20px;
		font-size: 14px;
	}

	&.input-container--big {
		i {
			left: 10px;
			font-size: 14px;
		}
		.form-control {
			padding-left: 30px;
		}
	}
}

.Filters {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
</style>
