<template>
	<div
		class="ConditionNode Toolbox-draggable"
		:class="getClass()"
		:data-test-identifier="`conditionNode${displayName}`"
		:data-node-id="node.id"
		:data-node-name="node.name"
	>
		<div
			class="ConditionNode-inner"
			:class="{
				'is-active': node === selectedNode,
				'has-dropzone': canBeDroppedOn
			}"
		>
			{{ displayName }}
			<div v-if="chartOptions.showNodeDeleteBtn" class="ConditionNode-delete" @click="handleDeleteBtnClick"></div>
		</div>
		<div class="ConditionNode-addBranch"><AddBranchIcon /></div>
		<div class="ConditionNode-addBranchFromTask"><AddBranchFromTaskIcon /></div>
	</div>
</template>

<script type="text/javascript">
import AddBranchIcon from '../icons/AddBranchIcon';
import AddBranchFromTaskIcon from '../icons/AddBranchFromTaskIcon';

export default {
	components: {
		AddBranchIcon,
		AddBranchFromTaskIcon
	},
	props: ['node', 'selectedNode', 'canBeDroppedOn', 'onDrop', 'onDeleteBtnClick', 'draggedTask', 'chartOptions'],
	computed: {
		displayName() {
			return this.node.label || this.node.displayName;
		}
	},
	methods: {
		handleDeleteBtnClick($event) {
			$event.stopPropagation();
			this.onDeleteBtnClick(this.node);
		},
		getClass() {
			const isDraggingBranch = this.canBeDroppedOn && this.draggedTask && this.draggedTask.type === 'branch';
			const isDraggingTaskOver = this.canBeDroppedOn && this.draggedTask && this.draggedTask.type === 'task';

			return {
				'is-active': this.node === this.selectedNode,
				'has-dropzone': this.canBeDroppedOn,
				'is-draggingBranch': isDraggingBranch,
				'is-draggingTask': isDraggingTaskOver
			};
		}
	}
};
</script>

<style lang="scss">
@import './Chart-utils';

.ConditionNode {
	z-index: 3;
	width: $shapeWidth;
	.ConditionNode-inner {
		@extend %PolygonNode;
		@extend %SelectableNode;
		margin: 0 auto;
		&.is-active {
			&:before {
				border-color: transparent $blue-background-color transparent transparent;
			}

			&:after {
				border-color: transparent transparent transparent $blue-background-color;
			}
		}
	}
	// Add branch
	.ConditionNode-addBranch {
		z-index: 3;
		position: absolute;
		left: 100%;
		top: 50%;
		display: none;
	}
	&.is-hovering.is-draggingBranch .Node-addBranch {
		display: block;
	}

	// Add branch from task
	.ConditionNode-addBranchFromTask {
		z-index: 3;
		position: absolute;
		left: 100%;
		top: 50%;
		margin-left: -98px;
		margin-top: -19px;
		transform: scale(0.8);
		display: none;
	}
	&.is-hovering.is-draggingTask .ConditionNode-addBranchFromTask {
		display: block;
	}

	.ConditionNode-delete {
		display: none;
		@extend %DeleteIcon;
	}
	&:hover .ConditionNode-delete {
		display: inline-block;
	}
}
</style>
