<template>
	<section class="LeftPanel">
		<div class="LeftPanel-top">
			<div class="WorkersSearch" @click="onShowSearch">
				<div v-if="mode === 'list'" class="WorkersSearch-left">
					<h4>{{ $t('Tasks') }}</h4>
				</div>
				<div v-if="mode === 'search'" class="WorkersSearch-left">
					<input
						type="text"
						class="form-control"
						placeholder="Start typing"
						data-test-id="searchInput"
						@keyup="handleSearchChange"
					/>
				</div>
				<div class="WorkersSearch-icon">
					<template v-if="mode === 'list'">
						<i class="ion-ios-search-strong" data-test-id="searchIcon" @click.stop="onShowSearch" />
					</template>
					<template v-if="mode === 'search'">
						<i class="ion-ios-close-outline" @click.stop="onCloseSearch" />
					</template>
				</div>
			</div>
		</div>
		<div class="LeftPanel-content">
			<toolbox
				:mode="mode"
				:search-value="searchValue"
				:on-drag-status-change="onDragStatusChange"
				:show-only-included-tasks="showOnlyIncludedTasks"
				:on-show-toolbox-task-details="onShowToolboxTaskDetails"
			/>
		</div>
		<div v-if="$config.isHpMode && userAccess" class="LeftPanel-footer">
			<button class="LeftPanel-settings" @click.prevent="onToggleToolboxOverlay">
				<img :src="settingsIcon" />
			</button>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import $ from 'jquery';
import Toolbox from './Toolbox';
import settingsIcon from '../../../../public/img/settings.svg';
import './LeftPanel.scss';

export default {
	props: [
		'mode',
		'onShowSearch',
		'onCloseSearch',
		'onSearchChange',
		'onDragStatusChange',
		'onShowToolboxTaskDetails',
		'onToggleToolboxOverlay',
		'showOnlyIncludedTasks'
	],
	data() {
		return {
			searchValue: null,
			settingsIcon
		};
	},
	computed: {
		...mapGetters(['$config', 'userAccess'])
	},
	components: {
		Toolbox
	},
	updated() {
		if (this.mode === 'search') {
			const input = $(this.$el).find('input');
			input.focus();
		} else {
			this.searchValue = '';
		}
	},
	methods: {
		handleSearchChange(event) {
			this.searchValue = event.target.value;
		}
	}
};
</script>
