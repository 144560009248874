<template>
	<section class="StartNodeConfiguration">
		<div class="ConfigPanel-section">
			<div class="ConfigPanel-section-body">
				<div
					v-for="(input, index) in formattedNode.inputs"
					v-if="formattedNode.inputs && formattedNode.inputs.length > 0"
					:key="index"
					class="StartNodeConfiguration-row"
				>
					<div class="StartNodeConfiguration-input">
						<div class="form-group md-group">
							<label>{{ $t('Name') }}</label>
							<input
								v-if="input.siteflowAttribute"
								type="text"
								class="form-control"
								:value="input.name || input.key"
								disabled
							/>
							<input
								v-else
								v-model="input.key"
								type="text"
								class="form-control"
								:disabled="readOnly"
								@change="handleChangeInput"
							/>
						</div>
					</div>

					<div class="StartNodeConfiguration-input">
						<div class="form-group md-group">
							<label>{{ $t('Type') }}</label>
							<select
								v-model="input.type"
								class="form-control"
								:disabled="readOnly || input.siteflowAttribute"
								@change="handleChangeInput"
							>
								<option value="text">{{ $t('String') }}</option>
								<option value="file">{{ $t('File') }}</option>
								<option value="number">{{ $t('Number') }}</option>
								<option value="boolean">{{ $t('Boolean') }}</option>
							</select>
						</div>
					</div>

					<div class="StartNodeConfiguration-input">
						<div class="form-group md-group">
							<label>{{ $t('Value') }}</label>
							<input
								:type="getInputType(input)"
								class="form-control"
								:value="input.value"
								:disabled="readOnly"
								@change="handleCustomValueChange($event, input, index)"
							/>
						</div>
					</div>

					<div v-if="!readOnly" class="StartNodeConfiguration-buttons">
						<button class="button button--small button--round" @click="handleRemoveInputBtnClick(index)">
							<i class="ion-minus-round"></i>
						</button>
					</div>
				</div>
				<div v-if="!formattedNode.inputs || !formattedNode.inputs.length" class="StartNodeConfiguration-empty">
					{{ $t('No inputs configured.') }}
				</div>

				<div class="StartNodeConfiguration-bottom">
					<button
						v-if="showSiteflowImport"
						class="button button--small"
						:style="{ marginRight: showSiteflowImport && !readOnly ? '10px' : 0 }"
						@click="handleImportAttributes()"
					>
						{{ $t('Import from Siteflow') }}
					</button>
					<button v-if="!readOnly" class="button button--small" @click="handleAddInputBtnClick()">
						<i class="ion-plus-round"></i> {{ $t('Add input') }}
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions } from 'vuex';
import InputLink from './input-link';

export default {
	components: {
		InputLink
	},
	props: ['flow', 'showSiteflowImport', 'node', 'setInput', 'readOnly'],
	computed: {
		formattedNode() {
			if (!this.node) {
				return {};
			}

			const { inputs = [] } = this.node;

			return { ...this.node, inputs: inputs.filter(input => input.type !== 'hidden') };
		}
	},
	methods: {
		...mapActions([
			'addStartNodeInput',
			'removeStartNodeInput',
			'checkRefStartNodeInput',
			'openImportSiteflowAttributesModal'
		]),
		handleCustomValueChange($event, input, inputIndex) {
			let val = $event.currentTarget.value;
			if (input.type === 'number') {
				val = Number(val);
			}

			if (input.type === 'boolean') {
				if (val === 'true') val = true;
				if (val === 'false') val = false;
			}

			this.setInput('value', inputIndex, { value: val, node: this.node });
		},
		handleAddInputBtnClick() {
			this.addStartNodeInput();
			this.checkRefStartNodeInput();
		},
		handleRemoveInputBtnClick(index) {
			this.removeStartNodeInput(index);
			this.checkRefStartNodeInput();
		},
		handleChangeInput() {
			this.checkRefStartNodeInput();
		},
		handleImportAttributes() {
			this.openImportSiteflowAttributesModal();
		},
		getInputType(input) {
			if (input.type === 'number') return 'number';

			return 'text';
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.StartNodeConfiguration {
	.ConfigPanel-section-body {
		.StartNodeConfiguration-row {
			display: flex;
			align-items: center;
			border-bottom: 1px solid $gray-border-color;
			padding: 10px 15px;
			&:last-child {
				margin-bottom: 20px;
			}
			.StartNodeConfiguration-input {
				width: 33%;
				padding: 0 5px;
			}
			.StartNodeConfiguration-buttons {
				flex-shrink: 1;
				padding: 0 5px;
				button {
					font-size: 12px;
					width: 16px;
					height: 16px;
					padding: 0;
					border-color: #979797;
					color: #979797;
					line-height: 1;

					i {
						line-height: 1;
					}
					span {
						display: block;
						line-height: 1;
						transform: translateY(-33%);
					}
				}
			}
		}
		.StartNodeConfiguration-bottom {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 10px 15px;
		}
		.StartNodeConfiguration-empty {
			padding: 10px 15px;
			color: $gray-text-color;
		}
	}

	.form-control {
		&:disabled {
			background-color: $gray-background-color !important;
			border-bottom-color: rgba(0, 0, 0, 0.28);
			cursor: not-allowed;
		}
	}
}
</style>
