<template>
	<div class="TaskDataIcon">
		<i :class="iconClass"></i>
	</div>
</template>
<script type="text/javascript">
export default {
	components: {},
	props: ['status'],
	computed: {
		iconClass() {
			const status = this.status;

			if (status === 'skipped') {
				return 'is-skipped ion-minus';
			}

			if (['notstarted', 'queued', 'started'].includes(status)) {
				return 'is-onHold ion-ios-loop-strong spinning';
			}

			if (status === 'ended') {
				return 'is-ended ion-checkmark';
			}

			if (status === 'failed') {
				return 'is-failed ion-alert';
			}
		}
	}
};
</script>

<style lang="scss">
@import '../style/colors';

$circleSideLength: 20px;
.TaskDataIcon {
	display: inline-block;
	width: $circleSideLength;
	height: $circleSideLength;
	z-index: 10;
	border-radius: 50%;
	overflow: hidden;
	i {
		width: 100%;
		height: 100%;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
	}
	i.is-skipped {
		background: $gray-border-color;
	}
	i.is-onHold {
		background: $blue-brand-color;
	}
	i.is-ended {
		background: $blue-brand-color;
	}
	i.is-failed {
		background: $danger-background-color;
	}
}
</style>
