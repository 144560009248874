import List from './list/WorkqueuesList.vue';
import View from './view/WorkqueueView.vue';

export const routes = [
	{
		path: '/activity',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'workqueues',
				path: '',
				component: List
			},
			{
				name: 'workqueues.view',
				path: 'view/:id',
				component: View
			}
		]
	}
];
