<template>
	<div ref="chart" class="Chart" :class="getClass()" @click="handleChartClick">
		<div id="canvas" class="jtk-demo-canvas canvas-wide perimeter-demo jtk-surface jtk-surface-nopan">
			<div v-for="node in flow.nodes" :key="node.id">
				<div
					:id="node.id"
					class="Chart-node"
					:class="getChartNodeContainerClass(node)"
					@mouseover="handleNodeHover(node)"
				>
					<div
						class="Chart-node-dropzone Chart-node-dropzone--top"
						:data-node-id="node.id"
						data-target="top"
						:class="{ 'is-visible': nodeHasDropzone('top', node) }"
					>
						<Dropzone :node="node" type="top" :on-drop="onTaskDrop" />
					</div>

					<div class="Chart-node-node">
						<GenericNode
							:node="node"
							:tasks="tasks"
							:upgrade="upgrade"
							:selected-node="selectedNode"
							:workqueue-task-data="getWorkqueueTaskData(node)"
							:set-input="setInput"
							:outputs-that-can-be-linked="outputsThatCanBeLinked"
							:on-drop="onTaskDrop"
							:can-be-dropped-on="nodeHasDropzone('node', node)"
							:on-node-click="handleNodeClick"
							:on-delete-btn-click="onDeleteNodeRequest"
							:dragged-task="draggedTask"
							:chart-options="chartOptions"
						/>
					</div>

					<div
						class="Chart-node-dropzone Chart-node-dropzone--bottom"
						:data-node-id="node.id"
						data-target="bottom"
						:class="{ 'is-visible': nodeHasDropzone('bottom', node) }"
					>
						<Dropzone :node="node" type="bottom" :on-drop="onTaskDrop" />
					</div>

					<transition name="output">
						<OutputsContainer
							v-if="areNodeOutputsVisible(node)"
							:node="node"
							:outputs="getNodeOutputs(node)"
							:linked-outputs="getNodeLinkedOutputs(node)"
							:on-output-click="onOutputSelection"
						/>
					</transition>
				</div>
			</div>
		</div>

		<slot></slot>
	</div>
</template>

<script type="text/javascript">
import $ from 'jquery';
import dagre from 'dagre';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import filter from 'lodash/filter';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import {
	getNodeParents,
	nodeHasTopDropzone,
	nodeHasBottomDropzone,
	canTaskBeDroppedOn
} from '../../lib/flowInspectionUtils';

import GenericNode from './GenericNode.vue';
import OutputsContainer from './OutputsContainer.vue';
import Dropzone from './Dropzone.vue';

import 'jsplumb';
import 'jsplumb/dist/css/jsplumbtoolkit-defaults.css';

// window.$ = $;

export default {
	props: {
		flow: null,
		chartOptions: {
			default: () => ({
				showNodeDeleteBtn: true,
				showWorkqueueProgress: false,
				jsPlumbPaintTimeout: 400,
				showInvalidNodes: true,
				redrawJSPlumb: true
			})
		},
		workqueue: null,
		tasks: null,
		upgrade: null,
		onNodeClick: {
			default: () => () => false
		},
		onCanvasClick: {
			default: () => () => false
		},
		selectedNode: null,
		selectNodeInputKey: null,
		setInput: null,
		outputsThatCanBeLinked: null,
		areNodeOutputsVisible: {
			default: () => () => false
		},
		areIoConnectionsVisible: null,

		onTaskDrop: null,
		onOutputSelection: null,
		taskDragStatus: null,
		draggedTask: null,
		onNodeHover: {
			default: () => () => false
		},
		onChartRendered: {
			default: () => () => false
		},
		onDeleteNodeRequest: null,
		onScroll: null,
		onDragStatusChange: null
	},
	computed: {
		...mapGetters(['layout']),
		nodeIndex() {
			_get(this, 'flow.nodes', []).reduce((nodeIndex, node) => {
				nodeIndex[node.id] = node;
				return nodeIndex;
			}, {});
		}
	},
	data() {
		const graphConfig = {
			nodesep: 50,
			ranksep: 50,
			marginx: 20,
			marginy: 10,
			rankdir: 'BT'

			// align: 'UL'
			// ranker: 'longest-path'
		};

		return {
			status: 'ready',
			overlayInfo: null,
			isOverlayShown: false,
			graphConfig,
			mousePosition: {
				top: 0,
				left: 0
			},
			firstRun: true
		};
	},
	components: {
		GenericNode,
		OutputsContainer,
		Dropzone
	},
	watch: {
		flow() {
			this.$nextTick(() => !isEmpty(this.flow) && this.initLayout(true));
			this.bindUI();
		},
		'flow.nodes': function() {
			this.$nextTick(() => !isEmpty(this.flow) && this.initLayout(false));
			this.bindUI();
		}
	},
	mounted() {
		this.$chart = $(this.$refs.chart);
		this.bindUI();
		this.$nextTick(() => !isEmpty(this.flow) && this.initLayout(true));
	},
	beforeDestroy() {
		this.unbindUI();
	},
	methods: {
		initLayout(shouldRerenderConnectors) {
			if (!this.flow || !this.flow.nodes) return false;
			jsPlumb.ready(() => {
				if (this.firstRun || (!this.firstRun && this.chartOptions.redrawJSPlumb && shouldRerenderConnectors)) {
					$('.jtk-endpoint, .jtk-connector').remove();
					this.jsp = jsPlumb.getInstance({
						PaintStyle: { strokeWidth: 3, stroke: '#E2DFDE' },
						Endpoint: ['Blank', { radius: 5 }],
						EndpointStyle: { fill: '#ffa500' },
						Container: 'canvas'
					});
				}

				this.jsp.batch(() => {
					if (
						this.firstRun ||
						(!this.firstRun && this.chartOptions.redrawJSPlumb && shouldRerenderConnectors)
					) {
						this.status = 'loading';
					}

					const g = new dagre.graphlib.Graph({ directed: true, compound: true, multigraph: false });
					g.setGraph(this.graphConfig);
					g.setDefaultEdgeLabel(() => ({}));

					const nodes = jsPlumb.getSelector('.Chart-node');
					nodes.forEach((node, index) => {
						const n = $(node);
						g.setNode(n.attr('id'), {
							width: Math.round(n.outerWidth()),
							height: Math.round(n.outerHeight())
						});
					});

					// suspend drawing and initialise.

					// loop through them and connect each one to each other one.
					this.flow.nodes.forEach(node => {
						const htmlNode = filter(nodes, n => node.id === Number(n.id));
						const parents = getNodeParents(node, this.flow.nodes).map(n => n.id);

						const parentsHtmlNodes = filter(nodes, n => parents.indexOf(Number(n.id)) > -1);
						parentsHtmlNodes.forEach(parentNode => {
							const minlen = this.calcMinNodeLen(node);
							g.setEdge(`${node.id}`, `${parentNode.id}`, {
								minlen
								// weight: 1
							});

							if (
								this.firstRun ||
								(!this.firstRun && this.chartOptions.redrawJSPlumb && shouldRerenderConnectors)
							) {
								const connection = this.jsp.connect({
									source: htmlNode, // just pass in the current node in the selector for source
									target: parentNode,
									connector: ['Flowchart', { cornerRadius: 9, midpoint: 0 }],
									anchor: ['Top', 'Bottom'],
									deleteEndpointsOnDetach: true
								});
							}
						});
					});

					dagre.layout(g);

					g.nodes().forEach(n => {
						const node = g.node(n);
						if (node) {
							const top = `${Math.round(node.y)}px`;
							const left = `${Math.round(node.x)}px`;
							$(`#${n}`).css({ left, top });
						}
					});

					const jsPlumbPaintTimeout = this.firstRun ? 0 : Number(this.chartOptions.jsPlumbPaintTimeout);
					setTimeout(() => {
						!this.firstRun &&
							this.chartOptions.redrawJSPlumb &&
							shouldRerenderConnectors &&
							this.jsp.repaintEverything();
						this.status = 'ready';
						this.firstRun = false;
						this.onChartRendered();
					}, jsPlumbPaintTimeout);
				});
			});
		},
		bindUI() {
			this.$chart.scroll(this.handleScroll);

			const _this = this;
			this.$nextTick(() => {
				const $elements = $('#canvas > div div.Toolbox-draggable');
				try {
					$elements.draggable('destroy');
				} catch (err) {}
				$elements.draggable({
					delay: 100,
					appendTo: '.Chart',
					helper: 'clone',
					cursor: 'move',
					revert: 'invalid',
					start() {
						_this.onDragStatusChange('dragging', this.dataset);
					},
					stop() {
						_this.onDragStatusChange('ready');
					}
				});
			});
		},

		unbindUI() {
			this.$chart.off('scroll', this.handleScroll);
		},

		handleScroll() {
			if (this.onScroll) {
				const scrollValues = `${this.$chart.scrollTop()} ${this.$chart.scrollLeft()}`;
				this.onScroll && this.onScroll(scrollValues);
			}
		},
		handleChartClick($event) {
			if ($event.target === this.$refs.chart) {
				// TODO: onCanvasClick
				this.onCanvasClick();
			}
		},
		handleNodeClick(node) {
			this.onNodeClick(node.name);
		},
		handleNodeHover(node) {
			this.onNodeHover(node);
		},
		getNodeOutputs(node) {
			return (
				(this.outputsThatCanBeLinked &&
					this.outputsThatCanBeLinked.get &&
					this.outputsThatCanBeLinked.get(node)) ||
				[]
			);
		},
		getNodeLinkedOutputs(node) {
			if (!this.selectedNode || this.selectedNode.inputs) return {};
			return pickBy(this.selectedNode.inputs, { nodeName: node.name });
		},
		nodeHasDropzone(position, node) {
			let checkDropzoneFn;
			const args = { node, nodes: this.flow.nodes, draggedTask: this.draggedTask };
			if (position === 'top') checkDropzoneFn = nodeHasTopDropzone.bind(this, args);
			if (position === 'bottom') checkDropzoneFn = nodeHasBottomDropzone.bind(this, args);
			if (position === 'node') checkDropzoneFn = canTaskBeDroppedOn.bind(this, args);

			return this.taskDragStatus === 'dragging' && checkDropzoneFn(node);
		},
		getChartNodeContainerClass(node) {
			return {
				'has-assignableOutputs':
					this.outputsThatCanBeLinked &&
					this.outputsThatCanBeLinked.get &&
					this.outputsThatCanBeLinked.get(node),
				'has-no-assignableOutputs':
					this.selectedNode !== node &&
					this.selectNodeInputKey &&
					this.outputsThatCanBeLinked &&
					this.outputsThatCanBeLinked.get &&
					!this.outputsThatCanBeLinked.get(node)
			};
		},

		calcMinNodeLen(node) {
			let minlen = 1;
			return minlen;
			const nodeHasMultipleChildren = node.next.length > 1 && node.childNodes[0].type !== 'circleCondition';
			const childHasMultipleParents = node.childNodes[0].parentNodes.length > 1;

			if (nodeHasMultipleChildren || childHasMultipleParents) {
				minlen = 1;
			}

			return minlen;
		},

		getWorkqueueTaskData(node) {
			if (!this.workqueue) return null;
			return _get(this, 'workqueue.tasks', []).find(t => t.name === node.name);
		},

		getClass() {
			return {
				[`is-${this.status}`]: true,
				'is-firstRun': this.firstRun,
				'Chart-workqueue': this.workqueue
			};
		}
	}
};
</script>

<style lang="scss">
@import './Chart-utils';
@import '../../style/colors';

.Chart {
	/*position: relative;
        height: 100%;*/
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 30;
	padding-top: 0;
	padding-bottom: 0;
	overflow: auto;

	&.is-firstRun {
		opacity: 0;
	}
	#canvas {
		position: relative;
		min-width: 200%;
		&:before {
			// content: '';
			position: absolute;
			top: 100%;
			height: 25%;
			width: 20px;
		}
		&:after {
			// content: '';
			position: absolute;
			left: 100%;
			width: 100%;
			height: 20px;
			top: 0;
		}
	}

	.Chart-node {
		position: absolute;
		transition: all 0.4s;
		&.has-no-assignableOutputs {
			opacity: 0.3;
		}
	}

	.Chart-node-node {
		height: $shapeHeight;
		display: flex;
		align-items: center;
	}
	.Chart-node-dropzone {
		position: absolute;
		left: 0;
		height: $shapeHeight / 1.5;
		width: $shapeWidth;
		z-index: 20;
		transition: all 0.2s;
		.Dropzone {
			position: relative;
			height: 0;
			width: 0;
			transition: all 0.2s;
			top: 50%;
			margin: 0 auto;
			transform: translateY(-50%);
		}
		&.is-visible {
			.Dropzone {
				width: 100%;
				height: 2px;
				border-bottom: 1px dashed #979797;
				position: relative;
				&:before {
					content: '\f3d3';
					font-family: 'Ionicons';
					color: #979797;
					position: absolute;
					right: 100%;
					top: 0;
					margin-top: -9px;
					transition: all 0.2s;
				}

				&:after {
					content: '\f3d2';
					font-family: 'Ionicons';
					position: absolute;
					color: #979797;
					left: 100%;
					top: -50%;
					margin-top: -9px;
					transition: all 0.2s;
				}
			}
		}

		&.is-hovering {
			.Dropzone {
				width: 100%;
				height: 100%;
				border: 1px dashed #979797;
				background: #f9f9f9;
				z-index: 100;
				&:before,
				&:after {
					opacity: 0;
				}
			}
		}
	}

	.Chart-node-dropzone--top {
		top: -((40 / 2) + $shapeHeight/4);
	}

	.Chart-node-dropzone--bottom {
		bottom: -((40 / 2) + $shapeHeight/4);
	}

	.jtk-surface-nopan {
		overflow: visible !important;
	}

	.jtk-surface {
		touch-action: manipulation !important;
	}

	/** JSPLUMB ARTEFACTS **/
	.jtk-connector {
		z-index: 1;
		transform: scaleY(1);
		//transition: transform 0.3s;
	}

	&.is-loading {
		.jtk-connector {
			opacity: 0;
			transform: scaleY(0);
		}
	}
}

/*
     * Animations
     */

//Outputs
.output-enter-active {
	animation: output-in 0.3s;
}

.output-leave-active {
	animation: output-out 0.3s;
}

@keyframes output-in {
	0% {
		opacity: 0;
		width: 0;
	}

	100% {
		opacity: 1;
		width: 200px;
	}
}

@keyframes output-out {
	0% {
		opacity: 1;
		width: 200px;
	}
	100% {
		opacity: 0;
		width: 0;
	}
}

.Chart-workqueue {
	@media (max-width: 992px) {
		padding-top: 0;
		padding-bottom: 170px;
	}
}

//Dropzone
.Chart-node-dropzone-enter-active {
	animation: Chart-node-dropzone-in 0.3s;
}

.Chart-node-dropzone-leave-active {
	animation: Chart-node-dropzone-out 0.3s;
}

@keyframes Chart-node-dropzone-in {
	0% {
		transform: scaleX(0);
	}

	100% {
		transform: scaleX(1);
	}
}

@keyframes Chart-node-dropzone-out {
	0% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0);
	}
}
</style>
