export const openRunningWorkqueueModal = (state, workqueue) => {
	state.runningWorkqueueModal = {
		...state.runningWorkqueueModal,
		isOpen: true,
		workqueue
	};
};

export const closeRunningWorkqueueModal = state => {
	state.runningWorkqueueModal = {
		...state.runningWorkqueueModal,
		isOpen: false,
		workqueue: null
	};
};
