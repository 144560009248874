import Vue from 'vue';
import _ from 'lodash';

export default function() {
	Vue.$ofsCrud.registerAccountModule({
		authBase: window.$config.authBase,
		state: {
			currentAccountId: null
		},
		actions: {
			setCurrentAccount({ commit }, accountId) {
				commit('SET_ACCOUNT', accountId);
			},
			async fetchCurrentHpAccount({ dispatch }) {
				const { data: account } = await dispatch('request', {
					method: 'get',
					path: 'accounts/current',
					transport: 'autoflow'
				});

				await dispatch('setCurrentAccount', account.id);
				return account;
			}
		},
		mutations: {
			SET_ACCOUNT(state, accountId) {
				state.currentAccountId = accountId;
			}
		},
		getters: {
			currentAccountId: state => state.currentAccountId,
			accounts: state => _.sortBy(_.map(state.memberships, 'account'), 'name'),
			currentAccount: (state, getters) => _.find(getters.accounts, { _id: getters.currentAccountId })
		}
	});
}
