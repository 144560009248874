import values from 'lodash/values';
import map from 'lodash/map';
import axios from 'axios';
import {
	addTaskToFlow,
	deleteNodeFromFlow,
	replaceFlowTask,
	addBranchToFlow,
	removeRefsFromInputs
} from '../../lib/flowManipulationUtils';
import { getNodeParents, getNodeChildren } from '../../lib/flowInspectionUtils';
import { sanitizeFlowBeforeSave } from '../../lib/flowSanitisationUtils';

export const newFlow = ({ commit }) => {
	commit('NEW_FLOW');
};

export const saveFlow = async ({ dispatch, commit }, { id, data }) => {
	let response;
	const sanitizedFlow = sanitizeFlowBeforeSave(data);

	if (id) {
		response = await dispatch('updateFlow', {
			id,
			data: sanitizedFlow
		});
	} else {
		response = await dispatch('createFlow', sanitizedFlow);
	}
	commit('FLOW_HAS_CHANGES', false);

	return response;
};

export const runFlow = async ({ commit, dispatch }, data) => {
	data.flow = sanitizeFlowBeforeSave(data.flow);
	return dispatch('api/request', {
		method: 'POST',
		path: 'flows/run',
		payload: data,
		options: { skipPostMiddleware: true }
	});
};

export const openRunFlowModal = async ({ commit, dispatch }, payload) => commit('OPEN_RUNFLOW_MODAL', payload);

export const openEditFlowInfoModal = async ({ commit }, payload) => commit('OPEN_EDIT_INFO_FLOW_MODAL', payload);

export const closeEditFlowInfoModal = async ({ commit }) => commit('CLOSE_EDIT_INFO_FLOW_MODAL');

export const openImportSiteflowAttributesModal = async ({ commit }, payload) =>
	commit('OPEN_IMPORT_SITEFLOW_ATTRIBUTES_MODAL', payload);

export const closeImportSiteflowAttributesModal = async ({ commit }) =>
	commit('CLOSE_IMPORT_SITEFLOW_ATTRIBUTES_MODAL');

export const closeRunFlowModal = async ({ commit }) => commit('CLOSE_RUNFLOW_MODAL');

export const handleTaskDrop = async (
	{ state, dispatch, commit },
	{ node, nodeName, nodeTaskStatus = 'live', target, flow, tasks, copyNode }
) => {
	const task = tasks.find(w => w.name === nodeName && w.status === nodeTaskStatus);
	if (!task) return false;

	const parentNodes = getNodeParents(node, flow.nodes);
	let newNode;

	if (target === 'node') {
		if (node.type === 'placeholder') {
			if (task.type === 'macro') {
				const childrenNodes = getNodeChildren(node, flow.nodes);
				await dispatch('deleteNode', { node, flow });

				newNode = await dispatch('addTask', {
					fromId: map(parentNodes, 'id'),
					toId: map(childrenNodes, 'id'),
					task,
					tasks
				});
			} else if (task.type !== 'branch') {
				newNode = await dispatch('replaceTask', {
					replaceId: node.id,
					task,
					flow,
					tasks
				});
			}
		} else {
			dispatch('addBranch', {
				node,
				task,
				nodes: flow.nodes,
				tasks
			});
		}
	} else if (target === 'top') {
		newNode = await dispatch('addTask', {
			fromId: parentNodes.map(n => n.id),
			toId: [node.id],
			task,
			tasks
		});
	} else if (target === 'bottom') {
		newNode = await dispatch('addTask', {
			fromId: [node.id],
			toId: node.next,
			task,
			tasks
		});
	}

	if (copyNode) {
		const currentNode = flow.nodes.find(el => el.id === newNode.id);
		currentNode.displayName = copyNode.displayName;
		currentNode.inputs = copyNode.inputs;
		currentNode.condition = copyNode.condition;
		await dispatch('deleteNode', { node: copyNode, flow });

		const checkNextNodes = (refsNode, nodeSearchName) => {
			if (refsNode.name === nodeSearchName) return true;
			if (refsNode.next && refsNode.next.length > 0) {
				let result = false;
				refsNode.next.forEach(id => {
					const nextNode = state.flow.nodes.find(nd => nd.id === id);
					result = result || checkNextNodes(nextNode, nodeSearchName);
				});
				return result;
			}
			return false;
		};

		Object.values(currentNode.inputs || {}).forEach(input => {
			input.values
				.filter(value => value.type === 'ref')
				.forEach((el, i) => {
					const refsNode = state.flow.nodes.find(nd => nd.name === el.nodeName);
					if (!checkNextNodes(refsNode, currentNode.name)) {
						input.values[i] = { type: 'custom', value: null };
					}
				});
		});

		dispatch('updateNode', { node: currentNode });
	} else {
		let isParentStartTask = false;
		let startTaskHasMultipleChildren = false;
		if ((node.type === 'input' && target === 'bottom') || (parentNodes[0] && parentNodes[0].type === 'input')) {
			isParentStartTask = true;
			if (parentNodes[0] && parentNodes[0].next) {
				startTaskHasMultipleChildren = node.next.length > 1;
			} else {
				startTaskHasMultipleChildren = parentNodes[0].next.length > 1;
			}
		}

		const droppedTask = (tasks || []).find(t => t.name === nodeName);
		const hasSomeInputs = droppedTask && Object.keys(droppedTask.inputs || {}).length > 0;

		if (isParentStartTask && !startTaskHasMultipleChildren && hasSomeInputs) {
			dispatch('setAddInputsToStartNodeModalVisible', true);
			const inputNode = flow.nodes.find(el => el.type === 'input');
			if (inputNode.next.length === 1) {
				const nextNodeId = inputNode.next[0];
				const nextNode = flow.nodes.find(el => el.id === nextNodeId);
				dispatch('selectNode', { name: nextNode.name });
			}
		}
	}

	commit('FLOW_HAS_CHANGES', true);
};

export const addTask = ({ state, commit, dispatch }, { fromId, toId, task, tasks, options }) => {
	const { flow } = state;
	const { newNodes, newNode } = addTaskToFlow({ flow, fromId, toId, task, tasks, options });
	commit('NEW_NODES', { nodes: newNodes });
	commit('FLOW_HAS_CHANGES', true);

	return newNode;
};

export const replaceTask = ({ state, commit, dispatch }, { replaceId, task, flow, tasks }) => {
	const { nodes, newNode } = replaceFlowTask({ replaceId, task, flow, tasks });

	commit('NEW_NODES', { nodes });
	commit('FLOW_HAS_CHANGES', true);
	return newNode;
};

export const deleteNode = ({ commit, state, dispatch }, { node, flow }) => {
	const nodes = deleteNodeFromFlow({ node, flow });
	const nodesWithRemovedInputRefs = removeRefsFromInputs(nodes, node);

	commit('NEW_NODES', { nodes: nodesWithRemovedInputRefs });
	commit('FLOW_HAS_CHANGES', true);
};

export const updateNode = ({ commit, state, dispatch }, { node }) => {
	const {
		flow: { nodes }
	} = state;
	const nodeIndex = nodes.findIndex(n => n.id === node.id);
	nodes[nodeIndex] = node;
	commit('NEW_NODES', { nodes });
	commit('FLOW_HAS_CHANGES', true);
};

export const addBranch = ({ commit, state, dispatch }, { node, task, nodes: nodeList, tasks }) => {
	const nodes = addBranchToFlow({ node, task, nodes: nodeList, tasks });
	commit('NEW_NODES', { nodes });
	commit('FLOW_HAS_CHANGES', true);
};

export const selectNode = ({ commit }, { name } = {}) => {
	commit('SELECT_NODE', { name });
};

export const deselectNode = ({ commit }) => {
	commit('DESELECT_NODE');
};

// function to assign input/outputs

export const setNodeInputValue = ({ commit, state, dispatch }, payload) => {
	commit('SET_NODE_INPUT_VALUE', payload);
	commit('FLOW_HAS_CHANGES', true);
};

export const setNodeInputsValue = ({ commit, state, dispatch }, payload) => {
	commit('SET_NODE_INPUTS_VALUE', payload);
	commit('FLOW_HAS_CHANGES', true);
};

export const clearNodeInputValue = ({ commit }, payload) => {
	commit('CLEAR_NODE_INPUT_VALUE', payload);
	commit('FLOW_HAS_CHANGES', true);
};

export const addNewStartTaskInput = ({ commit, state }, input) => {
	const {
		flow: { nodes }
	} = state;
	const startNode = nodes.find(n => n.type === 'input');
	startNode.inputs = values(startNode.inputs) || [];
	const alreadyExists = startNode.inputs.find(i => i.key === input.key && i.type === input.type);

	if (alreadyExists) {
		// TODO:
	} else {
		startNode.inputs.push(input);
	}

	commit('NEW_NODES', { nodes });
	commit('FLOW_HAS_CHANGES', true);

	return startNode;
};

export const updateNodeCondition = ({ commit, state }, { nodes = [], nodeName, condition }) => {
	const node = nodes.find(n => n.name === nodeName);
	node.condition = { ...condition };
	commit('NEW_NODES', { nodes });
	commit('FLOW_HAS_CHANGES', true);
};

export const addNodeInputRow = ({ commit }, { nodes, nodeName, inputKey, atIndex }) => {
	const node = nodes.find(n => n.name === nodeName);
	const input = node.inputs[inputKey];
	input.values.splice(atIndex, 0, {});
	commit('NEW_NODES', { nodes });
	commit('FLOW_HAS_CHANGES', true);
};

export const removeNodeInputRow = ({ commit }, { nodes, nodeName, inputKey, atIndex }) => {
	const node = nodes.find(n => n.name === nodeName);
	const input = node.inputs[inputKey];
	input.values.splice(atIndex, 1);
	commit('NEW_NODES', { nodes });
	commit('FLOW_HAS_CHANGES', true);
};

export const selectNodeInputKeyForLinking = ({ commit, state }, { inputKeyPath = '', selectedNode } = {}) => {
	commit('SELECT_NODE_INPUT_KEY_FOR_LINKING', inputKeyPath);
};

export const selectOutputKeyIndex = ({ commit, state }, { index } = {}) => commit('SELECT_OUTPUT_KEY_INDEX', index);

export const markFlowChanges = ({ commit }, flowHasChanges) => {
	commit('FLOW_HAS_CHANGES', flowHasChanges);
};

export const addStartNodeInput = ({ commit }) => {
	commit('ADD_START_NODE_INPUT');
	commit('FLOW_HAS_CHANGES', true);
};

export const removeStartNodeInput = ({ commit }, index) => {
	commit('REMOVE_START_NODE_INPUT', index);
	commit('FLOW_HAS_CHANGES', true);
};

export const openDeleteFlowModal = async ({ dispatch, commit }, flow) => {
	const title = `Delete ${flow.name}`;
	const body = 'Are you sure?';

	const toast = {
		title: 'Flow deleted successfully'
	};

	try {
		await dispatch('openConfirmModal', { title, body });
		await dispatch('deleteFlow', { id: flow.id });
		dispatch('displayToast', { data: toast });
		commit('FLOW_HAS_CHANGES', false);
		return true;
	} catch (err) {
		return false;
	}
};

export const setAddInputsToStartNodeModalVisible = ({ commit }, value) => {
	commit('IS_ADD_INPUTS_TO_START_NODE_MODAL_VISIBLE', value);
};

export const checkRefStartNodeInput = ({ commit, state, dispatch }) => {
	const {
		flow: { nodes }
	} = state;
	const inputNode = nodes.find(n => n.name === 'inputs');
	nodes.forEach(node => {
		for (const [key, input] of Object.entries(node.inputs)) {
			(input.values || []).forEach((value, indx) => {
				if (
					value.type === 'ref' &&
					value.nodeName === 'inputs' &&
					!inputNode.inputs.find(val => val.key === value.field)
				) {
					setNodeInputValue(
						{ commit, state, dispatch },
						{
							node,
							inputKeyPath: `${key}.values.${indx}`,
							value: {
								type: 'custom',
								value: null
							}
						}
					);
				}
			});
		}
	});
};

export const getEPMPresets = async ({ commit }) => {
	let { presets } = (
		await axios.get('https://files-static.hpsiteflow.com/EpmConfiguration/production/ProfilePresets.json')
	).data;

	presets = presets.map(p => {
		p._id = p.name;
		return p;
	});

	commit('SET_EPM_PRESETS', presets);
};

export const getEPMProfiles = async ({ commit, dispatch, getters }) => {
	const profiles = await dispatch('api/request', {
		method: 'GET',
		path: `${window.$config.fileApiBase}/api/epm-profiles`,
		query: {
			query: {
				$limit: 100
			}
		}
	}); // { total, limit, skip, data }

	commit('SET_EPM_PROFILES', profiles.data.data);
	commit('SET_ARE_PROFILES_FETCHED');

	return getters.epmProfiles;
};

export const loadEPMProfileValues = ({ commit }, profile) => {
	commit('LOAD_VALUES_FROM_EPM_PROFILE', profile);
};

export const nullifyProfileId = async ({ commit }) => {
	commit('NULLIFY_PROFILE_ID');
};

export const getEPMProfile = async ({ dispatch }, profileId) => {
	const response = await dispatch('api/request', {
		method: 'GET',
		path: `${window.$config.fileApiBase}/api/epm-profiles/${profileId}`
	});

	return response.data;
};

export const saveEPMProfile = async ({ dispatch }, profile) => {
	const response = await dispatch('api/request', {
		method: 'POST',
		path: `${window.$config.fileApiBase}/api/epm-profiles`,
		payload: profile
	});

	return response.data;
};

export const updateEPMProfile = async ({ dispatch }, profile) => {
	const response = await dispatch('api/request', {
		method: 'PATCH',
		path: `${window.$config.fileApiBase}/api/epm-profiles/${profile._id}`,
		payload: profile
	});

	return response.data;
};

export const deleteEPMProfile = async ({ dispatch }, profileId) => {
	const response = await dispatch('api/request', {
		method: 'DELETE',
		path: `${window.$config.fileApiBase}/api/epm-profiles/${profileId}`
	});

	return response.data;
};
