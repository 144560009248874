<template>
	<modal v-if="isModalOpen" class="EditFlowInfoModal" :show="'true'" method="mount" size="medium" :on-close="onClose">
		<h1 slot="header">
			{{ $t('Edit flow') }}
		</h1>

		<section v-if="flow" slot="body" v-focus>
			<div class="EditFlowInfoModal-name form-group md-group">
				<label class="control-label">{{ $t('Name') }}</label>
				<input v-model="flow.name" type="text" class="form-control" :placeholder="$t('Flow Name')" />
				<span v-if="flow.name && flow.name.length >= 30" class="error">
					{{ $t('Name too long') }}
				</span>
				<span v-if="!flow.name" class="error">{{ $t('Name is empty') }}</span>
			</div>
			<div class="EditFlowInfoModal-description form-group md-group">
				<label class="control-label">{{ $t('Description') }}</label>
				<textarea v-model="flow.description" class="form-control" />
			</div>
		</section>

		<div slot="footer">
			<button class="button" @click="onClose">{{ $t('Cancel') }}</button>
			<button type="submit" class="button button--success" @click="onSave">
				{{ $t('Save') }}
			</button>
		</div>
	</modal>
</template>
<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import get from 'lodash/get';
import Modal from '../../../components/modal';
import analytics from '../../../lib/analytics';

export default {
	computed: {
		...mapGetters(['editFlowInfoModal']),
		isModalOpen() {
			return get(this.editFlowInfoModal, 'isOpen', false);
		},
		flow() {
			return get(this.editFlowInfoModal, 'flow');
		}
	},
	components: {
		Modal
	},
	methods: {
		...mapActions(['closeEditFlowInfoModal', 'saveFlow']),
		onClose() {
			this.closeEditFlowInfoModal();
		},
		async onSave() {
			await this.saveFlow({ id: this.flow.id, data: this.flow });
			analytics.trackEventFlowSave(this.flow);
			this.onClose();
		}
	}
};
</script>

<style lang="scss">
.EditFlowInfoModal {
	.form-group {
		.error {
			color: red;
		}
	}

	&-description {
		textarea {
			resize: vertical;
		}
	}
}
</style>
