<template>
	<span>
		<input
			class="form-control"
			placeholder="type or select a value"
			type="number"
			:data-test-id="config.key"
			:disabled="config.readOnly"
			:value="value"
			:min="config.min"
			:max="config.max"
			:step="config.step"
			@input="handleCustomValueChange"
		/>
	</span>
</template>
<script type="text/javascript">
import toFinite from 'lodash/toFinite';
import throttle from 'lodash/throttle';

export default {
	props: ['value', 'onChange', 'config'],
	methods: {
		handleCustomValueChange: throttle(function(event) {
			this.onChange && this.onChange(toFinite(event.target.value) || event.target.value);
		}, 500)
	}
};
</script>
