<template>
	<modal
		v-if="isModalOpen"
		class="ImportSiteflowAttributesModal"
		data-test-id="attributesModal"
		:show="'true'"
		method="mount"
		size="large"
		:on-close="onClose"
	>
		<h1 slot="header">{{ $t('Import Siteflow Attributes') }}</h1>

		<section slot="body" v-focus>
			<p class="ImportSiteflowAttributesModal-text">
				{{ $t('Selected component attributes will be imported as Flow Inputs') }}
			</p>
			<div class="ImportSiteflowAttributesModal-product">
				<div class="ImportSiteflowAttributesModal-productSelect">
					<label class="control-label">{{ $t('Product') }}</label>
					<div>
						<select v-model="selectedProduct" data-test-id="attributesModalProductSelect">
							<option v-for="product in products" :key="product._id" :value="product">{{
								product.productCode
							}}</option>
						</select>
						<div>
							<img src="../../../../public/img/icon-double-arrow.svg" />
						</div>
					</div>
				</div>
				<div class="ImportSiteflowAttributesModal-productComponentSelect">
					<label class="control-label">{{ $t('Component') }}</label>
					<div>
						<select v-model="selectedComponent" data-test-id="attributesModalComponentSelect">
							<option v-for="component in productComponents" :key="component._id" :value="component">{{
								component.code
							}}</option>
						</select>
						<div>
							<img src="../../../../public/img/icon-double-arrow.svg" />
						</div>
					</div>
				</div>
			</div>
			<form class="ImportSiteflowAttributesModal-attributes">
				<table class="ImportSiteflowAttributesModal-table">
					<thead>
						<tr>
							<th>
								<input
									v-model="selectedAll"
									type="checkbox"
									:class="{ partial: !allAttributesAreSelected }"
									class="ImportSiteflowAttributesModal-checkbox"
								/>
							</th>
							<th>{{ $t('Name') }}</th>
							<th>{{ $t('Type') }}</th>
							<th>{{ $t('Default Value') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="property in properties" :key="`property-${property.key}`">
							<td>
								<input
									v-model="property.selected"
									type="checkbox"
									class="ImportSiteflowAttributesModal-checkbox"
								/>
							</td>
							<td>{{ property.name }}</td>
							<td>
								<div class="ImportSiteflowAttributesModal-typeSelect">
									<div>
										<img src="../../../../public/img/icon-double-arrow.svg" />
									</div>
									<select v-model="property.type">
										<option
											v-for="option in typeOptions"
											:key="`property-${option.value}`"
											:value="option.value"
											>{{ option.title }}</option
										>
									</select>
								</div>
							</td>
							<td>
								<input v-model="property.defaultValue" type="text" :placeholder="$t('no value')" />
							</td>
						</tr>
						<tr class="subHeader">
							<td colspan="4">{{ $t('Ship Address') }}</td>
						</tr>
						<tr v-for="property in shipAddress" :key="`shipAddress-${property.key}`">
							<td>
								<input
									v-model="property.selected"
									type="checkbox"
									class="ImportSiteflowAttributesModal-checkbox"
								/>
							</td>
							<td>{{ property.name }}</td>
							<td>
								<div class="ImportSiteflowAttributesModal-typeSelect">
									<div>
										<img src="../../../../public/img/icon-double-arrow.svg" />
									</div>
									<select v-model="property.type">
										<option
											v-for="option in typeOptions"
											:key="`property-${option.value}`"
											:value="option.value"
											>{{ option.title }}</option
										>
									</select>
								</div>
							</td>
							<td>
								<input v-model="property.defaultValue" type="text" :placeholder="$t('no value')" />
							</td>
						</tr>
						<tr v-if="!componentAttributesAreEmpty" class="subHeader">
							<td colspan="4">{{ $t('Component Attributes') }}</td>
						</tr>
						<tr v-for="property in attributes" :key="`attribute-${property.key}`">
							<td>
								<input
									v-model="property.selected"
									type="checkbox"
									class="ImportSiteflowAttributesModal-checkbox"
								/>
							</td>
							<td>{{ property.name }}</td>
							<td>
								<div class="ImportSiteflowAttributesModal-typeSelect">
									<div>
										<img src="../../../../public/img/icon-double-arrow.svg" />
									</div>
									<select v-model="property.type">
										<option
											v-for="option in typeOptions"
											:key="`property-${option.value}`"
											:value="option.value"
											>{{ option.title }}</option
										>
									</select>
								</div>
							</td>
							<td>
								<input v-model="property.defaultValue" type="text" :placeholder="$t('no value')" />
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</section>

		<div slot="footer">
			<button class="button" @click="onClose">{{ $t('Close') }}</button>
			<button
				type="submit"
				class="button button--success"
				data-test-id="attributesModalSaveButton"
				:disabled="!selectedComponent"
				@click="onSave"
			>
				{{ $t('Save Changes') }}
			</button>
		</div>
	</modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Modal from '../../../components/modal';
import analytics from '../../../lib/analytics';
import { properties, shipAddress } from '../../../lib/siteflowDefaultAttributes';

export default {
	name: 'ImportSiteflowAttributesModal',
	computed: {
		...mapGetters(['isSiteflowUser', 'flow', 'importSiteflowAttributesModal']),
		isModalOpen() {
			return _.get(this.importSiteflowAttributesModal, 'isOpen', false);
		},
		flowInputsAttributes() {
			const nodes = _.get(this.flow, 'nodes');
			const inputsNode = _.find(nodes, { name: 'inputs' });
			const attributes = _.get(inputsNode, 'inputs');
			return _.filter(attributes, { siteflowAttribute: true });
		},
		componentAttributes() {
			return _.get(this.selectedComponent, 'attributes', {});
		},
		componentAttributesAreEmpty() {
			return _.isEmpty(this.componentAttributes);
		},
		allAttributes() {
			return [...this.properties, ...this.shipAddress, ...this.attributes];
		},
		formattedAllAttributes() {
			const selectedAttributes = _.filter(this.allAttributes, { selected: true });
			return _.map(selectedAttributes, ({ key, type, name, defaultValue }) => ({
				key,
				type,
				name,
				defaultValue,
				siteflowAttribute: true
			}));
		},
		allAttributesAreSelected() {
			return _.every(this.allAttributes, { selected: true });
		},
		productId() {
			return _.get(this.flow, 'productId') || _.get(this.$route, 'query.productId');
		},
		componentId() {
			return _.get(this.flow, 'componentId') || _.get(this.$route, 'query.componentId');
		},
		typeOptions() {
			return [
				{ title: this.$t('File'), value: 'file' },
				{ title: this.$t('String'), value: 'text' },
				{ title: this.$t('Select'), value: 'select' },
				{ title: this.$t('Number'), value: 'number' },
				{ title: this.$t('Boolean'), value: 'boolean' }
			];
		},
		oneOrMoreAttributesAreSelected() {
			return _.some(this.allAttributes, 'selected');
		}
	},
	components: {
		Modal
	},
	filters: {
		type(type) {
			if (type === 'text') {
				return 'string';
			}

			return type;
		}
	},
	data() {
		return {
			selectedAll: false,
			selectedProduct: null,
			selectedComponent: null,
			productComponents: [],
			products: [],
			attributes: [],
			properties: [],
			shipAddress: []
		};
	},
	watch: {
		isModalOpen(open) {
			if (!open) {
				return this.clearModal();
			}

			this.getAllProducts();
			return this.selectAttributes();
		},
		isSiteflowUser() {
			if (typeof this.isSiteflowUser === 'boolean' && !this.isSiteflowUser) {
				this.onClose();
			}
		},
		flow(flow) {
			if (flow) {
				this.selectAttributes();
			}
		},
		selectedProduct(product) {
			if (product) {
				this.productComponents = product.components;
			} else {
				this.productComponents = [];
			}

			if (product && !_.find(product.components, this.selectedComponent)) {
				this.selectedComponent = _.isEmpty(product.components) ? null : product.components[0];
			}
		},
		componentAttributes() {
			const componentAttributes = _.filter(this.componentAttributes, attr => attr.type !== 'fileReady');
			this.attributes = _.map(componentAttributes, ({ name, type }) => ({
				name: _.startCase(_.toLower(name)),
				type,
				key: `attributes.${name}`,
				defaultValue: '',
				selected: this.isFlowInputsAttributes(`attributes.${name}`)
			}));
		},
		selectedAll(newVal) {
			if (this.oneOrMoreAttributesAreSelected && newVal) {
				return;
			}

			this.properties = _.map(this.properties, p => ({ ...p, selected: newVal }));
			this.shipAddress = _.map(this.shipAddress, p => ({ ...p, selected: newVal }));
			this.attributes = _.map(this.attributes, a => ({ ...a, selected: newVal }));
		},
		oneOrMoreAttributesAreSelected(val) {
			this.selectedAll = val;
		}
	},
	created() {
		this.siteflowCalls();
	},
	methods: {
		...mapActions(['closeImportSiteflowAttributesModal', 'getSiteflowProducts', 'displayToast', 'saveFlow']),
		onClose() {
			this.closeImportSiteflowAttributesModal();
		},
		clearModal() {
			this.selectedProduct = null;
			this.selectedComponent = null;
			this.productComponents = [];
			this.selectedAll = false;
			this.attributes = [];
			this.products = [];
		},
		selectAttributes() {
			const processProperties = p => ({ ...p, selected: this.isFlowInputsAttributes(p.key) });
			this.properties = _.map(_.cloneDeep(properties), processProperties);
			this.shipAddress = _.map(_.cloneDeep(shipAddress), processProperties);
		},
		async onSave() {
			const nodes = _.cloneDeep(this.flow.nodes);
			// Find inputs node index
			const inputsIndex = _.findIndex(nodes, { name: 'inputs' });
			// Get all original (no siteflow) inputs from the node
			const originalInputs = _.filter(nodes[inputsIndex].inputs, i => !i.siteflowAttribute);
			// Make node inputs array from the original inputs and current selected attributes
			nodes[inputsIndex].inputs = [...originalInputs, ...this.formattedAllAttributes];
			// Make flow based on the current flow
			let flow = { ...this.flow, nodes };

			// Make flow (if it has siteflow attributes) as a siteflow flow with current componentId
			if (!_.isEmpty(this.formattedAllAttributes)) {
				flow = {
					...flow,
					siteflow: true,
					productId: this.selectedProduct._id,
					componentId: this.selectedComponent._id
				};
			}

			await this.saveFlow({ id: this.flow.id, data: flow });
			analytics.trackEventFlowSave(flow);

			if (this.selectedProduct && this.selectedComponent) {
				this.$router.push({
					path: this.$route.path,
					query: {
						productId: this.selectedProduct._id,
						componentId: this.selectedComponent._id
					}
				});
			}

			this.onClose();
		},
		siteflowCalls() {
			return this.getSiteflowProducts({ pagesize: 1 });
		},
		async getAllProducts(page = 1) {
			const productsResponse = await this.getSiteflowProducts({ page });
			const products = _.get(productsResponse, 'data', []);
			const pages = _.get(productsResponse, 'pages');
			this.products = [...this.products, ...products];
			const product = _.find(products, { _id: this.productId });

			if (product) {
				this.selectedProduct = product;
				this.selectedComponent = _.find(product.components, { _id: this.componentId });
			}

			if (page < pages) {
				this.getAllProducts(page + 1);
			}
		},
		isFlowInputsAttributes(key) {
			return !!_.find(this.flowInputsAttributes, { key });
		}
	}
};
</script>

<style lang="scss">
.ImportSiteflowAttributesModal {
	&-text {
		font-size: 1.2em;
	}

	&-product {
		display: flex;
		justify-content: space-around;

		@media (max-width: 714px) {
			flex-direction: column;
		}

		&Select,
		&ComponentSelect {
			display: flex;
			align-items: center;

			@media (max-width: 714px) {
				flex-direction: column;
				align-items: flex-start;
			}

			label {
				font-size: 1.1em;
				color: #979797;
				font-weight: normal;
				margin: 0;
			}

			div {
				display: flex;
				align-items: center;

				select {
					z-index: 2;
					background-color: transparent;
					margin-left: 12px;
					border: 1px solid #e2dfde;
					min-height: 40px;
					padding: 6px 22px 6px 12px;
					width: 240px;

					@media (max-width: 714px) {
						margin-left: 0;
					}
				}

				div {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: -18px;
					width: 1px;
					height: 10px;
				}

				img {
					z-index: 1;
					width: 10px;
					height: 10px;
				}
			}
		}
	}

	&-checkbox {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: inline-block;
		position: relative;
		background-color: rgba(20, 145, 247, 0.2);
		color: #fff;
		height: 17px;
		width: 16px;
		border: 0;
		border-radius: 4px;
		cursor: pointer;
		border: 1px solid rgb(20, 145, 247);

		&:checked::before {
			position: absolute;
			font-size: 0.8em;
			left: 4px;
			content: '\02143';
			transform: rotate(45deg);
		}

		&:checked::after {
			position: absolute;
			font-size: 16px;
			font-weight: 900;
			content: '\2212';
			top: -4px;
			left: 3px;
			display: none;
		}

		&.partial {
			&:before {
				display: none;
			}

			&:after {
				display: block;
			}
		}

		&:hover {
			background-color: rgba(20, 145, 247, 0.4);
		}

		&:checked {
			background-color: rgba(20, 145, 247, 1);
		}
	}

	&-table {
		width: 100%;

		th {
			text-transform: uppercase;
			color: #979797;
			font-weight: normal;
			font-size: 0.9em;
		}

		td {
			text-transform: capitalize;
			font-size: 1.1em;

			&:last-child {
				color: #979797;
				font-style: italic;
				text-transform: none;

				input {
					background-color: transparent;
					border: none;
				}
			}

			.ImportSiteflowAttributesModal-checkbox {
				&:before {
					top: -2px;
				}
			}
		}

		th,
		td {
			padding: 0.4em;
			width: calc((100% - 30px) / 3);

			&:first-child {
				width: 30px;
			}
		}

		tbody {
			tr {
				&:nth-of-type(odd) {
					background: rgba(0, 0, 0, 0.02);
				}

				&.subHeader {
					padding-top: 10px;

					td {
						opacity: 0.8;
						padding-top: 15px;
						font-size: 1em;
						letter-spacing: 1px;
						font-style: normal;
						color: inherit;
						text-transform: uppercase;
					}
				}
			}
		}
	}

	&-typeSelect {
		display: flex;
		align-items: center;

		select {
			cursor: pointer;
			z-index: 2;
			padding-left: 15px;
			background-color: transparent;
			border: none;
		}

		div {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: -5px;
			width: 1px;
			height: 10px;
		}

		img {
			opacity: 0.4;
			z-index: 1;
			width: 10px;
			height: 10px;
		}
	}

	select {
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}
</style>
