<template>
	<span>
		<textarea
			class="form-control"
			placeholder="type or select a value"
			:data-test-id="config.key + 'Input'"
			:disabled="config.readOnly"
			:value="jsonValue"
			@input="handleCustomValueChange"
		/>
	</span>
</template>
<script type="text/javascript">
import debounce from 'lodash/debounce';

export default {
	props: ['value', 'onChange', 'config'],
	computed: {
		inputType() {
			return (this.config && this.config.type) || 'text';
		},
		jsonValue() {
			if (typeof this.value === 'object') return JSON.stringify(this.value);
			return this.value;
		}
	},
	methods: {
		handleCustomValueChange: debounce(function(event) {
			const value = event.target.value;
			let jsonValue = value;
			try {
				jsonValue = JSON.parse(value);
			} catch (err) {}

			this.onChange && this.onChange(jsonValue);
		}, 500)
	}
};
</script>
