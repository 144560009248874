import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';
import flow from './flow';
import files from './files';
import workqueue from './workqueue';
import task from './task';
import toasts from './toasts';
import user from './user';

window.$config = {
	isHpMode: false,
	...(window.$config || {})
};

const state = {
	$config: window.$config,
	isSiteflowUser: null
};

const createStore = () => {
	Vue.use(Vuex);
	return new Vuex.Store({
		state,
		getters,
		actions,
		mutations,
		modules: {
			flow,
			files,
			workqueue,
			task,
			toasts,
			user
		},
		plugins: [
			createPersistedState({
				key: 'autoflow',
				paths: ['auth.token', 'auth.isAuthenticated', 'account.currentAccountId', 'lang.lang']
			})
		]
	});
};

export default createStore;
