<template>
	<div class="NodeConfiguration">
		<div class="MainSettingsPanel-payloads">
			<div v-if="Object.keys(nodePayloadInputs).length > 0" class="MainSettingspanel-payloads-in">
				<h2>Inputs</h2>
				<div v-for="(inputVal, inputKey) in nodePayloadInputs" :key="inputKey">
					<InputLinkList
						:input-key="inputKey"
						:selected-node-input-key="selectedNodeInputKey"
						:on-create-link-btn-click="handleCreateLinkBtnClick"
						:on-clear-link-btn-click="handleClearLinkBtnClick"
						:on-disable-selection-btn-click="handleDisableSelectionBtnClick"
						:on-custom-value-change="handleCustomValueChange"
						:on-show-advanced-input-field="onShowAdvancedInputField"
						:on-add-input-to-input-node-btn-click="onAddInputToInputNodeBtnClick"
						:node="node"
						:task="task"
						:tasks="tasks"
						:flow="flow"
						:input-values="inputVal"
						:config="{ showInputsList }"
						:handle-add-node-input-row="handleAddNodeInputRow"
						:handle-remove-node-input-row="handleRemoveNodeInputRow"
						:read-only="readOnly"
					/>
				</div>
			</div>
			<div
				v-if="selectedNodeLinkedOutputs && selectedNodeLinkedOutputs.length > 0"
				class="MainSettingspanel-payloads-in"
			>
				<h2>Outputs</h2>
				<div v-for="(item, index) in selectedNodeLinkedOutputs" :key="index">
					<NodeOutputField
						:label="item.source.inputFormConfig.displayName"
						:value="
							`${item.destination.node.displayName} - ${item.destination.inputFormConfig.displayName ||
								item.destination.outputKey}`
						"
						:linked-to="item.destination.node.name"
						:output-key="item.destination.outputKey"
						:read-only="readOnly"
					/>
				</div>
			</div>
		</div>

		<!-- if it's an epm task -->
		<div
			v-if="Object.keys(nodeNormalInputs).length > 0 && task.name === 'epmCheck'"
			class="MainSettingsPanel-inputList"
		>
			<EPMProfileForm
				v-bind="{
					inputs: nodeNormalInputs,
					formLayout: task && task.formLayout, // shouldn't pass epm-layout here insted of defining in EPMProfileForm
					selectedNodeInputKey,
					onCreateLinkBtnClick: handleCreateLinkBtnClick,
					onClearLinkBtnClick: handleClearLinkBtnClick,
					onDisableSelectionBtnClick: handleDisableSelectionBtnClick,
					onCustomValueChange: handleCustomValueChange,
					onShowAdvancedInputField,
					onAddInputToInputNodeBtnClick,
					node,
					flow,
					task,
					tasks,
					config: { showInputsList },
					handleAddNodeInputRow,
					handleRemoveNodeInputRow,
					readOnly
				}"
			/>
		</div>
		<div
			v-if="Object.keys(nodeNormalInputs).length > 0 && task.name !== 'epmCheck'"
			class="MainSettingsPanel-inputList"
		>
			<FormGenerator
				v-bind="{
					inputs: nodeNormalInputs,
					formLayout: task && task.formLayout,
					selectedNodeInputKey,
					onCreateLinkBtnClick: handleCreateLinkBtnClick,
					onClearLinkBtnClick: handleClearLinkBtnClick,
					onDisableSelectionBtnClick: handleDisableSelectionBtnClick,
					onCustomValueChange: handleCustomValueChange,
					onShowAdvancedInputField,
					onAddInputToInputNodeBtnClick,
					node,
					flow,
					task,
					tasks,
					config: { showInputsList },
					handleAddNodeInputRow,
					handleRemoveNodeInputRow,
					readOnly
				}"
			/>
		</div>
	</div>
</template>

<script type="text/javascript">
import _get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { getNodeParents } from '../../../lib/flowInspectionUtils';
import InputLinkList from './NodeInputFieldList';
import NodeOutputField from './NodeOutputField';
import Modal from '../../../components/modal';
import FormGenerator from '../../../components/FormGenerator/FormGenerator';
import EPMProfileForm from './EPMProfileForm';

export default {
	components: {
		InputLinkList,
		NodeOutputField,
		Modal,
		FormGenerator,
		EPMProfileForm
	},
	props: [
		'flow',
		'node',
		'task',
		'tasks',
		'getAvailableInputValues',
		'selectNodeInput',
		'selectedNodeLinkedInputs',
		'selectedNodeLinkedOutputs',
		'selectedNodeInputKey',
		'setInput',
		'selectOutputKeyIndex',
		'openConditionsModal',
		'onDeleteTaskRequest',
		'onShowAdvancedInputField',
		'onAddInputToInputNodeBtnClick',

		'handleAddNodeInputRow',
		'handleRemoveNodeInputRow',

		'readOnly'
	],
	data() {
		return {
			availableInputValues: []
		};
	},
	computed: {
		showInputsList() {
			if (!this.node) return false;
			return getNodeParents(this.node, this.flow.nodes).length > 1;
		},
		taskPayloadInputs() {
			const inputNodes = _get(this, 'task.inputs');
			return pickBy(inputNodes, input => input.isPayload);
		},
		taskNormalInputs() {
			const inputNodes = _get(this, 'task.inputs');
			return pickBy(inputNodes, input => !input.isPayload && input.type !== 'hidden');
		},
		nodePayloadInputs() {
			const node = this.node;
			return mapValues(this.taskPayloadInputs, (inputValue, inputKey) => _get(node, `inputs.${inputKey}`));
		},
		nodeNormalInputs() {
			const node = this.node;
			return mapValues(this.taskNormalInputs, (inputValue, inputKey) => _get(node, `inputs.${inputKey}`));
		}
	},
	methods: {
		handleCreateLinkBtnClick(inputKeyPath) {
			this.selectNodeInput(inputKeyPath);
		},
		handleDisableSelectionBtnClick() {
			this.selectNodeInput();
		},
		handleClearLinkBtnClick(inputKeyPath) {
			this.setInput('value', inputKeyPath, { value: null });
		},

		handleCustomValueChange(inputKeyPath, value) {
			this.setInput('value', inputKeyPath, { value });
		},
		handleDeleteTaskBtnClick() {
			this.onDeleteTaskRequest(this.node);
		}
	}
};
</script>
<style lang="scss">
@import '../../../style/colors';

.NodeConfiguration {
	h2 {
		font-size: 16px;
		color: $blue-brand-color;
		margin: 0;
	}
	h3 {
		padding: 5px 20px;
		font-size: 14px;
		color: $blue-brand-color;
		margin: 0;
	}
	.MainSettingsPanel-payloads {
		padding: 10px 20px;
		border-bottom: 1px solid $gray-border-color;

		.MainSettingsPanel-payloads-in {
		}
		.MainSettingsPanel-payloads-out {
		}
	}
}

.MainSettingsPanel-inputList {
	.MainSettingsPanel-input {
		border-bottom: 1px solid $gray-border-color;
		padding: 10px 20px;
	}
}

.MainSettingsPanel-section {
	border-bottom: 1px solid $gray-border-color;
	padding: 10px 20px;
}
</style>
