import Vue from 'vue';

import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function() {
	const state = {
		user: null,
		printos: {
			canRun: null,
			userAccess: 'basic'
		}
	};

	Vue.$ofsCrud.addResource({
		name: 'user',
		options: { namespaced: false },
		transport: 'autoflow',
		actions,
		mutations,
		getters,
		state
	});
}
