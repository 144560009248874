import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import { defaultListPreferences } from '../defaultListPreferences';

export default function() {
	const state = {
		listPreferences: {
			...defaultListPreferences
		},
		runningWorkqueueModal: {
			isOpen: false,
			workqueue: null
		}
	};

	Vue.$ofsCrud.addResource({
		name: 'workqueue',
		options: { namespaced: false },
		transport: 'autoflow',
		actions,
		mutations,
		state
	});
}
