<template>
	<div v-show="isVisible()" class="MainSettingsPanel" :class="componentStyle">
		<div class="MainSettingsPanel-wrapper">
			<div class="MainSettingsPanel-top">
				<div class="MainSettingsPanel-title">
					<h3 v-if="!isEditingTitle">{{ node.displayName }}</h3>
				</div>
				<div v-if="hasTopActions" class="MainSettingsPanel-top-actions d-none d-md-block">
					<b-dropdown class="m-md-2">
						<b-dropdown-item @click="handleRenameNodeBtnClick()">
							<i class="ion-edit"></i> {{ $t('Rename') }}
						</b-dropdown-item>
					</b-dropdown>
				</div>
				<button class="button button--noBorder button--big d-md-none d-lg-none" @click="hidePanel()">
					{{ $t('Hide') }}
				</button>
			</div>
			<div class="MainSettingsPanel-body">
				<p v-if="requiredAccess !== true" class="MainSettingsPanel-access">
					<span class="UpgradeWarning">!</span> You need a {{ requiredAccess.toUpperCase() }} Plan to run this
					task. <a v-if="onUpgradeClick" href="#" @click.prevent="onUpgradeClick">Upgrade.</a>
				</p>
				<!-- Input -->
				<div v-if="node.type === 'input'">
					<StartNodeConfiguration
						v-bind="{
							flow,
							showSiteflowImport,
							selectNodeInput,
							node,
							getAvailableInputValues,
							selectedNodeInputKey,
							setInput,
							readOnly
						}"
					/>
				</div>
				<!-- /Input -->

				<!-- Task -->
				<div v-if="node.type === 'task' || node.type === 'placeholder'">
					<TaskConfiguration
						v-bind="{
							selectNodeInput,
							selectOutputKeyIndex,
							node,
							task,
							tasks,
							flow,
							onNodeNameChange,
							getAvailableInputValues,
							selectedNodeInputKey,
							setInput,
							onDeleteTaskRequest,
							onShowAdvancedInputField,
							onAddInputToInputNodeBtnClick,
							handleAddNodeInputRow,
							handleRemoveNodeInputRow,
							selectedNodeLinkedInputs,
							selectedNodeLinkedOutputs,
							readOnly
						}"
					/>
				</div>
				<!-- /Task -->

				<!-- Output -->
				<div v-if="node.type === 'end'">
					<EndNodeConfiguration
						v-bind="{
							flow,
							selectNodeInput,
							selectOutputKeyIndex,
							node,
							getAvailableInputValues,
							selectedNodeInputKey,
							selectedOutputKeyIndex,
							setInput,
							readOnly
						}"
					/>
				</div>
				<!-- /Output -->

				<!-- Condition -->
				<div v-if="node.type === 'circleCondition' || node.type === 'condition'">
					<ConditionConfiguration
						v-bind="{
							node,
							flow,
							tasks,
							selectConditionRuleInput,
							selectedConditionRuleInput,
							updateCondition,
							readOnly
						}"
					/>
				</div>
				<!-- /Condition -->
			</div>
		</div>

		<div v-dom-portal>
			<RenameNodeModal
				:show="isRenameNodeModalOpen"
				:node="nodeToRename"
				:on-confirm="handleRenameModal"
				:on-close="handleRenameNodeModalClose"
			/>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import { mapGetters } from 'vuex';
import TaskConfiguration from '../TaskConfiguration';
import StartNodeConfiguration from '../StartNodeConfiguration';
import ConditionConfiguration from '../ConditionConfiguration';
import EndNodeConfiguration from '../EndNodeConfiguration';
import RenameNodeModal from '../RenameNodeModal';
import { userHasAccess } from '../../../../lib/flowValidationUtils';

import './MainSettingsPanel.scss';

export default {
	components: {
		TaskConfiguration,
		StartNodeConfiguration,
		EndNodeConfiguration,
		ConditionConfiguration,
		RenameNodeModal
	},
	props: [
		'flow',
		'node',
		'task',
		'showSiteflowImport',
		'tasks',
		'onNodeNameChange',
		'getAvailableInputValues',
		'selectNodeInput',
		'selectedNodeLinkedInputs',
		'selectedNodeLinkedOutputs',
		'selectedNodeInputKey',
		'selectOutputKeyIndex',
		'selectedOutputKeyIndex',
		'setInput',
		'onAddInputToInputNodeBtnClick',
		'handleAddNodeInputRow',
		'handleRemoveNodeInputRow',

		'selectConditionRuleInput',
		'selectedConditionRuleInput',
		'updateCondition',

		'onDeleteTaskRequest',
		'onShowAdvancedInputField',
		'onUpgradeClick',

		'onScroll',
		'componentStyle',

		'hidePanel',
		'readOnly'
	],
	data() {
		return {
			isEditingTitle: false,
			isRenameNodeModalOpen: false,
			nodeToRename: null
		};
	},
	computed: {
		...mapGetters(['$config', 'userAccess']),
		hasTopActions() {
			return ['task', 'condition'].includes(this.node.type);
		},
		requiredAccess() {
			if (!$config.isHpMode || !this.task) {
				return true;
			}

			return userHasAccess({
				requiredAccess: this.task.access,
				userAccess: this.userAccess
			});
		}
	},
	mounted() {
		this.$element = $(this.$el);
		this.bindUI();
	},
	beforeDestroy() {
		this.unbindUI();
	},
	methods: {
		isVisible() {
			return !!this.node;
		},

		bindUI() {
			this.$element.scroll(this.handleScroll);
		},

		unbindUI() {
			this.$element.off('scroll', this.handleScroll);
		},
		handleScroll() {
			if (this.onScroll) {
				const scrollValues = `${this.$element.scrollTop()} ${this.$element.scrollLeft()}`;
				this.onScroll && this.onScroll(scrollValues);
			}
		},
		handleRenameNodeBtnClick() {
			this.isRenameNodeModalOpen = true;
			this.nodeToRename = this.node;
		},
		handleRenameNodeModalClose() {
			this.nodeToRename = null;
			this.isRenameNodeModalOpen = false;
		},
		handleRenameModal(value) {
			this.onNodeNameChange(value);
			this.isRenameNodeModalOpen = false;
		}
	}
};
</script>

<style lang="scss">
.MainSettingsPanel-access {
	padding: 20px;
	border-bottom: 1px solid #eee;
	display: flex;
	align-items: center;

	a {
		margin-left: 5px;
	}
}
</style>
