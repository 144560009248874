import Vue from 'vue';
import OfsCrud from '@workflow-solutions/ofs-vuex-crud';
import httpProviderInstance from './autoflowHttpProviderInstance';
import printosProviderInstance from './printosHttpProviderInstance';
import registerAuthModule from './auth';
import registerAccountModule from './account';
import registerConfirmModalsModule from './confirmModals';
import registerUserModule from './user';
import registerFlowModule from './flow';
import registerTaskModule from './task';
import registerProductModule from './product';
import registerWorkqueueModule from './workqueue';
import registerFilesModule from './files';
import registerMenuModule from './menu';
import registerUpgradeAccountModal from './upgradeAccountModal';

const setupModules = store => {
	Vue.use(OfsCrud, { store });
	Vue.$ofsCrud.registerTransport({ name: 'autoflow', fn: () => httpProviderInstance });

	if (window.$config.isHpMode) {
		Vue.$ofsCrud.registerTransport({ name: 'printos', fn: () => printosProviderInstance });
	}

	registerAuthModule(store);
	registerAccountModule(store);
	registerConfirmModalsModule(store);
	registerUserModule(store);
	registerFlowModule(store);
	registerTaskModule(store);
	registerWorkqueueModule(store);
	registerProductModule(store);
	registerFilesModule(store);
	registerMenuModule(store);
	registerUpgradeAccountModal(store);
};

export default setupModules;
