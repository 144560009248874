import _get from 'lodash/get';
import compact from 'lodash/compact';
import defaultsDeep from 'lodash/defaultsDeep';
import localStorageService from 'store2';
import { defaultListPreferences } from './defaultListPreferences';

export const $config = state => state.$config;

export const isSiteflowUser = state => state.isSiteflowUser;

export const getListPreferences = state => (resource, options = {}) => {
	const mountPath = options.mountPath;
	const path = compact([resource, mountPath, 'listPreferences']).join('.');
	const stateListPreferences = _get(state, path);
	let storedListPreferences = {};
	try {
		storedListPreferences = localStorageService(path);
	} catch (err) {}

	return defaultsDeep({}, storedListPreferences, stateListPreferences, defaultListPreferences);
};
