<template>
	<section class="Pagination">
		<div v-if="totalPages > 1" class="Pagination-wrapper">
			<ul>
				<!--<li :class="getFirstPageClass()"><a @click="handleFirstPageClick">First</a></li>-->
				<li :class="getPreviousPageClass()"><a @click="handlePreviousPageClick">Previous</a></li>
				<li v-for="p in getVisiblePages()" :class="getPageClass(p)">
					<a @click="handlePageClick(p)">{{ p }}</a>
				</li>
				<li :class="getNextPageClass()"><a @click="handleNextPageClick">Next</a></li>
				<!--<li :class="getLastPageClass()"><a @click="handleLastPageClick">Last</a></li>-->
			</ul>
		</div>
	</section>
</template>
<script type="text/javascript">
import Promise from 'bluebird';
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import './Pagination.scss';

export default {
	name: 'Pagination',
	props: ['totalPages', 'currentPage', 'onPageChange', 'status'],
	methods: {
		handleFirstPageClick() {
			this.onPageChange(1);
		},
		handlePreviousPageClick() {
			if (this.currentPage === 1) {
				return false;
			}

			this.onPageChange(this.currentPage - 1);
		},
		handlePageClick(pageNum) {
			if (pageNum === '...') {
				return false;
			}
			this.onPageChange(pageNum);
		},
		handleNextPageClick() {
			if (this.currentPage === this.totalPages) {
				return false;
			}
			this.onPageChange(this.currentPage + 1);
		},
		handleLastPageClick() {
			this.onPageChange(this.totalPages);
		},
		getFirstPageClass() {
			if (this.currentPage === 1) {
				return 'is-disabled';
			}
		},
		getPreviousPageClass() {
			if (this.currentPage === 1) {
				return 'is-disabled';
			}
		},
		getPageClass(pageNum) {
			if (pageNum === '...') {
				return 'is-disabled';
			}
			if (this.currentPage === pageNum) {
				return 'is-active';
			}
		},
		getNextPageClass() {
			if (this.currentPage === this.totalPages) {
				return 'is-disabled';
			}
		},
		getLastPageClass() {
			if (this.currentPage === this.totalPages) {
				return 'is-disabled';
			}
		},
		getVisiblePages() {
			let VISIBLE_PAGES = 7;
			VISIBLE_PAGES = VISIBLE_PAGES >= this.totalPages ? this.totalPages : VISIBLE_PAGES;

			const visiblePagesInterval = Math.floor(this.totalPages / VISIBLE_PAGES);
			const pages = [];
			let clickablePages = 0;

			//add first 2 pages
			pages.push(1);
			if (this.totalPages >= 2) {
				pages.push(2);
			}
			clickablePages = 2;

			////////add [...]
			if (this.currentPage > 2 && !includes(pages, this.currentPage - 2)) {
				pages.push('...');
			}

			////////add close pages to the current one
			if (this.currentPage - 1 > 0 && !includes(pages, this.currentPage - 1)) {
				pages.push(this.currentPage - 1);
				clickablePages++;
			}
			if (!includes(pages, this.currentPage)) {
				pages.push(this.currentPage);
				clickablePages++;
			}

			if (this.currentPage + 1 <= this.totalPages && !includes(pages, this.currentPage + 1)) {
				pages.push(this.currentPage + 1);
				clickablePages++;
			}

			////////add [...]
			if (this.currentPage + 2 < this.totalPages && !includes(pages, this.currentPage + 2)) {
				pages.push('...');
			}

			////////add last 2 pages
			if (this.totalPages >= 2 && !includes(pages, this.totalPages - 1)) {
				pages.push(this.totalPages - 1);
				clickablePages++;
			}
			if (!includes(pages, this.totalPages)) {
				pages.push(this.totalPages);
				clickablePages++;
			}

			//TODO
			let pagesLeft = VISIBLE_PAGES - clickablePages;

			return pages;
		}
	}
};
</script>
