<template>
	<div class="LoginPage">
		<div class="LoginPage-wrapper">
			<div class="LoginPage-top"><img src="../../../public/img/autoflow-horizontal-logo.svg" alt="" /></div>
			<div class="LoginPage-form">
				<form @submit.prevent="handleSubmit">
					<div v-if="errorMessage" class="form-group u-colorDanger">{{ errorMessage }}</div>
					<div class="form-group md-group">
						<label>{{ $t('Username') }}</label>
						<input type="text" name="username" class="form-control" @input="handleFormChange" />
					</div>
					<div class="form-group md-group">
						<label>{{ $t('Password') }}</label>
						<input type="password" name="password" class="form-control" @input="handleFormChange" />
					</div>

					<div class="LoginPage-form-actions u-textAlignRight">
						<button type="submit" class="button button--big" :disabled="!isFormValid || disableLoginBtn">
							{{ $t('Login') }}
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
	data() {
		return {
			formData: {
				username: null,
				password: null
			},
			errorMessage: null,
			disableLoginBtn: false
		};
	},
	computed: {
		...mapGetters('auth', ['isAuthenticated']),
		isFormValid() {
			const { username, password } = this.formData;
			return username && password && String(username).length > 2 && String(password).length > 3;
		}
	},
	created() {
		if (this.isAuthenticated) this.redirect();
	},
	methods: {
		...mapActions('auth', ['login', 'resetPassword']),
		...mapActions(['fetchBaseData']),
		handleFormChange($event) {
			const { currentTarget: { name, value } = {} } = $event;
			this.formData = {
				...this.formData,
				[name]: value
			};
		},
		async handleSubmit() {
			this.disableLoginBtn = true;
			this.errorMessage = null;
			const authError = this.$t('Authentication Error');
			try {
				await this.login(this.formData);
				await this.fetchBaseData();
				this.redirect();
			} catch (err) {
				const errorResponse = _get(err, 'response.data.error', {});
				this.errorMessage = errorResponse.message || authError;
				this.disableLoginBtn = false;
			}
		},
		redirect() {
			const redirectTo = _get(this, '$route.query.redirectTo');
			const path = redirectTo || '/';
			this.$router.push({ path });
		}
	}
};
</script>
<style lang="scss">
.LoginPage {
	width: 100vw;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f9f9f9;
	.LoginPage-wrapper {
		background: white;
		max-width: 400px;
		flex-grow: 1;
	}
	.LoginPage-top {
		background-color: #008ec7;
		padding: 10px 20px;
	}
	.LoginPage-form {
		padding: 20px;
		.LoginPage-form-actions {
			margin-top: 20px;
		}
	}
}
</style>
