import { uuid } from '../../lib/flowManipulationUtils';

const DEFAULT_TIMEOUT = 5000;

export const displayToast = ({ commit, dispatch }, { data: _data, timeout = DEFAULT_TIMEOUT }) => {
	const data = _data;

	const toastId = (data.id = data.id || uuid());
	if (data instanceof Error) {
		data.type = 'error';
		data.title = data.name;
		data.body = data.message;
	} else {
		data.type = data.type || 'success';
	}

	commit('DISPLAY_TOAST', data);

	setTimeout(() => {
		commit('HIDE_TOAST', toastId);
	}, timeout);
};

export const hideToast = ({ commit, dispatch }, { id }) => commit('HIDE_TOAST', id);
