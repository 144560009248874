import { render, staticRenderFns } from "./input-link.vue?vue&type=template&id=a896e298"
import script from "./input-link.vue?vue&type=script&lang=js"
export * from "./input-link.vue?vue&type=script&lang=js"
import style0 from "./input-link.vue?vue&type=style&index=0&id=a896e298&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports