<template>
	<div
		class="InfoPanel-progressList has-connectLine"
		data-test-id="progressPanel"
		:class="`InfoPanel-progressList--${workqueueData.status}`"
	>
		<ul>
			<li v-if="isQueued">
				<ProgressIcon status="queued"></ProgressIcon>
				<div>{{ $t('Queued') }}</div>
			</li>
			<li v-if="isNotStarted">
				<ProgressIcon status="queued"></ProgressIcon>
				<div>{{ $t('Not Started') }}</div>
			</li>
			<li v-if="isStarted">
				<ProgressIcon status="ended"></ProgressIcon>
				<div>{{ $t('Started') }}</div>
			</li>
			<li v-if="isRunning">
				<ProgressIcon status="started"></ProgressIcon>
				<div>{{ $t('Running') }}</div>
			</li>
			<li v-if="isCompleted">
				<ProgressIcon status="ended"></ProgressIcon>
				<div>{{ $t('Running') }}</div>
			</li>
			<li v-if="isCompleted">
				<ProgressIcon status="ended"></ProgressIcon>
				<div>{{ $t('Completed') }}</div>
			</li>
			<li v-if="isFailed">
				<ProgressIcon status="failed"></ProgressIcon>
				<div>{{ $t('Failed') }}</div>
			</li>
		</ul>
	</div>
</template>

<script type="text/javascript">
import _get from 'lodash/get';
import ProgressIcon from '../../../components/ProgressIcon';

export default {
	components: {
		ProgressIcon
	},
	props: {
		workqueue: {
			default: () => ({})
		}
	},
	computed: {
		workqueueData() {
			return _get(this, 'workqueue.data', {});
		},
		isStarted() {
			const status = _get(this, 'workqueue.data.status');
			return ['started', 'completed', 'failed'].includes(status);
		},
		isRunning() {
			return _get(this, 'workqueue.data.status') === 'started';
		},
		isCompleted() {
			return _get(this, 'workqueue.data.status') === 'completed';
		},
		isFailed() {
			return _get(this, 'workqueue.data.status') === 'failed';
		},
		isQueued() {
			return _get(this, 'workqueue.data.status') === 'queued';
		},
		isNotStarted() {
			const status = _get(this, 'workqueue.data.status');
			return !status || status === 'notstarted';
		}
	}
};
</script>
