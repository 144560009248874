<template>
	<span>
		<input type="text" class="form-control" :value="value" readonly />
	</span>
</template>

<script type="text/jsx">
export default{
	components: {},
	props: {
		config: Object,
		value: null
	},
	data() {
		return {};
	}
};
</script>

<style lang="scss" scoped>
input {
	background-color: transparent !important;
}
</style>
