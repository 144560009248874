<template>
	<span class="ImageSelectField">
		<ul>
			<li
				v-for="option in getOptions()"
				class="ImageSelectField-item"
				:class="getItemClass(option)"
				:data-test-id="'image' + option.value"
				@click="!config.readOnly && handleItemClick(option)"
			>
				<div class="ImageSelectField-item-wrapper">
					<div class="ImageSelectField-item-img">
						<img :src="option.image" />
					</div>

					<div class="ImageSelectField-item-label">
						{{ option.label }}
					</div>
				</div>
			</li>
		</ul>
	</span>
</template>
<script type="text/javascript">
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import _get from 'lodash/get';
export default {
	components: { Multiselect },
	props: ['value', 'config', 'onChange'],
	data() {
		return {
			options: null
		};
	},
	created() {
		if (this.config.async) {
			this.asyncLoad();
		}
	},
	methods: {
		getOptions() {
			return this.options || this.config.options;
		},
		handleSelect(selectedItem) {
			selectedItem && this.onChange && this.onChange(selectedItem.value);
		},
		handleItemClick(option) {
			this.onChange && this.onChange(option.value);
		},
		asyncLoad() {
			const {
				url,
				headers,
				method = 'GET',
				body: data,
				query: params,
				afterSending = response => response.data
			} = this.config.async;

			return axios({
				method,
				url,
				headers,
				data,
				params
			})
				.then(this.parseFunction(afterSending))
				.then(options => (this.options = options));
		},

		parseFunction(fn) {
			if (typeof fn === 'function') return fn;

			return response => {
				const _fn = new Function('response', `return (${fn})(response)`);
				return _fn(response);
			};
		},

		getItemClass(option) {
			return {
				'is-selected': option.value === this.value
			};
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/utils';

.ImageSelectField {
	ul {
		@extend %u-listUnstyled;
		display: flex;
		margin: 0 auto;
		li.ImageSelectField-item {
			padding: 0 5px;
			flex-grow: 1;
			&.is-selected {
				.ImageSelectField-item-img {
					border: 2px solid $blue-brand-color !important;
				}
				.ImageSelectField-item-label {
					color: rgba($blue-brand-color, 0.75);
				}
			}
			.ImageSelectField-item-wrapper {
				cursor: pointer;
				&:hover {
					.ImageSelectField-item-img {
						background: rgba($blue-brand-color, 0.21);
					}
				}
				.ImageSelectField-item-img {
					max-width: 80px;
					max-height: 80px;
					border-bottom: 1px solid $gray-border-color;
					padding: 10px;
					text-align: center;
					margin: 0 auto;
					img {
						margin-top: 50%;
						transform: translateY(-50%);
						max-width: 100%;
						max-height: 100%;
					}
				}
				.ImageSelectField-item-label {
					font-size: 12px;
					text-align: center;
				}
			}
		}
	}
}
</style>
