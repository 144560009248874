<template>
	<div class="CircleConditionNode">
		<div class="CircleConditionNode-inner" :class="getClass()">
			{{ displayName }}
		</div>
	</div>
</template>

<script type="text/javascript">
export default {
	props: ['node', 'selectedNode', 'canBeDroppedOn', 'onDrop'],
	computed: {
		displayName() {
			return this.node.label || this.node.displayName;
		}
	},
	methods: {
		getClass() {
			return {
				'is-active': this.node === this.selectedNode,
				'has-dropzone': this.canBeDroppedOn
			};
		}
	}
};
</script>

<style lang="scss">
@import './Chart-utils';

.CircleConditionNode {
	z-index: 3;
	width: $shapeWidth;
	.CircleConditionNode-inner {
		@extend %CircleNode;
		@extend %SelectableNode;
		margin: 0 auto;
		&.is-active {
			&:before {
				border-color: transparent $blue-background-color transparent transparent;
			}

			&:after {
				border-color: transparent transparent transparent $blue-background-color;
			}
		}
	}
}
</style>
