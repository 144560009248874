<template>
	<modal class="RenameFlowModal" :show="show" size="small">
		<h2 slot="header">
			{{ $t('Rename flow') }}
		</h2>

		<div slot="body" class="container-fluid">
			<div class="form-group md-group">
				<input v-model="newName" type="text" class="form-control" data-test-id="editNameInput" />
			</div>
		</div>

		<div slot="footer">
			<button class="button" @click="handleCancel(modal)">Cancel</button>
			<button
				class="button button--success"
				:disabled="!isFormValid"
				data-test-id="editNameSave"
				@click="handleOk(modal)"
			>
				Ok
			</button>
		</div>
	</modal>
</template>
<script type="text/javascript">
import { mapActions } from 'vuex';
import Modal from './../../components/modal.vue';

export default {
	props: ['show', 'flow', 'onClose'],
	data() {
		return {
			newName: ''
		};
	},
	computed: {
		flowName() {
			return (this.flow && this.flow.name) || '';
		},
		isFormValid() {
			return this.newName.length > 0;
		}
	},
	watch: {
		flow() {
			this.newName = this.flowName;
		}
	},
	methods: {
		...mapActions(['updateFlow', 'displayToast']),
		async handleOk() {
			await this.updateFlow({
				id: this.flow.id,
				data: {
					...this.flow,
					name: this.newName
				}
			});

			const toast = {
				title: this.$t('Flow Renamed successfully')
			};

			this.displayToast({ data: toast });
			this.onClose();
		},
		handleCancel(modal) {
			this.onClose();
		},
		isModalOpen(modal) {
			return modal.status === 'PENDING';
		}
	},
	components: {
		Modal
	}
};
</script>

<style lang="scss">
@import '../../style/colors';

.RenameFlowModal {
	.Modal-close {
		display: none;
	}
}
</style>
