var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible()),expression:"isVisible()"}],staticClass:"MainSettingsPanel",class:_vm.componentStyle},[_c('div',{staticClass:"MainSettingsPanel-wrapper"},[_c('div',{staticClass:"MainSettingsPanel-top"},[_c('div',{staticClass:"MainSettingsPanel-title"},[(!_vm.isEditingTitle)?_c('h3',[_vm._v(_vm._s(_vm.node.displayName))]):_vm._e()]),(_vm.hasTopActions)?_c('div',{staticClass:"MainSettingsPanel-top-actions d-none d-md-block"},[_c('b-dropdown',{staticClass:"m-md-2"},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleRenameNodeBtnClick()}}},[_c('i',{staticClass:"ion-edit"}),_vm._v(" "+_vm._s(_vm.$t('Rename'))+" ")])],1)],1):_vm._e(),_c('button',{staticClass:"button button--noBorder button--big d-md-none d-lg-none",on:{"click":function($event){return _vm.hidePanel()}}},[_vm._v(" "+_vm._s(_vm.$t('Hide'))+" ")])]),_c('div',{staticClass:"MainSettingsPanel-body"},[(_vm.requiredAccess !== true)?_c('p',{staticClass:"MainSettingsPanel-access"},[_c('span',{staticClass:"UpgradeWarning"},[_vm._v("!")]),_vm._v(" You need a "+_vm._s(_vm.requiredAccess.toUpperCase())+" Plan to run this task. "),(_vm.onUpgradeClick)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onUpgradeClick($event)}}},[_vm._v("Upgrade.")]):_vm._e()]):_vm._e(),(_vm.node.type === 'input')?_c('div',[_c('StartNodeConfiguration',_vm._b({},'StartNodeConfiguration',{
						flow: _vm.flow,
						showSiteflowImport: _vm.showSiteflowImport,
						selectNodeInput: _vm.selectNodeInput,
						node: _vm.node,
						getAvailableInputValues: _vm.getAvailableInputValues,
						selectedNodeInputKey: _vm.selectedNodeInputKey,
						setInput: _vm.setInput,
						readOnly: _vm.readOnly
					},false))],1):_vm._e(),(_vm.node.type === 'task' || _vm.node.type === 'placeholder')?_c('div',[_c('TaskConfiguration',_vm._b({},'TaskConfiguration',{
						selectNodeInput: _vm.selectNodeInput,
						selectOutputKeyIndex: _vm.selectOutputKeyIndex,
						node: _vm.node,
						task: _vm.task,
						tasks: _vm.tasks,
						flow: _vm.flow,
						onNodeNameChange: _vm.onNodeNameChange,
						getAvailableInputValues: _vm.getAvailableInputValues,
						selectedNodeInputKey: _vm.selectedNodeInputKey,
						setInput: _vm.setInput,
						onDeleteTaskRequest: _vm.onDeleteTaskRequest,
						onShowAdvancedInputField: _vm.onShowAdvancedInputField,
						onAddInputToInputNodeBtnClick: _vm.onAddInputToInputNodeBtnClick,
						handleAddNodeInputRow: _vm.handleAddNodeInputRow,
						handleRemoveNodeInputRow: _vm.handleRemoveNodeInputRow,
						selectedNodeLinkedInputs: _vm.selectedNodeLinkedInputs,
						selectedNodeLinkedOutputs: _vm.selectedNodeLinkedOutputs,
						readOnly: _vm.readOnly
					},false))],1):_vm._e(),(_vm.node.type === 'end')?_c('div',[_c('EndNodeConfiguration',_vm._b({},'EndNodeConfiguration',{
						flow: _vm.flow,
						selectNodeInput: _vm.selectNodeInput,
						selectOutputKeyIndex: _vm.selectOutputKeyIndex,
						node: _vm.node,
						getAvailableInputValues: _vm.getAvailableInputValues,
						selectedNodeInputKey: _vm.selectedNodeInputKey,
						selectedOutputKeyIndex: _vm.selectedOutputKeyIndex,
						setInput: _vm.setInput,
						readOnly: _vm.readOnly
					},false))],1):_vm._e(),(_vm.node.type === 'circleCondition' || _vm.node.type === 'condition')?_c('div',[_c('ConditionConfiguration',_vm._b({},'ConditionConfiguration',{
						node: _vm.node,
						flow: _vm.flow,
						tasks: _vm.tasks,
						selectConditionRuleInput: _vm.selectConditionRuleInput,
						selectedConditionRuleInput: _vm.selectedConditionRuleInput,
						updateCondition: _vm.updateCondition,
						readOnly: _vm.readOnly
					},false))],1):_vm._e()])]),_c('div',{directives:[{name:"dom-portal",rawName:"v-dom-portal"}]},[_c('RenameNodeModal',{attrs:{"show":_vm.isRenameNodeModalOpen,"node":_vm.nodeToRename,"on-confirm":_vm.handleRenameModal,"on-close":_vm.handleRenameNodeModalClose}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }