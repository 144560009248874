<template>
	<div class="Placeholder" :class="getClass()" :data-node-id="node.id" :data-node-name="node.name">
		<div class="Placeholder-delete" @click="handleDeleteBtnClick"></div>
	</div>
</template>

<script type="text/javascript">
export default {
	props: ['node', 'selectedNode', 'canBeDroppedOn', 'onDrop', 'onDeleteBtnClick', 'chartOptions'],
	methods: {
		handleDeleteBtnClick($event) {
			$event.stopPropagation();
			this.onDeleteBtnClick(this.node);
		},
		getClass() {
			return {
				'is-active': this.node === this.selectedNode,
				'has-dropzone': this.canBeDroppedOn
			};
		}
	}
};
</script>

<style lang="scss">
@import './Chart-utils';
@import '../../style/colors';

.Placeholder {
	z-index: 2;
	opacity: 0.9;
	text-align: center;
	color: #333;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $shapeWidth;
	height: $shapeHeight;
	background: transparent;
	border: 3px solid $gray-border-color;
	&.is-active {
		background: $blue-background-color;
		color: white;
		border: none;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
	}

	&.has-dropzone {
		border: 3px dashed $gray-border-color;
		background: white;
	}
	&.is-hovering {
		background: $gray-background-color;
	}

	.Placeholder-delete {
		display: none;
		@extend %DeleteIcon;
	}
	&:hover .Placeholder-delete {
		display: inline-block;
	}
}
</style>
