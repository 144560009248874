import Home from './Home.vue';

export const routes = [
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		redirect: '/flows'
	}
];
