<template>
	<section class="ConnectorsCanvas">
		<!--const bezierControlX = x1 + (x2 - x1) / 2;-->
		<svg
			v-if="canvasBoundRect"
			:width="canvasBoundRect.width"
			:height="canvasBoundRect.height"
			:viewBox="`0 0 ${canvasBoundRect.width} ${canvasBoundRect.height}`"
		>
			<g v-for="(path, pathIndex) in inputPaths" v-if="path" class="ConnectorsCanvas-inputs">
				<defs>
					<!--<marker :id="`arrowEnd${pathIndex}`" orient="auto" markerWidth="2" markerHeight="4" refX="0.1" refY="2" markerUnits="userSpaceOnUse">
                        <path d="M0,0 V4 L2,2 Z" fill="red"/>
                    </marker>-->
					<marker
						:id="`inputArrowStart${pathIndex}`"
						markerWidth="13"
						markerHeight="13"
						refX="2"
						refY="7"
						markerUnits="userSpaceOnUse"
						orient="auto"
					>
						<path class="ConnectorsCanvas-arrowPath" d="M2,2 L2,13 L8,7 L2,2"></path>
					</marker>
					<marker
						:id="`inputArrowEnd${pathIndex}`"
						markerWidth="13"
						markerHeight="13"
						refX="7"
						refY="7"
						markerUnits="userSpaceOnUse"
						orient="auto"
					>
						<path class="ConnectorsCanvas-arrowPath" d="M2,2 L2,13 L8,7 L2,2"></path>
					</marker>
				</defs>
				<path
					class="ConnectorsCanvas-path"
					:d="
						`M${path.x1},${path.y1} C${path.bezierControlX},${path.y1} ${path.bezierControlX},${path.y2} ${path.x2},${path.y2}`
					"
					:marker-start="`url(#inputArrowStart${pathIndex})`"
					:marker-end="`url(#inputArrowEnd${pathIndex})`"
				/>
			</g>

			<g v-for="(path, pathIndex) in outputPaths" v-if="path" class="ConnectorsCanvas-outputs">
				<defs>
					<!--<marker :id="`arrowEnd${pathIndex}`" orient="auto" markerWidth="2" markerHeight="4" refX="0.1" refY="2" markerUnits="userSpaceOnUse">
                        <path d="M0,0 V4 L2,2 Z" fill="red"/>
                    </marker>-->
					<marker
						:id="`outputArrowStart${pathIndex}`"
						markerWidth="13"
						markerHeight="13"
						refX="2"
						refY="7"
						markerUnits="userSpaceOnUse"
						orient="auto"
					>
						<path class="ConnectorsCanvas-arrowPath" d="M2,2 L2,13 L8,7 L2,2"></path>
					</marker>
					<marker
						:id="`outputArrowEnd${pathIndex}`"
						markerWidth="13"
						markerHeight="13"
						refX="7"
						refY="7"
						markerUnits="userSpaceOnUse"
						orient="auto"
					>
						<path class="ConnectorsCanvas-arrowPath" d="M2,2 L2,13 L8,7 L2,2"></path>
					</marker>
				</defs>
				<path
					class="ConnectorsCanvas-path"
					:d="
						`M${path.x1},${path.y1} C${path.bezierControlX},${path.y1} ${path.bezierControlX},${path.y2} ${path.x2},${path.y2}`
					"
					:marker-start="`url(#outputArrowStart${pathIndex})`"
					:marker-end="`url(#outputArrowEnd${pathIndex})`"
				/>
			</g>
		</svg>
	</section>
</template>

<script type="text/javascript">
import $ from 'jquery';
import _get from 'lodash/get';
export default {
	props: ['selectedNodeLinkedInputs', 'selectedNodeLinkedOutputs', 'scrollingValues'],
	data() {
		return {
			inputPaths: [],
			outputPaths: [],
			canvasBoundRect: null
		};
	},
	watch: {
		selectedNodeLinkedInputs() {
			this.$nextTick(() => this.createPaths());
		},
		selectedNodeLinkedOutput() {
			this.$nextTick(() => this.createPaths());
		},
		scrollingValues() {
			this.$nextTick(() => {
				this.calcCanvasBoundRect();
				this.createPaths();
			});
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.calcCanvasBoundRect();
			this.createPaths();
		});
	},
	methods: {
		calcCanvasBoundRect() {
			if (this.selectedNodeLinkedInputs && this.$el) {
				this.canvasBoundRect = this.$el.getBoundingClientRect();
			}
		},
		createPaths() {
			this.inputPaths = (this.selectedNodeLinkedInputs || []).map(item => {
				const { inputKey, inputValueIndex, nodeName } = item;

				if (!inputKey || !nodeName) return;
				const $node = $(`[data-node-name="${nodeName}"]`)[0];
				const $nodeField = $(`[data-input-name="${inputKey}.${inputValueIndex}"]:visible`)[0];

				return this.createPathCoords($node, $nodeField);
			});

			this.outputPaths = (this.selectedNodeLinkedOutputs || []).map(item => {
				const { source = {}, destination = {} } = item;
				const sourceNodeName = _get(item, 'source.node.name');
				const destinationNodeName = _get(item, 'destination.node.name');

				if (!sourceNodeName || !destinationNodeName) return;
				const $nodeOutputField = $(`[data-linked-to="${destinationNodeName}"]:visible`)[0];
				const $node = $(`[data-node-name="${destinationNodeName}"]`)[0];

				return this.createPathCoords($nodeOutputField, $node, ['left', 'right']);
			});
		},

		createPathCoords($from, $to, anchors = ['right', 'left']) {
			if (!$from || !$to) return;
			const { left: offsetLeft, top: offsetTop } = this.canvasBoundRect || {};
			const boundingClientRect1 = $from.getBoundingClientRect();
			const boundingClientRect2 = $to.getBoundingClientRect();

			const padding = 20;

			const x1 = boundingClientRect1[anchors[0]] - offsetLeft;
			const y1 = boundingClientRect1.bottom - boundingClientRect1.height / 2 - offsetTop;

			const leftPadding = 0;
			const x2 = boundingClientRect2[anchors[1]] - offsetLeft - (anchors[0] === 'right' ? padding : 0);
			const y2 = boundingClientRect2.bottom - boundingClientRect2.height / 2 - offsetTop;
			const bezierControlX = x1 + (x2 - x1) / 2;

			return { x1, y1, x2, y2, bezierControlX };
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.ConnectorsCanvas {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: 20;
	.ConnectorsCanvas-inputs {
		path.ConnectorsCanvas-path {
			stroke-width: 3px;
			stroke: $blue-brand-color;
			fill: none;
		}
		path.ConnectorsCanvas-arrowPath {
			stroke: $blue-brand-color;
			stroke-width: 1px;
			fill: $blue-brand-color;
		}
	}

	.ConnectorsCanvas-outputs {
		path.ConnectorsCanvas-path {
			stroke-width: 3px;
			stroke: #bfbfbf;
			fill: none;
		}
		path.ConnectorsCanvas-arrowPath {
			stroke: #bfbfbf;
			stroke-width: 1px;
			fill: #bfbfbf;
		}
	}
}
</style>
