import axios from 'axios';
import Rx from 'rxjs';

export const uploadFile = (signedUrl, file) => {
	const config = {
		headers: {
			'Content-Type': file.type
		}
	};

	let observer;
	const observable = Rx.Observable.create(observ => {
		observer = observ;
		return () => {};
	});

	config.onUploadProgress = progressEvent => {
		const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;
		return observer && observer.next(percentCompleted);
	};

	return {
		promise: axios.put(signedUrl, file, config),
		observable
	};
};
