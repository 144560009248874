import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import _get from 'lodash/get';
import _flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';

export function sanitizeFlow(flow) {
	if (isCorruptedFlow(flow)) return flow;

	return _flow(sanitizeStartNodeInputs, convertInputsToInputList, addTaskNameToNodes, reorderNodes)(flow);
}

function isCorruptedFlow(flow) {
	const inputNode = _get(flow, 'nodes', []).find(n => n.type === 'input');
	return !flow || !flow.nodes || !inputNode;
}

function sanitizeStartNodeInputs(flow) {
	const nodes = _get(flow, 'nodes', []);
	const inputNode = nodes.find(n => n.type === 'input');
	inputNode.inputs = values(inputNode.inputs);
	return flow;
}

function convertInputsToInputList(flow) {
	const nodes = _get(flow, 'nodes', []);
	flow.nodes = nodes.map(node => {
		if (node.type === 'input') return node;

		node.inputs = mapValues(node.inputs || {}, inputVal => {
			if (!inputVal) {
				return { values: [] };
			}
			if (!inputVal.values) {
				return { values: [inputVal] };
			}

			return inputVal;
		});

		return node;
	});

	return flow;
}

function addTaskNameToNodes(flow) {
	const nodes = _get(flow, 'nodes', []);
	flow.nodes = nodes.map(node => {
		if (node.type === 'input') return node;
		node.taskName = node.taskName || _get(node, 'task.name');
		delete node.task;

		return node;
	});

	return flow;
}

export function reorderNodes(flow, orderedNodes = new Set(), node) {
	if (!flow) return;
	if (!node) {
		node = flow.nodes.find(n => n.name === 'inputs');
		orderedNodes.add(node);
	}

	(node.next || []).forEach(nodeId => {
		const node = flow.nodes.find(n => n.id === nodeId);
		if (node) {
			orderedNodes.add(node);
			flow = reorderNodes(flow, orderedNodes, node);
		}
	});

	// exit case
	if (orderedNodes.size === flow.nodes.length) {
		flow.nodes = Array.from(orderedNodes.values());
		return flow;
	}

	return flow;
}

export function sanitizeFlowBeforeSave(flow) {
	return _flow(compactInputLists)(flow);
}

function compactInputLists(flow) {
	const nodes = _get(flow, 'nodes', []);
	flow.nodes = nodes.map(node => {
		if (node.type === 'input') return node;

		node.inputs = mapValues(node.inputs || {}, inputVal => {
			inputVal.values = (inputVal.values || []).filter(v => typeof v === 'object' && !isEmpty(v));
			return inputVal;
		});

		return node;
	});

	return flow;
}
