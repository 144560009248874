<template>
	<modal v-if="task" class="TaskDetailsModal" :show="show" :on-close="onClose" size="large">
		<h2 slot="header">
			{{ task.displayName }}
		</h2>

		<div slot="body">
			<div>
				<h2 class="task-description">{{ $t('Description') }}</h2>
				<div>{{ task.description || $t('No description') }}</div>
			</div>
			<hr />
			<div v-if="task.type === 'task'">
				<h3>Inputs</h3>
				<table class="table">
					<thead>
						<tr>
							<th>{{ $t('Display Name') }}</th>
							<th>{{ $t('Type') }}</th>
							<th>{{ $t('Description') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="value in task.form">
							<td>{{ value.displayName }}</td>
							<td>{{ value.type }}</td>
							<td>{{ value.description }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-if="task.type === 'task'">
				<h3>Outputs</h3>
				<table class="table">
					<thead>
						<tr>
							<th>{{ $t('Display Name') }}</th>
							<th>{{ $t('Description') }}</th>
							<th>{{ $t('Form Type') }}</th>
							<th>{{ $t('Key') }}</th>
							<th>{{ $t('Type') }}</th>
							<th>{{ $t('Is Payload') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="value in task.outputs">
							<td>{{ value.displayName }}</td>
							<td>{{ value.description }}</td>
							<td>{{ value.FormType }}</td>
							<td>{{ value.key }}</td>
							<td>{{ value.type }}</td>
							<td>{{ value.isPayload }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</modal>
</template>
<script type="text/javascript">
import Modal from '../../../components/modal.vue';

export default {
	components: {
		Modal
	},
	props: ['show', 'task', 'onClose'],
	data() {
		return {
			names: {},
			selections: {}
		};
	},
	methods: {
		handleCancel() {
			this.onClose();
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.TaskDetailsModal {
	table {
		thead {
			tr {
				th {
					color: $gray-text-color;
					font-weight: normal;
				}
			}
		}
		tbody {
			tr {
				td {
					text-align: left;
					vertical-align: middle;
				}
			}
		}
	}
	h2 {
		text-transform: capitalize;
		&.task-description {
			color: $blue-brand-color;
		}
	}
	i {
		padding-left: 5px;
	}
}
</style>
