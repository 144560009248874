<template>
	<div class="Toast">
		<ul>
			<transition v-for="toast in orderedToasts" key="toast" name="toast-appear" appear>
				<li>
					<section class="Toast-item" :class="getToastClass(toast)">
						<div v-if="toast.icon !== false" class="Toast-item-icon">
							<i :class="getIcon(toast)"></i>
						</div>
						<div class="Toast-item-content">
							<div class="Toast-item-title">{{ toast.title }}</div>
							<div class="Toast-item-description">{{ toast.body }}</div>
						</div>
					</section>
				</li>
			</transition>
		</ul>
	</div>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters(['toasts']),
		orderedToasts() {
			return (this.toasts && this.toasts.reverse()) || [];
		}
	},
	methods: {
		getIcon(toast) {
			if (toast.icon) return toast.icon;
			switch (toast.type) {
				case 'success':
				return 'ion-ios-checkmark-outline u-colorSuccess';
					break;
			case 'error':
				return 'ion-alert-circled u-colorDanger';
				break;
				case 'info':
			default:
				return '';
					break;
			}
		},
		getToastClass(toast) {
			return {
				'Toast-item--success': toast.type === 'success',
				'Toast-item--error': toast.type === 'error',
				'Toast-item--info': toast.type === 'info'
			};
		}
	}
};
</script>

<style lang="scss">
@import '../style/utils';
@import '../style/colors';

.Toast {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	ul {
		@extend %u-listUnstyled;
		li {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.Toast-item {
	display: flex;
	align-items: center;
	min-height: 60px;
	background: white;
	padding: 5px 15px;
	text-align: center;
	justify-content: center;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.11);
	border-top: 2px solid transparent;
	&.Toast-item--success {
		border-color: $success-color;
	}
	&.Toast-item--error {
		border-color: $danger-color;
	}
	&.Toast-item--info {
		border-color: $info-color;
	}
	.Toast-item-icon {
		padding: 5px 10px;
		font-size: 18px;
	}
	.Toast-item-content {
		display: flex;
		flex-direction: column;
		.Toast-item-title {
			text-align: left;
			font-size: 18px;
			color: $darkerGray-text-color;
			font-weight: bold;
		}
		.Toast-item-description {
			text-align: left;
			font-size: 14px;
			color: $darkGray-text-color;
		}
	}
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.toast-appear-enter-active,
.toast-appear-leave-active {
	transition: all 0.5s;
	max-height: 100px;
}

.toast-appear-enter,
.toast-appear-leave-to {
	max-height: 0;
}
</style>
