<template>
	<div class="MeasurementField">
		<div>
			<input
				type="number"
				class="form-control"
				placeholder="type or select a value"
				:data-test-id="config.key + 'Input'"
				:disabled="config.readOnly"
				:value="number"
				@change="handleNumberValueChange"
			/>
		</div>
		<div>
			<select
				class="form-control"
				:value="unit"
				:disabled="config.readOnly"
				:data-test-id="config.key + 'UnitInput'"
				@change="handleUnitValueChange"
			>
				<option value="" selected disabled>choose an unit</option>
				<option value="in">in</option>
				<option value="mm">mm</option>
				<option value="pt">pt</option>
			</select>
		</div>
	</div>
</template>
<script type="text/javascript">
export default {
	props: ['value', 'onChange', 'config'],
	computed: {
		number() {
			const value = this.value || '';
			return Number(value.replace(/(in|mm|pt)/g, ''));
		},
		unit() {
			const value = this.value || '';
			return value.replace(/[0-9.-]/g, '');
		}
	},
	methods: {
		handleNumberValueChange(event) {
			const number = event.target.value;
			const unit = this.unit;
			console.log(`${number}${unit}`);
			this.onChange && this.onChange(`${number}${unit}`);
		},
		handleUnitValueChange(event) {
			const number = this.number;
			const unit = event.target.value;
			console.log(`${number}${unit}`);
			this.onChange && this.onChange(`${number}${unit}`);
		}
	}
};
</script>

<style lang="scss">
.MeasurementField {
	display: flex;
	> div {
		margin-right: 5px;
		width: 50%;
		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
