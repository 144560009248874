export const openDeleteTaskModal = async ({ dispatch, commit }, { task, message }) => {
	const title = `Delete ${task.displayName} - ${task.status}`;
	const body = message || 'Are you sure?';
	const toast = {
		title: 'Deleted Task'
	};
	try {
		await dispatch('openConfirmModal', { title, body });
		await dispatch('deleteTask', { id: task.id });
		dispatch('displayToast', { data: toast });
		commit('DELETED_TASK', false);
		return true;
	} catch (err) {
		return false;
	}
};

export const createOrUpdateTask = async ({ dispatch, commit }, task) => {
	const {
		data: { task: result, created }
	} = await dispatch(
		'api/request',
		{
			path: 'tasks/createUpdateDeleteTasks',
			method: 'POST',
			payload: { newStatus: 'draft', task },
			transport: 'autoflow'
		},
		{ root: true }
	);

	created ? commit('CREATED_DRAFT_TASK') : commit('UPDATED_DRAFT_TASK');
	return { task: result, created };
};

export const publishTask = async ({ dispatch, commit }, task) => {
	delete task.id;
	const result = await dispatch(
		'api/request',
		{
			path: 'tasks/createUpdateDeleteTasks',
			method: 'POST',
			payload: { newStatus: 'live', task },
			transport: 'autoflow'
		},
		{ root: true }
	);

	commit('PUBLISHED_LIVE_TASK');
	return result;
};

export const checkIfDraftTaskAlreadyExists = async ({ dispatch, commit }, name) => {
	try {
		await dispatch(
			'api/request',
			{
				path: 'tasks/findOne',
				query: { filter: { where: { and: [{ name }, { status: 'draft' }] } } },
				method: 'GET',
				transport: 'autoflow'
			},
			{ root: true }
		);
		return true;
	} catch (err) {
		return false;
	}
};
