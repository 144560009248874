import compact from 'lodash/compact';
import _set from 'lodash/set';
import * as types from './types';

const mutations = {};

mutations[types.LIST_PREFERENCES] = (state, { resource, mountPath, prefs }) => {
	const path = compact([resource, mountPath, 'listPreferences']);
	_set(state, path, prefs);
};

mutations[types.SET_IS_SITEFLOW_USER] = (state, value) => {
	_set(state, 'isSiteflowUser', !!value);
};

export default mutations;
