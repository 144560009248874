<template>
	<section class="NodeField" :class="classes">
		<div class="form-group md-group">
			<div class="NodeField-top">
				<label v-if="configuration.showLabel && inputKey" :data-test-id="`${inputKey}InputLabel`">
					{{ getFormConfig().displayName }}
					<span v-if="validation.required">({{ $t('required') }})</span>
				</label>

				<div v-if="configuration.allowModalitySwitching && !readOnly" class="NodeField-top-switcher">
					<NodeFieldSwitcher
						:modality="modality"
						:has-link="hasLink"
						:on-item-click="handleSwitcherLinkClick"
					/>
				</div>
			</div>

			<div
				v-show="isLinkingMode && hasLink"
				class="NodeField-input form-control"
				:data-input-name="dataInputName"
			>
				<div class="NodeField-input-leftIcon">
					<i class="ion-pinpoint"></i>
				</div>
				<div class="NodeField-input-text">
					{{ linkedLabel }}
				</div>
				<div v-if="isFile" class="NodeField-input-rightIcon">
					<i class="ion-document"></i>
				</div>

				<button
					v-if="configuration.allowClearValue && !readOnly"
					class="NodeField-input-clearButton"
					type="button"
					@click="handleClearLinkedValueBtnClick"
				>
					<i class="ion-close-round"></i>
				</button>
			</div>

			<div
				v-show="isLinkingMode && !isActive && !hasLink"
				class="NodeField-input NodeField-input--notLinked form-control"
				:data-input-name="dataInputName"
				@click="handleLinkButtonClick"
			>
				<div class="NodeField-input-leftIcon">
					<i class="ion-pinpoint"></i>
				</div>
				<div class="NodeField-input-text">
					{{ $t('Click here to start linking') }}
				</div>
			</div>

			<div
				v-show="isLinkingMode && isActive"
				class="NodeField-input NodeField-input--linking form-control"
				:data-input-name="dataInputName"
				@click="handleLinkButtonClick"
			>
				<div class="NodeField-input-leftIcon">
					<i class="ion-pinpoint"></i>
				</div>
				<div class="NodeField-input-text">
					{{ $t('Linking') }}
				</div>
			</div>

			<div
				v-show="isCustomValueMode"
				class="NodeField-input NodeField-input--customValue"
				:data-input-name="dataInputName"
			>
				<InputRenderer
					v-if="canRenderOnRightPanel()"
					template="inputOnly"
					:config="getFormConfig()"
					:value="value.value"
					:on-custom-value-change="handleCustomValueChange"
					:read-only="readOnly"
				></InputRenderer>
			</div>

			<div v-if="getFormConfig().description" class="NodeField-input-helpText">
				<i v-tippy class="ion-ios-help-outline" :title="getFormConfig().description"></i>
			</div>
		</div>
	</section>
</template>
<script type="text/javascript">
import _get from 'lodash/get';
import find from 'lodash/find';

import { getInputFormConfig } from '../../../lib/flowInspectionUtils';
import { validateNodeInput } from '../../../lib/flowValidationUtils';
import NodeFieldSwitcher from './NodeFieldSwitcher';
import InputRenderer from '../../../components/FormGenerator/InputRenderer';

export default {
	components: {
		InputRenderer,
		NodeFieldSwitcher
	},
	props: {
		disabled: false,
		node: null,
		task: null,
		tasks: null,
		inputKey: null,
		inputRowIndex: null,
		label: null,
		config: {
			default: {}
		},
		inputConfig: null,
		value: {
			default: {}
		},
		flow: null,
		isActive: null,
		onCreateLinkBtnClick: null,
		onClearLinkBtnClick: null,
		onCustomValueChange: null,
		onShowAdvancedInputField: null,
		onDisableSelectionBtnClick: null,
		onAddInputToInputNodeBtnClick: null,
		readOnly: false
	},
	data() {
		return {
			fieldConfig: {},
			isInsertingCustomValue: false,
			setModality: null
		};
	},
	computed: {
		modality: {
			get() {
				if (this.setModality) return this.setModality;
				if (this.hasLink) return 'link';
				if (this.hasCustomValue) return 'customValue';
				return _get(this, 'config.modality', 'customValue');
			},
			set(newValue) {
				this.setModality = newValue;
			}
		},
		dataInputName() {
			return `${this.inputKey}.${this.inputRowIndex}`;
		},
		isLinkingMode() {
			return this.modality === 'link';
		},
		isCustomValueMode() {
			return this.modality === 'customValue';
		},
		isEmpty() {
			this.value.value &&
				validateNodeInput({
					inputValue: this.value.value,
					inputFormConfig: this.getFormConfig()
				});
			const { type, value } = this.value;
			return (!type || type === 'custom') && (value === null || typeof value === 'undefined' || value === '');
		},
		hasLink() {
			if (typeof _get(this, 'config.hasLink') !== 'undefined') return this.config.hasLink;
			return this.value.type === 'ref';
		},
		hasCustomValue() {
			return !this.isEmpty && (!this.value.type || this.value.type === 'custom');
		},
		validation() {
			return this.getFormConfig().validation || {};
		},
		configuration() {
			return {
				allowCustomValue: true,
				showLabel: true,
				allowModalitySwitching: true,
				allowClearValue: true,
				...this.config
			};
		},
		linkedLabel() {
			if (this.label) return this.label;
			if (!this.flow || this.value.type !== 'ref') return;
			const node = _get(this, 'flow.nodes', []).find(node => node.name === this.value.nodeName);
			const nodeName = (node && node.displayName) || this.value.nodeName;
			const destinationNode = find(this.flow.nodes, { name: node.name });
			const destinationTask = find(this.tasks, { name: destinationNode.taskName });
			const outputDisplayName = _get(
				destinationTask,
				`outputs.${this.value.field}.displayName`,
				this.value.field
			);

			return `${outputDisplayName} - ${nodeName}`;
		},
		isValid() {
			// if (!this.value) return false;
			if (this.value.type === 'ref') return true;

			const result = this.validateNodeInput({
				inputValue: this.value.value,
				inputFormConfig: this.getFormConfig()
			});

			return Object.keys(result).every(key => result[key]);
		},
		isFile() {
			return this.getFormConfig().type === 'file';
		},
		classes() {
			return {
				'is-pill': this.value && this.value.type === 'ref',
				'is-active': this.isActive,
				'is-empty': this.isEmpty,
				'is-insertingCustomValue': this.isInsertingCustomValue,
				'is-invalid': !this.isValid,
				'is-valid': this.isValid,
				'has-label': this.configuration.showLabel && this.inputKey
			};
		}
	},
	methods: {
		validateNodeInput,
		getFormConfig() {
			if (!this.inputConfig && (!this.node || !this.inputKey)) return {};

			let inputConfiguration;
			if (this.task) {
				inputConfiguration = getInputFormConfig(this.inputKey, _get(this, 'task.form'));
			} else {
				inputConfiguration = this.inputConfig;
			}

			const defaultConfig = {
				type: 'text',
				readOnly: this.readOnly,
				key: this.inputKey
			};

			return {
				...defaultConfig,
				...inputConfiguration
			};
		},
		canRenderOnRightPanel() {
			const type = this.getFormConfig().type;
			if (!type) return true;

			const allowedTypes = [
				'text',
				'number',
				'integer',
				'double',
				'password',
				'select',
				'checkbox',
				'measurement',
				'margin',
				'textarea',
				'file',
				'imageSelect',
				'tags',
				'horizontalAlign',
				'verticalAlign',
				'colourPicker',
				'jsonEditor'
			];

			return allowedTypes.indexOf(type) > -1;
		},
		handleCustomValueChange(value) {
			this.onCustomValueChange && this.onCustomValueChange(value, this.inputKey);
		},
		handleAdvancedFieldBtnClick() {
			this.onShowAdvancedInputField({ inputKey: this.inputKey, inputVal: this.value });
		},
		handleLinkButtonClick() {
			if (this.isActive) {
				return this.onDisableSelectionBtnClick();
			}

			return this.onCreateLinkBtnClick();
		},
		handleClearLinkedValueBtnClick() {
			this.onClearLinkBtnClick();
			this.setModality = 'link';
		},

		handleSwitcherLinkClick(action, data) {
			if (action === 'changeModality') {
				return (this.modality = data);
			}

			if (action === 'addInputToStartNodeInputs') {
				this.setModality = 'link';
				return this.onAddInputToInputNodeBtnClick(this.node, this.inputKey);
			}
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';
@import '../../../style/variables';
@import '../../../style/utils';

.NodeField {
	&.is-invalid {
		.NodeField-input--notLinked,
		.NodeField-input--customValue {
			.form-control {
				border-bottom: 2px solid $danger-color;
			}
		}
	}
	&.has-label {
		.NodeField-top {
			justify-content: space-between;
		}
	}
	.NodeField-top {
		display: flex;
		margin-bottom: 2px;
		justify-content: flex-end;
		align-items: center;
		label {
			margin: 0;
		}
	}
	.NodeField-input {
		display: flex;
		position: relative;
		&:hover {
			.NodeField-input-clearButton {
				display: block;
			}
		}
		&.NodeField-input--notLinked {
			cursor: pointer;
			&:hover {
				background: rgba($blue-background-color, 0.2);
			}

			.NodeField-input-leftIcon {
				background: white;
				color: $blue-brand-color;
				border: 1px solid $blue-brand-color;
			}
		}
		&.NodeField-input--linking {
			cursor: pointer;
			background: $blue-background-color !important;
			color: white;
			.NodeField-input-leftIcon {
				background: transparent;
				color: white;
				border: 1px solid white;
			}
		}

		&.NodeField-input--customValue {
			> span {
				flex-grow: 1;
			}
		}
		.NodeField-input-leftIcon {
			margin-right: 6px;
			background: $blue-background-color;
			color: white;
			border-radius: 50%;
			width: 20px;
			min-width: 20px;
			height: 20px;
			min-height: 20px;
			text-align: center;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.NodeField-input-text {
			flex-grow: 1;
			line-height: 1;
		}
		.NodeField-input-rightIcon {
			font-size: 22px;
			padding-right: 10px;
		}
		.NodeField-input-clearButton {
			@extend %u-buttonUnstyled;
			position: absolute;
			top: 0;
			display: none;
			right: 0;
			margin: 0;
			padding: 0 2px;
			font-style: 10px;
			background: none;
			border: none;
			&,
			&:focus,
			&:visited,
			&:active,
			&:hover {
				color: #4a4a4a;
			}
			&:hover {
				opacity: 0.8;
			}
		}

		.NodeField-input-helpText {
			font-size: 12px;
		}
	}
}

.u-timesNewRoman {
	font-family: 'Times New Roman';
	font-style: normal !important;
}
</style>
