<template>
	<div
		class="FileInput NodeField"
		:class="{ 'is-dragging': isDragging, 'is-invalid': !isValid && !isUploading }"
		droppable="true"
		@dragover.prevent
		@drop="onDrop"
	>
		<div class="form-group md-group">
			<label class="control-label">{{ input.key }}</label>

			<div class="FileInput-container">
				<div class="FileInput-input">
					<input
						type="text"
						class="form-control"
						placeholder="Enter file path or drop a file"
						:data-test-id="`${input.key}Input`"
						:value="getValue()"
						@input="onUrlChanged"
					/>
				</div>
				<div class="FileInput-button">
					<div v-if="isFileUploaded()" class="FileInput-button-clear" @click="clearInput">
						<i class="ion-ios-close-outline"></i>
					</div>

					<div class="FileInput-button-upload" @click.prevent="open">
						<i v-tippy class="ion-upload" title="Choose file"></i>
					</div>
				</div>

				<div class="FileInput-dropzone">
					<i class="ion-ios-cloud-upload-outline"></i> &nbsp;{{ $t('Drag & Drop here') }}
				</div>
			</div>

			<ProgressBar v-if="input.progress" :amount="input.progress"></ProgressBar>
		</div>

		<input ref="fileInput" type="file" class="hidden-fileinput" @change="handleFileChange" />
	</div>
</template>

<script type="text/javascript">
import $ from 'jquery';
import _get from 'lodash';
import ProgressBar from '../../components/ProgressBar';

export default {
	components: { ProgressBar },
	props: {
		input: null,
		dragging: null,
		onChange: null,
		onFileUpload: null,
		isValid: false
	},
	data() {
		return {
			isUploading: false,
			file: null,
			fileName: '',
			fileUrl: null,
			progress: 0,
			tmpFileInput: null
		};
	},
	computed: {
		isDragging() {
			if (this.file) return false;
			return this.dragging;
		}
	},
	methods: {
		_get,
		onDrop(e) {
			e.preventDefault();
			e.stopPropagation();

			this.file = e.dataTransfer.files[0];
			this.fileName = this.file.name;
			this.onChange(this.input, this.file);
			this.onFileUpload(this.input, this.file);
		},
		open() {
			this.openFileInput();
		},
		uploadProgress(progress) {
			this.progress = progress * 100;
		},
		openFileInput() {
			$(this.$refs.fileInput).trigger('click');
		},
		handleFileChange(e) {
			e.preventDefault();
			this.file = e.target.files[0];
			this.onFileUpload(this.input, this.file);
		},
		onUrlChanged(event) {
			const value = event.target.value;
			this.onChange && this.onChange(this.input, { value });
		},
		clearInput() {
			this.file = null;
			this.onChange(this.input, {});
			$(this.$refs.fileInput).val('');
		},
		getValue() {
			const url = this.input.value;
			const fileName = (this.file && this.file.name) || '';
			return url || fileName;
		},
		isInputEmpty() {
			return !this.input || !this.input.value;
		},
		isFileSelected() {
			return this.file;
		},
		isFileUploaded() {
			return this.input && this.input.value;
		}
	}
};
</script>
<style lang="scss">
@import '../../style/utils';
@import '../../style/colors';

.FileInput {
	.FileInput-container {
		position: relative;
		.FileInput-button {
			position: absolute;
			top: 1px;
			right: 1px;
			bottom: 2px;
			display: flex;
			padding: 0 5px;
			align-items: center;
			justify-content: center;
			background: white;
			cursor: pointer;
			> div {
				margin-left: 5px;
			}
			i {
				color: $blue-brand-color;
				font-size: 25px;
			}
			.FileInput-button-upload {
			}
			.FileInput-button-uploaded {
				color: $success-color;
			}
		}

		.FileInput-dropzone {
			display: none;
			background: white;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			border: 2px dashed $gray-border-color;
			align-items: center;
			justify-content: center;
			color: $gray-text-color;
			i {
				color: $blue-brand-color;
				font-size: 30px;
			}
		}

		.FileInput-input {
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			-webkit-user-drag: none;
		}
	}
	.Progress {
		border: none !important;
		height: 5px;
	}

	@at-root .FileInput.is-dragging {
		.FileInput-dropzone {
			display: flex;
		}
	}
}

.hidden-fileinput {
	display: none !important;
}

.InputBox .InputBox-wrapper {
	margin-right: 65px;
}
</style>
