import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';
import { defaultListPreferences } from '../defaultListPreferences';
import { categories as templatesCategories, templates } from './flowTemplates';

export default function() {
	const state = {
		listPreferences: {
			...defaultListPreferences
		},
		runFlowModal: {
			isOpen: false,
			flow: null,
			runConfiguration: null
		},
		editFlowInfoModal: {
			isOpen: false
		},
		importSiteflowAttributesModal: {
			isOpen: false
		},
		isTriggerSelectionOpen: false,
		selectedNodeName: null,
		flowHasChanges: false,

		selectedNodeInputKey: null,
		selectedOutputKeyIndex: null,
		templatesCategories,
		templates,
		isAddInputsToStartNodeModalVisible: false,

		epmPresets: [],
		epmCustomProfiles: [],
		epmProfiles: [],
		areProfilesFetched: false
	};

	Vue.$ofsCrud.addResource({
		name: 'flow',
		options: { namespaced: false },
		transport: 'autoflow',
		actions,
		mutations,
		getters,
		state
	});
}
