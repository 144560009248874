<template>
	<div v-if="areOutputsVisible" class="OutputsContainer">
		<ul>
			<li v-for="output in outputs.values" :class="getOutputClass(output)" @click="handleOutputClick(output)">
				{{ output.displayName || output.key }}
			</li>
		</ul>
	</div>
</template>

<script type="text/javascript">
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

export default {
	props: ['node', 'outputs', 'onOutputClick', 'linkedOutputs'],
	computed: {
		areOutputsVisible() {
			const outputs = this.outputs;
			return outputs && outputs.values && Object.keys(outputs.values).length > 0;
		}
	},
	methods: {
		handleOutputClick(output) {
			this.onOutputClick(this.node, output.key);
		},
		getOutputClass(output) {
			const isAssigned = !isEmpty(pickBy(this.linkedOutputs, { field: output.key }));

			if (isAssigned) return 'is-linked';
		}
	}
};
</script>

<style lang="scss">
@import './Chart-utils';
@import '../../style/colors';
@import '../../style/utils';

.OutputsContainer {
	position: absolute;
	z-index: 40;
	top: 0;
	left: calc(100% - 2px);
	margin-left: 50px;
	width: 200px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);

	&:before {
		content: '';
		position: absolute;
		right: 100%;
		height: 2px;

		top: ($shapeHeight/2) - 1px;
		width: 50px;
		background: $gray-border-color;
	}

	ul {
		@extend %u-listUnstyled;
		li {
			border-bottom: 1px solid $gray-border-color;
			padding: 10px 10px;
			font-size: 14px;
			color: #62717a;
			background: white;
			cursor: pointer;
			&:hover,
			&.is-linked {
				background: $blue-background-color;
				color: white;
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
</style>
