import { render, staticRenderFns } from "./FlowEdit.vue?vue&type=template&id=58975dc5"
import script from "./FlowEdit.vue?vue&type=script&lang=js"
export * from "./FlowEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports