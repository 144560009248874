<template>
	<div class="InputLink" :class="classes">
		<label v-if="configuration.showLabel && inputKey">
			{{ getFormConfig().displayName }}
			<span v-if="validation.required" class="u-colorDanger">*</span>
		</label>
		<div v-if="value" class="InputLink-container">
			<div class="InputLink-content">
				<div v-show="isEmpty" class="InputLink-empty">
					<div @click="handleLinkButtonClick"><i class="ion-pinpoint"></i> Link from</div>

					<div v-if="configuration.allowCustomValue" @click="handleInsertCustomValueBtnClick">Set value</div>

					<div v-if="configuration.showAddToInputNode" @click="handleAddToInputNodeBtnClick">
						<i class="ion-ios-plus"></i> Add to inputs
					</div>
				</div>

				<div v-show="isLinked" class="InputLink-linked" :title="linkedLabel">
					<div class="InputLink-linked-wrapper">
						<div class="InputLink-linked-text">
							{{ linkedLabel }}
						</div>
						<button
							v-if="value.type === 'ref'"
							class="InputLink-clearButton"
							type="button"
							@click="handleClearValueBtnClick"
						>
							<i class="ion-close-round"></i>
						</button>
					</div>
				</div>

				<div v-if="isCustomValue" class="InputLink-customValue" @click="handleCustomValueBtnClick">
					{{ value.value }}

					<button type="button" class="InputLink-clearButton" @click="handleClearValueBtnClick">
						<i class="ion-close-round"></i>
					</button>
				</div>

				<div v-show="isInsertingCustomValue" class="InputLink-input">
					<InputRenderer
						v-if="canRenderOnRightPanel()"
						template="inputOnly"
						:config="getFormConfig()"
						:value="value.value"
						:on-custom-value-change="handleCustomValueChange"
					></InputRenderer>

					<AdvancedInputPreview
						v-if="!canRenderOnRightPanel()"
						:config="getFormConfig()"
						:value="value.value"
					/>

					<button type="button" class="InputLink-clearButton" @click="handleClearValueBtnClick">
						<i class="ion-close-round"></i>
					</button>
				</div>

				<div v-show="isActive" class="InputLink-linking" @click="onDisableSelectionBtnClick">
					<i class="ion-pinpoint"></i> Linking
				</div>
			</div>
		</div>
		<div v-if="getFormConfig().description" class="InputLink-helpText">
			<i class="ion-ios-help-outline"></i>
			{{ getFormConfig().description }}
		</div>
	</div>
</template>
<script type="text/javascript">
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import $ from 'jquery';
import { validateNodeInput } from '../../../lib/flowValidationUtils';

import InputRenderer from '../../../components/FormGenerator/InputRenderer.vue';
import AdvancedInputPreview from '../../../components/FormGenerator/AdvancedInputPreview.vue';
import InputLinkBtnIcon from './InputLinkBtnIcon.vue';

export default {
	components: {
		InputRenderer,
		AdvancedInputPreview,
		InputLinkBtnIcon
	},
	props: {
		node: null,
		inputKey: null,
		config: {
			default: {}
		},
		inputConfig: null,
		value: {
			default: {}
		},
		chart: null,
		isActive: null,
		onCreateLinkBtnClick: null,
		onClearLinkBtnClick: null,
		onCustomValueChange: null,
		onShowAdvancedInputField: null,
		onDisableSelectionBtnClick: null,
		onAddInputToInputNodeBtnClick: null
	},
	data() {
		return {
			fieldConfig: {},
			isInsertingCustomValue: false
		};
	},
	computed: {
		isEmpty() {
			this.value.value &&
				validateNodeInput({
					inputValue: this.value.value,
					inputFormConfig: this.getFormConfig()
				});
			const { type, value } = this.value;
			return (
				!this.isActive &&
				(!type || type === 'custom') &&
				(value === null || typeof value === 'undefined' || value === '') &&
				!this.isInsertingCustomValue
			);
		},
		isLinked() {
			return !this.isActive && this.value.type === 'ref';
		},
		isCustomValue() {
			return (
				!this.isEmpty &&
				!this.isActive &&
				(!this.value.type || this.value.type === 'custom') &&
				!this.isInsertingCustomValue
			);
		},
		validation() {
			return this.getFormConfig().validation;
		},
		configuration() {
			return {
				allowCustomValue: true,
				showLabel: true,
				...this.config
			};
		},
		linkedLabel() {
			if (!this.chart || this.value.type !== 'ref') return;
			const node = this.chart.getRawNodes().find(node => node.name === this.value.nodeName);
			const nodeName = (node && node.displayName) || this.value.nodeName;
			const outputDisplayName = _get(node, `task.outputs.${this.value.field}.displayName`, this.value.field);

			return `${outputDisplayName} - ${nodeName}`;
		},
		isValid() {
			if (!this.value) return false;
			if (this.value.type === 'ref') return true;

			const result = this.validateNodeInput({
				inputValue: this.value.value,
				inputFormConfig: this.getFormConfig()
			});

			return Object.keys(result).every(key => result[key]);
		},
		classes() {
			return {
				'is-pill': this.value && this.value.type === 'ref',
				'is-active': this.isActive,
				'is-empty': this.isEmpty,
				'is-insertingCustomValue': this.isInsertingCustomValue,
				'is-invalid': !this.isValid,
				'is-valid': this.isValid
			};
		}
	},
	methods: {
		validateNodeInput,
		getFormConfig() {
			if (!this.inputConfig && (!this.node || !this.inputKey)) return {};

			let inputConfiguration;
			if (this.node) {
				const formConfiguration = this.node.task.form || {};
				inputConfiguration = formConfiguration[this.inputKey] || {};
			} else {
				inputConfiguration = this.inputConfig;
			}

			const defaultConfig = {
				type: 'text',
				key: this.inputKey
			};

			return {
				...defaultConfig,
				...inputConfiguration
			};
		},
		canRenderOnRightPanel() {
			const type = this.getFormConfig().type;
			const allowedTypes = [
				'text',
				'number',
				'password',
				'select',
				'checkbox',
				'measurement',
				'textarea',
				'file',
				'margin'
			];

			return allowedTypes.indexOf(type) > -1;
		},
		handleCustomValueBtnClick() {
			this.isInsertingCustomValue = true;
		},
		handleCustomValueChange(value) {
			this.onCustomValueChange && this.onCustomValueChange(value, this.inputKey);
		},
		handleAdvancedFieldBtnClick() {
			this.onShowAdvancedInputField({ inputKey: this.inputKey, inputVal: this.value });
		},
		handleLinkButtonClick() {
			if (this.isActive) {
				return this.onDisableSelectionBtnClick();
			}

			return this.onCreateLinkBtnClick();
		},
		handleInsertCustomValueBtnClick() {
			this.isInsertingCustomValue = true;
			this.$nextTick(() => {
				$(this.$el)
					.find(':input')
					.focus();
			});
		},
		handleClearValueBtnClick() {
			this.onClearLinkBtnClick();
			this.isInsertingCustomValue = false;
		},
		handleAddToInputNodeBtnClick() {
			this.onAddInputToInputNodeBtnClick(this.node, this.inputKey);
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';
@import '../../../style/variables';
@import '../../../style/utils';

.InputLink {
	label {
		font-weight: normal;
	}

	&.is-insertingCustomValue {
		.InputLink-container {
			.InputLink-content {
				.InputLink-input {
					input[type='text'],
					input[type='number'],
					select,
					textarea {
					}
				}
			}
		}
	}

	.InputLink-container {
		position: relative;
		display: flex;
		border-bottom: 1px solid #c6c6c6;
		.InputLink-content {
			flex-grow: 1;
			min-height: 34px;
			width: 100%;
			.InputLink-empty {
				flex: 1;
				display: flex;
				height: 100%;
				> div {
					background: #edf1f2;
					flex: 1;
					text-align: center;
					padding: 7px 10px;
					cursor: pointer;
					align-items: center;
					margin-right: 5px;
					&:last-child {
						margin-right: 0;
					}
					&:hover {
						background: #c6c6c6;
					}
				}
			}

			.InputLink-linked {
				.InputLink-linked-wrapper {
					border: 1px solid $gray-border-color;
					display: flex;
					padding: 5px 5px;
					.InputLink-linked-text {
						flex-grow: 1;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
					button {
						flex-shrink: 1;
					}
				}
			}
			.InputLink-linking {
				background: $blue-brand-color;
				color: white;
				padding: 7px 12px;
				text-align: center;
				height: 100%;
				cursor: pointer;
			}

			.InputLink-input {
				position: relative;
				//background: #EDF1F2;
				padding-right: 20px;
				input[type='text'],
				input[type='number'],
				input[type='password'],
				select,
				textarea {
					//background: transparent;
					background: #edf1f2;
					min-height: 34px;
					border: none;
					width: 100%;
					height: 100%;
					appearance: none;
					border-radius: 0;
					padding: 6px;
					&:focus {
						background: rgba($blue-brand-color, 0.1);
					}
				}
				.InputLink-clearButton {
					position: absolute;
					top: 50%;
					display: none;
					transform: translateY(-50%);
					left: calc(100% - 15px);
				}

				&:hover .InputLink-clearButton {
					display: block;
				}
			}

			.InputLink-customValue {
				padding: 6px;
				position: relative;
				.InputLink-clearButton {
					position: absolute;
					top: 50%;
					display: none;
					transform: translateY(-50%);
					left: calc(100% - 15px);
				}

				&:hover .InputLink-clearButton {
					display: block;
				}
			}
		}
	}

	.InputLink-helpText {
		font-size: 12px;
		color: #c6c6c6;
	}

	.InputLink-clearButton {
		@extend %u-buttonUnstyled;
		margin: 0;
		padding: 0 2px;
		&,
		&:focus,
		&:visited,
		&:active,
		&:hover {
			color: #4a4a4a;
		}
		&:hover {
			opacity: 0.8;
		}
	}

	&.is-empty {
		.InputLink-container {
			//background: #EDF1F2;
		}
	}
	&.is-active {
		.InputLink-container {
			.InputLink-linkButton {
				button {
					background: $primary-color;
					&,
					&:hover,
					&:visited,
					&:focus {
						border: none;
					}
					svg {
						g {
							fill: white;
						}
					}
				}
			}
			.InputLink-pill {
				.InputLink-clearButton {
					color: white;
				}
			}
		}
	}
	&.is-linked {
	}

	&.is-invalid {
		.InputLink-container {
			border: none;
			.InputLink-input {
				input,
				textarea,
				select {
					border: 1px solid $danger-color !important;
				}
			}
			.InputLink-customValue {
				border-bottom: 1px solid $danger-color;
			}
		}
	}
}
</style>
