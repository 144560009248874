<template>
	<Modal :show="show" size="big">
		<div slot="body">
			<section class="InputEditModal">
				<form @submit.prevent="handleSubmit">
					<FormGenerator :fields="fieldsConfig"></FormGenerator>

					<div class="InputEditModal-actions">
						<button class="button button--success">Save</button>
						<button class="button" @click="handleCancelBtnClick">Cancel</button>
					</div>
				</form>
			</section>
		</div>
	</Modal>
</template>
<script type="text/javascript">
import Modal from '../../../components/modal.vue';
import FormGenerator from '../../../components/FormGenerator/FormGenerator.vue';

export default {
	components: {
		Modal,
		FormGenerator
	},
	props: ['show']
};
</script>
