<template>
	<div>
		<ToolboxWorker
			v-if="requiredAccess !== true"
			v-tippy
			:task="task"
			:title="`${requiredAccess.toUpperCase()} Task`"
			:required-access="requiredAccess"
			:on-show-toolbox-task-details="onShowToolboxTaskDetails"
		/>
		<ToolboxWorker
			v-else-if="task.status === 'draft'"
			v-tippy
			:task="task"
			title="Draft Task"
			:required-access="requiredAccess"
			:on-show-toolbox-task-details="onShowToolboxTaskDetails"
		/>
		<ToolboxWorker
			v-else
			:task="task"
			:required-access="requiredAccess"
			:on-show-toolbox-task-details="onShowToolboxTaskDetails"
		/>
	</div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import WorkerIcon from '../../../components/icons/worker-icon';
import { userHasAccess } from '../../../lib/flowValidationUtils';
import ToolboxWorker from './ToolboxWorker';
import './ToolboxTask.scss';

export default {
	components: {
		WorkerIcon,
		ToolboxWorker
	},
	props: ['task', 'onShowToolboxTaskDetails'],
	data() {
		return {
			status: null
		};
	},
	computed: {
		...mapGetters(['userAccess', '$config']),
		requiredAccess() {
			if (!$config.isHpMode) {
				return true;
			}

			return userHasAccess({
				requiredAccess: this.task.access,
				userAccess: this.userAccess
			});
		}
	}
};
</script>
