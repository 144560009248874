<template>
	<div class="InputLinkList">
		<label v-if="config.showInputsList">
			{{ getFormConfig().displayName }}
			<span v-if="validation.required" class="u-colorDanger">*</span>
		</label>
		<div v-for="(inputRow, inputRowIndex) in inputValuesList" class="InputLinkList-item">
			<div class="InputLinkList-item-content">
				<div class="InputLinkList-item-left">
					<NodeInputField
						:input-key="inputKey"
						:input-row-index="inputRowIndex"
						:is-active="selectedNodeInputKey === `${inputKey}.values.${inputRowIndex}`"
						:on-create-link-btn-click="handleCreateLinkBtnClick({ inputRow, inputRowIndex })"
						:on-clear-link-btn-click="handleClearLinkBtnClick({ inputRow, inputRowIndex })"
						:on-disable-selection-btn-click="onDisableSelectionBtnClick"
						:on-custom-value-change="handleCustomValueChange({ inputRow, inputRowIndex })"
						:on-show-advanced-input-field="onShowAdvancedInputField"
						:on-add-input-to-input-node-btn-click="onAddInputToInputNodeBtnClick"
						:node="node"
						:task="task"
						:tasks="tasks"
						:flow="flow"
						:value="inputRow"
						:read-only="readOnly"
						:config="inputFieldConfig"
					></NodeInputField>
				</div>
				<div v-if="config.showInputsList && !readOnly" class="InputLinkList-item-right">
					<button
						class="button button--small button--round"
						@click="handleAddInputRowBtnClick(inputRowIndex)"
					>
						<i class="ion-plus-round"></i>
					</button>
					<button
						class="button button--small button--round"
						:disabled="isRemoveInputRowBtnDisabled"
						@click="handleRemoveInputRowBtnClick(inputRowIndex)"
					>
						<i class="ion-minus-round"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
import _get from 'lodash/get';
import NodeInputField from './NodeInputField';
import { getInputFormConfig } from '../../../lib/flowInspectionUtils';

export default {
	components: {
		NodeInputField
	},
	props: {
		node: null,
		task: null,
		tasks: null,
		inputKey: null,
		selectedNodeInputKey: null,
		config: {
			default: {
				showInputsList: false
			}
		},
		inputConfig: null,
		inputValues: {
			default: () => ({})
		},
		flow: null,
		isActive: null,
		onCreateLinkBtnClick: null,
		onClearLinkBtnClick: null,
		onCustomValueChange: null,
		onShowAdvancedInputField: null,
		onDisableSelectionBtnClick: null,
		onAddInputToInputNodeBtnClick: null,

		handleAddNodeInputRow: null,
		handleRemoveNodeInputRow: null,
		readOnly: false
	},
	computed: {
		inputValuesList() {
			if (this.config.showInputsList) return this.inputValues.values;

			return [(this.inputValues.values && this.inputValues.values[0]) || {}];
		},
		validation() {
			return this.getFormConfig().validation;
		},
		isRemoveInputRowBtnDisabled() {
			return this.inputValuesList.length < 2;
		},
		inputFieldConfig() {
			const inputConfig = {
				showAddToInputNode: true,
				showLabel: !this.config.showInputsList
			};

			if (this.config.allowModalitySwitching === false) {
				inputConfig.allowModalitySwitching = false;
			}
			return inputConfig;
		}
	},
	methods: {
		handleCreateLinkBtnClick({ inputRow, inputRowIndex }) {
			return () => {
				this.onCreateLinkBtnClick(`${this.inputKey}.values.${inputRowIndex}`);
			};
		},

		handleCustomValueChange({ inputRow, inputRowIndex }) {
			return value => {
				this.onCustomValueChange(`${this.inputKey}.values.${inputRowIndex}`, value);
			};
		},

		handleClearLinkBtnClick({ inputRow, inputRowIndex }) {
			return () => {
				this.onClearLinkBtnClick(`${this.inputKey}.values.${inputRowIndex}`);
			};
		},

		handleAddInputRowBtnClick(index) {
			this.handleAddNodeInputRow({
				node: this.node,
				inputKey: this.inputKey,
				atIndex: index + 1
			});
		},
		handleRemoveInputRowBtnClick(index) {
			this.handleRemoveNodeInputRow({
				node: this.node,
				inputKey: this.inputKey,
				atIndex: index
			});
		},

		getFormConfig() {
			if (!this.inputConfig && (!this.node || !this.inputKey)) return {};

			let inputConfiguration;
			if (this.node) {
				inputConfiguration = getInputFormConfig(this.inputKey, _get(this, 'task.form'));
			} else {
				inputConfiguration = this.inputConfig;
			}

			const defaultConfig = {
				type: 'text',
				key: this.inputKey
			};

			return {
				...defaultConfig,
				...inputConfiguration
			};
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';
@import '../../../style/variables';
@import '../../../style/utils';

.InputLinkList {
	> label {
		font-weight: normal;
	}
	.InputLinkList-item {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
		.InputLinkList-item-content {
			display: flex;
			align-items: flex-start;
		}
		.InputLinkList-item-left {
			flex-grow: 1;
		}
		.InputLinkList-item-right {
			flex-shrink: 1;
			padding: 25px 0 0 5px;
			> button {
				display: block;
				font-size: 12px;
				width: 16px;
				height: 16px;
				margin: 0 0 5px;
				padding: 0;
				border-color: #979797;
				color: #979797;
				line-height: 1;
				i {
					line-height: 1;
				}
				span {
					display: block;
					line-height: 1;
					transform: translateY(-33%);
				}
			}
		}
	}
}
</style>
