<template>
	<InputTag
		:disabled="config.readOnly"
		:on-change="handleCustomValueChange"
		:tags="formattedValue"
		:data-test-id="config.key + 'Input'"
	/>
</template>
<script type="text/javascript">
import InputTag from 'vue-input-tag';
import throttle from 'lodash/throttle';
export default {
	props: ['value', 'onChange', 'config', 'disabled'],
	computed: {
		formattedValue() {
			return this.value ? String(this.value).split(',') : [];
		}
	},
	methods: {
		handleCustomValueChange: throttle(function(valueArray) {
			this.onChange && this.onChange(valueArray.join(','));
		}, 500)
	},
	components: { InputTag }
};
</script>
<style lang="scss">
@import '../../../style/colors';
@import '../../../style/inputs-mixins';

.vue-input-tag-wrapper {
	background: none !important;
	border: none !important;
	border-top: 1px solid #e2dfde !important;
	border-left: 1px solid #e2dfde !important;
	border-right: 1px solid #e2dfde !important;
	border-bottom: 2px solid $blue-brand-color !important;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	min-height: 40px !important;
	display: flex !important;
	align-items: center !important;
	padding: 6px 8px !important;
	&:focus {
		border-top: 1px solid $blue-brand-color !important;
		border-left: 1px solid $blue-brand-color !important;
		border-right: 1px solid $blue-brand-color !important;
		background: rgba($blue-brand-color, 0.1) !important;
	}
	.input-tag {
		background: white !important;
		border: 1px solid $gray-border-color !important;
		color: #4a4a4a !important;
		.remove {
			color: #4a4a4a !important;
		}
	}
}

.NodeField.is-invalid .NodeField-input--notLinked .vue-input-tag-wrapper,
.NodeField.is-invalid .NodeField-input--customValue .vue-input-tag-wrapper {
	border-bottom: 2px solid #e94e4e !important;
}
</style>
