<template>
	<span>
		<div v-if="template === 'formGroup'" class="form-group">
			<label v-if="config.type !== 'hidden'" class="control-label">{{ config.title || config.key }}</label>

			<!--<div class="FieldGroup-description">{{config.description}}</div>-->
			<div>
				<TextField
					v-if="config.type === 'text' || config.type === 'textarea' || config.type === 'password'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
					:placeholder="placeholder"
				/>
				<NumberField
					v-if="config.type === 'number' || config.type === 'integer' || config.type === 'double'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<SelectField
					v-if="config.type === 'select'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<CheckboxField
					v-if="config.type === 'checkbox'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<MeasurementField
					v-if="config.type === 'measurement'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<MarginField
					v-if="config.type === 'margin'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<FileField
					v-if="config.type === 'file'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<ImageSelectField
					v-if="config.type === 'imageSelect'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<TagsField
					v-if="config.type === 'tags'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<HorizontalAlignmentField
					v-if="config.type === 'horizontalAlign'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<VerticalAlignmentField
					v-if="config.type === 'verticalAlign'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<ColorPickerField
					v-if="config.type === 'colourPicker'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
				<JsonField
					v-if="config.type === 'jsonEditor'"
					:config="config"
					:value="value"
					:on-change="onCustomValueChange"
				/>
			</div>
		</div>

		<span v-if="template === 'inputOnly'">
			<TextField
				v-if="config.type === 'text' || config.type === 'textarea' || config.type === 'password'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
				:placeholder="placeholder"
			/>
			<NumberField
				v-if="config.type === 'number' || config.type === 'integer' || config.type === 'double'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
			/>
			<HiddenInput v-if="config.type === 'hidden'" :config="config" :on-change="onCustomValueChange" />
			<SelectField
				v-if="config.type === 'select'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
			/>
			<CheckboxField
				v-if="config.type === 'checkbox'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
			/>
			<MeasurementField
				v-if="config.type === 'measurement'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
			/>
			<MarginField
				v-if="config.type === 'margin'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
			/>
			<FileField v-if="config.type === 'file'" :config="config" :value="value" :on-change="onCustomValueChange" />
			<ImageSelectField
				v-if="config.type === 'imageSelect'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
			/>
			<TagsField v-if="config.type === 'tags'" :config="config" :value="value" :on-change="onCustomValueChange" />
			<HorizontalAlignmentField
				v-if="config.type === 'horizontalAlign'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
			/>
			<VerticalAlignmentField
				v-if="config.type === 'verticalAlign'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
			/>
			<ColorPickerField
				v-if="config.type === 'colourPicker'"
				v-model="valueWrapper"
				:config="config"
				:value="value"
			/>
			<JsonField
				v-if="config.type === 'jsonEditor'"
				:config="config"
				:value="value"
				:on-change="onCustomValueChange"
			/>
		</span>
	</span>
</template>

<script type="text/jsx">
import TextField from './fields/TextField.vue';
import HiddenInput from './fields/HiddenInput';
import NumberField from './fields/NumberField.vue';
import SelectField from './fields/SelectField.vue';
import CheckboxField from './fields/CheckboxField.vue';
import MeasurementField from './fields/MeasurementField.vue';
import MarginField from './fields/MarginField.vue';
import FileField from './fields/FileField.vue';
import ImageSelectField from './fields/ImageSelectField.vue';
import TagsField from './fields/TagsField.vue';
import HorizontalAlignmentField from './fields/HorizontalAlignmentField.vue';
import VerticalAlignmentField from './fields/VerticalAlignmentField.vue';
import ColorPickerField from './fields/ColorPickerField.vue';
import JsonField from './fields/JsonField.vue';

export default{
	components: {
		TextField,
		HiddenInput,
		NumberField,
		SelectField,
		CheckboxField,
		MeasurementField,
		MarginField,
		FileField,
		ImageSelectField,
		TagsField,
		HorizontalAlignmentField,
		VerticalAlignmentField,
		ColorPickerField,
		JsonField
	},
	props: {
		config: Object,
		onCustomValueChange: Function,
		value: null,
		placeholder: String,
		template: {
			type: String,
			default: 'formGroup'
		}
	},
	data() {
		return {};
	},
	computed: {
		valueWrapper: {
			get() {
				return this.value;
			},
			set(data) {
				this.onCustomValueChange(data);
			}
		}
	}
};
</script>
