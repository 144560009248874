export const isAuthenticated = state => state.isAuthenticated;

export const currentAccountId = state => state.currentAccountId;

export const account = (state, getters) => {
	// eslint-disable-next-line no-shadow
	const { memberships, currentAccountId } = getters;
	const membership = (memberships || []).find(m => m.account._id === currentAccountId);
	return membership ? membership.account : null;
};

export const accounts = (state, getters) => (getters.memberships || []).map(m => m.account);

export const memberships = state => state.memberships;

export const user = state => state.user;

export const userAccess = state => state.printos.userAccess;

export const canRunAutoflow = state => state.printos.canRun;
