var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FormGenerator"},[(_vm.formLayout)?_c('div',_vm._l((_vm.formLayout.sections),function(section){return _c('div',{staticClass:"FormSection"},[(section.title)?_c('h3',{staticClass:"FormSection-title"},[_vm._v(_vm._s(section.title))]):_vm._e(),_vm._l((section.rows),function(row){return (!(row.columns.length === 1 && !_vm.inputs[row.columns[0].key]))?_c('div',{staticClass:"FormRow"},_vm._l((row.columns),function(column){return (_vm.inputs[column.key])?_c('div',{staticClass:"FormColumn",style:(column.style)},[_c('InputLinkList',_vm._b({},'InputLinkList',{
							inputKey: column.key,
							selectedNodeInputKey: _vm.selectedNodeInputKey,
							onCreateLinkBtnClick: _vm.onCreateLinkBtnClick,
							onClearLinkBtnClick: _vm.onClearLinkBtnClick,
							onDisableSelectionBtnClick: _vm.onDisableSelectionBtnClick,
							onCustomValueChange: _vm.onCustomValueChange,
							onShowAdvancedInputField: _vm.onShowAdvancedInputField,
							onAddInputToInputNodeBtnClick: _vm.onAddInputToInputNodeBtnClick,
							node: _vm.node,
							task: _vm.task,
							tasks: _vm.tasks,
							flow: _vm.flow,
							inputValues: _vm.inputs[column.key],
							config: _vm.config,
							handleAddNodeInputRow: _vm.handleAddNodeInputRow,
							handleRemoveNodeInputRow: _vm.handleRemoveNodeInputRow,
							readOnly: _vm.readOnly
						},false))],1):_vm._e()}),0):_vm._e()})],2)}),0):_vm._e(),(!_vm.formLayout)?_c('div',[_c('h3',[_vm._v(_vm._s(this.node.displayName && ((this.node.displayName) + " -"))+_vm._s(_vm.$t('Settings')))]),_vm._l((_vm.inputs),function(inputValues,inputKey){return _c('div',{staticClass:"MainSettingsPanel-input"},[_c('InputLinkList',_vm._b({},'InputLinkList',{
					inputKey: inputKey,
					selectedNodeInputKey: _vm.selectedNodeInputKey,
					onCreateLinkBtnClick: _vm.onCreateLinkBtnClick,
					onClearLinkBtnClick: _vm.onClearLinkBtnClick,
					onDisableSelectionBtnClick: _vm.onDisableSelectionBtnClick,
					onCustomValueChange: _vm.onCustomValueChange,
					onShowAdvancedInputField: _vm.onShowAdvancedInputField,
					onAddInputToInputNodeBtnClick: _vm.onAddInputToInputNodeBtnClick,
					node: _vm.node,
					task: _vm.task,
					tasks: _vm.tasks,
					flow: _vm.flow,
					inputValues: inputValues,
					config: _vm.config,
					handleAddNodeInputRow: _vm.handleAddNodeInputRow,
					handleRemoveNodeInputRow: _vm.handleRemoveNodeInputRow,
					readOnly: _vm.readOnly
				},false))],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }