<template>
	<span>
		<!--<Multiselect
            v-if="getOptions()"
            :value="value"
            :options="getOptions()"
            label="label"
            track-by="value"
            @input="handleSelect"/>-->
		<select
			class="form-control"
			:data-test-id="config.key + 'Input'"
			:value="value"
			:disabled="config.readOnly"
			@change="handleValueChange"
		>
			<option v-for="option in getOptions()" :value="option.value">
				{{ option.label }}
			</option>
		</select>
	</span>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script type="text/javascript">
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import _get from 'lodash/get';
export default {
	components: { Multiselect },
	props: ['value', 'config', 'onChange'],
	data() {
		return {
			options: null
		};
	},
	created() {
		if (this.config.async) {
			this.asyncLoad();
		}
	},
	methods: {
		getOptions() {
			return this.options || this.config.options;
		},
		handleSelect(selectedItem) {
			selectedItem && this.onChange && this.onChange(selectedItem.value);
		},
		handleValueChange({ currentTarget: { value } }) {
			this.onChange && this.onChange(value);
		},
		asyncLoad() {
			const {
				url,
				headers,
				method = 'GET',
				body: data,
				query: params,
				afterSending = response => response.data
			} = this.config.async;

			return axios({
				method,
				url,
				headers,
				data,
				params
			})
				.then(this.parseFunction(afterSending))
				.then(options => (this.options = options));
		},

		parseFunction(fn) {
			if (typeof fn === 'function') return fn;

			return response => {
				const _fn = new Function('response', `return (${fn})(response)`);
				return _fn(response);
			};
		}
	}
};
</script>
