<template>
	<Modal class="ProfileListModal" :show="show" size="small">
		<h2 slot="header">
			Load Profile
		</h2>

		<div slot="body" class="container-fluid">
			<div v-if="epmProfiles" class="profiles">
				<div>
					<div v-for="(profile, index) in epmProfiles" :key="profile._id || index" class="ModalBodyProfiles">
						<p class="my-auto">{{ profile.name }}</p>
						<div>
							<button
								class="button"
								:data-test-id="`${profile.name}ProfileLoadButton`"
								@click="onConfirm(profile)"
							>
								Load
							</button>
							<button
								v-if="!defaultProfiles.includes(profile.name)"
								class="button epm-delete-button"
								@click="onDelete(profile)"
							>
								<i class="icon ion-ios-trash-outline epm-thrash-icon"></i>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div v-else>
				Loading...
			</div>
		</div>

		<div slot="footer">
			<button class="button" @click="handleCancel">Cancel</button>
		</div>
	</Modal>
</template>
<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import Modal from './../../../components/modal';

export default {
	props: ['show', 'node', 'onConfirm', 'onClose'],
	data() {
		return {
			defaultProfiles: ['tolerant', 'standard', 'strict']
		};
	},
	computed: {
		...mapGetters({
			epmProfiles: 'epmProfiles',
			selectedEPMProfile: 'selectedEPMProfile',
			areProfilesFetched: 'areProfilesFetched'
		})
	},
	methods: {
		...mapActions({
			getEPMProfiles: 'getEPMProfiles',
			deleteEPMProfile: 'deleteEPMProfile',
			loadEPMProfileValues: 'loadEPMProfileValues',
			openConfirmModal: 'openConfirmModal',
			displayToast: 'displayToast',
			nullifyProfileId: 'nullifyProfileId'
		}),
		handleCancel() {
			this.onClose();
		},
		isModalOpen(modal) {
			return modal.status === 'PENDING';
		},
		async onDelete(profile) {
			const title = this.$t('Profile delete');
			const body = this.$t(`Are you sure you want to delete this profile: '${profile.name}'?`);
			return this.openConfirmModal({ title, body }).then(async () => {
				await this.deleteEPMProfile(profile._id);
				await this.getEPMProfiles();
				if (this.selectedEPMProfile && this.selectedEPMProfile._id === profile._id) {
					this.loadEPMProfileValues(profile); // to keep the form populated
					this.nullifyProfileId(); // but remove deleted profilId from flow
				}

				await this.displayToast({ data: { title: `Profile: ${profile.name} has been deleted` } });
				this.onClose();
			});
		}
	},
	components: {
		Modal
	},
	async created() {
		if (!this.areProfilesFetched) {
			await this.getEPMProfiles();
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.ProfileListModal {
	z-index: 700;
	.Modal-close {
		display: none;
	}
}

.epm-delete-button {
	padding: 0;
}

.epm-thrash-icon {
	font-size: 20px;
	padding: 0px 10px;
}

.ModalBodyProfiles {
	display: flex;
	justify-content: space-between;
	padding: 3px 0px;
	border-bottom: 1px solid $gray-border-color;

	&:last-child {
		border-bottom: unset;
	}
}
</style>
