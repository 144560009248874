<template>
	<modal class="NewFlowModal" :show="show" :on-close="onClose" size="big">
		<h2 slot="header"></h2>

		<div slot="body">
			<form :key="flow ? flow.id : 'new'" class="NewFlowModel-form" @submit.prevent="handleSubmit">
				<h3 v-if="flow">{{ $t('Duplicate Flow') }}</h3>
				<h3 v-else>{{ $t('New Flow') }}</h3>
				<section class="NewFlowModel-formInputs" :class="{ isDuplicate: !!flow }">
					<div class="form-group md-group">
						<label v-if="flow" class="control-label">{{ $t('Name') }}</label>
						<input
							v-model="flowName"
							type="text"
							class="form-control"
							data-test-id="flowNameInput"
							:placeholder="$t('Flow Name')"
						/>
						<span v-if="newFlow.name && newFlow.name.length >= 30" class="error">Name too long</span>
					</div>
					<div class="form-group md-group">
						<label v-if="flow" class="control-label">{{ $t('Description') }}</label>
						<textarea
							v-model="flowDescription"
							rows="1"
							class="form-control"
							:placeholder="$t('Flow Description')"
						/>
					</div>
				</section>
			</form>

			<section v-if="!flow" class="NewFlowModel-templates">
				<div class="NewFlowModel-templates-leftPanel">
					<div class="OneflowLeftPanel">
						<menu class="OneflowLeftPanel-Menu OneflowLeftPanelMenu">
							<h5 class="OneflowLeftPanel-Title">{{ $t('Templates') }}</h5>

							<li
								class="OneflowLeftPanel-MenuItem OneflowLeftPanelMenu-MenuItem"
								:class="{ 'is-active': !this.selectedCategory }"
								@click="handleCategoryClick()"
							>
								<a><span>All Flows</span></a>
							</li>

							<li
								v-for="category in templatesCategories"
								:key="category.dataTestId"
								class="OneflowLeftPanel-MenuItem OneflowLeftPanelMenu-MenuItem"
								:data-test-id="category.dataTestId"
								:class="getCategoryItemClass(category)"
								@click="handleCategoryClick(category)"
							>
								<a
									><span>{{ category.title }}</span></a
								>
							</li>
						</menu>
					</div>
				</div>
				<div class="NewFlowModel-templates-main">
					<ul class="list-unstyled">
						<li
							v-for="template in categoryTemplates"
							:key="template.dataTestId"
							:data-test-id="template.dataTestId"
							:class="{ 'is-selected': selectedTemplate === template }"
							@click="handleTemplateClick(template)"
						>
							<strong>{{ template.title }}</strong>
							<p>{{ template.description }}</p>
						</li>
					</ul>
				</div>
			</section>
		</div>

		<div slot="footer">
			<button class="button" @click="handleCancel">
				{{ $t('Cancel') }}
			</button>
			<button
				class="button button--success"
				data-test-id="createFlowConfirmButton"
				:disabled="!isFormValid"
				@click="handleSubmit"
			>
				{{ $t('Create Flow') }}
			</button>
		</div>
	</modal>
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import Modal from '../../components/modal';
import analytics from '../../lib/analytics';
import { properties, shipAddress } from '../../lib/siteflowDefaultAttributes';

export default {
	props: ['flow', 'show', 'onClose', 'onSubmit', 'createSiteflowArtworkFlow', 'selectArtworkTemplate'],
	computed: {
		...mapGetters(['isSiteflowUser', 'templatesCategories', 'templates', 'siteflowProduct']),
		newFlow() {
			if (this.flow) {
				const { nodes } = this.flow;

				return {
					nodes,
					name: this.flowName,
					description: this.flowDescription
				};
			}

			if (this.createSiteflowArtworkFlow || (this.isSiteflowTemplate && this.templateNodes)) {
				const templateNodes = _.cloneDeep(this.templateNodes);

				if (this.isSiteflowArtworkTemplate) {
					// Find inputs node index
					const inputsIndex = _.findIndex(templateNodes, { name: 'inputs' });
					const originalNodeInputs = _.get(templateNodes[inputsIndex], 'inputs');

					// Make node inputs array from the current siteflow attributes
					if (originalNodeInputs) {
						templateNodes[inputsIndex].inputs = [
							...originalNodeInputs,
							...this.formattedSiteflowAttributes
						];
					} else {
						templateNodes[inputsIndex].inputs = this.formattedSiteflowAttributes;
					}
				}

				// Make flow as a siteflow flow with current productId and componentId
				return {
					name: this.flowName,
					description: this.flowDescription,
					nodes: templateNodes,
					siteflow: true,
					productId: this.productId,
					componentId: this.componentId
				};
			}

			return {
				name: this.flowName,
				description: this.flowDescription,
				nodes: this.templateNodes
			};
		},
		categoryTemplates() {
			if (!this.selectedCategory) return this.templates;

			return (this.templates || []).filter(template => template.categoryName === this.selectedCategory.name);
		},
		isFormValid() {
			return (
				this.newFlow &&
				this.newFlow.name &&
				this.newFlow.name.length > 2 &&
				this.newFlow.name.length < 30 &&
				this.newFlow.nodes &&
				this.newFlow.nodes.length >= 2
			);
		},
		siteflowProperties() {
			return _.cloneDeep(properties);
		},
		siteflowShipAddress() {
			return _.cloneDeep(shipAddress);
		},
		productId() {
			return _.get(this.$route, 'query.productId');
		},
		componentId() {
			return _.get(this.$route, 'query.componentId');
		},
		currentComponent() {
			return _.find(_.get(this.siteflowProduct, 'components', []), { _id: this.componentId });
		},
		componentAttributes() {
			const attributes = _.get(this.currentComponent, 'attributes', {});
			return _.map(attributes, ({ name, type }) => ({
				type,
				key: `attributes.${name}`,
				name: _.startCase(_.toLower(name)),
				defaultValue: ''
			}));
		},
		componentAttributesAreEmpty() {
			return _.isEmpty(this.componentAttributes);
		},
		isSiteflowTemplate() {
			const selectedCategoryName = _.get(this.selectedTemplate, 'categoryName');
			return selectedCategoryName === 'siteflow-file-processing';
		},
		isSiteflowArtworkTemplate() {
			const selectedCategoryName = _.get(this.selectedTemplate, 'type');
			return selectedCategoryName === 'component-artwork-flow';
		},
		siteflowAttributes() {
			const componentAttributes = _.filter(this.componentAttributes, attr => attr.type !== 'fileReady');
			return [...this.siteflowProperties, ...this.siteflowShipAddress, ...componentAttributes];
		},
		formattedSiteflowAttributes() {
			return _.map(this.siteflowAttributes, ({ key, type, name, defaultValue }) => ({
				key,
				type,
				name,
				defaultValue,
				siteflowAttribute: true
			}));
		},
		templateNodes() {
			return _.get(this.selectedTemplate, 'data.nodes');
		}
	},
	data() {
		return {
			selectedCategory: null,
			selectedTemplate: null,
			flowName: '',
			flowDescription: ''
		};
	},
	watch: {
		async show() {
			if (this.show && this.flow) {
				this.flowName = `New-${this.flow.name}`;
				this.flowDescription = this.flow.description;
			} else if (this.show && !this.flow) {
				this.flowName = '';
				this.flowDescription = '';
			}
		}
	},
	created() {
		if (this.selectArtworkTemplate || this.createSiteflowArtworkFlow) {
			this.selectedTemplate = _.find(this.templates, { type: 'component-artwork-flow' });
		}

		if (!this.createSiteflowArtworkFlow) {
			this.getProduct();
		}
	},
	destroyed() {
		this.removeSiteflowProduct();
	},
	methods: {
		...mapActions(['displayToast', 'saveFlow', 'getSiteflowProduct', 'removeSiteflowProduct']),
		async handleSubmit() {
			if (!this.isFormValid) return;

			if (!this.isSiteflowUser && this.isSiteflowArtworkTemplate) {
				this.onClose();
				const body = this.$t('Siteflow account does not exist');
				this.displayToast({ data: { type: 'error', title: 'Error', body } });
				return;
			}

			try {
				const flow = await this.saveFlow({ data: this.newFlow });
				analytics.trackEventFlowCreate();
				this.$router.push({
					name: 'flows.edit',
					params: { id: flow.id },
					query: {
						productId: this.productId,
						componentId: this.componentId,
						showAttributesModal: this.isSiteflowTemplate
					}
				});
			} catch (err) {
				const { status } = err.response;

				if (status === 422) {
					const body = `${this.$t('Flow')} ${this.newFlow.name} ${this.$t('already exists')}`;
					this.displayToast({ data: { type: 'error', title: 'Error', body } });
				} else {
					this.displayToast({ data: err });
				}
			}
		},
		handleCancel() {
			this.onClose();
		},
		getCategoryItemClass(category) {
			return {
				'is-active': this.selectedCategory && this.selectedCategory.name === category.name
			};
		},
		handleCategoryClick(category) {
			this.selectedCategory = category;
		},
		handleTemplateClick(template) {
			this.selectedTemplate = template;
		},
		async getProduct() {
			if (this.productId) {
				await this.getSiteflowProduct(this.productId);
			}
		}
	},
	components: {
		Modal
	}
};
</script>

<style lang="scss">
@import '../../style/colors';

.NewFlowModal {
	.Modal-header {
		display: none;
	}
	.Modal-body {
		padding: 0;
		h3 {
			margin: 0 0 10px;
			font-size: 18px;
			color: $blue-brand-color;
		}
		.NewFlowModel-form {
			padding: 20px;
			border-bottom: 1px solid $gray-border-color;
			display: flex;
			flex-direction: column;

			&Inputs {
				display: flex;
				justify-content: space-between;

				.form-group {
					width: 49%;
					vertical-align: top !important;

					.error {
						color: red;
					}

					.form-control {
						width: 100%;
					}

					textarea {
						resize: vertical;
					}
				}

				&.isDuplicate {
					flex-direction: column;

					.form-group {
						width: 100%;
					}
				}
			}
		}
		.NewFlowModel-templates {
			display: flex;
			.NewFlowModel-templates-leftPanel {
				min-width: 200px;
				border-right: 1px solid $gray-border-color;
				padding-bottom: 10%;
				@media (max-width: 767px) {
					display: none;
				}
				.OneflowLeftPanel {
					display: flex !important;
				}
				.LeftMenuV2-list-item-content {
					cursor: pointer;
				}
			}
			.NewFlowModel-templates-main {
				flex-grow: 1;
				ul {
					margin: 0;
					li {
						cursor: pointer;
						width: 100%;
						padding: 10px 20px;
						&:hover {
							background: rgba(#0096d6, 0.05);
						}
						&.is-selected {
							background: rgba(#0096d6, 0.1);
						}
						p {
							margin: 0;
						}
					}
				}
			}
		}
	}
}
</style>
