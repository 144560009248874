<template>
	<modal v-if="isModalOpen" :show="'true'" method="mount" size="big" :on-close="handleModalClose">
		<h1 slot="header">
			{{ workqueue && workqueue.flow && workqueue.flow.name }}
		</h1>

		<div slot="body">
			<form class="form form-horizontal">
				workqueue id: {{ workqueue.id }}
				<div class="u-textAlignCenter">{{ status }}</div>
				<progress-bar :amount="progress.amount" :type="progress.type"></progress-bar>

				<div v-for="output in outputs.fileOutputs">
					<div class="InputBox">
						<div class="InputBox-wrapper">
							<div class="form-group row">
								<label class="col-sm-2 control-label">{{ output.key }}</label>
								<div class="col-sm-10">
									<input
										v-model="output.value"
										type="text"
										class="form-control"
										placeholder="Enter file path or drop a file"
									/>
								</div>
							</div>
						</div>
						<div class="downloadBtn" @click="downloadFile(output.value)">
							<img width="47" src="../../../public/img/icon-upload.svg" />
						</div>
					</div>
				</div>

				<hr v-if="outputs.otherOutputs.length > 0" />

				<div v-if="outputs.otherOutputs.length > 0" class="input-box">
					<div v-for="output in outputs.otherOutputs" class="form-group row">
						<label class="col-sm-2 control-label">{{ output.key }}</label>
						<div v-if="output.type === 'number'" class="col-sm-10">
							<input class="form-control" :value="output.value" type="number" />
						</div>
						<div v-if="output.type === 'string'" class="col-sm-10">
							<input class="form-control" :value="output.value" type="text" />
						</div>
						<div v-if="output.type === 'boolean'" class="col-sm-10">
							<input class="form-control" :value="output.value" type="text" />
						</div>
					</div>
				</div>
				<!--<div class="u-textAlignCenter">
                    <button class="button" type="button" @click="onClose">Run on the background</button>
                </div>-->
			</form>
		</div>
	</modal>
</template>
<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import Modal from '../../components/modal';
import ProgressBar from '../../components/ProgressBar';

export default {
	// props: ['show', 'workqueue', 'name', 'onClose'],
	data() {
		return {
			progressClass: {
				'progress-bar-success': false,
				'progress-bar-error': false,
				active: true,
				'progress-bar-striped': true
			},
			subscription: null
		};
	},
	updated() {
		if (this.workqueueId && !this.subscription) {
			this.subscription = this.observeWorkqueue({ id: this.workqueueId });
		}
	},
	computed: {
		...mapGetters(['workqueue']),
		isModalOpen() {
			return this.$store.state.workqueue.runningWorkqueueModal.isOpen;
		},
		workqueueId() {
			return _get(this, '$store.state.workqueue.runningWorkqueueModal.workqueue.id');
		},
		outputs() {
			const fileOutputs = [];
			const otherOutputs = [];

			if (this.workqueue && this.workqueue.data && this.workqueue.data.state) {
				for (const key of Object.keys(this.workqueue.data.state)) {
					let value = this.workqueue.data.state[key];

					if (typeof value === 'boolean') value = `${value}`;
					if (typeof value === 'object') value = value.url;

					if (typeof value === 'string' && value.match(/^(https?):\/\/[^\s\/$.?#].[^\s]*$/)) {
						fileOutputs.push({ key, value });
					} else {
						otherOutputs.push({ key, value, type: typeof value });
					}
				}
			}

			return {
				fileOutputs,
				otherOutputs
			};
		},
		status() {
			if (this.workqueue && this.workqueue.data) {
				if (this.workqueue.data.status === 'completed') {
					this.progressClass = {
						'progress-bar-success': true,
						'progress-bar-error': false,
						active: false,
						'progress-bar-striped': false
					};
				} else if (this.workqueue.data.status === 'failed') {
					this.progressClass = {
						'progress-bar-success': false,
						'progress-bar-danger': true,
						active: false,
						'progress-bar-striped': false
					};
				}

				return this.workqueue.data.status;
			}
			return 'notstarted';
		},
		progress() {
			if (!this.workqueue) {
				return 0;
			}
			let type;
			switch (_get(this.workqueue, 'data.status')) {
			case 'completed':
					type = 'success';
				break;
				case 'failed':
				type = 'danger';
					break;
			}

			const totalTasks = (_get(this.workqueue, 'flow.nodes.length') || 2) - 2;
			let completedTasks = 0;

			(this.workqueue.tasks || []).forEach(t => t.status === 'ended' && completedTasks++);

			const amount = Math.ceil((completedTasks * 100) / totalTasks);

			return { type, amount };
		}
	},
	methods: {
		...mapActions(['observeWorkqueue', 'closeRunningWorkqueueModal']),
		handleModalClose() {
			this.closeRunningWorkqueueModal();
		},
		downloadFile(file) {
			window.open(file, '_blank');
		}
	},
	components: {
		Modal,
		ProgressBar
	}
};
</script>
<style lang="scss">
@import '../../style/colors';

.InputBox .downloadBtn {
	background-color: $blue-brand-color;
	padding: 7px 12px 7px 12px;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 1px;
}

.InputBox .downloadBtn img {
	-ms-transform: rotate(180deg); /* IE 9 */
	-webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
	transform: rotate(180deg);
}

.form .progress-bar {
	height: 150%;
}
</style>
