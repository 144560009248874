<template>
	<div class="RightPanel">
		<div class="RightPanel-wrapper">
			<slot></slot>
		</div>
	</div>
</template>

<script type="text/javascript">
export default {};
</script>

<style lang="scss">
@import './RightPanel.scss';
</style>
