<template>
	<transition name="Modal">
		<div v-if="canBeMounted()" v-show="show" class="Modal-mask" transition="modal" @click="handleMaskClick">
			<div class="Modal-wrapper">
				<div class="Modal-container" :class="classes" :style="componentStyle">
					<div class="Modal-header">
						<slot name="header"></slot>
						<div v-if="allowClose" class="Modal-close" @click="handleClose"><i class="ion-close"></i></div>
					</div>

					<div class="Modal-body"><slot name="body"></slot></div>

					<div class="Modal-footer"><slot name="footer"></slot></div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script type="text/javascript">
export default {
	props: {
		show: {
			type: Boolean
		},
		onClose: {
			type: Function
		},
		size: {
			type: String
		},
		method: {
			type: String
		},
		componentStyle: {
			type: String
		},
		allowClose: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		classes() {
			const size = this.size || 'medium ';
			return `Modal-container--${size}`;
		}
	},
	methods: {
		handleMaskClick(event) {
			if (event.target.className === 'Modal-wrapper') {
				this.handleClose();
			}
		},

		canBeMounted() {
			const method = this.method || 'display';
			if (!this.show && method === 'mount') {
				return false;
			}

			return true;
		},

		handleClose() {
			if (!this.allowClose) return;
			typeof this.onClose === 'function' && this.onClose();
		}
	}
};
</script>

<style lang="scss">
@import '../style/colors';

.Modal {
}

.Modal-mask {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.Modal-wrapper {
	/*text-align: center;*/
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	min-height: 100vh;
}

.Modal-container {
	//flex: 1;
	max-width: 900px;
	width: 100%;
	margin: auto;
	padding: 0;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
}

.Modal-container.Modal-container--small {
	max-width: 400px;
}

.Modal-container.Modal-container--medium {
	max-width: 600px;
}

.Modal-container.Modal-container--big {
	max-width: 800px;
}

.Modal-header {
	padding: 0px 20px;
	position: relative;
	color: $blue-brand-color;
	min-height: 45px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $gray-border-color;
	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 0;
		display: inline-block;
		vertical-align: middle;
		//flex-grow: 2;
	}
	h1,
	h2 {
		font-size: 18px;
		margin: 0;
	}

	h2 {
		font-size: 16px;
	}
	h3 {
		color: #42b983;
	}
}

.Modal-header {
	.Modal-close {
		position: absolute;
		top: 50%;
		right: 15px;
		line-height: 1;
		transform: translate(0, -50%);
	}
}

.Modal-body {
	padding: 15px 20px;
	max-height: 70vh;
	overflow: auto;
}

.Modal-footer {
	border-top: 1px solid $gray-border-color;
	> div {
		display: flex;
		justify-content: space-between;
		padding: 15px 20px;
		button {
			min-width: 140px;
			font-size: 14px;
		}
	}
}

.Modal-default-button {
	float: right;
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.Modal-enter {
	opacity: 0;
}

.Modal-leave-active {
	opacity: 0;
}

.Modal-enter .Modal-container,
.Modal-leave-active .Modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.Modal-close {
	color: #aaa;
	position: relative;
	font-size: 18px;
	font-weight: bold;
	cursor: pointer;
}

.Modal-close:hover,
.Modal-close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.Modal-content .calltoactions,
.Modal-run-content .calltoactions,
.worker-settings-content .calltoactions {
	position: relative;
	width: 100%;
	border-top: solid 1px #f4f4f4;
	float: left;
	padding: 10px 0px;
}

.Modal-content .calltoactions a,
.Modal-run-content .calltoactions a,
.worker-settings-content .calltoactions a {
	display: block;
}

.Modal-content .calltoactions a.cancel-modal,
.Modal-content .calltoactions a.cancel-run-modal,
.Modal-run-content .calltoactions a.cancel-modal,
.Modal-run-content .calltoactions a.cancel-run-modal,
.worker-settings-content .calltoactions a.cancel-worker-modal {
	float: left;
	margin-left: 20%;
}

.Modal-content .calltoactions a.create-plan,
.Modal-run-content .calltoactions a.create-plan,
.worker-settings-content .calltoactions a.create-plan {
	float: right;
	margin-right: 20%;
}
</style>
