<template>
	<div class="InfoPanel">
		<div class="WorkqueueView-right-top">
			<h2 class="WorkqueueView-name">
				<router-link v-if="flow" :to="{ name: 'flows.edit', params: { id: this.flow.id || this.flow._id } }">
					<i class="ion-chevron-left" alt="Edit Flow"></i> Edit:
					{{ workqueue && workqueue.flow && workqueue.flow.name }}
				</router-link>
				<span v-else>
					{{ workqueue && workqueue.flow && workqueue.flow.name }}
				</span>
			</h2>
			<button v-if="showMore" class="button button--success button--big" @click="showMore(false)">
				{{ $t('Hide') }}
			</button>
		</div>
		<div class="WorkqueueView-right-body">
			<FlowInfoPanelHeader :workqueue="workqueue"></FlowInfoPanelHeader>
			<div class="InfoPanel-details">
				<div class="InfoPanel-details-row">
					<div>
						<div class="InfoPanel-details-title">Start Time</div>
						<div class="InfoPanel-details-content">
							{{ workqueueData && workqueueData.started | moment('HH:mm:ss') }}
						</div>
					</div>
					<div v-if="isCompleted || isFailed">
						<div class="InfoPanel-details-title">End Time</div>
						<div class="InfoPanel-details-content">
							{{ workqueueData && workqueueData.updated | moment('HH:mm:ss') }}
						</div>
					</div>
				</div>
				<div class="InfoPanel-details-row">
					<div>
						<div v-if="isCompleted || isFailed">
							<div class="InfoPanel-details-title">
								{{ $t('Duration') }}
							</div>
							<div class="InfoPanel-details-content">
								<span v-if="duration.minutes">
									{{ duration.minutes }} <span class="small">{{ $t('min') }}</span>
								</span>
								{{ duration.seconds }} <span class="small">{{ $t('sec') }}</span>
							</div>
						</div>
					</div>
					<div>
						<div class="InfoPanel-details-title">
							{{ $t('Task completed') }}
						</div>
						<div class="InfoPanel-details-content">
							{{ completedTasks }}<span class="small"> of {{ totalTasks }}</span>
							<ProgressBar :amount="percentCompleted" />
						</div>
					</div>
				</div>
			</div>

			<div class="WorkqueueView-accordion">
				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" role="tab">
						<div v-b-toggle.accordion1>{{ $t('Inputs') }}</div>
					</b-card-header>
					<b-collapse id="accordion1" role="tabpanel">
						<b-card-body>
							<div
								v-for="(input, inputName) in workqueueInputs"
								v-if="workqueueData && workqueueData.initialState"
								class="WorkqueueView-inputOutput md-group form-group"
							>
								<label>{{ inputName }}</label>
								<div
									v-if="input.config && input.config.type !== 'file'"
									class="WorkqueueView-inputOutput-value is-text clearfix"
								>
									<div>{{ input.value }}</div>
								</div>
								<div
									v-if="input.config && input.config.type === 'file'"
									class="WorkqueueView-inputOutput-value is-file clearfix"
								>
									<div>{{ $t('Download') }} {{ inputName }}</div>
									<a class="u-cursorPointer pull-right" :href="input.value" target="_blank">
										<i class="ion-ios-download-outline"></i>
									</a>
								</div>
							</div>
							<div v-if="workqueueData && !workqueueData.initialState">{{ $t('No data') }}</div>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" role="tab">
						<div v-b-toggle.accordion2>{{ $t('Outputs') }}</div>
					</b-card-header>
					<b-collapse id="accordion2" role="tabpanel">
						<b-card-body>
							<div v-for="(nodeOutputs, nodeName) in workqueueOutputs" v-if="!noWorkqueueOutputs">
								<div
									v-for="(output, outputName) in nodeOutputs"
									class="WorkqueueView-inputOutput md-group form-group"
								>
									<label>{{ output.displayName || nodeName }} - {{ outputName }}</label>
									<div
										v-if="output.config && output.config.type !== 'file'"
										class="WorkqueueView-inputOutput-value is-text clearfix"
									>
										<div>
											<TruncateText>{{ output.value }}</TruncateText>
										</div>
									</div>
									<div
										v-if="output.config && output.config.type === 'file'"
										class="WorkqueueView-inputOutput-value is-file clearfix"
									>
										<div>{{ $t('Download') }} {{ outputName }}</div>
										<a class="u-cursorPointer pull-right" :href="output.value" target="_blank">
											<i class="ion-ios-download-outline"></i>
										</a>
									</div>
								</div>
							</div>

							<div v-if="noWorkqueueOutputs">{{ $t('No data') }}</div>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" role="tab">
						<div v-b-toggle.accordion3>{{ $t('Logs') }}</div>
					</b-card-header>
					<b-collapse id="accordion3" role="tabpanel">
						<b-card-body class="WorkqueueView-taskLogs">
							<div v-for="taskLogs in allTasksLogs" class="WorkqueueView-taskLogs-item">
								<h5>{{ taskLogs.node.displayName }}</h5>
								<table class="table">
									<tbody>
										<tr v-for="log in taskLogs.logs">
											<td class="table-time">
												<div>{{ log.timestamp | moment('h:mm:ss') }}</div>
											</td>
											<td class="table-message">
												<TruncateText>{{ log.message }}</TruncateText>
											</td>
											<td class="table-icon">
												<div v-if="log.level === 'warn'" class="table-icon-warn">
													<i class="ion-alert-circled"></i>
												</div>
												<div v-if="log.level === 'error'" class="table-icon-error">
													<i class="ion-close-circled"></i>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" role="tab">
						<div v-b-toggle.accordion4>{{ $t('Config') }}</div>
					</b-card-header>
					<b-collapse id="accordion4" role="tabpanel">
						<b-card-body>
							<div class="WorkqueueView-config">
								<div
									v-if="workqueue && workqueue.postback && workqueue.postback.url"
									class="WorkqueueView-config-item"
								>
									<h5>Postback Url</h5>
									<div>{{ workqueue.postback.url }}</div>
								</div>
								<div v-else>{{ $t('No data') }}</div>
							</div>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>

			<div v-if="!workqueueData" class="WorkqueueView-right-empty">
				{{ $t('No information available') }}
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
import _get from 'lodash/get';
import reduce from 'lodash/reduce';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import keys from 'lodash/keys';
import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';
import padStart from 'lodash/padStart';
import findIndex from 'lodash/findIndex';
import ProgressBar from '../../../components/ProgressBar';
import TruncateText from '../../../components/TruncateText';
import FlowInfoPanelHeader from './FlowInforPanelHeader';

export default {
	components: {
		ProgressBar,
		TruncateText,
		FlowInfoPanelHeader
	},
	props: {
		flow: {
			default: null
		},
		workqueue: {
			default: () => ({})
		},
		isMobilePanelExpanded: {
			type: Boolean
		},
		showMore: {
			default: null
		},
		tasksHash: {
			default: {}
		}
	},
	computed: {
		workqueueData() {
			return _get(this, 'workqueue.data', {});
		},
		workqueueOutputs() {
			const state = _get(this, 'workqueue.data.state', {});
			const nodes = _get(this, 'workqueue.flow.nodes', []);
			const orderedNodeDisplayNames = map(nodes, 'displayName');
			const wOutputs = reduce(
				state,
				(acc, outputs, nodeName) => {
					let name = nodeName;
					let node = null;

					if (nodeName !== 'inputs') {
						node = nodes.find(t => t.name === nodeName) || {};

						let task; // same value as in NodeInfoPanel.computed.task
						if (!node || !this.tasksHash) {
							task = {};
						} else {
							const taskName = node.taskName || (node.task && node.task.name);
							task = this.tasksHash[taskName];
						}

						name = node.name;
						acc[name] = reduce(
							outputs,
							(outputsAcc, oValue, oKey) => {
								const oName = _get(task, `outputs.${oKey}.displayName`, oKey);
								const oConfig = _get(task, `outputs.${oKey}`, {});
								outputsAcc[oName] = {
									config: oConfig,
									value: oValue,
									displayName: node.displayName || node.name
								};
								return outputsAcc;
							},
							{}
						);
					}

					return acc;
				},
				{}
			);

			return this.sortKeysBy(wOutputs, (output, nodeName) => orderedNodeDisplayNames.indexOf(nodeName));
		},
		noWorkqueueOutputs() {
			if (!this.workqueueOutputs) {
				return true;
			}

			return values(this.workqueueOutputs).every(value => isEmpty(value));
		},
		workqueueInputs() {
			const inputState = _get(this, 'workqueue.data.initialState.inputs', {});
			const nodes = _get(this, 'workqueue.flow.nodes', []);
			const inputNode = nodes.find(n => n.type === 'input');
			if (!inputNode) return {};

			return reduce(
				inputState,
				(acc, inputVal, inputName) => {
					const inputConf = (inputNode.inputs || []).find(i => i.key === inputName);
					acc[inputName] = { value: inputVal, config: inputConf };
					return acc;
				},
				{}
			);
		},
		duration() {
			const started = _get(this, 'workqueue.data.started');
			const updated = _get(this, 'workqueue.data.updated');
			if (!started || !updated) return null;

			const diffTime = moment.duration(moment(updated).diff(started));
			let minutes;
			const duration = {
				minutes: (minutes = padStart(diffTime.minutes(), 2, '0')) === '00' ? false : minutes,
				seconds: padStart(diffTime.seconds(), 2, '0')
			};
			return duration;
		},
		isCompleted() {
			return _get(this, 'workqueue.data.status') === 'completed';
		},
		isFailed() {
			return _get(this, 'workqueue.data.status') === 'failed';
		},
		totalTasks() {
			const workqueueNodes = _get(this, 'workqueue.flow.nodes', []);
			const nodesNumber = workqueueNodes.filter(node => node.type === 'task').length;

			return Math.max(0, nodesNumber);
		},
		completedTasks() {
			const workqueueTasks = _get(this, 'workqueue.tasks', []);
			return workqueueTasks.filter(task => ['skipped', 'ended'].includes(task.status)).length;
		},
		percentCompleted() {
			const percent = Math.ceil((this.completedTasks * 100) / this.totalTasks);
			return percent;
		},
		allTasksLogs() {
			const tasks = _get(this, 'workqueue.tasks');
			const flowNodes = _get(this, 'workqueue.flow.nodes');
			if (!tasks || !flowNodes) return [];
			const workqueueTasks = sortBy(tasks, task => findIndex(flowNodes, { name: task.name }));

			return workqueueTasks
				.map(task => ({
					task,
					node: flowNodes.find(n => n.name === task.name) || {},
					logs: (task.logs || []).concat().sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
				}))
				.filter(taskLogs => taskLogs.logs && taskLogs.logs.length > 0)
				.sort((a, b) => new Date(a.logs[0].timestamp) > new Date(b.logs[0].timestamp));
		}
	},
	methods: {
		sortKeysBy(obj, comparator) {
			const sortedKeys = sortBy(keys(obj), key => (comparator ? comparator(obj[key], key) : key));

			return fromPairs(map(sortedKeys, key => [key, obj[key]]));
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/utils';
@import '../../../style/colors';

.WorkqueueView-name {
	&:hover,
	a:hover {
		text-decoration: underline;
	}

	i.ion-chevron-left {
		font-size: initial;
	}
}

.WorkqueueView-taskLogs {
	padding: 10px;
}

.WorkqueueView-taskLogs-item {
	h5 {
		font-size: 14px;
		margin: 0 0 2px;
		padding: 0 5px;
		font-weight: bold;
	}
	> table {
		max-width: 100%;
		tbody {
			tr {
				td {
					max-width: 0;
					&.table-time {
						width: 20%;
					}
					&.table-message {
						width: 70%;
					}
					&.table-icon {
						width: 10%;
						.table-icon-warn {
							color: $info-color;
						}
						.table-icon-error {
							color: $danger-color;
						}
					}
				}
			}
		}
	}
}

.WorkqueueView {
	&-truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.WorkqueueView-config-item {
	h5 {
		font-weight: bold;
		border-bottom: 1px solid #dfdfdf;
	}
	div {
		padding: 0 5px;
		margin: 0 0 2px;
	}
}
</style>
