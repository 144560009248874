<template>
	<DefaultLayout>
		<div>
			<div class="FlowListLayout">
				<div class="FlowListLayout-newFlow">
					<button
						class="button button--success button--big"
						data-test-id="createNewFlowButton"
						@click="handleCreateNewFlowBtnClick"
					>
						{{ $t('Create a new Flow') }}
					</button>
				</div>

				<div @click="tableClick">
					<list-layout
						:resource="'flow'"
						:config="{ defaultSort: 'updatedAt DESC' }"
						:table-config="flowsTableConfig"
						:boxes-config="flowsBoxesConfig"
						:filters-config="flowsFiltersConfig"
					/>
				</div>
			</div>
		</div>

		<RenameFlowModal :show="isRenameFlowModalOpen" :flow="flowToRename" :on-close="handleRenameFlowModalClose" />
		<NewFlowModal
			:select-artwork-template="selectArtworkTemplate"
			:create-siteflow-artwork-flow="createSiteflowArtworkFlow"
			:flow="flowDuplicate"
			:show="isNewFlowModalOpen"
			:on-close="handleNewFlowModalClose"
		/>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { validateFlow, userHasAccessToRunFlow, userHasAccess } from '../../../lib/flowValidationUtils';
import { withSubscriptionMixin } from '../../../lib/withSubscriptionMixin';
import Tabs from '../../../components/Tabs';
import Tab from '../../../components/Tab';
import ListLayout from '../../../components/ListLayout';
import DefaultLayout from '../../../components/DefaultLayout';
import RenameFlowModal from '../RenameFlowModal';
import NewFlowModal from '../NewFlowModal';
import Lock from '../../../../public/img/lock.svg';
import analytics from '../../../lib/analytics';

export default {
	components: {
		DefaultLayout,
		Tab,
		Tabs,
		ListLayout,
		RenameFlowModal,
		NewFlowModal
	},
	mixins: [withSubscriptionMixin],
	data() {
		const _this = this;

		const flowsTableConfig = {
			baseUrl: 'flows/edit',
			isRowClickable: true,
			columns: [
				{
					title: 'Plan Name',
					field: 'name',
					isSortable: true
				},
				{
					title: 'Updated',
					field: 'updatedAt',
					isSortable: true,
					type: 'html',
					getHtml(item) {
						if (!item || !item.updatedAt) return;
						return moment(item.updatedAt).format('H:mm:ss, MMMM Do YYYY');
					}
				},
				{
					title: 'Status',
					type: 'html',
					getHtml(item) {
						const isValid = validateFlow(item, _this.tasks);
						let userCanRunFlow = true;
						if (_this.$config.isHpMode) {
							userCanRunFlow = userHasAccessToRunFlow({
								flow: item,
								tasks: _this.tasks,
								userAccess: _this.userAccess
							});
						}

						if (isValid) {
							if (userCanRunFlow === true) {
								return `<i class="ion-checkmark-round u-colorSuccess"></i>
                                            &nbsp;&nbsp;&nbsp;${_this.$t('Ready to run')}`;
							}

							return `<a class="UpgradeStatus"><div class="UpgradeBadge UpgradeBadge--${userCanRunFlow}" data-plan="${userCanRunFlow}">Get ${userCanRunFlow.toUpperCase()}</div><img src="${Lock}" class="UpgradeLock" /></a>`;
						}

						return `<i class="ion-close-round u-colorDanger"></i>
                                    &nbsp;&nbsp;&nbsp;${_this.$t('Incomplete')}`;
					}
				},
				{
					title: '',
					type: 'actions',
					getActions(item) {
						let userCanRunFlow = true;
						if (_this.$config.isHpMode) {
							userCanRunFlow = userHasAccessToRunFlow({
								flow: item,
								tasks: _this.tasks,
								userAccess: _this.userAccess
							});
						}

						return [
							{
								content: '<i class="ion-edit"></i> ',
								identifier: 'edit',
								onClick($event, { item, row, col }) {
									if ($event && $event.stopPropagation) {
										$event.stopPropagation();
									}
									_this.handleRenameFlowActionClick(item);
								}
							},
							{
								content: '<i class="ion-ios-photos"></i> ',
								identifier: 'duplicate',
								onClick($event, { item, row, col }) {
									if ($event && $event.stopPropagation) {
										$event.stopPropagation();
									}
									_this.handleDuplicateFlowBtnClick(item);
								}
							},
							{
								content:
									userCanRunFlow === true
										? '<i class="ion-ios-play"></i> '
										: '<i class="ion-ios-play task--disabled"></i> ',
								identifier: 'run',
								onClick($event, { item, row, col }) {
									if ($event && $event.stopPropagation) {
										$event.stopPropagation();
									}
									const runConfiguration = {
										inputs: cloneDeep(item.nodes[0].inputs)
									};

									if (userCanRunFlow !== true) {
										_this.displayToast({
											data: {
												title: `This flow required a ${userCanRunFlow.toUpperCase()} Plan to run`,
												type: 'error'
											}
										});
										return;
									}

									_this.openRunFlowModal({ runConfiguration, flow: item });
								}
							},
							{
								content: '<i class="ion-ios-trash-outline"></i> ',
								identifier: 'delete',
								onClick($event) {
									if ($event && $event.stopPropagation) {
										$event.stopPropagation();
									}
									_this.handleDeleteFlowActionClick(item);
								}
							}
						];
					}
				}
			]
		};

		const flowsBoxesConfig = {
			titleField: 'name',
			getActions(item) {
				return [
					{
						title:
							'<button class="button button--block button--info"><i class="ion-edit"></i>  Edit Flow</button>',
						type: 'primary',
						href() {
							return `/#/flows/edit/${item.id}`;
						}
					},
					{
						title:
							'<button class="button button--block button--success"><i class="ion-ios-play-outline"></i>  Run Flow</button>',
						type: 'primary',
						onClick() {
							const runConfiguration = {
								inputs: cloneDeep(item.nodes[0].inputs)
							};

							_this.openRunFlowModal({ runConfiguration, flow: item });
						}
					}
				];
			}
		};

		return {
			flowsTableConfig,
			flowsBoxesConfig,
			flowDuplicate: null,
			isRenameFlowModalOpen: false,
			isNewFlowModalOpen: false,
			requiredPlanLevel: null,
			flowToRename: {}
		};
	},
	async mounted() {
		this.siteflowCalls();
		await this.findTasks({
			query: { filter: { limit: 500, skip: 0 } }
		});

		if (this.selectArtworkTemplate || this.createSiteflowArtworkFlow) {
			this.handleCreateNewFlowBtnClick();
		}
	},
	computed: {
		...mapGetters(['tasks', 'userAccess', '$config']),
		unavailableTaskNames() {
			if (this.tasks) {
				const availableTasks = this.tasks.filter(
					task =>
						userHasAccess({
							requiredAccess: task.access,
							userAccess: this.userAccess
						}) !== true
				);
				return availableTasks.map(task => task.name);
			}

			return [];
		},
		flowsFiltersConfig() {
			const filters = [
				{
					name: 'search',
					basePath: 'where',
					key: 'name',
					type: 'search'
				}
			];

			if ($config.isHpMode) {
				filters.push({
					name: 'premiumtasks',
					label: 'Show flows only included in my plan',
					key: 'nodes.taskName',
					handleValue: (filter, val) => {
						const query = val
							? {
									nin: [...this.unavailableTaskNames]
							  }
							: null;
						return query;
					},
					type: 'checkbox',
					value: true,
					position: 'right'
				});
			}

			return filters;
		},
		queryProductId() {
			return _.get(this.$route, 'query.productId');
		},
		queryComponentId() {
			return _.get(this.$route, 'query.componentId');
		},
		selectArtworkTemplate() {
			return _.get(this.$route, 'query.selectArtworkTemplate');
		},
		createSiteflowArtworkFlow() {
			return _.get(this.$route, 'query.createSiteflowArtworkFlow');
		}
	},
	methods: {
		...mapActions([
			'getSiteflowProducts',
			'openRunFlowModal',
			'displayToast',
			'openConfirmModal',
			'deleteFlow',
			'openDeleteFlowModal',
			'findTasks'
		]),
		...mapActions({ setUpgradeAccountModal: 'upgradeAccountModal/setOptions' }),
		siteflowCalls() {
			return this.getSiteflowProducts({ pagesize: 1 });
		},
		tableClick(e) {
			if (e.target.classList.contains('UpgradeBadge')) {
				this.requiredPlanLevel = e.target.dataset.plan;
				this.setUpgradeAccountModal({ isOpen: true, requiredPlan: this.requiredPlanLevel });
				e.preventDefault();
			}
		},
		handleRenameFlowActionClick(flow) {
			analytics.trackEventFlowRename(flow);
			this.flowToRename = flow;
			this.isRenameFlowModalOpen = true;
		},
		handleRenameFlowModalClose() {
			this.flowToRename = null;
			this.isRenameFlowModalOpen = false;
		},
		async handleDeleteFlowActionClick(flow) {
			const isDeleted = await this.openDeleteFlowModal(flow);
			if (isDeleted) {
				analytics.trackEventFlowDelete(flow);
				this.$router.push({ name: 'flows' });
			}
		},
		handleDuplicateFlowBtnClick(item) {
			analytics.trackEventFlowDuplicate(item);
			this.flowDuplicate = item;
			this.handleCreateNewFlowBtnClick();
		},
		handleCreateNewFlowBtnClick() {
			this.isNewFlowModalOpen = true;
		},
		handleNewFlowModalClose() {
			this.isNewFlowModalOpen = false;
			this.flowDuplicate = null;
		},
		loadTasks() {
			return this.registerObserve('observeTasks', {
				query: { filter: { order: 'updatedAt ASC' } }
			});
		}
	}
};
</script>
<style lang="scss">
.FlowListLayout {
	position: relative;
	.Tabs-panes {
		.Pane {
			/*background: none;
                box-shadow: none;*/
		}
	}
	.FlowListLayout-newFlow {
		//margin-top: -15px;
		text-align: right;
	}

	.task {
		&--disabled {
			opacity: 0.3;
			pointer-events: none;
		}
	}
}
</style>
