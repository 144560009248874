export const defaultListPreferences = {
	// selected viewTYpe
	viewType: 'tableView',

	// specific configurations per view
	gridView: {
		limit: 18,
		currentPage: 1
	},
	tableView: {
		limit: 10,
		currentPage: 1
	},

	filters: {}
};
