<script type="text/jsx">
import Tab from './Tab.vue';
import './Tabs.scss';
export default {
	component: {
		Tab
	},
	data () {
		return {
			activeTab: null,
			tabs: []
		}
	},
	created(){
		this.$nextTick(() => {
			if (this.tabs.length > 0 && this.activeTab === null) {
				this.activeTab = this.tabs[0];
			}
		});
	},
	methods: {
		handleTabClick(e, tab){
			this.activeTab = tab;
		}
	},
	render (h) { // <-- h must be in scope

		const tabHeaders = this.tabs.map(tab => {
			const isActive = tab === this.activeTab ? 'is-active' : '';
			return (
				<li
					class={{
						'Tabs-header': true,
						'is-active': isActive
					}}
					on-click={e => { this.handleTabClick(e, tab); }}>
					<span>{tab.$slots.header}</span>
				</li>
			);
		});

		return (
			<section class="Tabs">
				<div class="Tabs-headers">
					<ul>
						{tabHeaders}
					</ul>
				</div>
				<div class="Tabs-panes">
					{this.$slots.default}
				</div>
			</section>
		);
	}
}
</script>
