<template>
	<span>
		<input
			v-focus
			class="form-control"
			placeholder="type url"
			type="text"
			:disabled="config.readOnly"
			:value="value"
			@input="handleCustomValueChange"
		/>
	</span>
</template>
<script type="text/javascript">
import throttle from 'lodash/throttle';
export default {
	props: ['value', 'onChange', 'config'],
	methods: {
		handleCustomValueChange: throttle(function(event) {
			this.onChange && this.onChange(event.target.value);
		}, 500)
	}
};
</script>
