<template>
	<AppLayout>
		<TopBar slot="top-bar">
			<div slot="logo">
				<router-link to="/"> <img src="../../public/img/autoflow-horizontal-logo.svg" alt="" /> </router-link>
			</div>
			<div v-if="!isHpMode" slot="account" class="d-flex align-items-center">
				<AccountSwitcher
					:current-account="currentAccount"
					:on-account-selection="handleAccountSelection"
					:accounts="accounts"
				/>
				<b-dropdown size="sm" variant="link" right class="user-dropdown">
					<template slot="text">
						<icon name="user" />
					</template>
					<b-dropdown-item
						href="https://hpsiteflow.com/docs/siteflow/siteflow-open-source-software.html"
						target="_blank"
						>Open Source Software</b-dropdown-item
					>
					<b-dropdown-item data-test-id="logout" @click.prevent="handleLogoutBtnClick()"
						>Sign Out</b-dropdown-item
					>
				</b-dropdown>
			</div>
		</TopBar>
		<div slot="left-menu">
			<slot name="left-panel"> <LeftPanel /> </slot>
		</div>
		<div slot="content"><slot></slot></div>
	</AppLayout>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { AppLayout, TopBar, LeftPanel, AccountSwitcher } from '@workflow-solutions/ofs-vue-layout';

import './DefaultLayout.scss';

export default {
	components: { AppLayout, TopBar, LeftPanel, AccountSwitcher },
	computed: {
		...mapGetters(['$config']),
		...mapGetters('account', ['accounts', 'currentAccount']),
		isHpMode() {
			return this.$config && this.$config.isHpMode;
		}
	},
	methods: {
		...mapActions(['logout', 'resetMenuItems', 'setDefaultPreferences']),
		...mapActions('auth', ['logout']),
		...mapActions('account', ['setCurrentAccount']),
		handleAccountSelection(account) {
			this.setDefaultPreferences();
			this.setCurrentAccount(account._id);
			if (this.$router) {
				this.$router.push({ path: '/' });
			}
		},
		async handleLogoutBtnClick() {
			this.resetMenuItems();
			this.logout();
			this.$router.push({ name: 'login' });
		}
	}
};
</script>

<style lang="scss">
@import '../style/colors';
.sign-out {
	&:hover,
	&.is-active {
		color: $blue-brand-color;
	}
}
.user-dropdown {
	color: #fff;

	.dropdown-toggle {
		color: #fff;
		display: flex;
		align-items: center;
	}
}
</style>
