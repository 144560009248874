<template>
	<div class="ToolboxWorker" :class="getClasses()">
		<div class="ToolboxWorker-wrapper">
			<div class="ToolboxWorker-img">
				<worker-icon :category="task.category"></worker-icon>
			</div>
			<div class="ToolboxWorker-label">
				{{ task.displayName }}
			</div>
			<a v-if="requiredAccess === true" class="ToolboxWorker-grab" @click="onShowToolboxTaskDetails(task)">
				<i class="ion-information-circled task-details-icon"></i>
			</a>
			<div v-else :title="`Upgrade to ${requiredAccess} to use this task`" class="ToolboxWorker-grab">
				<img :src="lock" class="UpgradeLock" />
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import WorkerIcon from '../../../components/icons/worker-icon';
import Lock from '../../../../public/img/lock.svg';
import './ToolboxTask.scss';

export default {
	components: {
		WorkerIcon
	},
	props: ['task', 'onShowToolboxTaskDetails', 'requiredAccess'],
	data() {
		return {
			status: null,
			lock: Lock
		};
	},
	methods: {
		getClasses() {
			return {
				'is-dragging': this.status === 'dragging',
				'is-draft': this.task.status === 'draft',
				'is-premium': this.requiredAccess !== true
			};
		}
	}
};
</script>
