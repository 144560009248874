import Vue from 'vue';
import _ from 'lodash';
import VueI18n from 'vue-i18n';
import VueMoment from 'vue-moment';
import VueAnalytics from 'vue-analytics';
import DomPortal from 'vue-dom-portal';
import OfsMenu from '@workflow-solutions/ofs-vue-layout';
import BootstrapVue from 'bootstrap-vue';
import VueTippy from 'vue-tippy';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import '@workflow-solutions/ofs-vue-layout/dist/index.css';
import 'vue2-animate/dist/vue2-animate.min.css';
import 'ionicons/css/ionicons.css';
import 'vue-awesome/icons';

import App from './App';
import packageJson from '../package.json';
import store from './vuex';
import router, { menuStructure } from './router';
import './directives';
import './lib/polyfills';
import './registerServiceWorker';

// Sentry Error Logging
const sentryKey = _.get(window, '$config.sentryKey');
if (sentryKey && window.location.hostname !== 'localhost') {
	Vue.config.errorHandler = error => console.error(error);

	Sentry.init({
		dsn: sentryKey,
		release: packageJson.version,
		environment: window.$config.env,
		integrations: [new Sentry.Integrations.Vue({ Vue })]
	});
}

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(VueMoment, { moment });
Vue.use(DomPortal);
Vue.use(VueTippy, {
	flipDuration: 0,
	arrow: true,
	sticky: true,
	touchHold: true
});
Vue.use(OfsMenu, { router, store, menu: menuStructure });
Vue.use(BootstrapVue);

if (window.$config.analyticsId) {
	Vue.use(VueAnalytics, {
		id: window.$config.analyticsId,
		router
	});
}

new Vue({
	el: '#app',
	router,
	store,
	components: {
		App
	},
	template: '<App/>',
	i18n: new VueI18n()
});

// sync(store, router, { moduleName: 'RouteModule' });

export { router, store };
