<template>
	<section class="EndNodeConfiguration">
		<div class="ConfigPanel-section">
			<div class="ConfigPanel-section-body">
				<div v-if="!outputs || !outputs.length" class="EndNodeConfiguration-empty">
					{{ $t('No outputs configured.') }}
				</div>
				<div
					v-for="(output, outputIndex) in outputs"
					v-else
					:key="`EndNode-${outputIndex}`"
					class="EndNodeConfiguration-row"
				>
					<div class="StartNodeConfiguration-input">
						<div class="form-group md-group">
							<label>{{ $t('Name') }}</label>
							<input
								v-model="output.key"
								type="text"
								class="form-control"
								:disabled="readOnly || isSiteflowFlow"
								:placeholder="$t('Assign a name')"
							/>
						</div>
					</div>

					<div class="EndNodeConfiguration-input">
						<NodeField
							:flow="flow"
							:is-active="selectedOutputKeyIndex === outputIndex"
							:config="{ allowCustomValue: false, modality: 'link' }"
							:input-config="{ type: 'text', displayName: 'Value' }"
							:chart="chart"
							:value="output.value"
							:read-only="readOnly || isSiteflowFlow"
							:on-create-link-btn-click="handleCreateLinkBtnClick(outputIndex)"
							:on-disable-selection-btn-click="handleDisableSelectionBtnClick"
							:on-clear-link-btn-click="handleClearLinkBtnClick(outputIndex)"
							:on-custom-value-change="handleCustomValueChange(outputIndex)"
						/>
					</div>

					<div v-if="!(readOnly || isSiteflowFlow)" class="EndNodeConfiguration-buttons">
						<button
							class="button button--small button--round"
							@click="handleRemoveOutputBtnClick(outputIndex)"
						>
							<i class="ion-minus-round"></i>
						</button>
					</div>
				</div>

				<div v-if="!(readOnly || isSiteflowFlow)" class="EndNodeConfiguration-bottom">
					<div class="u-textAlignRight">
						<button class="button button--small" @click="handleAddOutputBtnClick()">
							<i class="ion-plus-round"></i> {{ $t('Add output') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import _ from 'lodash';
import NodeField from './NodeInputField';
import { mapActions } from 'vuex';

export default {
	components: {
		NodeField
	},
	props: ['flow', 'node', 'chart', 'setInput', 'selectOutputKeyIndex', 'selectedOutputKeyIndex', 'readOnly'],
	computed: {
		isSiteflowFlow() {
			return _.get(this.flow, 'siteflow');
		},
		outputs() {
			return _.get(this.node, 'outputs');
		}
	},
	methods: {
		...mapActions(['updateNode']),
		handleCustomValueChange(outputIndex) {
			return value => {
				this.setInput('value', outputIndex, { value });
			};
		},
		handleAddOutputBtnClick() {
			const outputs = this.node.outputs || [];
			outputs.push({ key: '', value: { type: 'custom', value: '' } });
			const updatedNode = { ...this.node, outputs };
			this.updateNode({ node: updatedNode });
		},
		handleRemoveOutputBtnClick(index) {
			this.node.outputs.splice(index, 1);
		},
		handleCreateLinkBtnClick(outputIndex) {
			return () => this.selectOutputKeyIndex(outputIndex);
		},
		handleDisableSelectionBtnClick() {},
		handleClearLinkBtnClick(outputIndex) {
			return () => this.setInput('value', outputIndex, { value: null });
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.EndNodeConfiguration {
	.ConfigPanel-section-body {
		.EndNodeConfiguration-row {
			display: flex;
			align-items: flex-end;
			border-bottom: 1px solid $gray-border-color;
			padding: 10px 15px;
			&:last-child {
				margin-bottom: 20px;
			}
			.EndNodeConfiguration-input {
				width: 100%;
				padding: 0 5px;
			}
			.EndNodeConfiguration-buttons {
				flex-shrink: 1;
				padding: 0 5px;
				button {
					font-size: 12px;
					width: 16px;
					height: 16px;
					padding: 0;
					border-color: #979797;
					color: #979797;
					line-height: 1;

					i {
						line-height: 1;
					}
					span {
						display: block;
						line-height: 1;
						transform: translateY(-33%);
					}
				}
			}
		}
		.EndNodeConfiguration-bottom {
			padding: 10px 15px;
		}
		.EndNodeConfiguration-empty {
			padding: 10px 15px;
			color: $gray-text-color;
		}
	}
}
</style>
