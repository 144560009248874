import { routes as homeRoutes } from '../containers/Home';
import { routes as flowRoutes } from '../containers/Flow';
import { routes as activityRoutes } from '../containers/Workqueue';
import { routes as loginRoutes } from '../containers/Login';
import { routes as previewRoutes } from '../containers/Preview';
import taskRoutes from '../containers/Task';

const routes = [...homeRoutes, ...flowRoutes, ...activityRoutes, ...loginRoutes, ...previewRoutes, ...taskRoutes];

export default routes;
