<template>
	<div @click="onNodeClick(node)">
		<span v-if="debug" style="position:absolute; z-index:1000; line-height:1;">
			id: {{ node.id }} <br />
			<!--name: {{node.name}} <br>-->
			<!--slug: {{node.slug}} <br>-->
			next: {{ node.next.join(',') }}
		</span>

		<InputNode
			v-if="node.type === 'input'"
			:id="node.id"
			:can-be-dropped-on="canBeDroppedOn"
			:dragged-task="draggedTask"
			:selected-node="selectedNode"
			:on-drop="onDrop"
			:node="node"
		/>

		<Node
			v-if="node.type === 'task'"
			:id="node.id"
			:node="node"
			:tasks="tasks"
			:upgrade="upgrade"
			:selected-node="selectedNode"
			:on-drop="onDrop"
			:can-be-dropped-on="canBeDroppedOn"
			:on-delete-btn-click="onDeleteBtnClick"
			:dragged-task="draggedTask"
			:chart-options="chartOptions"
			:workqueue-task-data="workqueueTaskData"
		/>

		<Placeholder
			v-if="node.type === 'placeholder'"
			:id="node.id"
			:node="node"
			:selected-node="selectedNode"
			:on-drop="onDrop"
			:can-be-dropped-on="canBeDroppedOn"
			:on-delete-btn-click="onDeleteBtnClick"
			:chart-options="chartOptions"
		/>

		<Condition
			v-if="node.type === 'condition'"
			:id="node.id"
			:node="node"
			:selected-node="selectedNode"
			:on-drop="onDrop"
			:can-be-dropped-on="canBeDroppedOn"
			:on-delete-btn-click="onDeleteBtnClick"
			:dragged-task="draggedTask"
			:chart-options="chartOptions"
		/>

		<CircleCondition
			v-if="node.type === 'circleCondition'"
			:id="node.id"
			:node="node"
			:selected-node="selectedNode"
			:on-drop="onDrop"
			:can-be-dropped-on="canBeDroppedOn"
		/>

		<BranchNode v-if="node.type === 'branch'" :id="node.id" :node="node" :on-drop="onDrop" />

		<MergeNode v-if="node.type === 'merge'" :id="node.id" :node="node" :on-drop="onDrop" />

		<OutputNode
			v-if="node.type === 'end'"
			:id="node.id"
			:node="node"
			:selected-node="selectedNode"
			:set-input="setInput"
			:outputs-that-can-be-linked="outputsThatCanBeLinked"
		/>
	</div>
</template>

<script type="text/javascript">
import Node from './Node.vue';
import InputNode from './InputNode.vue';
import OutputNode from './OutputNode.vue';
import Placeholder from './Placeholder.vue';
import Condition from './Condition.vue';
import CircleCondition from './CircleCondition.vue';

export default {
	components: {
		Node,
		InputNode,
		OutputNode,
		Placeholder,
		Condition,
		CircleCondition
	},
	props: [
		'node',
		'tasks',
		'onDrop',
		'selectedNode',
		'setInput',
		'outputsThatCanBeLinked',
		'onNodeClick',
		'canBeDroppedOn',
		'onDeleteBtnClick',
		'upgrade',
		'draggedTask',
		'chartOptions',
		'workqueueTaskData'
	],
	data() {
		return {
			debug: false
		};
	}
};
</script>
