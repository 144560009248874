<template>
	<modal class="AddInputsToStartNodeModal" :show="show" :on-close="onClose" size="medium">
		<h2 slot="header">
			{{ $t('Add to Inputs for Runtime?') }}
		</h2>

		<div slot="body">
			<table class="table">
				<thead>
					<tr>
						<th>{{ $t('Original Name') }}</th>
						<th>{{ $t('Input Name') }}</th>
						<th>
							<div class="checkbox">
								<label class="i-checks">
									<input
										type="checkbox"
										:checked="areAllInputsSelected"
										@change="handleSelectAllInputsChange"
									/>
									<i></i> {{ $t('All') }}
								</label>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="input in nodeInputs">
						<td :data-test-id="`${input.key}RunTimeInputLabel`">{{ getDisplayName(input) }}</td>
						<td>
							<div class="form-group md-group">
								<input
									type="text"
									class="form-control"
									:placeholder="getDisplayName(input)"
									:value="names[input.key]"
									@input="handleNameChange($event, input)"
								/>
							</div>
						</td>
						<td>
							<div class="checkbox">
								<label class="i-checks">
									<input
										type="checkbox"
										:checked="selections[input.key]"
										@change="handleSelectionChange($event, input)"
									/>
									<i></i> {{ $t('Add') }}
								</label>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<!--{{startNode}}
            {{node}}-->
		</div>

		<div slot="footer">
			<button class="button" @click="handleCancel">{{ $t('Cancel') }}</button>
			<button class="button button--success" data-test-id="addToInputsButton" @click="handleSubmit">
				{{ $t('Add to Inputs') }}
			</button>
		</div>
	</modal>
</template>
<script type="text/javascript">
import _get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import pickBy from 'lodash/pickBy';
import { getInputFormConfig } from '../../../lib/flowInspectionUtils';

import Modal from '../../../components/modal.vue';

export default {
	components: {
		Modal
	},
	props: ['show', 'node', 'task', 'startNode', 'onClose', 'onSubmit'],
	data() {
		return {
			names: {},
			selections: {}
		};
	},
	computed: {
		nodeInputs() {
			const inputs = _get(this, 'task.inputs', {});
			return pickBy(inputs, input => input.type !== 'hidden' && input.allowLinking !== false);
		},
		areAllInputsSelected() {
			const allInputs = this.nodeInputs;
			const selectedInputs = pickBy(this.selections, selected => selected);

			return Object.keys(allInputs).length === Object.keys(selectedInputs).length;
		}
	},
	watch: {
		show() {
			if (!this.show) return;

			this.names = mapValues(this.nodeInputs, inputVal => {
				const displayName = this.getDisplayName(inputVal);
				const inputNameRegex = new RegExp(`${displayName}( #([0-9]))?$`);

				const sameNameInputsCount = filter(this.startNode.inputs, input => inputNameRegex.test(input.key))
					.length;

				if (sameNameInputsCount > 0) {
					return `${displayName} #${sameNameInputsCount + 1}`;
				}
			});
			this.selections = reduce(
				this.nodeInputs,
				(acc, inputVal, inputKey) => {
					acc[inputKey] = true;
					return acc;
				},
				{}
			);
		}
	},
	methods: {
		handleSubmit() {
			const { node, names, selections } = this;
			const selectedInputs = reduce(
				this.selections,
				(result, selected, inputKey) => {
					if (selected) {
						const name = this.names[inputKey] || this.getDisplayName(this.nodeInputs[inputKey]);
						result.push({ inputKey, name });
					}

					return result;
				},
				[]
			);

			this.onSubmit({
				node,
				selectedInputs
			});
		},
		handleCancel() {
			this.onClose();
		},
		handleNameChange($event, input) {
			const {
				currentTarget: { value }
			} = $event;
			this.names = {
				...this.names,
				[input.key]: value
			};
		},
		handleSelectionChange($event, input) {
			const {
				currentTarget: { checked }
			} = $event;
			this.selections = {
				...this.selections,
				[input.key]: checked
			};
		},
		handleSelectAllInputsChange($event) {
			const {
				currentTarget: { checked }
			} = $event;
			if (checked) {
				this.selections = reduce(
					this.nodeInputs,
					(result, input) => {
						result[input.key] = true;
						return result;
					},
					{}
				);
			} else {
				this.selections = {};
			}
		},
		getDisplayName(input) {
			return getInputFormConfig(input.key, _get(this, 'task.form')).displayName || input.key;
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.AddInputsToStartNodeModal {
	table {
		thead {
			tr {
				th {
					color: $gray-text-color;
					font-weight: normal;
				}
			}
		}
		tbody {
			tr {
				td {
					text-align: left;
					vertical-align: middle;
				}
			}
		}
	}
}
</style>
