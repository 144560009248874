<template>
	<printos-header app-id="autoflow"></printos-header>
</template>
<script type="text/javascript">
import _set from 'lodash/set';
export default {
	props: ['$config'],
	created() {
		const script = window.document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = 'https://cdn.printos.com/blue/loader.js';
		window.document.head.append(script);

		const link = window.document.createElement('link');
		link.rel = 'stylesheet';
		link.type = 'text/css';
		link.href = 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic';
		window.document.head.append(link);
		_set(window, 'printos.blue.ui.registry.host', this.$config.printosBase);
	}
};
</script>
