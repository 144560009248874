import store from '../vuex';

const menuStructure = [
	{
		title: 'Flows',
		path: '/flows',
		matches: [{ name: 'flows' }, { name: 'workqueues' }],
		items: [
			{
				title: 'Flows',
				items: [{ title: 'All Flows', name: 'flows' }, { title: 'Activity', name: 'workqueues' }]
			}
		]
	},
	{
		title: 'Tasks',
		path: '/tasks',
		visible() {
			const user = store.getters.user || {};
			return user.isAdmin;
		},
		matches: [{ name: 'tasks' }],
		items: [
			{
				title: 'Tasks',
				items: [{ title: 'All Tasks', name: 'tasks' }]
			}
		]
	}
];

export default menuStructure;
