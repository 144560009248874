<template>
	<div class="OutputNode">
		Outputs
	</div>
</template>

<script type="text/javascript">
export default {
	props: ['node'],
	computed: {
		style() {
			if (!this.node) return null;

			return {
				left: `${this.node.x}px`,
				top: `${this.node.y}px`
			};
		}
	}
};
</script>

<style lang="scss">
@import './Chart-utils';

$borderRadius: $shapeHeight * 0.666666;
.OutputNode {
	z-index: 3;
	background: black;
	cursor: pointer;
	text-align: center;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $shapeWidth;
	height: $shapeHeight;
	border-radius: $borderRadius;
	//border: 1px solid #DFDFDF;
}
</style>
