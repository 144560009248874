import Vue from 'vue';

export default function(store) {
	Vue.$ofsCrud.registerAuthModule({
		authBase: window.$config.authBase,
		state: {
			currentAccount: null,
			isAuthenticated: window.$config.isHpMode
		},
		actions: {
			async doLogout({ dispatch }) {
				await dispatch('logout');
				await dispatch('account/setCurrentAccount', null, { root: true });
			},
			async generateJWT({ dispatch, commit }) {
				const { data } = await dispatch('request', {
					method: 'get',
					path: 'users/generatejwt',
					transport: 'autoflow'
				});
				const { token } = data;

				commit('SET_TOKEN', token);
				return token;
			},
			setAuthenticated({ commit }, isAuthenticated) {
				commit('SET_AUTHENTICATED', isAuthenticated);
			},
			setToken({ commit }, token) {
				commit('SET_TOKEN', token);
			}
		},
		mutations: {
			SET_AUTHENTICATED(state, isAuthenticated) {
				state.isAuthenticated = isAuthenticated;
			},
			SET_TOKEN(state, token) {
				state.token = token;
			}
		}
	});

	if (window.$config.isHpMode) {
		store.dispatch('auth/setAuthenticated', true);
	}
}
