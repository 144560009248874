<template>
	<section class="NodeFieldSwitcher">
		<b-dropdown>
			<template slot="button-content">
				<i v-if="modality === 'link'" class="icon ion-pinpoint"></i>
				<i v-if="modality === 'customValue'" class="u-timesNewRoman">T</i>
			</template>

			<b-dropdown-item @click="onItemClick('changeModality', 'link')">
				<i class="icon ion-pinpoint"></i> {{ $t('Link') }}
			</b-dropdown-item>

			<b-dropdown-item @click="onItemClick('changeModality', 'customValue')">
				<i class="u-timesNewRoman">T</i> {{ $t('Insert manual value') }}
			</b-dropdown-item>

			<b-dropdown-item @click="onItemClick('addInputToStartNodeInputs')">
				<i class="ion-ios-plus-outline"></i> {{ $t('Add to input node') }}
			</b-dropdown-item>
		</b-dropdown>
	</section>
</template>
<script type="text/javascript">
export default {
	props: {
		modality: {
			default: 'customValue'
		},
		onItemClick: {
			default: () => {}
		},
		hasLink: {
			default: false
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.NodeFieldSwitcher {
	.dropdown-toggle {
		padding: 0 3px;
		border: 0;
		margin: 0;
		appearance: none;
		border-radius: 0;

		&:hover,
		&:focus {
			background: #f2f2f2;

			.icon {
				color: $blue-brand-color;
			}
		}
	}
}
</style>
