import reduce from 'lodash/reduce';
import _get from 'lodash/get';
import find from 'lodash/find';
import { getInputFormConfig, getAvailableInputValuesForNodeInput } from '../../lib/flowInspectionUtils';

import { validateFlow } from '../../lib/flowValidationUtils';

export const isTriggerSelectionOpen = state => state.isTriggerSelectionOpen;

export const selectedNodeName = state => state.selectedNodeName;

export const selectedNode = ({ selectedNodeName, flow }) => {
	const nodes = (flow && flow.nodes) || [];
	return find(nodes, { name: selectedNodeName });
};

export const selectedNodeTask = (state, { selectedNode, tasks = [] }) => {
	if (!selectedNode) return null;
	return find(tasks, { name: selectedNode.taskName, status: selectedNode.status || 'live' });
};

export const selectedNodeLinkedInputs = state => {
	const { selectedNodeName, flow } = state;
	if (!flow) return [];
	const { nodes } = flow;
	if (!selectedNodeName || !nodes) return [];
	const selectedNode = nodes.find(n => n.name === selectedNodeName);
	if (!selectedNode) return [];

	return reduce(
		selectedNode.inputs,
		(topAcc, input, inputKey) => {
			if (input && input.values) {
				return topAcc.concat(
					reduce(
						input.values,
						(acc, inputValue, inputValueIndex) => {
							if (inputValue.type === 'ref') {
								const { nodeName, field } = inputValue;
								acc.push({ nodeName, inputKey, inputValueIndex });
							}
							return acc;
						},
						[]
					)
				);
			}

			return topAcc;
		},
		[]
	);
};

export const selectedNodeLinkedOutputs = (state, { tasksHashByName }) => {
	const { selectedNodeName, flow } = state;
	if (!flow) return [];
	const { nodes } = flow;
	if (!selectedNodeName || !nodes) return [];
	const selectedNode = nodes.find(n => n.name === selectedNodeName);
	if (!selectedNode) return [];

	const reduceNodes = () => reduce(nodes, (acc, node) => acc.concat(reduceNodeInputs(node)), []);

	const reduceNodeInputs = node =>
		reduce(node.inputs, (acc, input, inputKey) => acc.concat(reduceNodeInputsValues(input, inputKey, node)), []);

	const reduceNodeInputsValues = (input, inputKey, node) => {
		if (!input || !input.values) return [];

		return reduce(
			input.values,
			(acc, inputValue) => {
				if (inputValue.type === 'ref' && inputValue.nodeName === selectedNode.name) {
					const selectedNodeInputFormConfig = (tasksHashByName[selectedNode.taskName] || {}).form;
					const nodeInputFormConfig = (tasksHashByName[node.taskName] || {}).form;
					acc.push({
						source: {
							node: selectedNode,
							inputFormConfig: getInputFormConfig(inputValue.field, selectedNodeInputFormConfig) || {}
						},
						destination: {
							node,
							inputFormConfig: getInputFormConfig(inputKey, nodeInputFormConfig) || {},
							outputKey: inputKey
						}
					});
				}
				return acc;
			},
			[]
		);
	};

	return reduceNodes();
};

export const flowHasChanges = state => state.flowHasChanges;

export const selectedNodeInputKey = state => state.selectedNodeInputKey;

export const selectedOutputKeyIndex = state => state.selectedOutputKeyIndex;

export const templatesCategories = state => state.templatesCategories;

export const templates = state => state.templates;

export const editFlowInfoModal = state => state.editFlowInfoModal;

export const importSiteflowAttributesModal = state => state.importSiteflowAttributesModal;

export const outputsThatCanBeLinked = (state, { selectedNode, flow }, rootState) => {
	const tasks = _get(rootState, 'task.tasks', []);
	return getAvailableInputValuesForNodeInput({
		node: selectedNode,
		nodes: (flow && flow.nodes) || [],
		tasks
	});
};

export const isFlowValid = (state, { flow, tasks }) => validateFlow(flow, tasks);

export const isAddInputsToStartNodeModalVisible = state => state.isAddInputsToStartNodeModalVisible;

export const epmProfiles = state => [...state.epmPresets, ...state.epmCustomProfiles];
export const selectedEPMProfile = state => state.selectedEPMProfile;

export const epmPresets = state => state.epmPresets;
export const epmCustomProfiles = state => state.epmCustomProfiles;
export const areProfilesFetched = state => state.areProfilesFetched;
