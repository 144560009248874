import Promise from 'bluebird';
import uniqueId from 'lodash/uniqueId';

export default function(store) {
	const openConfirmModal = ({ commit, state }, config = {}) => {
		const modalId = uniqueId();
		commit('OPEN_CONFIRM_MODAL', { modalId, config });

		let subscription;

		return new Promise((resolve, reject) => {
			subscription = store.subscribe(mutation => {
				const isConfirmMutation = mutation.type === 'MODAL_CONFIRM';
				const isDeclineMutation = mutation.type === 'MODAL_DECLINE';
				const isSameModalId = mutation.payload && mutation.payload.modalId === modalId;

				if ((isConfirmMutation || isDeclineMutation) && isSameModalId) {
					if (isConfirmMutation) {
						if (config.returnBoolean) {
							resolve(true);
						} else {
							resolve({ modalId });
						}
					}
					if (isDeclineMutation) {
						if (config.returnBoolean) {
							resolve(false);
						} else {
							reject(new Error());
						}
					}
					subscription();
				}
			});
		});
	};

	const confirm = ({ commit, state }, modalId) => commit('MODAL_CONFIRM', modalId);

	const decline = ({ commit, state }, modalId) => commit('MODAL_DECLINE', modalId);

	return { openConfirmModal, confirm, decline };
}
