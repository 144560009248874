import Vue from 'vue';
import * as actions from './actions';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'files',
		options: { namespaced: false },
		transport: 'autoflow',
		actions
	});
}
