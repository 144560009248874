import * as actions from './actions';
import * as mutations from './mutations';

export default function(store) {
	store.registerModule(
		'menu',
		{
			namespaced: true,
			actions,
			mutations
		},
		{ preserveState: true }
	);
}
