<template>
	<div class="ListLayout" :class="classes">
		<section v-if="getUiConfiguration().showToolbar" class="Toolbar">
			<div v-if="getUiConfiguration().showFilters" class="Toolbar-desktopFilters">
				<Filters
					:config="filtersConfig"
					:values="getParsedPreferences().filters"
					:on-change="handleFilterChange"
				/>
			</div>
			<div class="Toolbar-mobileRight">
				<a :class="{ 'is-active': areMobileFiltersVisible }" @click="handleToggleMobileFiltersBtnClick()">
					<i class="ion-ios-settings"></i> Filters
				</a>
			</div>
		</section>

		<section v-if="areMobileFiltersVisible" class="Toolbar-mobileFilters">
			<Filters :config="filtersConfig" :values="getParsedPreferences().filters" :on-change="handleFilterChange" />
		</section>

		<div class="ListLayout-dataContainer">
			<div v-if="isLoading" class="ListLayout-loader">
				<Loader />
			</div>
			<div v-if="tableIsVisible" class="Pane">
				<TableView
					:config="tableConfig"
					:order-filter="getOrderFilter()"
					:items="localItems"
					:on-header-cell-click="handleHeaderCellClick"
				/>
			</div>
			<div class="ListLayout-pagination">
				<Pagination :total-pages="totalPages" :current-page="currentPage" :on-page-change="handlePageChange" />
			</div>
		</div>

		<div v-if="items && items.length === 0" class="ListLayout-empty Pane">
			{{ $t('No data available') }}
		</div>
	</div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _merge from 'lodash/merge';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import upperFirst from 'lodash/upperFirst';
import defaultsDeep from 'lodash/defaultsDeep';
import reduce from 'lodash/reduce';
import { LIST_PREFERENCES } from '../vuex/types';
import { getUIConfig } from '../vuex/helpers';
import BoxesView from './BoxesView';
import TableView from './TableView';
import Filters from './Filters';
import Pagination from './Pagination';
import Loader from './Loader';
import { withSubscriptionMixin } from '../lib/withSubscriptionMixin';
import './ListLayout.scss';

export default {
	components: {
		BoxesView,
		TableView,
		Pagination,
		Filters,
		Loader
	},
	mixins: [withSubscriptionMixin],
	props: [
		'resource',
		'uiConfig',
		'config',
		'tableConfig',
		'boxesConfig',
		'filtersConfig',
		'onPageChange',
		'filterCriteria',
		'onFilterChange'
	],
	computed: {
		...mapGetters(['getListPreferences']),
		items() {
			const mountPath = this.getMountPath();
			if (mountPath) {
				return _get(this, `$store.state.${this.resource}.${mountPath}`);
			}

			return _get(this, `$store.state.${this.resource}.${this.resource}s`);
		},
		totalPages() {
			const viewType = this.getViewType();
			return Math.ceil(this.totalCount / this.getParsedPreferences()[viewType].limit);
		},
		currentPage() {
			const viewType = this.getViewType();
			return this.getParsedPreferences()[viewType].currentPage;
		},
		classes() {
			return {
				'is-loading': this.status === 'loading'
			};
		}
	},
	data() {
		return {
			localItems: [],
			isLoading: true,
			tableIsVisible: false,
			totalCount: 0,
			areMobileFiltersVisible: false,
			status: 'ready'
		};
	},
	created() {
		this.observeData();
		this.subscribe();
		this.handleFilterChange = debounce(this.handleFilterChange, 300);
	},
	destroyed() {
		this.storeSubscription();
	},
	methods: {
		...mapActions(['saveListPreferences']),
		subscribe() {
			this.storeSubscription = this.$store.subscribe(mutation => {
				if (
					mutation.type === LIST_PREFERENCES &&
					mutation.payload.resource === this.resource &&
					mutation.payload.mountPath === this.getMountPath()
				) {
					this.observeData();
				}
			});
		},
		async observeData() {
			this.unsubscribeAll();
			this.isLoading = true;
			const query = this.createObserveQuery();
			const options = { mountPath: this.getMountPath() };
			const getTotalCountThrottled = throttle(() => this.getTotalCount(), 500);
			const { observable } = await this.registerObserve(`observe${upperFirst(this.resource)}s`, {
				query,
				options
			});

			this.addSubscription(
				observable.subscribe(async () => {
					this.status = 'ready';
					await getTotalCountThrottled();
					this.localItems = this.items;
					this.isLoading = false;
					if (this.localItems && this.localItems.length) {
						this.tableIsVisible = true;
					}
				})
			);
		},
		getUiConfiguration() {
			return getUIConfig(this.uiConfig);
		},
		getMountPath() {
			return this.getUiConfiguration().mountPath;
		},
		getParsedPreferences() {
			const mountPath = this.getMountPath();
			const listPrefs = this.getListPreferences(this.resource, { mountPath });

			listPrefs.filters = defaultsDeep({}, listPrefs.filters);
			return listPrefs;
		},

		getViewType() {
			return this.getUiConfiguration().viewType || this.getParsedPreferences().viewType || 'gridView';
		},

		getTotalCount() {
			const countQuery = {
				where: this.getWhereFilter()
			};
			return this.$store.dispatch(`count${upperFirst(this.resource)}s`, { query: countQuery }).then(response => {
				this.totalCount = response.count;
			});
		},

		createObserveQuery() {
			const viewType = this.getViewType();
			const viewTypePrefs = this.getParsedPreferences()[viewType] || {};
			const currentPage = viewTypePrefs.currentPage;
			const limit = viewTypePrefs.limit;
			const skip = limit * (currentPage - 1);

			const filter = _merge({}, this.filterCriteria, {
				order: this.getOrderFilter(),
				where: this.getWhereFilter(),
				limit,
				skip
			});

			return { filter };
		},

		handleFilterChange(filter, val) {
			if (this.onFilterChange) {
				return this.onFilterChange(filter, val);
			}

			this.status = 'loading';

			const prefs = {
				...this.getParsedPreferences(),
				gridView: {
					...this.getParsedPreferences().gridView,
					currentPage: 1
				},
				tableView: {
					...this.getParsedPreferences().tableView,
					currentPage: 1
				}
			};

			prefs.filters = { ...prefs.filters };
			_set(prefs.filters, filter.name, val);

			return this.saveListPreferences({
				resource: this.resource,
				mountPath: this.getMountPath(),
				prefs
			});
		},

		formatFilterValueForQuery(filter, val) {
			let value = val;

			// If custom handler, use it
			if (filter.handleValue) return filter.handleValue(filter, value);

			switch (filter.type) {
			case 'search':
				if (typeof value === 'undefined' || value === '') return;
				value = { like: `.*${value}.*`, options: 'i' };
				break;
				default:
				break;
			}

			return value;
		},

		getWhereFilter() {
			return reduce(
				this.filtersConfig || {},
				(acc, filter) => {
					const value = _get(this.getParsedPreferences(), `filters.${filter.name}`);
					if (filter.key && typeof value !== 'undefined') {
						const val = this.formatFilterValueForQuery(filter, value);
						if (val) acc[filter.key] = this.formatFilterValueForQuery(filter, value);
					}

					return acc;
				},
				{}
			);
		},

		getOrderFilter() {
			return this.getParsedPreferences().order || _get(this, 'config.defaultSort');
		},

		selectViewType(type) {
			const prefs = {
				...this.getParsedPreferences(),
				viewType: type
			};
			this.saveListPreferences({
				resource: this.resource,
				mountPath: this.getMountPath(),
				prefs
			});
		},

		handlePageChange(pageNum) {
			this.status = 'loading';
			const viewType = this.getViewType();
			const viewTypePrefs = this.getParsedPreferences()[viewType];
			this.saveListPreferences({
				resource: this.resource,
				mountPath: this.getMountPath(),
				prefs: {
					...this.getParsedPreferences(),
					[viewType]: {
						...viewTypePrefs,
						currentPage: pageNum
					}
				}
			});
		},

		handleToggleMobileFiltersBtnClick() {
			this.areMobileFiltersVisible = !this.areMobileFiltersVisible;
		},

		handleHeaderCellClick(c) {
			this.status = 'loading';
			const newOrderField = c.field;
			const prefs = {
				...this.getParsedPreferences(),
				gridView: {
					...this.getParsedPreferences().gridView,
					currentPage: 1
				},
				tableView: {
					...this.getParsedPreferences().tableView,
					currentPage: 1
				}
			};

			const currentOrdering = prefs.order || 'updatedAt';
			const [orderField, orderDirection] = currentOrdering.split(' ');
			let newOrderDirection = 'DESC';

			if (newOrderField === orderField) {
				newOrderDirection = orderDirection === 'DESC' ? 'ASC' : 'DESC';
			}

			prefs.order = `${newOrderField} ${newOrderDirection}`;

			this.saveListPreferences({
				resource: this.resource,
				mountPath: this.getMountPath(),
				prefs
			});
		}
	}
};
</script>

<style lang="scss">
.ListLayout {
	&-dataContainer {
		position: relative;
	}

	&-loader {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-empty {
		padding: 30px;
		font-style: italic;
	}
}
</style>
