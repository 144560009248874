<template>
	<div class="Toolbox">
		<ul v-if="mode === 'list'" class="Toolbox-categories list-unstyled">
			<li v-for="(category, label) in getTasksByCategory" :key="label">
				<div class="Toolbox-category" :data-category="label">
					<div class="Toolbox-category-header" @click="toggleCategoryCollapse(label)">
						<div class="Toolbox-category-header-icon">
							<worker-icon :category="label"></worker-icon>
						</div>
						<div class="Toolbox-category-header-name">
							{{ label }}
						</div>
					</div>

					<!--<transition name="fade">-->
					<ul class="Toolbox-tasksList" :class="{ 'is-collapsed': categoryCollapse[label] }">
						<li v-for="task in category" :key="`list-${task.id}`">
							<div
								class="Toolbox-draggable"
								:data-name="task.name"
								:data-type="task.type"
								:data-status="task.status"
								:data-access="task.access"
							>
								<ToolboxTask
									:task="task"
									:on-drag-status-change="onDragStatusChange"
									:on-show-toolbox-task-details="onShowToolboxTaskDetails"
								/>
							</div>
						</li>
					</ul>
					<!--</transition>-->
				</div>
			</li>
		</ul>

		<ul v-if="mode === 'search'" class="Toolbox-tasksList">
			<li v-for="task in getTasks()" :key="`search${task.id}`">
				<div
					class="Toolbox-draggable"
					:data-name="task.name"
					:data-type="task.type"
					:data-status="task.status"
					:data-access="task.access"
				>
					<ToolboxTask
						:task="task"
						:on-drag-status-change="onDragStatusChange"
						:on-show-toolbox-task-details="onShowToolboxTaskDetails"
					/>
				</div>
			</li>
		</ul>
	</div>
</template>

<script type="text/javascript">
import Vue from 'vue';
import _ from 'lodash';
import $ from 'jquery';
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import 'jquery-ui/ui/widgets/draggable';
import ToolboxTask from './ToolboxTask';
import { userHasAccess } from '../../../lib/flowValidationUtils';
import WorkerIcon from '../../../components/icons/worker-icon';

import './Toolbox.scss';

export default {
	components: {
		ToolboxTask,
		WorkerIcon
	},
	props: ['mode', 'searchValue', 'onDragStatusChange', 'onShowToolboxTaskDetails', 'showOnlyIncludedTasks'],
	computed: {
		...mapGetters(['tasks', 'account', 'userAccess', '$config']),
		filteredTasks() {
			let tasks = this.tasks || [];
			const accountName = (this.account || {}).name;
			if (['oneflow', 'oneflow-stage', 'oneflow-dev', 'testingonly'].includes(accountName)) return tasks;

			if ($config.isHpMode && this.showOnlyIncludedTasks) {
				tasks = tasks.filter(
					task => userHasAccess({ requiredAccess: task.access, userAccess: this.userAccess }) === true
				);
			}

			return tasks;
		},
		getTasksByCategory() {
			if (!this.filteredTasks) return [];
			return _.groupBy(this.filteredTasks, 'category');
		}
	},
	data() {
		return {
			categoryCollapse: {}
		};
	},
	watch: {
		tasks() {
			this.bindUI();
		},
		searchValue() {
			this.bindUI();
		}
	},
	mounted() {
		this.bindUI();
	},
	updated() {
		this.bindUI();
	},
	methods: {
		bindUI: debounce(function() {
			const _this = this;
			this.$nextTick(() => {
				const $elements = $('.Toolbox-tasksList > li > div.Toolbox-draggable');
				try {
					$elements.draggable('destroy');
				} catch (err) {}
				$elements.draggable({
					appendTo: '.EditPage',
					helper: 'clone',
					cursor: 'move',
					revert: 'invalid',
					start() {
						_this.onDragStatusChange('dragging', this.dataset);
					},
					stop() {
						_this.onDragStatusChange('ready');
					}
				});
			});
		}, 200),
		getTasks() {
			if (!this.filteredTasks) return [];
			// this.getTasksByCategory();
			const searchRegex = new RegExp(this.searchValue, 'i');
			return this.filteredTasks.filter(w => {
				const isVisible = !w.hide;
				return isVisible && (!this.searchValue || searchRegex.test(w.name) || searchRegex.test(w.displayName));
			});
		},
		// getTasksByCategory() {
		//     if (!this.filteredTasks) return [];
		//     const categories = {};
		//     this.filteredTasks.forEach(w => {
		//         const category = [
		//             ...(categories[w.category] || {}),
		//             w
		//         ];
		//         categories[w.category] = category;
		//     });
		//     return categories;
		// },
		toggleCategoryCollapse(label) {
			Vue.set(this.categoryCollapse, label, !this.categoryCollapse[label]);
		}
	}
};
</script>

<style lang="scss">
.Toolbox-tasksList {
	&.is-collapsed {
		display: none !important;
	}
}
</style>
