<template>
	<section class="Dropzone"></section>
</template>

<script type="text/javascript">
import $ from 'jquery';
window.$ = $;
export default {
	props: ['node', 'type', 'onDrop'],
	methods: {}
};
</script>

<style lang="scss">
@import '../../style/colors';

.Dropzone {
}
</style>
