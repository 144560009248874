<template>
	<section v-show="show" class="Tab">
		<span class="u-hide">
			<slot name="header"></slot>
		</span>
		<slot name="content"></slot>
	</section>
</template>
<script type="text/javascript">
export default {
	computed: {
		show() {
			return this.$parent.activeTab && this.$parent.activeTab == this;
		}
	},
	created() {
		this.$parent.tabs.push(this);
	}
};
</script>
