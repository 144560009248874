export const OPEN_CONFIRM_MODAL = (state, { modalId, config }) => {
	state.modals = {
		...state.modals,
		[modalId]: {
			status: 'PENDING',
			id: modalId,
			config
		}
	};
};

export const MODAL_CONFIRM = (state, { modalId }) => {
	const { [modalId]: modal, ...modals } = state.modals;
	state.modals = modals;
};

export const MODAL_DECLINE = (state, { modalId }) => {
	// removing the modal via destructuring and rest
	const { [modalId]: modal, ...modals } = state.modals;
	state.modals = modals;
};
