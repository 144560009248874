import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default function(store) {
	const state = {
		isOpen: false,
		requiredPlan: null,
		allowClose: true
	};
	const module = { namespaced: true, state, actions, mutations, getters, store };

	store.registerModule('upgradeAccountModal', module);
}
