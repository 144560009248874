import { set } from "vue-analytics";
<template>
	<div class="App" :class="classes" :data-version="version">
		<PrintosHeader v-if="$config.isHpMode" :$config="$config" />
		<div class="AppContainer"><router-view /></div>
		<RunFlowModal />
		<RunningFlowModal />
		<EditFlowInfoModal />
		<ConfirmModal />
		<Toast />
		<UpgradeAccountModal />
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { set } from 'vue-analytics';
import RunFlowModal from './containers/RunFlow/RunFlowModal';
import RunningFlowModal from './containers/RunFlow/RunningFlowModal';
import EditFlowInfoModal from './containers/Flow/edit/EditFlowInfoModal';
import ConfirmModal from './components/ConfirmModal';
import Toast from './components/Toast';
import PrintosHeader from './components/PrintosHeader';
import UpgradeAccountModal from './components/UpgradeAccountModal';
import store2 from './lib/store2';

const publicRoutes = ['/login', '/preview'];

export default {
	components: {
		RunFlowModal,
		RunningFlowModal,
		ConfirmModal,
		Toast,
		PrintosHeader,
		EditFlowInfoModal,
		UpgradeAccountModal
	},
	watch: {
		isAuthenticated: {
			immediate: true,
			async handler() {
				const isPublicRoute = publicRoutes.includes(this.$route.path);
				let isAuthenticated = this.isAuthenticated;
				const isHpMode = this.$config.isHpMode;

				// if the token and accountId are set in the query string (in non-hp mode), let's use them
				if (this.queryAccountId && this.queryToken) {
					if (!isHpMode) {
						await this.setAuthFromQueryString({
							accountId: this.queryAccountId,
							token: this.queryToken
						});
						isAuthenticated = true;
					}

					// once used, remove token and accountId from the query
					const newRoute = _.omit(_.merge({}, this.$route), ['query.accountId', 'query.token']);
					this.$router.replace(newRoute);
				}

				if (!isAuthenticated && !isPublicRoute) {
					if (!isHpMode) {
						return this.$router.push({ name: 'login', query: { redirectTo: this.$route.path } });
					}

					const currentUrl = window.location.href;
					const printosBase = this.$config.printosBase;
					const isDebugMode = store2.get('debugMode');
					if (isDebugMode) {
						// eslint-disable-next-line
						console.info('HP mode: not authenticated.');
					} else {
						window.location.href = `${printosBase}/start/#/signin?target=${currentUrl}`;
					}
				}
			}
		},
		'user._id': {
			immediate: true,
			handler(userId) {
				if (this.$ga && userId) {
					this.$ga.set('userId', userId);
				}
			}
		},
		currentAccount() {
			if (this.currentAccount) {
				set('dimension1', this.currentAccount._id);
				set('dimension2', this.currentAccount.name);
			}
		},
		canRunAutoflow: {
			immediate: true,
			handler(val) {
				if (!this.$config.isHpMode || typeof val !== 'boolean') return;

				if (!val) this.setUpgradeAccountModal({ isOpen: true, allowClose: false, requiredPlan: true });
			}
		}
	},
	computed: {
		...mapGetters(['$config', 'user', 'canRunAutoflow']),
		...mapGetters('auth', ['isAuthenticated']),
		classes() {
			return [
				{
					'is-hpMode': this.$config.isHpMode
				},
				`Route-${this.$route.name}`
			];
		},
		queryAccountId() {
			return _.get(this.$route, 'query.accountId');
		},
		queryToken() {
			return _.get(this.$route, 'query.token');
		}
	},
	created() {
		if (this.isAuthenticated) {
			this.fetchBaseData();
		}
	},
	methods: {
		...mapActions(['fetchBaseData', 'setAuthFromQueryString']),
		...mapActions('upgradeAccountModal', { setUpgradeAccountModal: 'setOptions' })
	},
	data: () => ({
		version: process.env.VERSION
	})
};
</script>

<style lang="scss">
@import './style/_index.scss';

.App {
	display: flex;
	flex-direction: column;
	height: 100vh;
	margin: 0;
}

.OneflowAppLayout {
	flex: 1;
	height: 100%;
}

.AppContainer {
	flex: 1;
	overflow: auto;
	position: relative;
	z-index: 100; // printos topbar has z-index 50
}

.MainModalContainer {
	position: relative;
	z-index: 1000000;
}
.tippy-tooltip .tippy-content {
	font-size: 14px;
}
</style>
