<template>
	<div class="TableView">
		<div class="table-responsive">
			<table class="table">
				<thead>
					<tr>
						<th v-for="c in columns">
							<div
								class="TableView-header-cell"
								:class="getHeaderCellClasses(c)"
								@click="handleHeaderCellClick(c)"
							>
								<span class="TableView-header-cell-name">{{ c.title }}</span>
								<span v-if="c.isSortable" class="TableView-header-cell-sorting">
									<i class="ion-chevron-up"></i>
									<i class="ion-chevron-down"></i>
								</span>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="row in formattedRows" :class="getBodyRowClasses({ row })">
						<td
							v-for="col in row"
							class="TableView-body-cell"
							:class="getBodyCellClasses({ row, col })"
							@click="handleCellClick($event, { col, row })"
						>
							<span v-if="!col.type && col.value && col.value.then">loading</span>
							<span v-else-if="!col.type">{{ col.value }}</span>
							<span v-else-if="col.type === 'html'">
								<span v-html="col.value"></span>
							</span>
							<span v-else-if="col.type === 'progress'">
								<progress-bar :amount="col.value.amount" :type="col.value.type"></progress-bar>
							</span>
							<span v-else-if="col.type === 'actions'">
								<div class="TableView-body-actions">
									<a
										v-for="(action, index) in col.actions"
										:key="index"
										v-tippy
										class="TableView-action"
										:title="action.title"
										:data-test-identifier="`action-${action.identifier}`"
										:href="action.href()"
										@click="action.onClick($event, { item: row._item, row, col })"
									>
										<span v-html="action.content"></span>
									</a>
								</div>
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script type="text/javascript">
import $ from 'jquery';
import isArray from 'lodash/isArray';
import _get from 'lodash/get';
import ProgressBar from './ProgressBar';
import './TableView.scss';

export default {
	components: {
		ProgressBar
	},
	props: {
		items: {
			default: []
		},
		config: {
			default: {}
		},
		onHeaderCellClick: {
			default: () => {}
		},
		orderFilter: {}
	},
	computed: {
		sortColumn() {
			return (this.orderFilter || '').split(' ')[0];
		},
		sortDirection() {
			return (this.orderFilter || '').split(' ')[1];
		},
		columns() {
			return (this.config && this.config.columns) || [];
		},
		formattedRows() {
			const _this = this;

			const formattedRows = (this.items || []).map(item => {
				const row = (this.columns || []).map(column => {
					const cell = {
						type: column.type,
						_column: column
					};

					switch (column.type) {
					case 'actions':
						let actions = column.getActions(item);
						actions = isArray(actions) ? actions : [actions];
						cell.actions = actions.map(a => ({
							onClick: () => {},
								href: () => {},
								...a
							}));
							break;
						case 'html':
							cell.value = column.getHtml ? column.getHtml(item) : _get(item, column.field) || '';

							if (cell.value && cell.value.then) {
								// is a promise
								cell.value
									.then(v => _this.$set(cell, 'value', v))
									.catch(err => _this.$set(cell, 'value', err.message));
						}
						break;
						case 'progress':
							cell.value = column.getProgress ? column.getProgress(item) : false;

							if (cell.value && cell.value.then) {
								// is a promise
								cell.value
									.then(v => _this.$set(cell, 'value', v))
								.catch(err => _this.$set(cell, 'value', err.message));
						}
						break;
						default:
							cell.value = column.getValue ? column.getValue(item) : _get(item, column.field) || '';

							if (cell.value && cell.value.then) {
								// is a promise
								cell.value
									.then(v => _this.$set(cell, 'value', v))
									.catch(err => _this.$set(cell, 'value', err.message));
							}
						break;
					}

					return cell;
				});
				row._item = item;
				return row;
			});
			return formattedRows;
		}
	},
	methods: {
		handleCellClick($event, { row, col }) {
			if ($($event.target).parents('button,a').length > 0) {
				return;
			}
			const baseUrl = this.config.isRowClickable && row._item && row._item.id && this.config.baseUrl;
			if (!baseUrl) return;

			this.$router.push({ path: `/${baseUrl}/${row._item.id}` });
		},
		handleHeaderCellClick(c) {
			if (!c.isSortable) return;
			this.onHeaderCellClick(c);
		},
		getHeaderCellClasses(c) {
			return {
				'is-sortingAsc': c.field === this.sortColumn && this.sortDirection === 'ASC',
				'is-sortingDesc': c.field === this.sortColumn && this.sortDirection === 'DESC',
				'is-sortable': c.isSortable
			};
		},
		getBodyCellClasses({ col }) {
			return {
				'is-actionsCell': col._column.type === 'actions'
			};
		},
		getBodyRowClasses({ row }) {
			return {
				'is-clickable': this.config.isRowClickable && row._item && row._item.id && this.config.baseUrl
			};
		}
	}
};
</script>
