<template>
	<div class="InputNode" :class="getClass()" data-node-id="0" :data-node-name="node.name">
		Inputs
	</div>
</template>

<script type="text/javascript">
export default {
	props: ['node', 'canBeDroppedOn', 'draggedTask', 'selectedNode', 'onDrop'],
	computed: {
		style() {
			if (!this.node) return null;

			return {
				left: `${this.node.x}px`,
				top: `${this.node.y}px`
			};
		}
	},
	methods: {
		getClass() {
			return {
				'is-active': this.node === this.selectedNode,
				'has-dropzone': this.canBeDroppedOn
			};
		}
	}
};
</script>

<style lang="scss">
@import './Chart-utils';

$borderRadius: $shapeHeight * 0.666666;
.InputNode {
	@extend %SelectableNode;
	z-index: 3;
	background: black;
	cursor: pointer;
	text-align: center;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $shapeWidth;
	height: $shapeHeight;
	border-radius: $borderRadius;
	//border: 1px solid #DFDFDF;
}
</style>
