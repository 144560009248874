export const getSiteflowProducts = async ({ dispatch }, options = {}) =>
	dispatch('siteflowRequest', {
		method: 'GET',
		path: 'product',
		query: { active: true, page: 1, pagesize: 50, ...options }
	});

export const getSiteflowProduct = async ({ dispatch, commit }, id) => {
	const product = await dispatch('siteflowRequest', {
		method: 'GET',
		path: `product/${id}`
	});

	commit('SET_SITEFLOW_PRODUCT', product);

	return product;
};

export const removeSiteflowProduct = ({ commit }) => commit('REMOVE_SITEFLOW_PRODUCT');
