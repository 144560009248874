<template>
	<div class="UpgradeModal">
		<modal :show="isOpen" size="large" :on-close="handleClose" :allow-close="allowClose">
			<h2 v-if="requiredPlan !== true" slot="header" class="UpgradeModal-header">
				You need at least a <strong class="text-uppercase">"{{ requiredPlan }}"</strong> Plan in order to use
				this flow
			</h2>
			<h2 v-else slot="header" class="UpgradeModal-header">Upgrade Plan</h2>

			<div slot="body" class="UpgradeModal-plans">
				<section class="UpgradeModal-plan">
					<div class="UpgradeModal-info">
						<h3 class="UpgradeModal-tier">Basic</h3>
						<p class="UpgradeModal-price">$350<span class="UpgradeModal-period">/mo</span></p>
					</div>
					<ul class="UpgradeModal-features">
						<li class="UpgradeModal-feature">
							Build automated flows to fetch and manipulate files. Deliver artworks and messages for
							printing or further processing.
						</li>
						<li class="UpgradeModal-feature">
							Fetch files from URL or FTP, extract zip files and analyse metadata. Merge text files for
							VDP.
						</li>
						<li class="UpgradeModal-feature">
							Manipulate PDFs - rotate, scale, copy, move, merge, set page boxes, resize, pad, flip,
							insert text or pages, etc.
						</li>
						<li class="UpgradeModal-feature">
							Send files to PrintOS devices or FTP, or make available in PrintOS Site Flow. Send e-mails &
							HTTP posts.
						</li>
					</ul>
				</section>
				<section class="UpgradeModal-plan">
					<div class="UpgradeModal-info">
						<h3 class="UpgradeModal-tier">Standard</h3>
						<p class="UpgradeModal-price">$500<span class="UpgradeModal-period">/mo</span></p>
						<p class="UpgradeModal-paragraph">includes everything in BASIC, plus:</p>
					</div>
					<ul class="UpgradeModal-features">
						<li class="UpgradeModal-feature">
							Automatically assemble parts of images or pages to create bespoke artworks. Pre-flight and
							add special marks.
						</li>
						<li class="UpgradeModal-feature">
							Extract parts of files, combine and assemble artworks. Automatically render PDFs from JSON
							and HTML.
						</li>
						<li class="UpgradeModal-feature">Apply industry standard & custom pre-flight profiles.</li>
						<li class="UpgradeModal-feature">Insert 1d and 2d barcodes and other special marks.</li>
					</ul>
				</section>
				<section class="UpgradeModal-plan">
					<div class="UpgradeModal-info">
						<h3 class="UpgradeModal-tier">Premium</h3>
						<p class="UpgradeModal-price">$1000<span class="UpgradeModal-period">/mo</span></p>
						<p class="UpgradeModal-paragraph">includes everything in STANDARD, plus:</p>
					</div>
					<ul class="UpgradeModal-features">
						<li class="UpgradeModal-feature">
							Verify whether files meet strict specifications and report or auto fix-up against target
							spec. Check large volumes of files for Enhanced Productivity Mode (EPM) suitability.
						</li>
						<li class="UpgradeModal-feature">
							Verify artworks (e.g. books) against specifications including: dimensions, rotation,
							page-count and bleed, then automatically apply fix-ups.
						</li>
						<li class="UpgradeModal-feature">
							Check artwork suitability for EPM printing. Set parameters at custom levels for different
							products or customers. Mark file for printing in EPM mode, or not.
						</li>
						<li class="UpgradeModal-feature">
							Add invisible barcodes. Automatic spine-size manipulation for book PDFs.
						</li>
					</ul>
				</section>
			</div>

			<div slot="footer"><button class="button" @click="subscribe">Subscribe on PrintOS Marketplace</button></div>
		</modal>
	</div>
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import Modal from './modal.vue';

export default {
	components: {
		Modal
	},
	computed: {
		...mapGetters('upgradeAccountModal', ['isOpen', 'requiredPlan', 'allowClose'])
	},
	methods: {
		...mapActions('upgradeAccountModal', ['setOptions']),
		subscribe(e) {
			window.location = `${window.$config.printosBase}/market/#/product/autoflow`;
			e.preventDefault();
		},
		handleClose() {
			if (this.allowClose) {
				this.setOptions({ isOpen: false });
			}
		}
	}
};
</script>

<style lang="scss">
.UpgradeModal {
	.Modal-container {
		max-width: 1000px;
	}

	.Modal-body {
		padding: 0;
	}

	.Modal-footer {
		button {
			display: block;
			width: 100%;
			background: #008bd0;
			border: 1px solid #0272ab;
			border-radius: 5px;
			color: #fff;
			padding: 1rem;
			font-size: 16px;
		}
	}

	&-header {
		color: #333;
		padding: 2rem 0;
		font-size: 18px;
	}

	&-plans {
		display: flex;
		flex-direction: column;
		background: #fafbfc;
		padding: 1rem;

		@media all and (min-width: 900px) {
			flex-direction: row;
		}
	}

	&-plan {
		background: #fff;
		border: 1px solid #eee;
		border-radius: 1rem;
		padding: 2rem;
		display: flex;
		flex-direction: column;
		margin: 1rem;

		@media all and (min-width: 900px) {
			flex: 1;
		}

		@media all and (min-width: 1200px) {
			padding: 3rem;
		}
	}

	&-info {
		display: flex;
		flex-direction: column;
		position: relative;

		@media all and (min-width: 900px) {
			min-height: 130px;
			text-align: center;
		}
	}

	&-tier {
		margin: 0;
		padding: 0;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 18px;
	}

	&-price {
		display: inline-flex;
		font-size: 32px;
		align-items: flex-end;
		line-height: 1;
		margin: 1rem 0;
		padding: 0;

		@media all and (min-width: 900px) {
			justify-content: center;
		}
	}

	&-period {
		font-size: 12px;
		font-weight: normal;
	}

	&-paragraph {
		margin: 0 0 10px;

		@media all and (min-width: 900px) {
			position: absolute;
			bottom: 0;
		}
	}

	&-features {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&-feature {
		margin: 5px 0 0 0;
		padding: 0 0 0 20px;
		position: relative;
		color: #777;

		&:first-child {
			margin: 0;
		}

		&:before {
			content: '\F122';
			font-family: 'Ionicons';
			position: absolute;
			top: 0;
			left: 0;
			font-size: 14px;
			color: #008bd0;
		}
	}
}
</style>
