var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"NodeConfiguration"},[_c('div',{staticClass:"MainSettingsPanel-payloads"},[(Object.keys(_vm.nodePayloadInputs).length > 0)?_c('div',{staticClass:"MainSettingspanel-payloads-in"},[_c('h2',[_vm._v("Inputs")]),_vm._l((_vm.nodePayloadInputs),function(inputVal,inputKey){return _c('div',{key:inputKey},[_c('InputLinkList',{attrs:{"input-key":inputKey,"selected-node-input-key":_vm.selectedNodeInputKey,"on-create-link-btn-click":_vm.handleCreateLinkBtnClick,"on-clear-link-btn-click":_vm.handleClearLinkBtnClick,"on-disable-selection-btn-click":_vm.handleDisableSelectionBtnClick,"on-custom-value-change":_vm.handleCustomValueChange,"on-show-advanced-input-field":_vm.onShowAdvancedInputField,"on-add-input-to-input-node-btn-click":_vm.onAddInputToInputNodeBtnClick,"node":_vm.node,"task":_vm.task,"tasks":_vm.tasks,"flow":_vm.flow,"input-values":inputVal,"config":{ showInputsList: _vm.showInputsList },"handle-add-node-input-row":_vm.handleAddNodeInputRow,"handle-remove-node-input-row":_vm.handleRemoveNodeInputRow,"read-only":_vm.readOnly}})],1)})],2):_vm._e(),(_vm.selectedNodeLinkedOutputs && _vm.selectedNodeLinkedOutputs.length > 0)?_c('div',{staticClass:"MainSettingspanel-payloads-in"},[_c('h2',[_vm._v("Outputs")]),_vm._l((_vm.selectedNodeLinkedOutputs),function(item,index){return _c('div',{key:index},[_c('NodeOutputField',{attrs:{"label":item.source.inputFormConfig.displayName,"value":((item.destination.node.displayName) + " - " + (item.destination.inputFormConfig.displayName ||
							item.destination.outputKey)),"linked-to":item.destination.node.name,"output-key":item.destination.outputKey,"read-only":_vm.readOnly}})],1)})],2):_vm._e()]),(Object.keys(_vm.nodeNormalInputs).length > 0 && _vm.task.name === 'epmCheck')?_c('div',{staticClass:"MainSettingsPanel-inputList"},[_c('EPMProfileForm',_vm._b({},'EPMProfileForm',{
				inputs: _vm.nodeNormalInputs,
				formLayout: _vm.task && _vm.task.formLayout, // shouldn't pass epm-layout here insted of defining in EPMProfileForm
				selectedNodeInputKey: _vm.selectedNodeInputKey,
				onCreateLinkBtnClick: _vm.handleCreateLinkBtnClick,
				onClearLinkBtnClick: _vm.handleClearLinkBtnClick,
				onDisableSelectionBtnClick: _vm.handleDisableSelectionBtnClick,
				onCustomValueChange: _vm.handleCustomValueChange,
				onShowAdvancedInputField: _vm.onShowAdvancedInputField,
				onAddInputToInputNodeBtnClick: _vm.onAddInputToInputNodeBtnClick,
				node: _vm.node,
				flow: _vm.flow,
				task: _vm.task,
				tasks: _vm.tasks,
				config: { showInputsList: _vm.showInputsList },
				handleAddNodeInputRow: _vm.handleAddNodeInputRow,
				handleRemoveNodeInputRow: _vm.handleRemoveNodeInputRow,
				readOnly: _vm.readOnly
			},false))],1):_vm._e(),(Object.keys(_vm.nodeNormalInputs).length > 0 && _vm.task.name !== 'epmCheck')?_c('div',{staticClass:"MainSettingsPanel-inputList"},[_c('FormGenerator',_vm._b({},'FormGenerator',{
				inputs: _vm.nodeNormalInputs,
				formLayout: _vm.task && _vm.task.formLayout,
				selectedNodeInputKey: _vm.selectedNodeInputKey,
				onCreateLinkBtnClick: _vm.handleCreateLinkBtnClick,
				onClearLinkBtnClick: _vm.handleClearLinkBtnClick,
				onDisableSelectionBtnClick: _vm.handleDisableSelectionBtnClick,
				onCustomValueChange: _vm.handleCustomValueChange,
				onShowAdvancedInputField: _vm.onShowAdvancedInputField,
				onAddInputToInputNodeBtnClick: _vm.onAddInputToInputNodeBtnClick,
				node: _vm.node,
				flow: _vm.flow,
				task: _vm.task,
				tasks: _vm.tasks,
				config: { showInputsList: _vm.showInputsList },
				handleAddNodeInputRow: _vm.handleAddNodeInputRow,
				handleRemoveNodeInputRow: _vm.handleRemoveNodeInputRow,
				readOnly: _vm.readOnly
			},false))],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }