<template>
	<section class="Progress" :class="getClass()">
		<div class="Progress-bar" :style="getWidth()"></div>
	</section>
</template>

<script type="text/javascript">
import './ProgressBar.scss';
export default {
	props: ['amount', 'type'],
	methods: {
		getClass() {
			if (this.type) {
				return `Progress--${this.type}`;
			}
		},
		getWidth() {
			const amount = Number(this.amount);
			if (isNaN(amount)) return { width: 0 };

			const width = `${Math.min(Number(this.amount), 100)}%`;
			return { width };
		}
	}
};
</script>
