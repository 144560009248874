<template>
	<img v-if="imgSrc()" :src="imgSrc()" :onerror="handleError" />
</template>
<script type="text/javascript">
import pdf from '../../../public/img/workers/icon-pdf.svg';
import device from '../../../public/img/workers/icon-device.svg';
import communication from '../../../public/img/workers/icon-communication.svg';
import file from '../../../public/img/workers/icon-file.svg';
import siteFlow from '../../../public/img/workers/icon-worker.svg';
import defaultIcon from '../../../public/img/workers/icon-worker.svg';

export default {
	props: ['category'],
	methods: {
		imgSrc() {
			switch (this.category) {
				case 'PDF':
					return pdf;
					break;
				case 'Device':
					return device;
					break;
				case 'Communication':
					return communication;
					break;
				case 'File':
					return file;
					break;
				case 'Site Flow':
					return siteFlow;
					break;
				default:
					return defaultIcon;
					break;
			}
		},
		handleError() {}
	}
};
</script>
