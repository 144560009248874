<template>
	<modal class="RenameFlowModal" :show="show" size="small">
		<h2 slot="header">
			{{ $t('Rename node') }}
		</h2>

		<div slot="body" class="container-fluid">
			<div class="form-group md-group">
				<input v-model="newName" type="text" class="form-control" />
			</div>
		</div>

		<div slot="footer">
			<button class="button" @click="handleCancel(modal)">Cancel</button>
			<button class="button button--success" :disabled="!isFormValid" @click="handleOk(modal)">Ok</button>
		</div>
	</modal>
</template>
<script type="text/javascript">
import { mapActions } from 'vuex';
import Modal from './../../../components/modal.vue';

export default {
	components: {
		Modal
	},
	props: ['show', 'node', 'onConfirm', 'onClose'],
	data() {
		return {
			newName: ''
		};
	},
	computed: {
		nodeDisplayName() {
			return (this.node && this.node.displayName) || '';
		},
		isFormValid() {
			return this.newName.length > 0;
		}
	},
	watch: {
		node() {
			this.newName = this.nodeDisplayName;
		}
	},
	methods: {
		async handleOk() {
			this.onConfirm && this.onConfirm(this.newName);
		},
		handleCancel(modal) {
			this.onClose();
		},
		isModalOpen(modal) {
			return modal.status === 'PENDING';
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.RenameFlowModal {
	.Modal-close {
		display: none;
	}
}
</style>
