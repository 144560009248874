<template>
	<span>
		<input
			v-if="inputType === 'text' || inputType === 'number' || inputType === 'password'"
			class="form-control"
			:placeholder="placeholder"
			:data-test-id="config.key + 'Input'"
			:type="inputType"
			:disabled="config.readOnly"
			:value="value"
			@input="handleCustomValueChange"
		/>

		<textarea
			v-if="inputType === 'textarea'"
			class="form-control"
			placeholder="type or select a value"
			:data-test-id="config.key + 'Input'"
			:value="value"
			@input="handleCustomValueChange"
		/>
	</span>
</template>
<script type="text/javascript">
import throttle from 'lodash/throttle';

export default {
	props: {
		value: null,
		onChange: Function,
		config: null,
		placeholder: {
			type: String,
			default: () => 'type or select as value'
		}
	},
	computed: {
		inputType() {
			return (this.config && this.config.type) || 'text';
		}
	},
	methods: {
		handleCustomValueChange: throttle(function(event) {
			this.onChange && this.onChange(event.target.value);
		}, 500)
	}
};
</script>
