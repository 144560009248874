import { LoopbackHttpTransport } from '@workflow-solutions/ofs-vuex-crud';
import axios from 'axios';

const httpClient = axios.create({ withCredentials: true });

class PrintosHttpProviderInstance extends LoopbackHttpTransport {
	constructor() {
		super({ apiBase: window.$config.printosBase }, httpClient);
	}
}

const providerInstance = new PrintosHttpProviderInstance();

export default providerInstance;
