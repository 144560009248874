import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';

const state = {
	toasts: []
};

const toastsStore = {
	state,
	getters,
	actions,
	mutations
};

export default toastsStore;
