<template>
	<div class="ColorPickerField" v-click-outside="handleOutsideClick">
		<input
			v-model="inputColor"
			class="form-control"
			placeholder="type or select a value"
			type="text"
			:disabled="config.readOnly"
			:data-test-id="config.key + 'Input'"
			@click="handleOpen"
		/>
		<div v-if="isOpen" class="ColorPickerField-popover">
			<ColorPicker v-model="colors" @input="handleCustomValueChange" />
		</div>
	</div>
</template>
<script type="text/javascript">
import { Chrome } from 'vue-color';

export default {
	components: { ColorPicker: Chrome },
	model: {
		prop: 'value',
		event: 'onChange'
	},
	props: {
		value: {
			type: String,
			default: 'black'
		},
		config: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			colors: '',
			isOpen: false
		};
	},
	computed: {
		inputColor: {
			get() {
				return this.value;
			},
			set(data) {
				this.colors = data;
				this.$emit('onChange', data);
			}
		},
		inputType() {
			return (this.config && this.config.type) || 'text';
		}
	},
	methods: {
		handleOpen() {
			this.colors = this.inputColor;
			this.isOpen = true;
		},
		handleCustomValueChange(value) {
			this.inputColor = value?.hex || value;
		},
		handleOutsideClick() {
			this.isOpen = false;
		}
	}
};
</script>
<style lang="scss">
.ColorPickerField {
	position: relative;
	.ColorPickerField-popover {
		position: absolute;
		bottom: 100%;
		left: 0;
	}
}
</style>
