import find from 'lodash/find';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

export function getInputFormConfig(input, taskFormConfig) {
	let inputKey = input;
	if (typeof input === 'object') {
		inputKey = input.key;
	}

	return find(taskFormConfig, inputFormConfig => inputKey === inputFormConfig.key) || {};
}

export function getNodeParents(node, nodes) {
	return nodes.filter(n => n.next.includes(node.id));
}

export function getNodeChildren(node, nodes) {
	if (!node.next) return [];
	return nodes.filter(n => node.next.includes(n.id));
}

export function getNodeType(taskType) {
	const taskTypes = {
		inlineCondition: 'condition',
		booleanCondition: 'condition'
	};

	return taskTypes[taskType] || taskType;
}

export function getNodeOutputsConfiguration(node, tasks = []) {
	const task = find(tasks, { name: node.taskName });
	if (task) return task.outputs;

	return {};
}

export function getAvailableInputValuesForNodeInput({ node, inputConfiguration, nodes = [], tasks }) {
	let values = new Map();

	let nodeStack = [node];
	let evaluatedNodes = new Set();

	while (nodeStack.length > 0) {
		let currentNode = nodeStack.pop();

		if (isEmpty(currentNode)) continue;

		const parentNodes = filter(getNodeParents(currentNode, nodes), parent => !evaluatedNodes.has(parent.id));
		if (isEmpty(parentNodes)) continue;

		for (let parentNode of parentNodes) {
			const task = {
				name: parentNode.name
			};
			let filterFn;

			if (parentNode.type === 'input') {
				task.values = (parentNode.inputs || []).map(output => Object.assign({}, output));
				filterFn = filter;
			} else {
				task.values = mapValues(getNodeOutputsConfiguration(parentNode, tasks), (output, key) => ({
					...output,
					key
				}));
				filterFn = pickBy;
			}

			if (inputConfiguration) {
				task.values = filterFn(task.values, v => v.type === inputConfiguration.type);
			}

			values.set(parentNode, task);
			nodeStack.push(parentNode);
		}
		evaluatedNodes.add(currentNode.id);
	}

	return values;
}

export const nodeHasTopDropzone = ({ node, nodes, draggedTask = {} }) => {
	const { type } = node;
	const parentNodes = getNodeParents(node, nodes);
	const excludedNodeTypes = ['input', 'placeholder', 'circleCondition'];
	if (excludedNodeTypes.includes(type)) {
		return false;
	}

	// TODO
	if (parentNodes.length > 1 && (draggedTask.type === 'branch' || draggedTask.type === 'macro')) return false;

	return true;
};

export const nodeHasBottomDropzone = ({ node, nodes, draggedTask = {} }) => {
	const { type } = node;
	const excludedNodeTypes = ['end', 'placeholder'];
	const childNodes = getNodeChildren(node, nodes);

	const isExcludedType = excludedNodeTypes.includes(type);
	const childHasTopDropzone = childNodes[0] ? nodeHasTopDropzone({ node: childNodes[0], nodes }) : false;

	if (isExcludedType) return false;

	if (childNodes[0] && getNodeParents(childNodes[0], nodes).length > 1) {
		return true;
	}

	if (childNodes.length > 1 && draggedTask.type !== 'branch' && draggedTask.type !== 'macro') return true;

	if (childNodes.length === 1 && !isExcludedType && !childHasTopDropzone) {
		return true;
	}

	return false;
};

export const canTaskBeDroppedOn = ({ node, nodes, draggedTask }) => {
	if (draggedTask.nodeId || draggedTask.type === 'macro') {
		return node.type === 'placeholder';
	}
	const parentNodes = getNodeParents(node, nodes);
	return !(parentNodes.length > 1);
};

export function getNodeInputs(node, tasks) {
	const task = tasks.find(t => t.name === node.taskName);
	if (!task || !task.inputs) return {};
	return task.inputs;
}

export function getNodeOutputs(node, tasks) {
	const task = tasks.find(t => t.name === node.taskName);
	if (!task || !task.outputs) return {};
	return task.outputs;
}

export const pathToArray = path =>
	path.split('.').map(segment => {
		const num = Number(segment);
		return isNaN(num) ? segment : num;
	});
