<template>
	<modal class="FillConditionInputsModal" :show="show" :on-close="handleCancel" size="medium">
		<h2 slot="header">
			{{ $t('Fill the conditions') }}
		</h2>

		<div slot="body">
			<div v-for="node in nodes">
				<ConditionConfiguration
					:node="node"
					:flow="flow"
					:tasks="tasks"
					:show-node-name="true"
					:show-add-more-conditions-button="false"
				/>
			</div>
		</div>

		<div slot="footer">
			<button class="button" @click="handleCancel">{{ $t('Cancel') }}</button>
			<button class="button button--success" @click="onClose">{{ $t('Confirm') }}</button>
		</div>
	</modal>
</template>
<script type="text/javascript">
import Modal from '../../../components/modal.vue';
import ConditionConfiguration from './ConditionConfiguration.vue';

export default {
	components: {
		Modal,
		ConditionConfiguration
	},
	props: ['show', 'nodes', 'tasks', 'onClose', 'flow', 'updateCondition'],
	data() {
		return {
			names: {},
			selections: {},
			initialNodes: []
		};
	},
	watch: {
		show() {
			if (!this.show) return;
			this.initialNodes = this.nodes.map(node => ({ ...JSON.parse(JSON.stringify(node)) }));
		}
	},
	methods: {
		handleCancel() {
			this.nodes.forEach((node, index) => {
				if (node.condition) {
					this.updateCondition(this.initialNodes[index].condition, node.name);
				}
			});
			this.onClose();
		}
	}
};
</script>
