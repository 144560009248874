<template>
	<div
		class="Node Toolbox-draggable"
		:class="getClass()"
		:data-test-id="`${node.taskName}Node`"
		:data-node-id="node.id"
		:data-node-name="node.name"
		:data-node-task-status="node.status"
	>
		<div class="Node-inner">
			<template v-if="requiredAccess !== true">
				<tippy
					v-if="requiredAccess !== true"
					:to="`tippy-node-${node.id}`"
					:interactive="true"
					:arrow="true"
					theme="light"
					:reactive="true"
					:distance="10"
				>
					<div class="UpgradeTooltip">
						<h4 class="UpgradeTooltip-heading">{{ requiredAccess }} Feature</h4>
						<p class="UpgradeTooltip-paragraph">
							This flow uses advanced tasks and requires a "{{ requiredAccess }}" plan.
						</p>
						<button class="UpgradeTooltip-button" @click.prevent="onUpgrade">Upgrade</button>
					</div>
				</tippy>
				<span class="UpgradeWarning" :name="`tippy-node-${node.id}`">!</span>
			</template>
			{{ node.displayName }}
		</div>
		<ProgressIcon
			v-if="chartOptions.showWorkqueueProgress && workqueueTaskData"
			class="Node-taskDataIcon"
			:status="workqueueTaskData.status"
		/>
		<div v-if="chartOptions.showNodeDeleteBtn" class="Node-delete" @click="handleDeleteBtnClick"></div>
		<AddBranchIcon class="Node-addBranch" />
		<AddBranchFromTaskIcon class="Node-addBranchFromTask" />
		<div v-if="chartOptions.showInvalidNodes && !isNodeValid" class="Node-isInvalid">
			<i class="ion-gear-a"></i>
		</div>
	</div>
</template>

<script type="text/javascript">
import _ from 'lodash';
import { mapGetters } from 'vuex';
import AddBranchIcon from '../icons/AddBranchIcon';
import AddBranchFromTaskIcon from '../icons/AddBranchFromTaskIcon';
import ProgressIcon from '../../components/ProgressIcon.vue';
import { validateNodeInputs, userHasAccess } from '../../lib/flowValidationUtils';
import Lock from '../../../public/img/lock.svg';

export default {
	props: [
		'node',
		'tasks',
		'selectedNode',
		'canBeDroppedOn',
		'upgrade',
		'onDrop',
		'onDeleteBtnClick',
		'draggedTask',
		'chartOptions',
		'workqueueTaskData'
	],
	data() {
		return {
			Lock
		};
	},
	computed: {
		...mapGetters(['userAccess', '$config']),
		taskDataIconClass() {
			if (!this.chartOptions.showWorkqueueProgress || !this.workqueueTaskData) return;
			const { status } = this.workqueueTaskData;

			if (['queued', 'started', 'skipped'].includes(status)) {
				return 'taskDataIcon-onHold ion-minus';
			}

			if (status === 'ended') {
				return 'taskDataIcon-ended ion-checkmark';
			}

			if (status === 'failed') {
				return 'taskDataIcon-failed ion-alert';
			}
		},
		isNodeValid() {
			return validateNodeInputs(this.node, this.tasks);
		},
		task() {
			return _.find(this.tasks, { name: this.node.taskName });
		},
		requiredAccess() {
			if ($config.isHpMode && this.task && this.task.access) {
				return userHasAccess({
					requiredAccess: this.task.access,
					userAccess: this.userAccess
				});
			}

			return true;
		}
	},
	methods: {
		handleDeleteBtnClick($event) {
			$event.stopPropagation();
			this.onDeleteBtnClick(this.node);
		},
		onUpgrade(e) {
			Array.from(this.$el.querySelectorAll('[data-tippy]'), el => el._tippy).forEach(tippy => tippy.hide());
			this.upgrade(e);
		},
		getClass() {
			const isDraggingBranch = this.canBeDroppedOn && this.draggedTask && this.draggedTask.type === 'branch';
			const isDraggingTaskOver = this.canBeDroppedOn && this.draggedTask && this.draggedTask.type === 'task';

			return {
				'is-active': this.node === this.selectedNode,
				'has-dropzone': this.canBeDroppedOn,
				'is-draggingBranch': isDraggingBranch,
				'is-draggingTask': isDraggingTaskOver,
				'is-draft': this.node.status === 'draft'
			};
		}
	},
	components: {
		AddBranchIcon,
		ProgressIcon,
		AddBranchFromTaskIcon
	}
};
</script>

<style lang="scss">
@import './Chart-utils';
@import '../../style/colors';

.Node {
	@extend %SelectableNode;
	z-index: 2;
	cursor: pointer;
	text-align: center;
	color: #333;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $shapeWidth;
	height: $shapeHeight;
	border: 1px solid $gray-border-color;
	background: white;
	line-height: 0.8;
	position: relative;

	&:hover {
		z-index: 30;
	}

	&.is-active {
		.Node-inner {
			background: $blue-background-color;
		}
	}
	&.is-draft {
		border: 1px dashed #dd6c2f;
	}
	&.is-draft.is-active {
		border-color: white;
	}
	.Node-inner {
		z-index: 5;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		img {
			margin-left: 5px;
		}
	}
	.Node-delete {
		display: none;
		z-index: 10;
		@extend %DeleteIcon;
	}
	.Node-draft-icon {
		color: #dd6c2f;
		padding-right: 5px;
	}
	&:hover .Node-delete {
		display: inline-block;
	}
	// Add branch
	.Node-addBranch {
		z-index: 3;
		position: absolute;
		left: 100%;
		top: 50%;
		display: none;
	}
	&.is-hovering.is-draggingBranch .Node-addBranch {
		display: block;
	}

	// Add branch from task
	.Node-addBranchFromTask {
		z-index: 3;
		position: absolute;
		left: 100%;
		top: 50%;
		margin-left: -98px;
		margin-top: -19px;
		transform: scale(0.8);
		display: none;
	}
	&.is-hovering.is-draggingTask .Node-addBranchFromTask {
		display: block;
	}

	// task progress data icon
	$circleSideLength: 20px;
	.Node-taskDataIcon {
		position: absolute;
		top: 100%;
		left: 50%;
		margin-top: -$circleSideLength/2;
		margin-left: -$circleSideLength/2;
	}
	.Node-draft {
		margin-right: 8px;
	}

	.Node-isInvalid {
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		height: 30px;
		text-align: left;
		padding: 0 3px;
		pointer-events: none;

		&:after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 30px 30px 0 0;
			border-color: $danger-color transparent transparent transparent;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
		i {
			position: relative;
			z-index: 5;
			color: white;
		}
	}
}
</style>
