<template>
	<div class="MarginField">
		<div class="MarginField-dimensions">
			<div class="MarginField-dimensionsTop">
				<input
					type="number"
					class="form-control"
					placeholder="Top"
					:value="data.top"
					:disabled="config.readOnly"
					@change="handleNumberValueChange('top', $event.target.value)"
				/>
			</div>
			<div class="MarginField-dimensionsLeft">
				<input
					type="number"
					class="form-control"
					placeholder="Left"
					:value="data.left"
					:disabled="config.readOnly"
					@change="handleNumberValueChange('left', $event.target.value)"
				/>
			</div>
			<div class="MarginField-dimensionsPage">
				{{ $t('Page') }}
			</div>
			<div class="MarginField-dimensionsRight">
				<input
					type="number"
					class="form-control"
					placeholder="Right"
					:value="data.right"
					:disabled="config.readOnly"
					@change="handleNumberValueChange('right', $event.target.value)"
				/>
			</div>
			<div class="MarginField-dimensionsBottom">
				<input
					type="number"
					class="form-control"
					placeholder="Bottom"
					:value="data.bottom"
					:disabled="config.readOnly"
					@change="handleNumberValueChange('bottom', $event.target.value)"
				/>
			</div>
		</div>
		<div>
			<select class="form-control" :value="data.unit" :disabled="config.readOnly" @change="handleUnitValueChange">
				<option value="" selected disabled>choose an unit</option>
				<option value="in">in</option>
				<option value="mm">mm</option>
				<option value="pt">pt</option>
			</select>
		</div>
	</div>
</template>
<script type="text/javascript">
export default {
	props: ['value', 'onChange', 'config'],
	computed: {
		data() {
			return this.parseMargin(this.value);
		}
	},
	methods: {
		parseMargin(value) {
			const arr = (value || '').split(' ');
			if (arr.length === 4) {
				return {
					top: Number(arr[0].replace(/(in|mm|pt)/g, '')),
					left: Number(arr[1].replace(/(in|mm|pt)/g, '')),
					bottom: Number(arr[2].replace(/(in|mm|pt)/g, '')),
					right: Number(arr[3].replace(/(in|mm|pt)/g, '')),
					unit: arr[0].replace(/[0-9.]/g, '')
				};
			}
			return {
				top: '',
				left: '',
				bottom: '',
				right: '',
				unit: ''
			};
		},
		change() {
			const d = this.data;
			this.onChange &&
				this.onChange(`${d.top}${d.unit} ${d.left}${d.unit} ${d.bottom}${d.unit} ${d.right}${d.unit}`);
		},
		handleNumberValueChange(position, value) {
			this.data[position] = value;
			this.change();
		},
		handleUnitValueChange(event) {
			this.data.unit = event.target.value;
			this.change();
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.MarginField {
	display: flex;
	align-items: center;
	justify-content: space-between;

	input::placeholder {
		font-size: 12px;
	}

	> div {
		width: 65%;

		&:last-child {
			width: 28%;
		}
	}

	&-dimensions {
		height: 160px;
		position: relative;

		input,
		input:focus {
			border: 1px solid $darkerGray-border-color !important;
		}

		&Top,
		&Left,
		&Bottom,
		&Right,
		&Page {
			position: absolute;
			width: 5em;
		}

		&Top {
			left: 5em;
			top: 0;

			input,
			input:focus {
				border-bottom: none !important;
			}
		}

		&Left {
			left: 0;
			top: 60px;

			input,
			input:focus {
				border-right: none !important;
			}
		}

		&Bottom {
			left: 5em;
			top: 120px;

			input,
			input:focus {
				border-top: none !important;
			}
		}

		&Right {
			left: 10em;
			top: 60px;

			input,
			input:focus {
				border-left: none !important;
			}
		}

		&Page {
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid $blue-brand-color;
			height: 80px;
			left: 5em;
			top: 40px;
		}
	}
}
</style>
