<template>
	<div class="ConfirmModal">
		<modal v-for="modal in confirmModals" key="modal" :show="isModalOpen(modal)" size="small">
			<h2 slot="header">
				{{ modal.config.title }}
			</h2>

			<div slot="body" class="container-fluid">
				<div class="ConfirmModal-text">{{ modal.config.body }}</div>
			</div>

			<div slot="footer">
				<button class="button" data-test-id="confirmationCancelButton" @click="handleCancel(modal)">
					Cancel
				</button>
				<button class="button button--success" data-test-id="confirmationOkButton" @click="handleOk(modal)">
					Ok
				</button>
			</div>
		</modal>
	</div>
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import Modal from './modal.vue';

export default {
	props: [],
	computed: {
		...mapGetters(['confirmModals'])
	},
	data() {
		return {};
	},
	methods: {
		...mapActions(['confirm', 'decline']),
		handleOk(modal) {
			this.confirm({ modalId: modal.id });
		},
		handleCancel(modal) {
			this.decline({ modalId: modal.id });
		},
		isModalOpen(modal) {
			return modal.status === 'PENDING';
		}
	},
	components: {
		Modal
	}
};
</script>

<style lang="scss">
@import '../style/colors';

.ConfirmModal {
	.ConfirmModal-text {
		padding: 30px;
		font-size: 16px;
		color: $darkGray-text-color;
		white-space: pre-line;
	}
	.Modal-close {
		display: none;
	}
	.ConfirmModal-actions {
		display: flex;
		justify-content: space-between;
		button {
			min-width: 150px;
		}
	}
}
</style>
