<template>
	<modal
		v-if="isModalOpen"
		class="RunFlowModal"
		:show="true"
		method="mount"
		size="medium"
		:on-close="handleModalClose"
		@dragover.native="onDrag"
	>
		<h1 slot="header">Run {{ flow.name }}</h1>

		<div slot="body">
			<div v-if="isFlowValid">
				<div v-if="runConfiguration && runConfiguration.inputs && runConfiguration.inputs.length > 0">
					<div class="RunFlowModal-section">
						<h3 class="RunFlowModal-section-title">
							Inputs
						</h3>
						<div v-for="input in runConfiguration.inputs">
							<div v-if="input.type !== 'file'">
								<div class="NodeField form-group md-group" :class="!isValid(input) && 'is-invalid'">
									<label v-if="input.type !== 'hidden'" class="control-label">{{ input.key }}</label>
									<InputRenderer
										v-if="input.form"
										template="inputOnly"
										:config="getFormConfig(input)"
										:value="input.value"
										:on-custom-value-change="value => handleCustomValueChange(input, value)"
										:read-only="false"
									></InputRenderer>
									<div v-if="!input.form">
										<div v-if="input.type === 'number'">
											<input
												class="form-control"
												type="number"
												:value="input.value"
												@input="$event => handleCustomValueChange(input, $event.target.value)"
											/>
										</div>
										<div v-else-if="input.type === 'boolean' || input.type === 'bool'">
											<select
												class="form-control"
												:value="input.value"
												@input="$event => handleCustomValueChange(input, $event.target.value)"
											>
												<option value="true">True</option>
												<option value="false">False</option>
											</select>
										</div>
										<div v-else>
											<textarea
												rows="1"
												class="form-control"
												:value="input.value"
												@input="$event => handleCustomValueChange(input, $event.target.value)"
											/>
										</div>
									</div>
								</div>
							</div>
							<FileInput
								v-if="input.type === 'file'"
								:input="input"
								:is-valid="isValid(input)"
								:dragging="isDragging"
								:on-change="handleFileInputChange"
								:on-file-upload="handleFileUpload"
							/>
						</div>
					</div>
				</div>
				<div
					v-if="
						!runConfiguration ||
							(runConfiguration && (!runConfiguration.inputs || runConfiguration.inputs.length === 0))
					"
				>
					<h4>Nothing to configure. Just click 'Run Flow'.</h4>
				</div>
				<div v-if="configurationIsVisible" class="RunFlowModal-section">
					<h3 class="RunFlowModal-section-title">
						Configuration
					</h3>
					<div class="NodeField form-group md-group">
						<label class="control-label">Postback</label>
						<InputRenderer
							template="inputOnly"
							:config="getFormConfig(postback)"
							:read-only="false"
							:value="postback.url"
							:on-custom-value-change="value => handlePostbackValueChange(value)"
							placeholder="Enter postback url"
						/>
						<i
							v-tippy
							class="ion-ios-help-outline"
							:title="$t('Flow information will be sent to this endpoint')"
						></i>
					</div>
				</div>
				<a
					href=""
					class="RunFlowModal-advanced"
					@click.prevent="configurationIsVisible = !configurationIsVisible"
				>
					<span
						v-tippy
						:title="$t(configurationIsVisible ? 'Hide advanced options' : 'Show advanced options')"
					>
						{{ $t('Advanced') }}
					</span>
					<i
						class="RunFlowModal-advancedIcon"
						:class="configurationIsVisible ? 'ion-chevron-up' : 'ion-chevron-down'"
					/>
				</a>
			</div>
			<div v-else class="InvalidFlow">
				Invalid Flow. Check your flow configuration
			</div>
		</div>

		<div slot="footer">
			<button class="button" @click="handleModalClose">{{ $t('Cancel') }}</button>
			<button
				type="submit"
				class="button button--success"
				data-test-id="runFlowConfirmButton"
				:disabled="!isFlowValid || !isFilledInputs"
				@click="handleRunPlanFormSubmit"
			>
				{{ $t('Run Flow') }}
			</button>
		</div>
	</modal>
</template>
<script type="text/javascript">
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import forEach from 'lodash/forEach';
import Modal from '../../components/modal';
import FileInput from './FileInput';
import InputRenderer from '../../components/FormGenerator/InputRenderer';
import { validateNodeInput } from '../../lib/flowValidationUtils';
import analytics from '../../lib/analytics';

export default {
	data() {
		return {
			configurationIsVisible: false,
			runData: {},
			isDragging: false,
			timer: null,
			postback: {
				url: null
			}
		};
	},
	computed: {
		...mapGetters(['isFlowValid']),
		isModalOpen() {
			return this.$store.state.flow.runFlowModal.isOpen;
		},
		runConfiguration() {
			return this.$store.state.flow.runFlowModal.runConfiguration;
		},
		flow() {
			return this.$store.state.flow.runFlowModal.flow;
		},
		isFilledInputs() {
			let result = true;
			if (this.runConfiguration && this.runConfiguration.inputs && this.runConfiguration.inputs.length > 0) {
				this.runConfiguration.inputs.forEach(input => {
					if (!this.isValid(input)) result = false;
				});
			}
			return result;
		}
	},
	components: {
		Modal,
		FileInput,
		InputRenderer
	},
	methods: {
		validateNodeInput,
		...mapActions([
			'runFlow',
			'closeRunFlowModal',
			'uploadFile',
			'getDownloadUrl',
			'createFilePath',
			'displayToast'
		]),
		handleModalClose() {
			this.postback.url = null;
			this.closeRunFlowModal();
		},
		handleRunPlanFormSubmit() {
			this.runData.workqueue = {
				name: _get(this, 'flow.name'),
				postback: this.postback
			};
			this.runData.workqueue.initialState = {};
			forEach(this.runConfiguration.inputs, input => {
				this.runData.workqueue.initialState[input.key] = input.value;
			});

			this.startFlow(this.runData);
		},
		startFlow(runData) {
			this.runFlow({ ...runData, flow: this.flow })
				.then(response => {
					const workqueue = response.data;
					this.$router.push({ name: 'workqueues.view', params: { id: workqueue.id } });
					analytics.trackEventFlowRun(this.flow);
					this.closeRunFlowModal();
				})
				.catch(err => {
					if (err?.response?.status === 403) {
						err.message = 'Please make sure you have a valid subscription plan via the Marketplace';
						this.displayToast({ data: err });
					}
				});
		},
		handleFileInputChange(input, { value }) {
			input.value = value;
			input.progress = null;
		},
		handleCustomValueChange(input, value) {
			let val = value;
			if (input.type === 'number') {
				val = val === '' ? undefined : Number(val);
			}

			if (input.type === 'boolean') {
				if (val === 'true') val = true;
				if (val === 'false') val = false;
			}

			input.value = val;
		},
		handlePostbackValueChange(url) {
			this.postback.url = url;
		},
		onDrag() {
			this.isDragging = true;
			if (this.timer) {
				clearTimeout(this.timer);
			}
			this.timer = setTimeout(() => {
				this.isDragging = false;
			}, 1000);
		},
		async handleFileUpload(input, file) {
			const { promise, observable } = await this.uploadFile({ file });
			observable.subscribe(progress => {
				Vue.set(input, 'progress', progress);
			});
			const { s3Path } = await promise;
			const { file: createdFile } = await this.createFilePath({ s3Path, file });
			const { url: downloadUrl } = await this.getDownloadUrl({ id: createdFile._id });

			input.value = downloadUrl;
		},
		getFormConfig(input) {
			const inputConfiguration = input.form || { validation: {} };
			if (input.type === 'file') {
				inputConfiguration.validation.match =
					'^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?';
			}
			const defaultConfig = {
				type: 'text',
				readOnly: false,
				key: input.key
			};
			return {
				...defaultConfig,
				...inputConfiguration
			};
		},
		isValid(input) {
			const result = this.validateNodeInput({
				inputValue: input.value,
				inputFormConfig: this.getFormConfig(input)
			});
			return Object.keys(result).every(key => result[key]);
		}
	}
};
</script>

<style lang="scss">
@import '../../style/colors';

.RunFlowModal {
	&-advanced {
		display: flex;
		align-items: center;

		&Icon {
			margin-left: 5px;
			font-size: 0.8em;
		}
	}

	.Modal-close {
		display: none;
	}

	.Modal-body {
		padding: 20px;

		h4 {
			font-size: 16px;
			text-align: center;
			margin: 0 auto 20px;
		}

		.InvalidFlow {
			color: red;
		}
	}

	.form-group {
		margin: 0 0 20px;
	}
}

.NodeField {
	&.is-invalid {
		.form-control,
		.vue-input-tag-wrapper {
			border-bottom: 2px solid $danger-color !important;
		}
	}
}

.RunFlowModal-section-title {
	color: #0096d6;
	font-size: 16px;
	padding: 5px 0px;
	margin: 0px 0px 10px;
}
</style>
