export default {
	nodes: [
		{
			id: 0,
			displayName: 'Inputs',
			name: 'inputs',
			type: 'input',
			next: [1]
		},
		{
			id: 1,
			displayName: 'End',
			name: 'end',
			type: 'end',
			outputs: [],
			next: []
		}
	]
};
