import { render, staticRenderFns } from "./FlowsList.vue?vue&type=template&id=04a79aa1"
import script from "./FlowsList.vue?vue&type=script&lang=js"
export * from "./FlowsList.vue?vue&type=script&lang=js"
import style0 from "./FlowsList.vue?vue&type=style&index=0&id=04a79aa1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports