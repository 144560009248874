export const categories = [
	{
		name: 'web-templates',
		dataTestId: 'webTemplates',
		title: 'Web Templates'
	},
	{
		name: 'pre-press',
		dataTestId: 'prePress',
		title: 'Pre-press'
	},
	{
		name: 'siteflow-file-processing',
		dataTestId: 'siteFileProcessing',
		title: 'Siteflow File Processing'
	}
	/* {
        name: 'communications',
        title: 'Communications'
    } */
];

export const templates = [
	{
		title: 'Empty template',
		dataTestId: 'emptyTemplate',
		description: 'Create a flow from scratch',
		data: {
			nodes: [
				{
					id: 0,
					displayName: 'Inputs',
					name: 'inputs',
					type: 'input',
					next: [1]
				},
				{
					id: 1,
					displayName: 'End',
					name: 'end',
					type: 'end',
					outputs: [],
					next: []
				}
			]
		}
	},
	{
		title: 'Preflight and Post',
		dataTestId: 'preflightAndPost',
		description: 'Preflight a file and post the results to an endpoint',
		categoryName: 'web-templates',
		data: {
			nodes: [
				{
					displayName: 'Inputs',
					id: 0,
					inputs: [
						{
							key: 'My File',
							type: 'file',
							value: ''
						},
						{
							key: 'Barcode Value',
							type: 'string',
							value: ''
						},
						{
							key: 'Post URL',
							type: 'string',
							value: ''
						}
					],
					name: 'inputs',
					next: [1],
					type: 'input'
				},
				{
					displayName: 'Fetch File (URL)',
					status: 'live',
					id: 1,
					inputs: {
						url: {
							values: [
								{
									field: 'My File',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									type: 'ref'
								}
							]
						}
					},
					name: 'fetchfileurl-0',
					next: [2],
					taskName: 'fetchFileURL',
					type: 'task'
				},
				{
					displayName: 'PDF Preflight (Callas)',
					status: 'live',
					id: 2,
					inputs: {
						analyzeOnly: {
							values: [
								{
									type: 'custom',
									value: 'true'
								}
							]
						},
						profile: {
							values: [
								{
									type: 'custom',
									value: 'pdfx3'
								}
							]
						},
						reportTypes: {
							values: [
								{
									value: 'SUMMARY'
								}
							]
						},
						url: {
							values: [
								{
									field: 'url',
									nodeName: 'fetchfileurl-0',
									taskDisplayName: 'Fetch File (URL)',
									type: 'ref'
								}
							]
						}
					},
					name: 'pdfpreflightcallas-0',
					next: [3],
					taskName: 'pdfPreflightCallas',
					type: 'task'
				},
				{
					displayName: 'Add Barcode',
					status: 'live',
					id: 3,
					inputs: {
						background: {
							values: [
								{
									value: 'white'
								}
							]
						},
						foreground: {
							values: [
								{
									value: 'black'
								}
							]
						},
						height: {
							values: [
								{
									value: '10mm'
								}
							]
						},
						horizontalAnchor: {
							values: [
								{
									type: 'custom',
									value: 'center'
								}
							]
						},
						horizontalPadding: {
							values: [
								{
									value: '0mm'
								}
							]
						},
						pages: {
							values: [
								{
									value: '1'
								}
							]
						},
						rotation: {
							values: [
								{
									type: 'custom',
									value: 0
								}
							]
						},
						showText: {
							values: [
								{
									value: 'FALSE'
								}
							]
						},
						type: {
							values: [
								{
									type: 'custom',
									value: 'code128'
								}
							]
						},
						url: {
							values: [
								{
									field: 'url',
									nodeName: 'pdfpreflightcallas-0',
									taskDisplayName: 'PDF Preflight (Callas)',
									type: 'ref'
								}
							]
						},
						value: {
							values: [
								{
									field: 'Barcode Value',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									type: 'ref'
								}
							]
						},
						verticalAnchor: {
							values: [
								{
									value: 'top'
								}
							]
						},
						verticalPadding: {
							values: [
								{
									value: '0mm'
								}
							]
						},
						width: {
							values: [
								{
									value: '10mm'
								}
							]
						},
						x: {
							values: [
								{
									value: '0mm'
								}
							]
						},
						y: {
							values: [
								{
									value: '0mm'
								}
							]
						}
					},
					name: 'addbarcode-3',
					next: [4],
					taskName: 'addBarcode',
					type: 'task'
				},
				{
					displayName: 'HTTP Request',
					status: 'live',
					id: 4,
					inputs: {
						body: {
							values: [
								{
									field: 'reportUrl',
									nodeName: 'pdfpreflightcallas-0',
									taskDisplayName: 'PDF Preflight (Callas)',
									type: 'ref'
								}
							]
						},
						expectedStatusCodes: {
							values: [
								{
									value: '200'
								}
							]
						},
						method: {
							values: [
								{
									value: 'POST'
								}
							]
						},
						timeout: {
							values: [
								{
									value: '20000'
								}
							]
						},
						url: {
							values: [
								{
									field: 'Address',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									type: 'ref'
								}
							]
						}
					},
					name: 'httprequest-2',
					next: [5],
					taskName: 'httpRequest',
					type: 'task'
				},
				{
					displayName: 'End',
					id: 5,
					inputs: {},
					name: 'end',
					next: [],
					outputs: [],
					type: 'end'
				}
			]
		}
	},

	// Pre-press

	{
		title: 'Preflight Check',
		dataTestId: 'preflightCheck',
		description: 'Preflight a given file with checks only disabled',
		categoryName: 'pre-press',
		data: {
			nodes: [
				{
					id: 0,
					displayName: 'Inputs',
					name: 'inputs',
					type: 'input',
					next: [2],
					inputs: [
						{
							key: 'URL',
							value: '',
							type: 'file'
						}
					]
				},
				{
					id: 2,
					displayName: 'Fetch File (URL)',
					name: 'fetchfileurl-0',
					status: 'live',
					type: 'task',
					taskName: 'fetchFileURL',
					next: [3],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'URL'
								}
							]
						}
					}
				},
				{
					id: 3,
					displayName: 'PDF Preflight (Callas)',
					name: 'pdfpreflightcallas-0',
					status: 'live',
					type: 'task',
					taskName: 'pdfPreflightCallas',
					next: [4, 6, 8],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'fetchfileurl-0',
									taskDisplayName: 'Fetch File (URL)',
									field: 'url'
								}
							]
						},
						profile: {
							values: [
								{
									value: 'pdfx4'
								}
							]
						},
						reportTypes: {
							values: [
								{
									value: 'SUMMARY'
								}
							]
						},
						analyzeOnly: {
							values: [
								{
									value: false
								}
							]
						}
					}
				},
				{
					id: 4,
					displayName: 'Passed',
					name: 'condition-0',
					status: 'live',
					type: 'condition',
					taskName: 'condition',
					next: [5],
					inputs: {},
					condition: {
						enabled: true,
						logic: {
							and: [
								{
									'==': [
										{
											var: '$state.pdfpreflightcallas-0.result'
										},
										'passed'
									]
								}
							]
						}
					}
				},
				{
					id: 5,
					displayName: 'Send to FTP',
					name: 'sendtoftp-0',
					status: 'live',
					type: 'task',
					taskName: 'sendToFTP',
					next: [1],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'pdfpreflightcallas-0',
									taskDisplayName: 'PDF Preflight (Callas)',
									field: 'result'
								}
							]
						},
						folder: {
							values: []
						},
						serverAddress: {
							values: []
						},
						port: {
							values: []
						},
						username: {
							values: []
						},
						password: {
							values: []
						},
						protocol: {
							values: []
						}
					}
				},
				{
					id: 1,
					displayName: 'End',
					name: 'end',
					type: 'end',
					outputs: [],
					next: [],
					inputs: {}
				},
				{
					id: 6,
					displayName: 'Failed',
					name: 'condition-1',
					status: 'live',
					type: 'condition',
					taskName: 'condition',
					next: [7],
					inputs: {},
					condition: {
						enabled: true,
						logic: {
							and: [
								{
									'==': [
										{
											var: '$state.pdfpreflightcallas-0.result'
										},
										'failed'
									]
								}
							]
						}
					}
				},
				{
					id: 7,
					displayName: 'Send Email',
					name: 'sendemail-0',
					status: 'live',
					type: 'task',
					taskName: 'sendEmail',
					next: [1],
					inputs: {
						toEmail: {
							values: []
						},
						toName: {
							values: []
						},
						ccEmail: {
							values: []
						},
						ccName: {
							values: []
						},
						bccEmail: {
							values: []
						},
						bccName: {
							values: []
						},
						fromEmail: {
							values: []
						},
						fromName: {
							values: []
						},
						attachment: {
							values: [
								{
									type: 'ref',
									nodeName: 'pdfpreflightcallas-0',
									taskDisplayName: 'PDF Preflight (Callas)',
									field: 'result'
								}
							]
						},
						title: {
							values: []
						},
						bodyType: {
							values: [
								{
									value: 'text/plain'
								}
							]
						},
						bodyContent: {
							values: []
						}
					}
				},
				{
					id: 8,
					displayName: 'Warning',
					name: 'condition-2',
					status: 'live',
					type: 'condition',
					taskName: 'condition',
					next: [9],
					inputs: {},
					condition: {
						enabled: true,
						logic: {
							and: [
								{
									'==': [
										{
											var: '$state.pdfpreflightcallas-0.result'
										},
										'warning'
									]
								}
							]
						}
					}
				},
				{
					id: 9,
					displayName: 'Send to FTP',
					name: 'sendtoftp-1',
					status: 'live',
					type: 'task',
					taskName: 'sendToFTP',
					next: [1],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'pdfpreflightcallas-0',
									taskDisplayName: 'PDF Preflight (Callas)',
									field: 'result'
								}
							]
						},
						folder: {
							values: []
						},
						serverAddress: {
							values: []
						},
						port: {
							values: []
						},
						username: {
							values: []
						},
						password: {
							values: []
						},
						protocol: {
							values: []
						}
					}
				}
			]
		}
	},
	{
		title: 'EPM Check',
		dataTestId: 'epmCheck',
		description: 'EPM check a file and send to FTP',
		categoryName: 'pre-press',
		data: {
			nodes: [
				{
					id: 0,
					displayName: 'Inputs',
					name: 'inputs',
					type: 'input',
					next: [2],
					inputs: [
						{
							key: 'URL',
							value: '',
							type: 'file'
						}
					]
				},
				{
					id: 2,
					displayName: 'Fetch File (URL)',
					name: 'fetchfileurl-0',
					status: 'live',
					type: 'task',
					taskName: 'fetchFileURL',
					next: [3],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'URL'
								}
							]
						}
					}
				},
				{
					id: 3,
					displayName: 'EPM Check',
					name: 'epmcheck-0',
					status: 'live',
					type: 'task',
					taskName: 'epmCheck',
					next: [4],
					inputs: {
						blackMinSizeToQualifyMm: {
							values: [
								{
									value: 10
								}
							]
						},
						blackSmallestBlackLineMm: {
							values: [
								{
									value: 0.2
								}
							]
						},
						blacksmallestBlackTextPt: {
							values: [
								{
									value: 5
								}
							]
						},
						pagesMaxBlackPagesPercent: {
							values: [
								{
									value: null
								}
							]
						},
						deltaCMaxBlobSizeMm: {
							values: [
								{
									value: 20
								}
							]
						},
						deltaCThreshold: {
							values: [
								{
									value: 10
								}
							]
						},
						deltaEMaxBlobSizeMm: {
							values: [
								{
									value: 20
								}
							]
						},
						deltaEThreshold: {
							values: [
								{
									value: 200
								}
							]
						},
						spotColoursInkList: {
							values: [
								{
									value: ''
								}
							]
						},
						spotColoursMethod: {
							values: [
								{
									value: 'blacklist'
								}
							]
						},
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'fetchfileurl-0',
									taskDisplayName: 'Fetch File (URL)',
									field: 'url'
								}
							]
						},
						profileId: {
							values: [
								{
									value: 'standard'
								}
							]
						},
						disabledCheckPages: {
							values: [
								{
									value: true
								}
							]
						}
					}
				},
				{
					id: 4,
					displayName: 'Send to FTP',
					name: 'sendtoftp-0',
					status: 'live',
					type: 'task',
					taskName: 'sendToFTP',
					next: [1],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'epmcheck-0',
									taskDisplayName: 'EPM Check',
									field: 'result'
								}
							]
						},
						folder: {
							values: []
						},
						serverAddress: {
							values: []
						},
						port: {
							values: []
						},
						username: {
							values: []
						},
						password: {
							values: []
						},
						protocol: {
							values: []
						}
					}
				},
				{
					id: 1,
					displayName: 'End',
					name: 'end',
					type: 'end',
					outputs: [
						{
							key: 'url',
							value: {
								type: 'ref',
								nodeName: 'sendtoftp-0',
								taskDisplayName: 'Send to FTP',
								field: 'result'
							}
						}
					],
					next: [],
					inputs: {}
				}
			]
		}
	},
	{
		title: 'Spec Check',
		dataTestId: 'specCheck',
		description: 'Spec check a file with checks only disabled, email the results to a given email address',
		categoryName: 'pre-press',
		data: {
			nodes: [
				{
					id: 0,
					displayName: 'Inputs',
					name: 'inputs',
					type: 'input',
					next: [2],
					inputs: [
						{
							key: 'url',
							value: '',
							type: 'file'
						}
					]
				},
				{
					id: 2,
					displayName: 'Fetch File (URL)',
					name: 'fetchfileurl-0',
					status: 'live',
					type: 'task',
					taskName: 'fetchFileURL',
					next: [3],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'url'
								}
							]
						}
					}
				},
				{
					id: 3,
					displayName: 'PDF Spec Check',
					name: 'pdfspeccheck-0',
					status: 'live',
					type: 'task',
					taskName: 'pdfSpecCheck',
					next: [4],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'fetchfileurl-0',
									taskDisplayName: 'Fetch File (URL)',
									field: 'url'
								}
							]
						},
						checksOnly: {
							values: [
								{
									value: false
								}
							]
						},
						pageWidth: {
							values: [
								{
									type: 'custom',
									value: '220mm'
								}
							]
						},
						pageHeight: {
							values: [
								{
									type: 'custom',
									value: '300mm'
								}
							]
						},
						pageSizing: {
							values: [
								{
									value: 'resize'
								}
							]
						},
						pageRotation: {
							values: [
								{
									type: 'custom',
									value: 90
								}
							]
						},
						pageBleed: {
							values: [
								{
									type: 'custom',
									value: '10mm'
								}
							]
						},
						pageCountMethod: {
							values: [
								{
									type: 'custom',
									value: 'exact'
								}
							]
						},
						pageCountCount: {
							values: [
								{
									value: 2
								}
							]
						}
					}
				},
				{
					id: 4,
					displayName: 'Send Email',
					name: 'sendemail-0',
					status: 'live',
					type: 'task',
					taskName: 'sendEmail',
					next: [1],
					inputs: {
						toEmail: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						},
						toName: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						},
						ccEmail: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						},
						ccName: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						},
						bccEmail: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						},
						bccName: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						},
						fromEmail: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						},
						fromName: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						},
						attachment: {
							values: [
								{
									type: 'ref',
									nodeName: 'pdfspeccheck-0',
									taskDisplayName: 'PDF Spec Check',
									field: 'url'
								}
							]
						},
						title: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						},
						bodyType: {
							values: [
								{
									value: 'text/plain'
								}
							]
						},
						bodyContent: {
							values: [
								{
									type: 'custom',
									value: ''
								}
							]
						}
					}
				},
				{
					id: 1,
					displayName: 'End',
					name: 'end',
					type: 'end',
					outputs: [
						{
							key: 'url',
							value: {
								type: 'ref',
								nodeName: 'pdfspeccheck-0',
								taskDisplayName: 'PDF Spec Check',
								field: 'checkResult'
							}
						}
					],
					next: [],
					inputs: {}
				}
			]
		}
	},

	// Siteflow File Processing

	{
		title: 'Blank Component Artwork Flow',
		dataTestId: 'blankComponentArtwork',
		description: 'Create a component artwork flow from scratch',
		categoryName: 'siteflow-file-processing',
		type: 'component-artwork-flow',
		data: {
			nodes: [
				{
					id: 0,
					displayName: 'Inputs',
					name: 'inputs',
					type: 'input',
					next: [1]
				},
				{
					id: 1,
					displayName: 'End',
					name: 'end',
					type: 'end',
					outputs: [
						{
							key: 'url',
							value: {
								type: 'custom',
								field: 'url'
							}
						}
					],
					next: []
				}
			]
		}
	},
	{
		title: 'Merge Images',
		dataTestId: 'mergeImages',
		description: 'Take two images, merge them and create a PDF from the result',
		categoryName: 'siteflow-file-processing',
		data: {
			nodes: [
				{
					id: 0,
					displayName: 'Inputs',
					name: 'inputs',
					type: 'input',
					next: [3],
					inputs: [
						{
							key: 'url',
							value: '',
							type: 'file'
						},
						{
							key: 'attributes.otherImage',
							value: '',
							type: 'file'
						}
					]
				},
				{
					id: 3,
					displayName: 'PDF Merge',
					name: 'pdfmerge-0',
					status: 'live',
					type: 'task',
					taskName: 'pdfMerge',
					next: [1],
					inputs: {
						url1: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'url'
								}
							]
						},
						url2: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'attributes.otherImage'
								}
							]
						}
					}
				},
				{
					id: 1,
					displayName: 'End',
					name: 'end',
					type: 'end',
					outputs: [
						{
							key: 'url',
							value: {
								type: 'ref',
								nodeName: 'pdfmerge-0',
								taskDisplayName: 'PDF Merge',
								field: 'url'
							}
						}
					],
					next: [],
					inputs: {}
				}
			]
		}
	},
	{
		title: 'PDF Manipulate',
		dataTestId: 'pdfManipulate',
		description: 'Receive a PDF, rotate all the pages by 90 degrees clockwise and pad to a multiple of 4',
		categoryName: 'siteflow-file-processing',
		data: {
			nodes: [
				{
					id: 0,
					displayName: 'Inputs',
					name: 'inputs',
					type: 'input',
					next: [5],
					inputs: [
						{
							key: 'url',
							value: '',
							type: 'file'
						}
					]
				},
				{
					id: 5,
					displayName: 'Rotate',
					name: 'rotate-0',
					status: 'live',
					type: 'task',
					taskName: 'rotate',
					next: [8],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'url'
								}
							]
						},
						pages: {
							values: [
								{
									value: '*'
								}
							]
						},
						rotation: {
							values: [
								{
									type: 'custom',
									value: 90
								}
							]
						}
					}
				},
				{
					id: 8,
					displayName: 'Pad Pages',
					name: 'padpages-0',
					status: 'live',
					type: 'task',
					taskName: 'padPages',
					next: [1],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'rotate-0',
									taskDisplayName: 'Rotate',
									field: 'url'
								}
							]
						},
						multiple: {
							values: [
								{
									type: 'custom',
									value: 4
								}
							]
						}
					}
				},
				{
					id: 1,
					displayName: 'End',
					name: 'end',
					type: 'end',
					outputs: [
						{
							key: 'url',
							value: {
								type: 'ref',
								nodeName: 'padpages-0',
								taskDisplayName: 'Pad Pages',
								field: 'url'
							}
						}
					],
					next: [],
					inputs: {}
				}
			]
		}
	},
	{
		title: 'Advanced Barcodes',
		dataTestId: 'advancedBarcodes',
		description: 'Apply different barcodes, based on the value of a product SKU',
		categoryName: 'siteflow-file-processing',
		data: {
			nodes: [
				{
					id: 0,
					displayName: 'Inputs',
					name: 'inputs',
					type: 'input',
					next: [7, 8],
					inputs: [
						{
							key: 'url',
							value: '',
							type: 'file',
							form: {
								key: 'url',
								type: 'file',
								description: 'The URL of the file to fetch',
								displayName: 'URL',
								validation: {
									required: true,
									match:
										'^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?',
									hint: 'Enter a web address, with http:// and a domain',
									example: 'http://www.mywebaddress.com/pdf.pdf'
								}
							}
						},
						{ key: 'itemSku', value: '', type: 'string' },
						{
							key: 'Barcode 1',
							value: '',
							type: 'string',
							form: {
								key: 'value',
								type: 'text',
								description: 'The value that the barcode should hold',
								displayName: 'Barcode Value',
								validation: { required: true }
							}
						},
						{ key: 'Barcode 2', value: '', type: 'string' }
					]
				},
				{
					id: 7,
					displayName: 'itemSku === itemSku1',
					name: 'condition-0',
					status: 'live',
					type: 'condition',
					taskName: 'condition',
					next: [4],
					inputs: {},
					condition: {
						enabled: false,
						logic: {
							or: [
								{
									'==': [{ var: '$state.inputs.itemSku' }, 'itemSku1']
								}
							]
						}
					}
				},
				{
					id: 4,
					displayName: 'Add Barcode 2',
					name: 'addbarcode-0',
					status: 'live',
					type: 'task',
					taskName: 'addBarcode',
					next: [9],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'url'
								}
							]
						},
						type: { values: [{ value: 'datamatrix' }] },
						value: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'Barcode 1'
								}
							]
						},
						pages: { values: [{ value: '1' }] },
						x: { values: [{ value: '0mm' }] },
						y: { values: [{ value: '0mm' }] },
						width: { values: [{ value: '10mm' }] },
						height: { values: [{ value: '10mm' }] },
						rotation: { values: [{ type: 'custom', value: 90 }] },
						showText: { values: [{ value: 'FALSE' }] },
						horizontalPadding: { values: [{ value: '0mm' }] },
						verticalPadding: { values: [{ value: '0mm' }] },
						horizontalAnchor: { values: [{ value: 'left' }] },
						verticalAnchor: { values: [{ value: 'top' }] },
						foreground: { values: [{ value: 'black' }] },
						background: { values: [{ value: 'white' }] }
					}
				},
				{
					id: 9,
					displayName: 'Fetch File (URL)',
					name: 'fetchfileurl-0',
					status: 'live',
					type: 'task',
					taskName: 'fetchFileURL',
					next: [1],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'addbarcode-0',
									taskDisplayName: 'Add Barcode',
									field: 'url'
								},
								{
									type: 'ref',
									nodeName: 'addbarcode-1',
									taskDisplayName: 'Add Barcode',
									field: 'url'
								}
							]
						}
					}
				},
				{
					id: 1,
					displayName: 'End',
					name: 'end',
					type: 'end',
					outputs: [
						{
							key: 'url',
							value: {
								type: 'ref',
								nodeName: 'fetchfileurl-0',
								taskDisplayName: 'Fetch File (URL)',
								field: 'url'
							}
						}
					],
					next: [],
					inputs: {},
					taskName: '__vue_devtool_undefined__'
				},
				{
					id: 8,
					displayName: 'itemSku === itemSku2',
					name: 'condition-1',
					status: 'live',
					type: 'condition',
					taskName: 'condition',
					next: [5],
					inputs: {},
					condition: {
						enabled: false,
						logic: {
							and: [
								{
									'==': [{ var: '$state.inputs.itemSku' }, 'itemSku 2']
								}
							]
						}
					}
				},
				{
					id: 5,
					displayName: 'Add Barcode 1',
					name: 'addbarcode-1',
					status: 'live',
					type: 'task',
					taskName: 'addBarcode',
					next: [9],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'url'
								}
							]
						},
						type: { values: [{ value: 'datamatrix' }] },
						value: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'Barcode 2'
								}
							]
						},
						pages: { values: [{ value: '1' }] },
						x: { values: [{ value: '0mm' }] },
						y: { values: [{ value: '0mm' }] },
						width: { values: [{ value: '10mm' }] },
						height: { values: [{ value: '10mm' }] },
						rotation: { values: [{ value: '0' }] },
						showText: { values: [{ value: 'FALSE' }] },
						horizontalPadding: { values: [{ value: '0mm' }] },
						verticalPadding: { values: [{ value: '0mm' }] },
						horizontalAnchor: { values: [{ value: 'left' }] },
						verticalAnchor: { values: [{ value: 'top' }] },
						foreground: { values: [{ value: 'black' }] },
						background: { values: [{ value: 'white' }] }
					}
				}
			]
		}
	},
	{
		title: 'Normalise',
		dataTestId: 'normalise',
		description: 'Spec check a file to achieve a given spec',
		categoryName: 'siteflow-file-processing',
		data: {
			nodes: [
				{
					id: 0,
					displayName: 'Inputs',
					name: 'inputs',
					type: 'input',
					next: [2],
					inputs: [
						{
							key: 'url',
							value: '',
							type: 'file'
						}
					]
				},
				{
					id: 2,
					displayName: 'Fetch File (URL)',
					name: 'fetchfileurl-0',
					status: 'live',
					type: 'task',
					taskName: 'fetchFileURL',
					next: [3],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'inputs',
									taskDisplayName: 'Inputs',
									field: 'url'
								}
							]
						}
					}
				},
				{
					id: 3,
					displayName: 'PDF Spec Check',
					name: 'pdfspeccheck-0',
					status: 'live',
					type: 'task',
					taskName: 'pdfSpecCheck',
					next: [1],
					inputs: {
						url: {
							values: [
								{
									type: 'ref',
									nodeName: 'fetchfileurl-0',
									taskDisplayName: 'Fetch File (URL)',
									field: 'url'
								}
							]
						},
						checksOnly: {
							values: [
								{
									value: false
								}
							]
						},
						pageWidth: {
							values: [
								{
									value: '210mm'
								}
							]
						},
						pageHeight: {
							values: [
								{
									value: '297mm'
								}
							]
						},
						pageSizing: {
							values: [
								{
									value: 'resize'
								}
							]
						},
						pageRotation: {
							values: [
								{
									value: 0
								}
							]
						},
						pageBleed: {
							values: [
								{
									value: '3mm'
								}
							]
						},
						pageCountMethod: {
							values: [
								{
									value: 'multiple'
								}
							]
						},
						pageCountCount: {
							values: [
								{
									value: 2
								}
							]
						}
					}
				},
				{
					id: 1,
					displayName: 'End',
					name: 'end',
					type: 'end',
					outputs: [
						{
							key: 'url',
							value: {
								type: 'ref',
								nodeName: 'pdfspeccheck-0',
								taskDisplayName: 'PDF Spec Check',
								field: 'checkResult'
							}
						}
					],
					next: [],
					inputs: {}
				}
			]
		}
	}

	// Communications

	// {
	//     title: 'Http Post File Details',
	//     description: '',
	//     categoryName: 'communications',
	//     data: {}
	// },
	// {
	//     title: 'Email File Details',
	//     description: '',
	//     categoryName: 'communications',
	//     data: {}
	// }
];
