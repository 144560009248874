<template>
	<span>
		<select
			class="form-control"
			:value="value"
			:disabled="config.readOnly"
			:data-test-id="config.key + 'Input'"
			@change="handleChange"
		>
			<option :value="getTrueValue()">{{ $t('True') }}</option>
			<option :value="getFalseValue()">{{ $t('False') }}</option>
		</select>
	</span>
</template>

<script type="text/javascript">
import _get from 'lodash/get';
import { uuid } from '../../../lib/flowManipulationUtils';

export default {
	props: ['value', 'config', 'onChange'],
	data() {
		return {
			id: null
		};
	},
	created() {
		this.id = uuid();
	},
	methods: {
		handleChange(event) {
			const value = event.currentTarget.value;

			this.onChange && this.onChange(value);
		},
		getTrueValue() {
			return _get(this, 'config.trueValue', 'true');
		},
		getFalseValue() {
			return _get(this, 'config.falseValue', 'false');
		},
		isChecked() {
			return this.getTrueValue() === this.value;
		}
	}
};
</script>
<style lang="scss">
@import '../../../style/colors';

$width: 50px;
$height: 23px;
$border-radius: $height;
$inner-side-offset: 2px;
$circle-side-offset: $inner-side-offset * 2;
$circle-width: $height - $circle-side-offset;
div.switch {
	label {
		margin: 0;
	}
}

.cmn-toggle + label {
	display: block;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.cmn-toggle {
	position: absolute;
	margin-left: -9999px;
	visibility: hidden;
}

input.cmn-toggle-round-flat + label {
	padding: 2px;
	width: $width;
	height: $height;
	background-color: #dddddd;
	border-radius: $border-radius;
	transition: background 0.4s;
}

input.cmn-toggle-round-flat + label:before,
input.cmn-toggle-round-flat + label:after {
	display: block;
	position: absolute;
	content: '';
}

input.cmn-toggle-round-flat + label:before {
	top: $inner-side-offset;
	left: $inner-side-offset;
	bottom: $inner-side-offset;
	right: $inner-side-offset;
	background-color: #fff;
	border-radius: $border-radius;
	transition: background 0.4s;
}

input.cmn-toggle-round-flat + label:after {
	top: $circle-side-offset;
	left: $circle-side-offset;
	bottom: $circle-side-offset;
	width: $circle-width;
	background-color: #dddddd;
	border-radius: $border-radius;
	transition: left 0.4s, background 0.4s;
}

input.cmn-toggle-round-flat:checked + label {
	background-color: $primary-color;
}

input.cmn-toggle-round-flat:checked + label:after {
	left: calc(#{$width} - #{$circle-side-offset} - #{$circle-width});
	background-color: $primary-color;
}
</style>
