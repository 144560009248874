<template>
	<Modal class="RenameFlowModal" :show="show" size="small">
		<h2 slot="header">
			Save Profile
		</h2>

		<div slot="body" class="container-fluid">
			<div class="form-group md-group">
				<label for="profile-name">Name:</label>
				<input v-model="newName" type="text" name="profile-name" class="form-control" />
			</div>
		</div>

		<div slot="footer">
			<button class="button" @click="handleCancel">Cancel</button>
			<button
				v-if="!this.isOverwrite"
				class="button button--success"
				:disabled="!isFormValid"
				@click="handleSave(false)"
			>
				Save
			</button>
			<button
				v-else
				class="button button--danger"
				:disabled="!isFormValid || this.defaultProfiles.includes(this.newName)"
				@click="handleSave(true)"
			>
				Overwrite {{ this.newName }}
			</button>
		</div>
	</Modal>
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import Modal from './../../../components/modal';

export default {
	props: ['show', 'onConfirm', 'onUpdate', 'onClose'],
	data() {
		return {
			newName: '',
			isOverwrite: false,
			defaultProfiles: ['tolerant', 'standard', 'strict']
		};
	},
	computed: {
		...mapGetters({
			epmProfiles: 'epmProfiles'
		}),
		isFormValid() {
			return this.newName.length > 0;
		}
	},
	watch: {
		newName() {
			const names = this.epmProfiles.map(p => p.name);
			if (names.includes(this.newName)) {
				this.isOverwrite = true;
			} else {
				this.isOverwrite = false;
			}
		}
	},
	methods: {
		...mapActions({
			displayToast: 'displayToast'
		}),
		async handleSave(isUpdate) {
			const type = isUpdate ? 'updated' : 'created';
			this.onConfirm && (await this.onConfirm(this.newName, isUpdate));
			await this.displayToast({ data: { title: `Profile: ${this.newName} has been ${type}` } });
			this.clearVals();
		},
		clearVals() {
			this.newName = '';
			this.isOverwrite = false;
		},
		handleCancel() {
			this.clearVals();
			this.onClose();
		}
	},
	components: {
		Modal
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.RenameFlowModal {
	.Modal-close {
		display: none;
	}
}
</style>
