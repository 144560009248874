<template>
	<DefaultLayout>
		Dashboard
	</DefaultLayout>
</template>

<script type="text/javascript">
import DefaultLayout from '../../components/DefaultLayout';

export default {
	components: { DefaultLayout }
};
</script>
