<template>
	<DefaultLayout>
		<section class="WorkqueueView">
			<div class="WorkqueueView-wrapper">
				<div class="WorkqueueView-chart">
					<ChartDisplay
						v-if="flow"
						:flow="flow"
						:chart-options="{
							showNodeDeleteBtn: false,
							showWorkqueueProgress: true,
							jsPlumbPaintTimeout: 0,
							redrawJSPlumb: false,
							showInvalidNodes: false
						}"
						:workqueue="workqueue"
						:on-node-click="handleNodeClick"
						:on-canvas-click="handleCanvasClick"
						:selected-node="selectedNode"
					/>
				</div>
				<div class="WorkqueueView-right d-none d-md-block">
					<RightPanel>
						<FlowInfoPanel
							v-if="!selectedNode"
							:flow="flow"
							:workqueue="workqueue"
							:tasks-hash="tasksHash"
						/>
						<NodeInfoPanel
							v-if="selectedNode"
							:node="selectedNode"
							:task-data="selectedNodeTaskData"
							:workqueue="workqueue"
							:tasks-hash="tasksHash"
						/>
					</RightPanel>
				</div>
				<div v-if="!selectedNode && !isOpenedMobilePanel" class="WorkqueueView-bottom d-md-none">
					<div class="InfoPanel">
						<div class="WorkqueueView-right-top">
							<h2>{{ workqueue && workqueue.flow && workqueue.flow.name }}</h2>
							<button class="button button--success button--big" @click="showMore(true)">
								{{ $t('Show more') }}
							</button>
						</div>
						<div class="WorkqueueView-right-body">
							<FlowInfoPanelHeader :workqueue="workqueue"></FlowInfoPanelHeader>
						</div>
					</div>
				</div>
				<div v-if="isOpenedMobilePanel || selectedNode" class="WorkqueueView-mobile d-md-none">
					<RightPanel>
						<FlowInfoPanel
							v-if="!selectedNode"
							:flow="flow"
							:workqueue="workqueue"
							:tasks-hash="tasksHash"
							:show-more="showMore"
						/>
						<NodeInfoPanel
							v-if="selectedNode"
							:node="selectedNode"
							:task-data="selectedNodeTaskData"
							:workqueue="workqueue"
							:tasks-hash="tasksHash"
							:hide-modal="hideSelectedNode"
						/>
					</RightPanel>
				</div>
			</div>
		</section>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import DefaultLayout from '../../../components/DefaultLayout.vue';
import ChartDisplay from '../../../components/Chart/Chart.vue';
import RightPanel from '../../../components/RightPanel.vue';
import InputRenderer from '../../../components/FormGenerator/InputRenderer.vue';
import AdvancedInputPreview from '../../../components/FormGenerator/AdvancedInputPreview.vue';
import FlowInfoPanel from './FlowInfoPanel.vue';
import FlowInfoPanelHeader from './FlowInforPanelHeader.vue';
import NodeInfoPanel from './NodeInfoPanel.vue';

export default {
	data() {
		return {
			id: this.$route.params.id,
			isOpenedMobilePanel: false,
			subscriptions: []
		};
	},
	computed: {
		...mapGetters(['workqueue', 'layout', 'tasks', 'selectedNodeName']),

		selectedNode() {
			const nodes = _get(this, 'flow.nodes', []);
			return find(nodes, { name: this.selectedNodeName });
		},
		selectedNodeTaskData() {
			if (!this.selectedNodeName) return {};
			let taskData = null;
			taskData = _get(this, 'workqueue.tasks', []).find(t => t.name === this.selectedNodeName);
			if (!taskData) {
				taskData = this.selectedNode;
			}
			return taskData;
		},
		flow() {
			return this.workqueue && this.workqueue.flow;
		},
		tasksHash() {
			return keyBy(this.tasks, 'name');
		}
	},
	async created() {
		this.loadWorkqueue();
		this.selectNode();
	},
	destroyed() {
		this.subscriptions.forEach(s => s.unsubscribe());
	},
	methods: {
		...mapActions(['observeWorkqueue', 'findTasks', 'openRunFlowModal', 'findFlow', 'selectNode']),
		async loadWorkqueue() {
			const { subscription, observable } = await this.observeWorkqueue({ id: this.id });
			this.subscriptions.push(subscription);
			this.subscriptions.push(
				observable.subscribe(async () => {
					this.loadTasks();
				})
			);
		},
		loadTasks() {
			if (!this.flow || !this.flow.nodes) return;
			const taskNames = this.flow.nodes
				.filter(node => !['input', 'end'].includes(node.type))
				.map(node => node.taskName || node.task.name);
			this.findTasks({ query: { filter: { where: { name: { inq: taskNames } } } } });
		},
		handleNodeClick(name) {
			if (['inputs', 'end'].includes(name)) return false;
			if (name === this.selectedNodeName) return this.selectNode();
			return this.selectNode({ name });
		},
		handleCanvasClick() {
			this.selectNode();
		}, //
		reRun() {
			if (!this.flow) return;
			const runConfiguration = {
				inputs: cloneDeep(this.flow.nodes[0].inputs)
			};
			this.openRunFlowModal({
				runConfiguration,
				flow: this.flow
			});
		},
		showMore(value) {
			this.isOpenedMobilePanel = value;
		},
		hideSelectedNode() {
			this.selectNode();
		}
	},
	watch: {
		$route(to, from) {
			if (to.path.includes('/activity/view')) {
				this.id = to.params.id;
				this.loadWorkqueue();
				this.selectNode();
			}
		}
	},
	components: {
		DefaultLayout,
		ChartDisplay,
		RightPanel,
		InputRenderer,
		AdvancedInputPreview,
		FlowInfoPanel,
		FlowInfoPanelHeader,
		NodeInfoPanel
	}
};
</script>

<style src="./WorkqueueView.scss" lang="scss" />
