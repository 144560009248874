<template>
	<span>
		<input type="hidden" :value="config.value" />
	</span>
</template>
<script type="text/javascript">
export default {
	props: ['onChange', 'config'],
	async mounted() {
		await this.$nextTick();
		this.onChange && this.onChange(this.config.value);
	}
};
</script>
