<template>
	<div class="InfoPanel">
		<div class="WorkqueueView-right-top">
			<h2>{{ node.displayName }}</h2>
			<button v-if="hideModal" class="button button--success button--big" @click="hideModal(false)">
				{{ $t('Hide') }}
			</button>
			<!--<div v-if="taskData"
                 class="WorkqueueView-right-top-status"
                 :class="`WorkqueueView-right-top-status&#45;&#45;${taskData.status}`">
                <span>{{taskData.status}}</span>
            </div>-->
		</div>
		<div class="WorkqueueView-right-body">
			<div
				v-if="taskData.status"
				class="InfoPanel-progressList is-horizontal"
				:class="`InfoPanel-progressList--${taskData.status}`"
			>
				<ul>
					<li v-if="isNotStarted">
						<ProgressIcon status="queued" />
						<div class="TaskStatusLabel">{{ $t('No started') }}</div>
					</li>
					<li v-if="isStarted">
						<ProgressIcon status="started" />
						<div class="TaskStatusLabel">{{ $t('Started') }}</div>
					</li>
					<li v-if="isEnded">
						<ProgressIcon status="ended" />
						<div class="TaskStatusLabel">{{ $t('Completed') }}</div>
					</li>
					<li v-if="isFailed">
						<ProgressIcon status="failed" />
						<div class="TaskStatusLabel">{{ $t('Failed') }}</div>
					</li>
				</ul>
			</div>
			<div class="InfoPanel-details">
				<div v-if="taskData.started" class="InfoPanel-details-row">
					<div>
						<div class="InfoPanel-details-title">Start Time</div>
						<div class="InfoPanel-details-content">
							{{ taskData.started | moment('HH:mm:ss') }}
						</div>
					</div>
					<div>
						<div class="InfoPanel-details-title">End Time</div>
						<div v-if="taskData.ended" class="InfoPanel-details-content">
							{{ taskData.ended | moment('HH:mm:ss') }}
						</div>
					</div>
					<div v-if="duration">
						<div>
							<div class="InfoPanel-details-title">
								{{ $t('Duration') }}
							</div>
							<div class="InfoPanel-details-content">
								<span v-if="duration.minutes">
									{{ duration.minutes }} <span class="small">{{ $t('min') }}</span>
								</span>
								{{ duration.seconds }} <span class="small">{{ $t('sec') }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ConditionConfiguration
				v-if="!taskData.status"
				v-bind="{
					node: taskData,
					flow: workqueue.flow,
					tasks,
					selectConditionRuleInput: null,
					selectedConditionRuleInput: null,
					updateCondition: null,
					readOnly: true
				}"
			/>

			<div v-if="outputs" class="WorkqueueView-accordion">
				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" role="tab">
						<div v-b-toggle.accordion2>{{ $t('Outputs') }}</div>
					</b-card-header>
					<b-collapse id="accordion2" accordion="my-accordion" role="tabpanel">
						<b-card-body class="WorkqueueView-accordion-inputsOutputs">
							<div v-for="(output, outputName) in nodeOutputs" class="md-group form-group">
								<label>{{ outputName }}</label>
								<div v-if="output.config.type !== 'file'" class="InfoPanel-outputRow is-text clearfix">
									<div>{{ output.value }}</div>
								</div>
								<div
									v-for="outputFile in output.value"
									v-if="output.config.type === 'file'"
									class="InfoPanel-outputRow is-file clearfix"
								>
									<div>{{ $t('Download') }} {{ outputFile.filePath || outputName }}</div>
									<a class="u-cursorPointer pull-right" :href="outputFile.url" target="_blank">
										<i class="ion-ios-download-outline"></i>
									</a>
								</div>
							</div>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>

			<div v-if="!outputs && taskData.status" class="WorkqueueView-right-empty">
				{{ $t('No information available') }}
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import reduce from 'lodash/reduce';
import padStart from 'lodash/padStart';
import moment from 'moment';
import ProgressIcon from '../../../components/ProgressIcon.vue';
import ProgressBar from '../../../components/ProgressBar.vue';
import ConditionConfiguration from '../../Flow/edit/ConditionConfiguration.vue';

export default {
	props: {
		node: {
			default: {}
		},
		taskData: {
			default: {}
		},
		workqueue: {
			default: {}
		},
		tasksHash: {
			default: {}
		},
		hideModal: {
			default: null
		}
	},
	computed: {
		...mapGetters(['tasks']),
		task() {
			if (!this.node || !this.tasksHash) return {};
			const taskName = this.node.taskName || (this.node.task && this.node.task.name);
			return this.tasksHash[taskName];
		},
		outputs() {
			const state = _get(this, `workqueue.data.state.${this.node.name}`, null);
			return state;
		},
		nodeOutputs() {
			return reduce(
				this.outputs,
				(outputsAcc, oValue, oKey) => {
					const oName = _get(this, `task.outputs.${oKey}.displayName`, oKey);
					const oConfig = _get(this, `task.outputs.${oKey}`, {});
					outputsAcc[oName] = {
						config: oConfig,
						value: typeof oValue === 'string' ? [{ url: oValue }] : oValue
					};
					return outputsAcc;
				},
				{}
			);
		},
		isNotStarted() {
			return this.taskData.status === 'notstarted';
		},
		isQueued() {
			return this.taskData.status === 'queued';
		},
		isStarted() {
			return this.taskData.status === 'started';
		},
		isEnded() {
			return this.taskData.status === 'ended';
		},
		isFailed() {
			return this.taskData.status === 'failed';
		},
		isSkipped() {
			return this.taskData.status === 'skipped';
		},
		duration() {
			const started = _get(this, 'taskData.started');
			const updated = _get(this, 'taskData.ended');
			if (!started || !updated) return null;

			const diffTime = moment.duration(moment(updated).diff(started));
			let minutes;
			const duration = {
				minutes: (minutes = padStart(diffTime.minutes(), 2, '0')) === '00' ? false : minutes,
				seconds: padStart(diffTime.seconds(), 2, '0')
			};
			return duration;
		}
	},
	data() {
		return {
			isAccordionOpen: {
				inputs: false,
				outputs: true
			},
			condition: null,
			logicalOperator: null,
			rules: null
		};
	},
	components: {
		ProgressIcon,
		ProgressBar,
		ConditionConfiguration
	},
	methods: {
		handleAccordionClick(key) {
			this.isAccordionOpen[key] = !this.isAccordionOpen[key];
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';

.InfoPanel {
	.InfoPanel-progressList {
		padding: 20px 25px 20px;
		background: $gray-border-color;
		.TaskDataIcon {
			i {
				background: $gray-border-color;
			}
		}

		&.InfoPanel-progressList--notstarted,
		&.InfoPanel-progressList--queued {
			background: $gray-border-color;
			.TaskDataIcon {
				i {
					background: $gray-border-color;
				}
			}
		}
		&.InfoPanel-progressList--started,
		&.InfoPanel-progressList--ended {
			background: #008ad3;
			.TaskDataIcon {
				i {
					background: #008ad3;
				}
			}
		}

		&.InfoPanel-progressList--failed {
			background: $danger-background-color;
			.TaskDataIcon {
				i {
					background: $danger-background-color;
				}
			}
		}
	}

	&-outputRow {
		border-bottom: 1px solid $gray-border-color;
		display: flex;
		> div {
			flex-grow: 1;
			width: 100%;
		}
		a {
			flex-shrink: 1;
			font-size: 25px;
			line-height: 1;
			margin: 0;

			i {
				line-height: 1;
			}
		}
	}
}
</style>
