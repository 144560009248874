import remove from 'lodash/remove';

export const DISPLAY_TOAST = (state, toastData) => {
	state.toasts = [...state.toasts, toastData];
};

export const HIDE_TOAST = (state, id) => {
	remove(state.toasts, { id });
	state.toasts = [...state.toasts];
};
