<template>
	<div class="SquaredBox">
		<div class="SquaredBox-wrapper">
			<div class="SquaredBox-wrapper-inner">
				<div class="SquaredBox-img">
					<img src="../../public/img/autoflow-logo.svg" alt="Worker Icon" />
				</div>
				<div class="SquaredBox-label">{{ title }}</div>
			</div>

			<div v-if="actions && actions.length" class="SquaredBox-actionOverlay">
				<ul class="SquaredBox-actionList">
					<li v-for="action in actions">
						<a :href="action.href && action.href()" @click="action.onClick">
							<span v-html="action.title"></span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import _get from 'lodash/get';
import './BoxesView.scss';

export default {
	props: ['title', 'link', 'actions'],
	methods: {
		getTitle(item) {
			const titleField = _get(this, 'config.titleField') ? _get(this, 'config.titleField') : 'name';
			return _get(item, titleField);
		},
		getLink(item) {
			const baseLink = _get(this, 'config.baseLink');
			if (!baseLink) {
				return false;
			}
			return `${baseLink}/${item.id}`;
		},

		getActions(item) {
			return _get(this, 'config.getActions', () => [])(item);
		}
	}
};
</script>
