<template>
	<section class="NodeField NodeField--output">
		<div class="form-group md-group">
			<div class="NodeField-top">
				<label>
					{{ label }}
				</label>
			</div>

			<div class="NodeField-input form-control" :data-linked-to="linkedTo" :data-output-key="outputKey">
				<div class="NodeField-input-leftIcon">
					<i class="ion-pinpoint"></i>
				</div>
				<div class="NodeField-input-text">
					{{ value }}
				</div>
				<!--<div class="NodeField-input-rightIcon" v-if="isFile">
                    <i class="ion-document"></i>
                </div>-->
			</div>

			<!--<div class="NodeField-input-helpText">
                <tooltip effect="scale" placement="right" :content="helpText">
                    <i class="ion-ios-help-outline"></i>
                </tooltip>
            </div>-->
		</div>
	</section>
</template>
<script type="text/javascript">
export default {
	components: {
		/*    tooltip */
	},
	props: {
		label: null,
		value: null,
		outputKey: null,
		linkedTo: null,
		readOnly: false
	}
};
</script>

<style lang="scss">
@import '../../../style/colors';
@import '../../../style/variables';

.NodeField--output {
	.NodeField-input.form-control {
		border-bottom: 2px solid #bfbfbf !important;
	}
	.NodeField-input .NodeField-input-leftIcon {
		margin-right: 10px;
	}
}
</style>
